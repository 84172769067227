import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import CloseIcon from '@material-ui/icons/Close';
import YouTubeIcon from '@material-ui/icons/YouTube';
import styleVariables from '@Styles/_variables.module.scss';
import { VideoTextField } from '@Components';
import styles from './ProjectUpdateVideoUpload.module.scss';

interface ProjectUpdateVideoUploadProps {
  isVideoActive: boolean;
  handleOpenVideo: () => void;
  handleCloseVideo: () => void;
}

export const ProjectUpdateVideoUpload: React.FC<ProjectUpdateVideoUploadProps> = ({
  handleOpenVideo,
  handleCloseVideo,
  isVideoActive,
}) => {
  const {
    watch,
    formState: { errors },
  } = useFormContext();
  const { t } = useTranslation('projectUpdate');
  const videoFieldValue = watch('video_url', '');

  return (
    <>
      {!isVideoActive && (
        <button className={styles.container} type="button" onClick={handleOpenVideo}>
          <div className={styles.iconWrapper}>
            <YouTubeIcon style={{ color: styleVariables.colorP2 }} />
          </div>

          <div className={styles.content}>
            <span className={styles.title}>{t('youtube_video')}</span>
            <span className={styles.hint}>{t('youtube_hint')}</span>
          </div>
        </button>
      )}
      {isVideoActive && (
        <div className={styles.videoWrapper}>
          <VideoTextField handleCloseVideo={handleCloseVideo} field="video_url" />
          {(videoFieldValue === '' || (Object.keys(errors) && errors.video_url !== undefined)) && (
            <>
              <button onClick={handleCloseVideo} className={styles.closeVideoInput} type="button">
                <span>{t('common:close')}</span>
                <CloseIcon />
              </button>
              <p className={styles.inputHint}>{t('youtube_hint')}</p>
            </>
          )}
        </div>
      )}
    </>
  );
};
