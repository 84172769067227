import { Doughnut } from '@Components/Charts/Doughnut';
import { useTranslation } from 'react-i18next';
import { dataAndLabelFormatToHighchartsDoughnut } from '@Helpers/DataVisualisation/dataAndLabelFormatToHighchartsDoughnut';
import { formatSDG } from '@Helpers/DataVisualisation/formatSDG';
import { SDG } from '@Types';

export interface SDGsDoughnutProps {
  sdgs: SDG[];
}

export const SDGsDoughnut = ({ sdgs }: SDGsDoughnutProps) => {
  const { t } = useTranslation('common');
  const label = t('sdg_breakdown_by_projects');
  const data: { label: string; value: number }[] = [];
  sdgs.forEach((sdg) => {
    data.push({
      label: formatSDG(sdg),
      value: sdg.value,
    });
  });

  const {
    dataSet: focusAreaDistributionDataset,
    title: focusAreaDistributionTitle,
    displayCenteredTitle: focusAreaDistributionDisplayCenteredTitle,
  } = dataAndLabelFormatToHighchartsDoughnut({ label, data }, false);

  return (
    <Doughnut
      dataSet={focusAreaDistributionDataset}
      title={focusAreaDistributionTitle}
      displayCenteredTitle={focusAreaDistributionDisplayCenteredTitle}
    />
  );
};
