import { User } from '@Types';

// This value is used to provide a very basic defence against non-users
// accessing our help documentation.
// It isn't intended to be a genuinely secure solution, or for actually authenticating users.
const HELP_CENTER_TOKEN = '2gm6McSW7wrEjJT3';

interface GetHelpCenterURLParams {
  path: string;
  user: User;
}

export const getHelpCenterURL = ({ path, user }: GetHelpCenterURLParams) => {
  const url = new URL(`https://help.actionfunder.org${path}`);
  url.searchParams.append('af-token', HELP_CENTER_TOKEN);
  url.searchParams.append('af-username', user.organization_name);
  url.searchParams.append('af-org-profile-image-url', user.organization_avatar);
  url.searchParams.append('af-user-type', user.user_type);
  return url.toString();
};
