import React from 'react';
import classnames from 'classnames';
import styles from './TitledTile.module.scss';

interface TitledTileProps {
  row?: boolean;
  wrap?: boolean;
  title?: string;
}

export const TitledTile: React.FC<TitledTileProps> = ({ row, wrap, children, title }) => {
  return (
    <div className={classnames(styles.tile, row && styles.tileRow, wrap && styles.tileWrap)}>
      {title && <h2 className={styles.title}>{title}</h2>}
      <div className={styles.container}>{children}</div>
    </div>
  );
};
