import { Project } from '@Types';

export const projectFundingDistributionToDataAndLabelFormat = (projects: Project[]) => {
  const data = projects.map((projectData) => {
    return {
      label: projectData.details.name,
      value: Number(projectData.details.amount),
    };
  });

  const total = projects.reduce((acc, object) => {
    return acc + Number(object.details.amount);
  }, 0);

  const label = total.toString();

  return { label, data };
};
