import React, { useCallback, useEffect, useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { CreateActionButtons, Loader } from '@Components';
import { useRouter } from '@Helpers/useRouter';
import { RoutingPaths } from '@App/paths';
import { PitchTypesEnum, ProjectWrittenPitch, ProjectVideoPitch } from '@Types';
import { useSetFieldErrors } from '@Hooks';
import { useAddProjectWrittenPitch, useGetProjectPitch, useAddProjectVideoPitch } from '@Hooks/Api';
import { useNotificationsContext } from '@Contexts';

import { ProjectPitch } from './ProjectPitch';

interface ProjectPitchControllerProps {
  currentStep: number;
  stepsCount: number;
  projectId?: number;
  nextStep: () => void;
  prevStep: () => void;
  pitchType?: PitchTypesEnum;
  setPitchType: (pitchType: PitchTypesEnum) => void;
  setPitchFormData: (FormData: any) => void;
  pitchFormData?: ProjectVideoPitch | ProjectWrittenPitch;
}

export const ProjectPitchController: React.FC<ProjectPitchControllerProps> = ({
  currentStep,
  stepsCount,
  projectId,
  nextStep,
  prevStep,
  pitchType,
  setPitchType,
  pitchFormData,
  setPitchFormData,
}) => {
  const { t } = useTranslation('project');
  const { push } = useRouter();
  const methods = useForm();
  const { handleSubmit, reset, setError, formState, getValues } = methods;
  const [
    addProjectWrittenPitch,
    { loading: isAddProjectWrittenPitchLoading, error: addProjectWrittenPitchError },
  ] = useAddProjectWrittenPitch();
  const [
    addProjectVideoPitch,
    { loading: isAddProjectVideoPitchLoading, error: addProjectVideoPitchError },
  ] = useAddProjectVideoPitch();
  const [getProjectPitch, { data: projectPitchData, loading: isGetProjectPitchLoading }] =
    useGetProjectPitch();
  const [currentPitchType, setCurrentPitchType] = useState<PitchTypesEnum>();
  const { error: errorNotification, success: successNotification } = useNotificationsContext();

  const isFormSaving = isAddProjectWrittenPitchLoading || isAddProjectVideoPitchLoading;

  useEffect(() => {
    if (pitchType) {
      setCurrentPitchType(pitchType);
    }
  }, [pitchType]);

  useEffect(() => {
    if (pitchFormData) {
      reset(pitchFormData);
    } else if (projectId && currentPitchType) {
      getProjectPitch(projectId, currentPitchType)
        .then(({ data }) => {
          reset(data);
        })
        .catch(() => {
          /**
           * This function is used to fetch form data.
           * This catch prevents throwing an unhandled error and sending it to sentry,
           * if there is nothing to fetch (404)
           */
        });
    }
  }, [getProjectPitch, currentPitchType, projectId, reset, pitchFormData]);

  useEffect(() => {
    if (projectId && currentPitchType) {
      getProjectPitch(projectId, currentPitchType);
    }
  }, [getProjectPitch, currentPitchType, projectId, reset, pitchFormData]);

  const handleWrittenPitchSubmit = useCallback(
    (data: ProjectWrittenPitch) => {
      // If the user hasn't made any changes but has data in the form
      // we don't need to make the update call
      if (!formState.isDirty && projectPitchData && !pitchFormData) {
        nextStep();
      } else if (projectId) {
        addProjectWrittenPitch(
          projectId,
          data as ProjectWrittenPitch,
          Boolean((projectPitchData as ProjectWrittenPitch)?.answer_1),
        )
          .then(() => {
            setPitchType(PitchTypesEnum.WRITTEN);
            successNotification(t('saved_step_2'));
            nextStep();
          })
          .catch((e) => {
            if (!e) {
              errorNotification(t('common:errors.generic_error'));
            }
          });
      }
    },
    [
      formState.isDirty,
      projectPitchData,
      pitchFormData,
      projectId,
      nextStep,
      addProjectWrittenPitch,
      setPitchType,
      successNotification,
      t,
      errorNotification,
    ],
  );

  const handleVideoPitchSubmit = useCallback(
    (data: ProjectVideoPitch) => {
      // If the user hasn't made any changes but has data in the form
      // we don't need to make the update call
      if (!formState.isDirty && projectPitchData && !pitchFormData) {
        nextStep();
      } else if (projectId) {
        addProjectVideoPitch(
          projectId,
          data as ProjectVideoPitch,
          Boolean((projectPitchData as ProjectVideoPitch)?.video_url_1),
        )
          .then(() => {
            setPitchType(PitchTypesEnum.VIDEO);
            successNotification(t('saved_step_2'));
            nextStep();
          })
          .catch((error) => {
            if (error?.data?.detail) {
              error.data.detail.forEach((err: string) => {
                errorNotification(err);
              });
            } else {
              errorNotification(t('pitch.unknown_error'));
            }
          });
      }
    },
    [
      addProjectVideoPitch,
      errorNotification,
      formState.isDirty,
      nextStep,
      pitchFormData,
      projectId,
      projectPitchData,
      setPitchType,
      successNotification,
      t,
    ],
  );

  const submit = handleSubmit((data: ProjectWrittenPitch | ProjectVideoPitch) => {
    if (currentPitchType === PitchTypesEnum.WRITTEN) {
      handleWrittenPitchSubmit(data as ProjectWrittenPitch);
    } else if (currentPitchType === PitchTypesEnum.VIDEO) {
      handleVideoPitchSubmit(data as ProjectVideoPitch);
    }
    setPitchFormData(undefined);
  });

  useSetFieldErrors({
    fieldErrors:
      currentPitchType === PitchTypesEnum.WRITTEN
        ? addProjectWrittenPitchError?.field_errors
        : addProjectVideoPitchError?.field_errors,
    setError,
    loading: isFormSaving,
  });

  // Allows us to store the data when going back to a previous stage
  // this gets around needing validation for saving OR losing data due to component unmount
  const storePitchFormData = () => {
    const formData = getValues();
    if (formData && formState.isDirty) {
      setPitchFormData(formData);
    }
  };

  if (isGetProjectPitchLoading) return <Loader />;

  return (
    <FormProvider {...methods}>
      <ProjectPitch
        onSubmit={submit}
        pitchType={currentPitchType}
        onPitchChoice={setCurrentPitchType}
        isLoading={isFormSaving}
        buttons={
          <CreateActionButtons
            currentStep={currentStep}
            stepsCount={stepsCount}
            backButtonProps={{
              buttonType: 'text',
              onClick: () => {
                storePitchFormData();
                prevStep();
              },
              disabled: isFormSaving,
            }}
            nextButtonProps={{
              buttonType: 'primary',
              onClick: submit,
              disabled: isFormSaving,
            }}
            projectId={projectId}
          />
        }
      />
    </FormProvider>
  );
};
