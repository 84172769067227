import React, { useEffect, useCallback } from 'react';
import { parseISO, isAfter, startOfDay, endOfDay } from 'date-fns';

import { Loader } from '@Components';
import { useGetFundDetails, useAddFundDetails, useGetFundData } from '@Hooks/Api';
import { FundDetails as FundDetailsType, FundFormStep2 } from '@Types';
import { useRouter } from '@Helpers/useRouter';
import { useNotificationsContext } from '@Contexts';
import { RoutingPaths } from '@App/paths';

import { FundDetailsForm } from './FundDetailsForm';
import { Grid } from '@material-ui/core';

interface FundDetailsControllerProps {
  currentStep: number;
  stepsCount: number;
  nextStep: () => void;
  prevStep: () => void;
  fundId: number | null;
  fundDetailsData?: FundDetailsType;
  setFundDetailsData: (formData: any) => void;
}

export const FundDetailsController: React.FC<FundDetailsControllerProps> = ({
  currentStep,
  stepsCount,
  nextStep,
  fundId,
  prevStep,
  fundDetailsData,
  setFundDetailsData,
}) => {
  const [getFundDetails, { loading: isFundDetailsLoading, data: fundDetails }] =
    useGetFundDetails();
  const [addFundDetails, { error: addFundDetailsError, loading: isAddFundDetailsLoading }] =
    useAddFundDetails();
  const { push, params } = useRouter();
  const { error: errorNotification } = useNotificationsContext();
  const [getFundData, { loading: isFundDataLoading, data: fundData }] = useGetFundData();

  useEffect(() => {
    if (fundId) {
      getFundDetails(fundId);
      getFundData(fundId);
    }
  }, [fundId, getFundData, getFundDetails]);

  const handleCancel = () => {
    push(RoutingPaths.DASHBOARD_FUNDER);
  };

  const handleNonFieldErrors = useCallback(
    (errors: string[]) => {
      errors.forEach((message) => {
        errorNotification(message);
      });
    },
    [errorNotification],
  );

  const onSubmit = (data: FundFormStep2) => {
    if (fundId) {
      addFundDetails(fundId, data, true)
        .then(() => {
          nextStep();
        })
        .catch(({ data }) => {
          if (data.detail.length) {
            handleNonFieldErrors(data.detail);
          }
        });
    }
  };

  if (isFundDetailsLoading || !fundDetails || !fundData) return <Loader />;

  const isFormSaving = isAddFundDetailsLoading;
  const isLaunched = fundDetails
    ? isAfter(new Date(), startOfDay(parseISO(fundDetails.launch_from)))
    : undefined;
  const isPastDeadline = fundDetails
    ? isAfter(new Date(), endOfDay(parseISO(fundDetails.deadline_to)))
    : undefined;
  const isPastDeliveryDate = fundDetails
    ? isAfter(new Date(), endOfDay(parseISO(fundDetails.project_delivery_deadline)))
    : undefined;

  return (
    <Grid container justifyContent="center">
      <Grid xs={12} lg={8}>
        <FundDetailsForm
          currentStep={currentStep}
          isLoading={isFormSaving}
          onSubmit={onSubmit}
          isLaunched={isLaunched}
          isPastDeadline={isPastDeadline}
          isPastDeliveryDate={isPastDeliveryDate}
          handleCancel={handleCancel}
          fundDetails={fundDetails}
          prevStep={prevStep}
          stepsCount={stepsCount}
          fundData={fundData}
          setFundDetailsData={setFundDetailsData}
          fundDetailsData={fundDetailsData}
        />
      </Grid>
    </Grid>
  );
};
