import classnames from 'classnames';

import { OrganizationAvatar } from '@Components';

import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled';
import ImageIcon from '@material-ui/icons/Image';

import styles from './HeaderImage.module.scss';

interface HeaderImageProps {
  fund?: boolean;
  project?: boolean;
  image?: string;
  avatar?: string;
  video?: boolean;
  status: string;
}

export const HeaderImage = ({ fund, project, image, avatar, video, status }: HeaderImageProps) => {
  return (
    <div className={styles.container}>
      {image ? (
        <div className={styles.imageWrapper}>
          <img
            src={image}
            alt=""
            className={classnames(styles.image, project && styles.projectImage)}
          />
          {video && <PlayCircleFilledIcon className={styles.videoWatermark} />}
        </div>
      ) : (
        <div className={styles.imagePlaceholder}>
          <ImageIcon style={{ fontSize: '42px' }} />
        </div>
      )}
      <OrganizationAvatar className={styles.avatar} avatarImage={avatar} />
      <div
        className={classnames(styles.statusFlag, fund && styles.fund, project && styles.project)}
      >
        {status}
      </div>
    </div>
  );
};
