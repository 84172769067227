export enum SDGIdentifier {
  SDG1 = 'SDG1',
  SDG2 = 'SDG2',
  SDG3 = 'SDG3',
  SDG4 = 'SDG4',
  SDG5 = 'SDG5',
  SDG6 = 'SDG6',
  SDG7 = 'SDG7',
  SDG8 = 'SDG8',
  SDG9 = 'SDG9',
  SDG10 = 'SDG10',
  SDG11 = 'SDG11',
  SDG12 = 'SDG12',
  SDG13 = 'SDG13',
  SDG14 = 'SDG14',
  SDG15 = 'SDG15',
  SDG16 = 'SDG16',
  SDG17 = 'SDG17',
  other = 'other',
}
