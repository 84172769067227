import { useCallback } from 'react';
import { useRequest } from '@Hooks';
import { API } from '@Services';
import { Match } from '@Types';

export const useProjectUpdateLike = () => {
  const [fetch, response] = useRequest<Match>();

  const likeProjectUpdate = useCallback(
    (projectUpdateId: number) =>
      fetch(API.paths.project_update_like(projectUpdateId), 'post', {
        data: {},
      }),
    [fetch],
  );

  return [likeProjectUpdate, response] as const;
};
