import React from 'react';
import styles from './FieldNote.module.scss';
import classnames from 'classnames';

interface FieldNoteProps {
  icon: JSX.Element;
  className?: string;
  transparent?: boolean;
}

export const FieldNote: React.FC<FieldNoteProps> = ({ icon, children, className, transparent }) => {
  const styleOverrides = transparent ? { backgroundColor: 'transparent' } : {};
  return (
    <div className={classnames(styles.fieldNoteWrapper, className)}>
      <div className={classnames(styles.fieldNoteIcon)} style={styleOverrides}>
        {icon}
      </div>

      <p className={styles.fieldNote}>{children}</p>
    </div>
  );
};
