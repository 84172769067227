import React from 'react';
import classnames from 'classnames';
import { FormControlLabel, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Checkbox } from '@Components/common';
import styles from './CategoryTile.module.scss';

interface CategoryTileProps {
  id: number;
  name: string;
  image: string;
  description: string;
  checked: boolean;
  disabled?: boolean;
  onChange: () => void;
  inputRef?: React.Ref<any>;
}

const useStyles = makeStyles({
  stretchContent: {
    justifyContent: 'space-between',
    alignItems: 'center',
  },
});

export const CategoryTile: React.FC<CategoryTileProps> = ({
  id,
  name,
  image,
  description,
  checked,
  disabled = false,
  onChange,
  inputRef,
}) => {
  const classes = useStyles();

  return (
    <div className={classnames(styles.tileWrapper, checked && styles.tileWrapperChecked)}>
      <Grid container direction="column" justifyContent="center">
        <FormControlLabel
          className={classnames(classes.stretchContent, styles.label)}
          control={
            <Checkbox
              value={id}
              disabled={disabled}
              checked={checked}
              onChange={onChange}
              inputRef={inputRef}
            />
          }
          labelPlacement="start"
          label={
            <div className={styles.flex}>
              <img className={styles.image} src={image} alt="" />
              <div className={styles.descriptionWrapper}>
                <strong className={styles.tileTitle}>{name}</strong>
                <div className={styles.groupDescription}>{description}</div>
              </div>
            </div>
          }
        />
      </Grid>
    </div>
  );
};
