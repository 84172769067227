import { useCallback, useEffect, useMemo, useState } from 'react';
import axios, { AxiosRequestConfig, Method } from 'axios';

import { ApiErrorObject } from 'Types/Errors';
import { useAppConfig } from '@App/ConfigLoader';

const breakPromise = (message: string = 'default') => {
  let _break_ = false;

  const doCancellable = <T extends (...args: any[]) => any>(promiseHandler: T) => {
    return (...rest: Parameters<T>) => {
      if (_break_) {
        // TODO: remove me after "warming up"
        console.warn(`Promise "cancelled" (${message})`);
        return;
      }
      return promiseHandler(...rest);
    };
  };

  return {
    doCancellable,
    cancel: () => {
      _break_ = true;
    },
  } as const;
};

/**
 * Older version on `useRequest` returns async function right away in useCallback.
 * This is considered as bad practice, because unmounted components still produce
 * async/await queue depending on async "stops" (each `await` is executed in a separate context).
 *
 * Hence why we provide `breakPromise` tool, to wrap each then/catch/finally block
 */
export const useRequest = <T>() => {
  const { axiosClient } = useAppConfig();
  // Local loading indicator
  const [loading, setLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [error, setError] = useState<ApiErrorObject>();
  const [responseData, setResponseData] = useState<T>();
  const { doCancellable, cancel } = useMemo(() => breakPromise('useRequest'), []);
  // TODO: axios is throwing unhandled error during cancelation phase, handle it!
  const cancelToken = useMemo(() => axios.CancelToken.source(), []);

  const fetchPromise = useCallback(
    (url: string, method: Method, config: AxiosRequestConfig = {}) => {
      setLoading(true);
      setIsError(false);
      setError(undefined);

      const fetch = axiosClient.request<T>({
        url,
        method,
        ...{ ...config, cancelToken: cancelToken.token },
      });

      fetch
        .then(
          doCancellable(({ data }) => {
            setResponseData(data);
          }),
        )
        .catch(
          doCancellable(({ data }) => {
            setIsError(true);
            if (data) {
              setError(data);
            }
          }),
        )
        .finally(
          doCancellable(() => {
            setLoading(false);
          }),
        );

      return fetch;
    },
    [doCancellable, cancelToken.token, axiosClient],
  );

  useEffect(
    () => () => {
      cancelToken.cancel();
      cancel();
    },
    [cancel, cancelToken],
  );

  return [fetchPromise, { data: responseData, loading, isError, error, setResponseData }] as const;
};
