import { useRequest } from '@Hooks/useRequest';
import { API } from '@Services';
import { useCallback } from 'react';

export const useGetSurveyPreviewConfig = () => {
  const [fetch, response] = useRequest<any>();

  const getSurveyPreviewConfig = useCallback(
    (selectionId: number) => fetch(API.paths.survey_preview(selectionId), 'get'),
    [fetch],
  );

  return [getSurveyPreviewConfig, response] as const;
};
