import React from 'react';
import {
  Radio as MaterialRadio,
  RadioGroup as MaterialRadioGroup,
  FormControl as MaterialFormControl,
  FormControlLabel as MaterialFormControlLabel,
  FormControlLabelProps as MaterialFormControlLabelProps,
  FormHelperText as MaterialFormHelperText,
  FormLabel as MaterialFormLabel,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useFormContext, Controller } from 'react-hook-form';
import { msgChooseOption } from '@Helpers/errorMessages';
import classNames from 'classnames';
import styleVariables from '@Styles/_variables.module.scss';
import { UserTypeEnum } from '@Types';

interface RadioGroupItem {
  value: string;
  label: MaterialFormControlLabelProps['label'];
}

interface RadioGroupProps {
  items: RadioGroupItem[];
  name: string;
  required?: boolean;
  disabled?: boolean;
  label?: string;
  validate?: (value: any) => string | boolean;
  // If the selected values here should be saved as something other than strings
  // (e.g. they represent numbers, or booleans)
  // supply these functions to do the conversion.
  valueToString?: (value: any) => string | boolean;
  valueFromString?: (value: string | null) => any;
  row?: boolean;
  defaultValue?: any;
  // Turns the style of the radio buttons to that of a card.
  card?: boolean;
  setSelectedValue?: (value: UserTypeEnum) => void;
}

const useStyles = makeStyles({
  root: {
    alignItems: 'center',
  },
  cardContainer: {
    gap: '20px',
    justifyContent: 'space-evenly',
    marginBottom: '36px',
    [`@media screen and (max-width: ${styleVariables.screenWidthXs})`]: {
      flexDirection: 'column',
      marginLeft: '9px',
      marginRight: '-9px',
    },
  },
  card: {
    border: `solid 1px ${styleVariables.AFSteel500}`,
    borderRadius: '10px',
    marginRight: 0,
    maxWidth: '45%',
    [`@media screen and (max-width: ${styleVariables.screenWidthXs})`]: {
      maxWidth: '100%',
    },
  },
  customActiveCheckMark: {
    '&.Mui-checked': {
      color: `${styleVariables.AFNavyBlue500}`,
    },
  },
});

export const RadioGroup: React.FC<RadioGroupProps> = ({
  items,
  name,
  required,
  disabled,
  label,
  validate,
  valueToString,
  valueFromString,
  row,
  defaultValue,
  card,
  setSelectedValue,
}) => {
  const classes = useStyles();
  const { control, errors: fieldErrors } = useFormContext();
  const onChangeWrapper = (onChange: any) => {
    if (valueFromString) {
      const wrapped = (event: React.ChangeEvent<HTMLInputElement>, value: string) => {
        return onChange(valueFromString(value));
      };
      return wrapped;
    } else {
      return onChange;
    }
  };

  const processValue = (value: any) => {
    if (setSelectedValue) {
      setSelectedValue(value);
    }
    if (valueToString) {
      return valueToString(value);
    } else {
      return value;
    }
  };

  return (
    <MaterialFormControl error={!!fieldErrors[name]} fullWidth>
      {label && <MaterialFormLabel>{label}</MaterialFormLabel>}
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        rules={{ required: required && msgChooseOption, validate: validate }}
        render={({ ref, onChange, value }) => (
          <MaterialRadioGroup
            ref={ref}
            onChange={onChangeWrapper(onChange)}
            value={processValue(value)}
            row={row}
            defaultValue={defaultValue}
            className={(card && classes.cardContainer) || undefined}
          >
            {items.map(({ value, label }) => {
              return (
                <MaterialFormControlLabel
                  key={value as string}
                  value={value}
                  label={label}
                  control={
                    <MaterialRadio
                      color="primary"
                      className={(card && classes.customActiveCheckMark) || undefined}
                    />
                  }
                  className={classNames(classes.root, card && classes.card)}
                  disabled={disabled}
                />
              );
            })}
          </MaterialRadioGroup>
        )}
      />
      <MaterialFormHelperText>{fieldErrors[name]?.message}</MaterialFormHelperText>
    </MaterialFormControl>
  );
};
