import { Tooltip } from '@Components/Tooltip/Tooltip';
import { DrawingMode } from '@Types';
import React from 'react';
import { useTranslation, Trans } from 'react-i18next';

interface FundCatchmentAreaProps {
  catchmentAreaType: DrawingMode;
  radius?: number;
  postcode?: string;
  localAuthority?: string;
}

export const FundCatchmentArea: React.FC<FundCatchmentAreaProps> = ({
  catchmentAreaType,
  radius,
  postcode,
  localAuthority,
}) => {
  const { t } = useTranslation('fund');
  postcode = t('common:postcode_format_uk', { postcode: postcode });

  const postcodeShort = postcode?.slice(0, -3);
  return (
    <>
      {catchmentAreaType === DrawingMode.POLYGON && (
        <Tooltip
          title={<Trans ns="fund" i18nKey="common:location.polygon" values={{ postcode }} />}
        >
          <span style={{ textDecoration: 'underline' }}>{t('common:location.polygon_short')}</span>
        </Tooltip>
      )}
      {catchmentAreaType === DrawingMode.RADIUS && (
        <Tooltip
          title={<Trans ns="fund" i18nKey="common:location.radius" values={{ radius, postcode }} />}
        >
          <span style={{ textDecoration: 'underline' }}>
            <Trans
              ns="fund"
              i18nKey="common:location.radius_short"
              values={{ radius, postcodeShort }}
            />
          </span>
        </Tooltip>
      )}
      {catchmentAreaType === DrawingMode.LOCAL_AUTHORITY && (
        <Trans
          ns="fund"
          i18nKey="common:location.localAuthority"
          components={[<strong />]}
          values={{ localAuthority }}
        />
      )}
      {catchmentAreaType === DrawingMode.UK_WIDE && t('common:location.uk_wide')}
    </>
  );
};
