import React, { useMemo } from 'react';
import { FacebookShareButton, TwitterShareButton, LinkedinShareButton } from 'react-share';
import { Modal } from '@material-ui/core';

import CloseIcon from '@material-ui/icons/Close';
import FacebookIcon from '@material-ui/icons/Facebook';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import { RiTwitterXFill } from 'react-icons/ri';

import isServer from '@Helpers/isServer';
import styleVariables from '@Styles/_variables.module.scss';
import styles from './ShareModal.module.scss';
import { CopyToClipboard } from '@Components/CopyToClipboard/CopyToClipboard';
import { IconButton } from '@Components/common/Button/IconButton';

interface ShareModalProps {
  isOpen: boolean;
  title: string;
  shareUrl?: string;
  onClose: () => void;
}

export const ShareModal: React.FC<ShareModalProps> = ({ isOpen, title, shareUrl, onClose }) => {
  let url = shareUrl || '';
  if (!isServer && !shareUrl) {
    url = window.location.href;
  }

  const socialsList = useMemo(
    () => [
      {
        name: 'Facebook',
        icon: <FacebookIcon />,
        tag: FacebookShareButton,
      },
      {
        name: 'Twitter',
        icon: <RiTwitterXFill />,
        tag: TwitterShareButton,
      },
      {
        name: 'LinkedIn',
        icon: <LinkedInIcon />,
        tag: LinkedinShareButton,
      },
    ],
    [],
  );

  return (
    <Modal
      open={isOpen}
      className={styles.container}
      style={{ zIndex: Number(styleVariables.zIndexShareModal) }}
      onClose={onClose}
    >
      <div className={styles.modal}>
        <h6 className={styles.title}>{title}</h6>
        <IconButton buttonType="text" className={styles.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
        <div className={styles.socialsWrapper}>
          {socialsList.map(({ name, icon, tag: Component }) => (
            <div className={styles.singleSocial} key={name}>
              <Component className={styles.socialButton} url={url}>
                {icon}
              </Component>
              <span className={styles.socialText}>{name}</span>
            </div>
          ))}
        </div>
        <CopyToClipboard valueToCopy={url} />
      </div>
    </Modal>
  );
};
