import isServer from './isServer';

export const getItem = (key: string) => {
  try {
    if (isServer) return;
    return window.localStorage.getItem(key);
  } catch (e) {
    console.warn(`An error occurred when trying to read field: "${key}" from storage.`, e);
  }
};

export const setItem = (key: string, value: string) => {
  try {
    if (isServer) return;
    window.localStorage.setItem(key, value);
  } catch (e) {
    console.warn(
      `An error occurred when trying to set value: "${value}" for the field: "${key}" in storage.`,
      e,
    );
  }
};

export const removeItem = (key: string) => {
  try {
    if (isServer) return;
    window.localStorage.removeItem(key);
    return true;
  } catch (e) {
    console.warn(`An error occurred when trying to remove field: "${key}" from storage.`);
  }

  return false;
};
