export const getInitials = (name?: string) => {
  if (!name) return undefined;

  const initials = name
    .split(' ')
    .map((o) => o.charAt(0))
    .join('');

  return initials;
};
