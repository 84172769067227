export const getGreeneKingFormUrl = (parsedFormData: string) =>
  `https://semble.activehosted.com/proc.php?u=39&f=39&s=&c=0&m=0&act=sub&v=2&field[151][]=~%7C&field[153][]=~%7C${parsedFormData}&jsonp=true`;

export const getGreeneKingPubFormUrl = (parsedFormData: string) =>
  `https://semble.activehosted.com/proc.php?u=60&f=60&s=&c=0&m=0&act=sub&v=2&field[151][]=~%7C&field[153][]=~%7C${parsedFormData}&jsonp=true`;

export interface GreeneKingFormData {
  [key: string]: string | { [key: string]: string };
}

export const parseGreeneKingFormData = (data: GreeneKingFormData) => {
  let queryString = '';

  Object.entries(data).forEach(([key, value]) => {
    if (typeof value === 'string') {
      if (Number(key)) {
        // Exception for multiline field
        if (key === '157') {
          const valueEscaped = value.replaceAll('\n', '\\n');
          queryString = queryString.concat(`&field[${key}]=${valueEscaped}`);
        } else {
          queryString = queryString.concat(`&field[${key}]=${encodeURIComponent(value)}`);
        }
      } else {
        queryString = queryString.concat(`&${key}=${encodeURIComponent(value)}`);
      }
    } else {
      Object.entries(value).forEach(([keyIn, valueIn]) => {
        if (valueIn) {
          queryString = queryString.concat(`&field[${key}][]=${encodeURIComponent(keyIn)}`);
        }
      });
    }
  });

  return queryString;
};
