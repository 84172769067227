import React, { Fragment, MutableRefObject } from 'react';
import { useTranslation } from 'react-i18next';
import { UserAvatar, Menu, createMenuItems, CreateMenuItemsProps } from '@Components';
import styles from './UserAvatarMenu.module.scss';
import { RiDashboardLine, RiLogoutBoxRLine, RiUserLine, RiArrowDownSFill } from 'react-icons/ri';

interface UserAvatarMenuProps {
  anchorEl: MutableRefObject<HTMLButtonElement | null>;
  isMenuOpen: boolean;
  avatarContent?: string;
  userName?: string;
  userEmail?: string;
  onMenuOpen: () => void;
  onMenuClose: () => void;
  onSignOut: () => void;
  onProfileClick: () => void;
  onDashboardClick: () => void;
  publicLayout?: boolean;
}

export const UserAvatarMenu: React.FC<UserAvatarMenuProps> = ({
  anchorEl,
  isMenuOpen,
  avatarContent,
  userName,
  userEmail,
  onMenuOpen,
  onMenuClose,
  onSignOut,
  onProfileClick,
  onDashboardClick,
  publicLayout,
}) => {
  const { t } = useTranslation();
  const dropDownMenuItems: CreateMenuItemsProps['items'] = [
    {
      label: (
        <div>
          <span className={styles.userName}>{userName}</span>
          <span className={styles.userEmail}>{userEmail}</span>
        </div>
      ),
      menuItemProps: {
        disabled: true,
      },
    },
    {
      label: t('common:nav.home'),
      menuItemProps: {
        onClick: onDashboardClick,
      },
      icon: <RiDashboardLine />,
    },
    {
      label: t('common:nav.profile'),
      menuItemProps: {
        onClick: onProfileClick,
      },
      icon: <RiUserLine />,
    },
    {
      label: t('common:nav.signout'),
      menuItemProps: {
        onClick: onSignOut,
      },
      icon: <RiLogoutBoxRLine />,
    },
  ];

  return (
    <Fragment>
      <button
        aria-label="user-menu"
        onClick={onMenuOpen}
        className={styles.container}
        ref={anchorEl}
      >
        <UserAvatar>{avatarContent}</UserAvatar>
        <RiArrowDownSFill color={publicLayout ? 'white' : 'black'} />
      </button>
      <Menu open={isMenuOpen} anchorEl={anchorEl.current} onClose={onMenuClose}>
        {createMenuItems({ items: dropDownMenuItems })}
      </Menu>
    </Fragment>
  );
};
