import { useEffect, useMemo } from 'react';
import { Loader } from '@Components';
import { useCategories } from '@Hooks/Api';
import { CategoryTiles } from './CategoryTiles';

interface CategoryTilesControllerProps {
  categoriesIds?: number[];
}

export const CategoryTilesController: React.FC<CategoryTilesControllerProps> = ({
  categoriesIds,
}) => {
  const [getCategories, { data: categoriesData, loading: isCategoriesLoading }] =
    useCategories(true);

  useEffect(() => {
    getCategories();
  }, [getCategories]);

  const categoriesList = useMemo(
    () =>
      categoriesIds &&
      categoriesData &&
      categoriesData.filter(({ id }) => categoriesIds.indexOf(id) !== -1),
    [categoriesIds, categoriesData],
  );

  if (isCategoriesLoading) return <Loader />;

  return <CategoryTiles items={categoriesList} />;
};
