import styles from './Totalizers.module.scss';
import { Trans, useTranslation } from 'react-i18next';
import { Divider, OldTabs } from '@Components';
import { useMemo } from 'react';
import { RiStarFill, RiMapPin2Fill } from 'react-icons/ri';

interface ProjectListProps {
  total_project_count?: number;
  openFilter: () => void;
  currentTab: number;
  setCurrentTab: (newTab: number) => void;
  hideTabs?: boolean;
  totals: {
    ns: string;
    i18nKey: string;
    value: number;
  }[];
  title?: string;
}

export const Totalizers = ({
  total_project_count = 0,
  openFilter,
  currentTab,
  setCurrentTab,
  hideTabs,
  totals,
  title,
}: ProjectListProps) => {
  const { t } = useTranslation('landingPage');

  const tabs = useMemo(
    () => [
      { title: t('tabs.explore_by_location'), icon: <RiMapPin2Fill size={16} /> },
      { title: t('tabs.explore_by_interest'), icon: <RiStarFill size={16} /> },
    ],
    [t],
  );

  const handleTabChange = (newTab: number) => {
    setCurrentTab(newTab);
    openFilter();
  };

  return (
    <div className={styles.totalizersContainer}>
      {!hideTabs && (
        <>
          <OldTabs
            tabs={tabs}
            currentTab={currentTab}
            fullWidth={false}
            onTabChange={handleTabChange}
            className={styles.tabs}
            primary
          />
          <Divider className={styles.divider} />
        </>
      )}
      {title && <h3 className={styles.title}>{title}</h3>}
      <p className={styles.subtitle}>{t('totalizers.subtitle')}</p>
      <div className={styles.values}>
        <div className={styles.projectMatchValue}>
          <Trans
            ns="landingPage"
            i18nKey="totalizers.project_matches"
            components={[<div />, <strong />]}
            values={{ amount: total_project_count }}
            count={total_project_count}
          />
          <img src="/assets/Explore/underline.svg" alt="underline" className={styles.underline} />
        </div>
        {totals.map((total, i) => {
          return (
            <div className={styles.textValue} key={i}>
              <Trans
                ns={total.ns}
                i18nKey={total.i18nKey}
                components={[<strong />, <p className={styles.text} />]}
                values={{ amount: total.value }}
                count={total.value}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};
