import { useRequest } from '@Hooks/useRequest';
import { API } from '@Services';
import { Fund } from '@Types';
import { useCallback } from 'react';

export const useGetFundData = () => {
  const [fetch, response] = useRequest<Fund>();

  const getFundData = useCallback(
    (fundId: number) => fetch(API.paths.fund(fundId), 'get'),

    [fetch],
  );

  return [getFundData, response] as const;
};
