import React from 'react';
import { LinearProgress, LinearProgressProps } from '@material-ui/core';
import { createStyles, withStyles } from '@material-ui/core/styles';
import styleVariables from '@Styles/_variables.module.scss';

const StyledProgressBar = withStyles(() =>
  createStyles({
    root: {
      height: '8px',
      borderRadius: '4px',
    },
    colorPrimary: {
      backgroundColor: styleVariables.colorT2Pale,
    },
    colorSecondary: {
      backgroundColor: styleVariables.colorT2Pale,
    },
    bar: {
      borderRadius: '4px',
      backgroundColor: styleVariables.colorT2Pale,
    },
    barColorPrimary: {
      backgroundColor: styleVariables.colorP1,
    },
    barColorSecondary: {
      backgroundColor: styleVariables.colorT1,
    },
  }),
)(LinearProgress);

export const ProgressBar: React.FC<LinearProgressProps> = (props) => {
  return <StyledProgressBar variant="determinate" {...props} />;
};
