import React from 'react';
import dynamic from 'next/dynamic';
import { MapContainerProps, TileLayerProps, MarkerProps } from 'react-leaflet';

import { MapProps } from '@Types';

import 'leaflet/dist/leaflet.css';
// @ts-ignore
let L: Leaflet;

if (typeof window !== 'undefined') {
  L = require('leaflet');
}

// @ts-ignore
import markerIcon2x from 'leaflet/dist/images/marker-icon-2x.png';
// @ts-ignore
import markerIcon from 'leaflet/dist/images/marker-icon.png';
// @ts-ignore
import markerShadow from 'leaflet/dist/images/marker-shadow.png';

function getMeters(radius: number): number {
  return radius * 1609.344;
}

const MapContainer = dynamic<MapContainerProps>(
  () => (import('react-leaflet') as any).then((mod: any) => mod.MapContainer),
  {
    ssr: false,
  },
);
const TileLayer = dynamic<TileLayerProps>(
  () => (import('react-leaflet') as any).then((mod: any) => mod.TileLayer),
  {
    ssr: false,
  },
);
const Marker = dynamic<MarkerProps>(
  () => (import('react-leaflet') as any).then((mod: any) => mod.Marker),
  {
    ssr: false,
  },
);

export const Map: React.FC<MapProps> = ({ coordinates, radius, zoom = 10, height, marker }) => {
  const addCircle = React.useCallback(
    async (map) => {
      if (radius) {
        const radiusInMeters = getMeters(radius);
        const circle = await (import('leaflet') as any).then((mod: any) => mod.circle);
        const circleOverlay = circle(coordinates, { radius: radiusInMeters }).addTo(map);

        map.fitBounds(circleOverlay.getBounds());
      }
    },
    [coordinates, radius],
  );

  // @ts-ignore
  delete L.Icon.Default.prototype._getIconUrl;
  L.Icon.Default.mergeOptions({
    iconUrl: markerIcon.src,
    iconRetinaUrl: markerIcon2x.src,
    shadowUrl: markerShadow.src,
  });

  var greenIcon = new L.Icon({
    iconUrl:
      'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-green.png',
    shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowSize: [41, 41],
  });

  if (!MapContainer || !TileLayer) return null;

  return (
    <MapContainer
      style={{ height }}
      center={coordinates}
      zoom={zoom}
      scrollWheelZoom={false}
      whenCreated={addCircle}
    >
      <TileLayer
        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />

      {marker && <Marker icon={greenIcon} position={coordinates}></Marker>}
    </MapContainer>
  );
};
