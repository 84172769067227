import { useTranslation } from 'react-i18next';
import styles from './SelectionDeadlineCard.module.scss';
import { Tooltip } from '@Components/Tooltip/Tooltip';
import { RiQuestionLine, RiCalendarEventLine } from 'react-icons/ri';
import { formatDate } from '@Helpers/formatDate';

interface SelectionDeadlineCardProps {
  selectionDeadline: string;
}

export const SelectionDeadlineCard = ({ selectionDeadline }: SelectionDeadlineCardProps) => {
  const { t } = useTranslation('fund');
  return (
    <div className={styles.container}>
      <RiCalendarEventLine size={24} />
      <div className={styles.titleContainer}>
        <p className={styles.title}>{t('details_page.selection_deadline.title')}</p>
        <Tooltip title={t('details_page.selection_deadline.tooltip') as string}>
          <div className={styles.helpIcon}>
            <RiQuestionLine />
          </div>
        </Tooltip>
      </div>
      <p className={styles.date}>{formatDate(selectionDeadline)}</p>
    </div>
  );
};
