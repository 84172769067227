import React from 'react';
import MaterialIconButton, {
  IconButtonProps as MaterialIconButtonProps,
} from '@material-ui/core/IconButton';
import styles from './Button.module.scss';
import { buttonTypeStyles, buttonTypes } from './Button';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core';

export interface IconButtonProps extends MaterialIconButtonProps {
  buttonType: buttonTypes;
}

const buttonStyles = makeStyles(buttonTypeStyles);

export const IconButton = ({ buttonType, children, ...props }: IconButtonProps) => {
  const { [buttonType]: buttonStyle } = buttonStyles();

  return (
    <MaterialIconButton
      {...props}
      className={classNames(props.className, styles.iconButton, buttonStyle)}
    >
      {children}
    </MaterialIconButton>
  );
};
