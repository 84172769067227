import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { QueryParams } from '@App/paths';
import { ViewProjectUpdateModal } from './ViewProjectUpdateModal';
import { useGetProjectUpdate } from '@Hooks/Api';
import { useRouter } from '@Helpers/useRouter';
import { ProjectUpdate } from '@Types';
import { SocialHeadData } from '@Components/SocialHeadData/SocialHeadData';
import { getHeaderImage } from '@Helpers/getHeaderImage';

interface ViewProjectUpdateModalControllerProps {
  initialProjectUpdateData?: ProjectUpdate;
}

export const ViewProjectUpdateModalController = ({
  initialProjectUpdateData,
}: ViewProjectUpdateModalControllerProps) => {
  const [getProjectUpdate, { data: projectUpdate, loading: isProjectUpdateLoading }] =
    useGetProjectUpdate();
  const { pathname, path, params, push } = useRouter();
  const { t } = useTranslation('projectUpdate');

  const projectUpdateId = params[QueryParams.PROJECT_UPDATE];

  useEffect(() => {
    if (projectUpdateId && !initialProjectUpdateData) {
      getProjectUpdate(Number(projectUpdateId));
    }
  }, [getProjectUpdate, initialProjectUpdateData, projectUpdateId]);

  const handleCloseModal = useCallback(() => {
    push({ pathname, query: { ...params, [QueryParams.PROJECT_UPDATE]: null } }, undefined, {
      scroll: false,
    });
  }, [params, pathname, push]);

  if (!projectUpdateId) return null;

  const {
    image,
    description,
    created,
    likes,
    video_url,
    organization_id,
    organization_name,
    avatar_thumbnail,
    project_id,
    project_name,
  } = projectUpdate || initialProjectUpdateData || {};

  const ogImage = getHeaderImage(image, video_url, true) || avatar_thumbnail;

  return (
    <>
      <SocialHeadData
        title={t('meta_title', { projectName: project_name })}
        description={description}
        url={`https://www.actionfunder.org${path}`}
        image={ogImage}
      />
      <ViewProjectUpdateModal
        id={Number(projectUpdateId)}
        isLoading={isProjectUpdateLoading}
        image={image}
        description={description}
        createdAt={created}
        likeCount={likes ? likes.length : 0}
        likes={likes}
        video={video_url}
        organizationId={organization_id}
        organizationName={organization_name}
        organizationAvatar={avatar_thumbnail}
        projectId={project_id}
        projectName={project_name}
        onClose={handleCloseModal}
      />
    </>
  );
};
