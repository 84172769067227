import { useCallback } from 'react';
import { useRequest } from '@Hooks';
import { API } from '@Services';
import { MemberDetails } from '@Types';

export const useGetSignedURL = () => {
  const [fetch, response] = useRequest<Array<MemberDetails>>();
  const {
    data: signedURL,
    loading: isURLsLoading,
    isError: isURLsError,
    error: urlsError,
  } = response;

  const getSignedURL = useCallback(
    (data: { url: string }) => {
      fetch(data.url, 'get');
    },
    [fetch],
  );

  return [getSignedURL, signedURL] as const;
};
