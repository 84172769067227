import { LocationFields } from '@Types';
import { GroupProfileData } from './GroupProfileData';
import { ImpactSurveyResponse } from './ImpactSurveyResponse';
import { MatchSimplified } from './Match';
import { Selection } from './Selection';

export interface ProjectDetails extends LocationFields {
  name: string;
  amount: number;
  currency: string;
  is_sensitive: boolean;
  categories: number[];
}

export interface ProjectWrittenPitch {
  answer_1: string;
  answer_2: string;
  answer_3: string;
  is_permission: boolean;
  image?: string;
}

export interface ProjectVideoPitch {
  video_url_1: string;
  is_permission: boolean;
}

export enum ProjectStatusEnum {
  'DRAFT' = 'DRAFT',
  'PUBLISHED' = 'PUBLISHED',
  'FUNDED' = 'FUNDED',
  'COMPLETE' = 'COMPLETE',
}

export enum TimeframeEstimateUnitEnum {
  'WEEK' = 'WEEK',
  'MONTH' = 'MONTH',
}

export enum PitchTypesEnum {
  'WRITTEN' = 'WRITTEN',
  'VIDEO' = 'VIDEO',
}

export interface ProjectBudgetBreakdownItem {
  cost_description: string;
  amount: number;
}

export interface ProjectDeliveryPlan {
  budget_breakdown: ProjectBudgetBreakdownItem[];
  beneficiary_types_people: string[];
  beneficiary_types_people_other: string;
  beneficiaries: number;
  timeframe_estimate_number: number;
  timeframe_estimate_unit: TimeframeEstimateUnitEnum;
  expected_impact: string;
  track_record: string;
  estimated_delivery_date: string;
}

export interface VolunteeringOpportunity {
  number_of_volunteers: number;
  description: string;
}

export interface Project {
  id: number;
  status: ProjectStatusEnum;
  details: ProjectDetails;
  like_count?: number;
  written_pitch: ProjectWrittenPitch | null;
  video_pitch: ProjectVideoPitch | null;
  delivery_plan?: ProjectDeliveryPlan;
  matches_count?: number;
  beneficiaries?: number;
  group_profile: GroupProfileData;
  user_can_manage: boolean;
  user_can_manage_updates: boolean;
  user_can_view_volunteering_opportunities: boolean;
  user_can_edit: boolean;
  user_can_archive: boolean;
  matches_list?: MatchSimplified[];
  selections?: Selection[];
  impact_survey_response?: ImpactSurveyResponse;
  volunteering_opportunity?: VolunteeringOpportunity | null;
  has_volunteering_opportunity: boolean;
  funding_offers_count?: number;
  update_count?: number;
  has_accepted_invitation?: boolean;
}

export interface SurveyUrl {
  survey_url: string;
}

export interface ProjectFundingSourceResponse {
  id: number;
  organization: {
    id: number;
    name: string;
    profile: {
      avatar_image: string | undefined;
    };
  };
  details: {
    name: string;
  };
  visibility: string;
}

export interface ProjectDelivery {
  delivery_plan: ProjectDeliveryPlan;
  volunteering_opportunity: VolunteeringOpportunity;
}
