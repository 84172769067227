import { useCallback } from 'react';
import { useRequest } from '@Hooks';
import { API } from '@Services';
import { ApiErrorObject } from '@Types';

export const useGetCampaignData = () => {
  const [fetch, response] = useRequest<{ name: string } & ApiErrorObject>();

  const getCampaignData = useCallback(
    (campaignCode: string) =>
      fetch(API.paths.campaigns, 'post', {
        data: {
          affiliate_code: campaignCode,
        },
      }),
    [fetch],
  );

  return [getCampaignData, response] as const;
};
