import { useCallback } from 'react';
import { useRequest } from '@Hooks';
import { API } from '@Services';
import { Match } from '@Types';

export const useProjectUpdateUnlike = () => {
  const [fetch, response] = useRequest<Match>();

  const unlikeProjectUpdate = useCallback(
    (projectUpdateId: number) =>
      fetch(API.paths.project_update_like(projectUpdateId), 'delete', {
        data: {},
      }),
    [fetch],
  );

  return [unlikeProjectUpdate, response] as const;
};
