import React, { useMemo, useReducer, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import { PitchAccordion, AccordionData as AccordionDataProps } from '@Components';
import { TextInput } from '@Components/common';
import { msgFieldRequired } from '@Helpers/errorMessages';

interface FieldsState {
  [key: string]: boolean;
}

interface FieldsStateActions {
  field: string;
  payload: string;
}

const fieldsStateReducer = (state: FieldsState, action: FieldsStateActions) => ({
  ...state,
  [action.field]: action.payload !== '',
});
const FIELD_NAMES = ['answer_1', 'answer_2', 'answer_3'];
const MAX_INPUT_LENGTH = 700;
const MIN_INPUT_LENGTH = 200;

export const WrittenPitchAccordion = () => {
  const { t } = useTranslation('project');
  const { register, errors: fieldErrors, getValues } = useFormContext();
  const initialFieldsState = FIELD_NAMES.reduce((acc, curr) => ({ ...acc, [curr]: false }), {});
  const [fieldsState, dispatchFieldsState] = useReducer(fieldsStateReducer, initialFieldsState);
  const fieldsData = useMemo(
    () => [
      {
        title: t('pitch.q1title'),
        subText: t('pitch.q1subText'),
        hint: t('pitch.q1hint'),
        field: FIELD_NAMES[0],
        accordionProps: { defaultExpanded: true },
      },
      {
        title: t('pitch.q2title'),
        subText: t('pitch.q2subText'),
        hint: t('pitch.q2hint'),
        field: FIELD_NAMES[1],
      },
      {
        title: t('pitch.q3title'),
        subText: t('pitch.q3subText'),
        hint: t('pitch.q3hint'),
        field: FIELD_NAMES[2],
      },
    ],
    [t],
  );

  const createInputField = useCallback(
    (field) => (
      <TextInput
        name={field}
        label={t('pitch.answer_label')}
        multiline
        displayCharLimit={MAX_INPUT_LENGTH}
        inputRef={register({
          required: msgFieldRequired,
          maxLength: MAX_INPUT_LENGTH,
          minLength: {
            value: MIN_INPUT_LENGTH,
            message: t('common:min_length_200'),
          },
        })}
        inputProps={{
          maxLength: MAX_INPUT_LENGTH,
        }}
        error={!!fieldErrors[field]}
        helperText={fieldErrors[field]?.message}
        onBlur={() => dispatchFieldsState({ field, payload: getValues(field) })}
      />
    ),
    [fieldErrors, getValues, register, t],
  );

  const createAccordionData = useCallback(
    ({ title, subText, hint, field, accordionProps }) => ({
      title,
      subText,
      hint,
      error: !!fieldErrors[field],
      success: fieldsState[field] && !fieldErrors[field],
      content: createInputField(field),
      accordionProps,
    }),
    [createInputField, fieldErrors, fieldsState],
  );

  const accordionData = fieldsData.map(createAccordionData);

  return <PitchAccordion accordionData={accordionData} />;
};
