import { useCallback } from 'react';
import { useRequest } from '@Hooks/useRequest';
import { API } from '@Services';
import { FundingProfileData } from '@Types';

export const usePublicFunderProfile = () => {
  const [fetch, response] = useRequest<FundingProfileData>();

  const getPublicFunderProfile = useCallback(
    (organizationId: number) => fetch(API.paths.public_funder_profile(organizationId), 'get'),
    [fetch],
  );

  return [getPublicFunderProfile, response] as const;
};
