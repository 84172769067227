import { format, parseISO } from 'date-fns';
import * as Sentry from '@sentry/react';

export const DATE_FORMAT = 'd/MM/y';

export const formatDate = (dateToFormat: string | null | undefined) => {
  if (dateToFormat == undefined) {
    return '';
  }
  try {
    return format(parseISO(dateToFormat), DATE_FORMAT);
  } catch (e) {
    // If it's in an unexpected format, still show it so the page doesn't break,
    // but do record the error in Sentry.
    Sentry.captureException(e);
    return dateToFormat;
  }
};
