import classnames from 'classnames';
import { useTranslation } from 'react-i18next';

import ImageIcon from '@material-ui/icons/Image';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import ScheduleIcon from '@material-ui/icons/Schedule';
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter';
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled';

import { StatusChip, OrganizationAvatar } from '@Components';
import { Link, Button } from '@Components/common';

import styles from './ActionListItem.module.scss';
import { getDeadlineDateInformationBreakdown } from '@Helpers/getDeadlineDateInformationBreakdown';

interface ActionListItemProps {
  className?: string;
  image?: string;
  videoThumbnail?: string;
  name: string;
  link: string;
  fundName?: string;
  fundLink?: string;
  organizationAvatar?: string;
  totalFundAmount?: string;
  maximumGrantAmount?: string;
  fundDeadline?: string;
  fundingRequired?: number;
  withOrganizationAvatar?: boolean;
  status?: string;
  ctaText: string;
}

export const ActionListItem = ({
  className,
  image,
  videoThumbnail,
  name,
  link,
  fundName,
  fundLink,
  organizationAvatar,
  totalFundAmount,
  maximumGrantAmount,
  fundDeadline,
  fundingRequired,
  withOrganizationAvatar,
  status,
  ctaText,
}: ActionListItemProps) => {
  const { t } = useTranslation('profilePage');

  const { isBeforeDeadline, distanceToDeadline } =
    getDeadlineDateInformationBreakdown(fundDeadline);
  const isChipPrimary = status === t('fund:fund_status.LIVE');

  return (
    <div className={classnames(styles.container, className)}>
      {withOrganizationAvatar ? (
        <div className={styles.organizationAvatar}>
          <OrganizationAvatar avatarImage={organizationAvatar} />
        </div>
      ) : (
        <div
          className={classnames(styles.thumbnail)}
          style={{ backgroundImage: `url(${image || videoThumbnail})` }}
        >
          {!image && !videoThumbnail && <ImageIcon style={{ fontSize: '32px' }} />}
          {videoThumbnail && <PlayCircleFilledIcon className={styles.videoWatermark} />}
        </div>
      )}
      <div className={styles.midSection}>
        <Link href={link} primary className={styles.name}>
          {name}
        </Link>
        {fundName && (
          <Link className={styles.fundName} href={fundLink}>
            {fundName}
          </Link>
        )}
        <div className={styles.textWithIconContainer}>
          {totalFundAmount && (
            <div className={styles.textWithIcon}>
              <AccountBalanceIcon style={{ fontSize: '20px' }} />
              <span>{t('fund_text_with_icon.total_amount', { amount: totalFundAmount })}</span>
            </div>
          )}
          {maximumGrantAmount && (
            <div className={styles.textWithIcon}>
              <MonetizationOnIcon style={{ fontSize: '20px' }} />
              <span>
                {t('fund_text_with_icon.maximum_grant_amount', { amount: maximumGrantAmount })}
              </span>
            </div>
          )}
          {fundDeadline && (
            <div className={styles.textWithIcon}>
              <ScheduleIcon style={{ fontSize: '20px' }} />
              <span>
                {isBeforeDeadline
                  ? `${t('fund_text_with_icon.fund_deadline')} ${distanceToDeadline}`
                  : t('fund_text_with_icon.fund_closed')}
              </span>
            </div>
          )}
          {fundingRequired && (
            <div className={styles.textWithIcon}>
              <BusinessCenterIcon style={{ fontSize: '20px' }} />
              <span>
                {t('project_text_with_icon.funding_required', { amount: fundingRequired })}
              </span>
            </div>
          )}
        </div>
      </div>
      <div className={styles.buttonsSection}>
        {status && <StatusChip label={status} color={isChipPrimary ? 'primary' : 'secondary'} />}
        <Button buttonType="tertiary" to={!fundName ? link : fundLink}>
          {ctaText}
        </Button>
      </div>
    </div>
  );
};
