import React from 'react';
import { useTranslation } from 'react-i18next';
import { useForm, FormProvider } from 'react-hook-form';
import { TextInput, DatePicker } from '@Components/common';
import { CreateActionButtons, SectionHeader } from '@Components';
import { msgFieldRequired } from '@Helpers/errorMessages';
import styles from './FundDetails.module.scss';
import { Fund, FundDetails, FundFormStep2 } from '@Types';

const PURPOSE_SUM_MAX_LENGTH = 2500;

export const FundDetailsForm = ({
  currentStep,
  isLoading,
  onSubmit,
  isLaunched,
  isPastDeadline,
  isPastDeliveryDate,
  handleCancel,
  prevStep,
  stepsCount,
  fundData,
  fundDetails,
  fundDetailsData,
  setFundDetailsData,
}: {
  currentStep: number;
  isLoading: boolean;
  onSubmit: (data: FundFormStep2) => void;
  isLaunched?: boolean;
  isPastDeadline?: boolean;
  isPastDeliveryDate?: boolean;
  handleCancel: () => void;
  prevStep: () => void;
  stepsCount: number;
  fundData: Fund;
  fundDetails?: FundDetails;
  fundDetailsData?: FundDetails;
  setFundDetailsData: (formData: any) => void;
}) => {
  const { t } = useTranslation('fund');
  const methods = useForm({
    // Checks if our temp data is set,
    // this will only be set if the user started to fill it in and then went back to page one and came back to page 2
    // as we set this back to undefined once they progress to page 3
    defaultValues: fundDetailsData
      ? fundDetailsData
      : {
          deadline_to: fundDetails?.deadline_to,
          launch_from: fundDetails?.launch_from,
          project_delivery_deadline: fundDetails?.project_delivery_deadline,
          promotion_hashtag: fundDetails?.promotion_hashtag,
          purpose: fundDetails?.purpose,
          project_name: fundDetails?.project_name,
          project_postcode: fundDetails?.project_postcode,
          project_cost_code: fundDetails?.project_cost_code,
          selection_deadline: fundDetails?.selection_deadline,
        },
  });
  const { errors: fieldErrors, register, getValues } = methods;

  // Allows us to store the data when going back to a previous stage
  // this gets around needing validation for saving OR losing data due to component unmount
  const storeFundDetailsFormData = () => {
    const formData = getValues();
    if (formData) {
      setFundDetailsData(formData);
    }
  };

  const submit = methods.handleSubmit(onSubmit);

  return (
    <FormProvider {...methods}>
      <form className={styles.form}>
        <TextInput
          label={t('details.labels.purpose_sum')}
          multiline
          minRows={6}
          displayCharLimit={PURPOSE_SUM_MAX_LENGTH}
          inputProps={{
            maxLength: PURPOSE_SUM_MAX_LENGTH,
          }}
          name="purpose"
          inputRef={register({ required: msgFieldRequired, maxLength: PURPOSE_SUM_MAX_LENGTH })}
          error={!!fieldErrors.purpose}
          helperText={fieldErrors.purpose?.message}
          disabled={isLoading}
        />
        {fundData.funding_profile.custom_fund_questions_enabled && (
          <>
            <TextInput
              label={t('details.labels.project_name')}
              name="project_name"
              inputRef={register({ required: msgFieldRequired })}
              error={!!fieldErrors.project_name}
              helperText={fieldErrors.project_name?.message}
              disabled={isLoading}
            />
            <TextInput
              label={t('details.labels.project_cost_code')}
              name="project_cost_code"
              inputRef={register({ required: msgFieldRequired })}
              error={!!fieldErrors.project_cost_code}
              helperText={fieldErrors.project_cost_code?.message}
              disabled={isLoading}
            />
            <TextInput
              label={t('details.labels.project_postcode')}
              name="project_postcode"
              inputRef={register({ required: msgFieldRequired })}
              error={!!fieldErrors.project_postcode}
              helperText={fieldErrors.project_postcode?.message}
              disabled={isLoading}
            />
          </>
        )}

        <SectionHeader
          title={t('details.key_deadlines')}
          subtitle={t('details.key_deadlines_subtitle')}
        />
        <div className={styles.inline}>
          <DatePicker
            name="launch_from"
            required={true}
            label={t('details.labels.launch_from')}
            error={!!fieldErrors.launch_from}
            helperText={fieldErrors.launch_from?.message}
            disabled={isLoading || isLaunched}
          />
          <DatePicker
            name="deadline_to"
            required={true}
            label={t('details.labels.deadline_to')}
            error={!!fieldErrors.deadline_to}
            helperText={fieldErrors.deadline_to?.message}
            disabled={isLoading || isPastDeadline}
          />
        </div>
        <div>
          <p className={styles.sectionSubtitle}>
            {t('details.labels.selection_deadline_subtitle')}
          </p>
          <DatePicker
            name="selection_deadline"
            required={true}
            label={t('details.labels.selection_deadline')}
            error={!!fieldErrors.selection_deadline}
            helperText={fieldErrors.selection_deadline?.message}
            disabled={isLoading}
          />
        </div>
        <div>
          <p className={styles.sectionSubtitle}>
            {t('details.labels.project_delivery_deadline_subtitle')}
          </p>
          <DatePicker
            name="project_delivery_deadline"
            required={true}
            label={t('details.labels.project_delivery_deadline')}
            error={!!fieldErrors.project_delivery_deadline}
            helperText={fieldErrors.project_delivery_deadline?.message}
            disabled={isLoading || isPastDeliveryDate}
          />
        </div>

        <SectionHeader title={t('details.promotion')} subtitle={t('details.promotion_subtitle')} />
        <TextInput
          placeholder="#communityfund"
          name="promotion_hashtag"
          inputRef={register}
          error={!!fieldErrors.promotion_hashtag}
          helperText={fieldErrors.promotion_hashtag?.message}
          disabled={isLoading}
        />
        <CreateActionButtons
          currentStep={currentStep}
          stepsCount={stepsCount}
          nextButtonProps={{
            onClick: () => {
              setFundDetailsData(undefined);
              submit();
            },
            buttonType: 'primary',
          }}
          cancelButtonProps={{ onClick: handleCancel, buttonType: 'tertiary' }}
          backButtonProps={{
            buttonType: 'text',
            onClick: () => {
              storeFundDetailsFormData();
              prevStep();
            },
          }}
        />
      </form>
    </FormProvider>
  );
};
