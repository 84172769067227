import { LocationFields } from '@Types';
import { Point } from 'geojson';

export enum OrganizationStructureEnum {
  'CIO' = 'CIO',
  'CHARITABLE_TRUST' = 'CHARITABLE_TRUST',
  'CO_OPERATIVE_SOCIETY' = 'CO_OPERATIVE_SOCIETY',
  'CASC' = 'CASC',
  'CCBS' = 'CCBS',
  'SCIO' = 'SCIO',
  'COMPANY_LIMITED_BY_GUARANTEE' = 'COMPANY_LIMITED_BY_GUARANTEE',
  'UNINCORPORATED_ASSOCIATION_OR_CLUB' = 'UNINCORPORATED_ASSOCIATION_OR_CLUB',
  'NOT_LISTED' = 'NOT_LISTED',
}

export enum SocialPlatform {
  FACEBOOK = 'facebook',
  LINKEDIN = 'linkedin',
  INSTAGRAM = 'instagram',
  TWITTER = 'twitter',
}

export interface PublicOrganizationProfile {
  avatar_image?: string;
  city: string;
}

export interface OrganizationProfileData extends PublicOrganizationProfile, LocationFields {
  name: string;
  description: string;
  categories: number[];
  organization_id: number;
  owner_email: string;
  website_url?: string;
  facebook_url?: string;
  linkedin_url?: string;
  instagram_url?: string;
  twitter_url?: string;
  avatar_thumbnail?: string;
  set_up_year?: number;
  charity_or_company_number?: string;
  phone_number?: string;
  paid_staff_number?: number;
  volunteers_number?: number;
  member_name_1?: string;
  member_email_1?: string;
  member_name_2?: string;
  member_email_2?: string;
  legal_structure?: OrganizationStructureEnum;
  custom_fund_questions_enabled?: Boolean;
}

export interface Organization {
  id: number;
  name: string;
  contact_email: string;
  profile: PublicOrganizationProfile;
}
