import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Timeline as MaterialTimeLine,
  TimelineItem,
  TimelineSeparator,
  TimelineConnector,
  TimelineContent,
  TimelineOppositeContent,
} from '@material-ui/lab';
import { getDate, format, parseISO } from 'date-fns';
import styleVariables from '@Styles/_variables.module.scss';
import styles from './Timeline.module.scss';

const useStyles = makeStyles({
  root: {
    maxWidth: 824,
    padding: 0,
    '& .MuiTimelineItem-root': {
      position: 'relative',
      paddingBottom: 40,
      '&:last-of-type': {
        paddingBottom: 0,
      },
      '&:last-of-type .MuiTimelineSeparator-root': {
        display: 'none',
      },
      [`@media screen and (max-width: ${styleVariables.screenWidthXs})`]: {
        flexDirection: 'column',
      },
    },
    '& .MuiTimelineOppositeContent-root': {
      backgroundColor: styleVariables.colorT5,
      alignSelf: 'flex-start',
      display: 'flex',
      flexDirection: 'column',
      maxWidth: 64,
      padding: 0,
      paddingTop: 24,
      textAlign: 'center',
      position: 'relative',
      zIndex: styleVariables.zIndexTimeline,
      [`@media screen and (max-width: ${styleVariables.screenWidthXs})`]: {
        maxWidth: '100%',
        width: '100%',
      },
    },
    '& .MuiTimelineContent-root': {
      padding: 0,
      paddingLeft: 40,
      maxWidth: 'calc(100% - 64px)',
      [`@media screen and (max-width: ${styleVariables.screenWidthSm})`]: {
        paddingLeft: 20,
      },
      [`@media screen and (max-width: ${styleVariables.screenWidthXs})`]: {
        marginTop: 8,
        maxWidth: '100%',
        paddingLeft: 0,
      },
    },
    '& .MuiTimelineSeparator-root': {
      left: 32,
      height: '100%',
      position: 'absolute',
      [`@media screen and (max-width: ${styleVariables.screenWidthXs})`]: {
        display: 'none',
      },
    },
    '& .MuiTimelineConnector-root': {
      backgroundColor: 'transparent',
      borderLeft: `1px dashed ${styleVariables.colorT3}`,
      opacity: 0.4,
      width: 1,
    },
  },
});

interface Content {
  id: number;
  date: string;
  update: React.ReactNode;
}

interface TimeLineProps {
  children: Content[];
}

export const Timeline: React.FC<TimeLineProps> = ({ children }) => {
  const classes = useStyles();

  return (
    <MaterialTimeLine classes={classes}>
      {children.map(({ update, date, id }) => (
        <TimelineItem key={id}>
          <TimelineOppositeContent>
            <strong className={styles.date}>{getDate(parseISO(date))}</strong>

            <p className={styles.month}>{format(parseISO(date), 'MMMM')}</p>

            <p className={styles.fullDate}>
              {getDate(parseISO(date))} {format(parseISO(date), 'MMMM')}
            </p>
          </TimelineOppositeContent>
          <TimelineSeparator>
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent>{update}</TimelineContent>
        </TimelineItem>
      ))}
    </MaterialTimeLine>
  );
};
