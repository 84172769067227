import { useRequest } from '@Hooks/useRequest';
import { API } from '@Services';
import { ApiErrorObject } from '@Types';
import { useCallback } from 'react';

export const useDeleteProjectUpdate = () => {
  const [fetch, response] = useRequest<null | ApiErrorObject>();

  const deleteUpdate = useCallback(
    (updateId: number) =>
      fetch(`${API.paths.all_project_updates}${updateId}/`, 'DELETE', {
        data: null,
      }),
    [fetch],
  );

  return [deleteUpdate, response] as const;
};
