import { useRequest } from '@Hooks/useRequest';
import { API } from '@Services';
import { LocalAuthorityWithFeature } from '@Types';
import { useCallback } from 'react';

export const useGetLocalAuthorityWithFeature = () => {
  const [fetch, response] = useRequest<LocalAuthorityWithFeature>();

  const getLocalAuthorityWithFeature = useCallback(
    (authorityId: number) => {
      return fetch(API.paths.local_authority_with_feature(authorityId), 'get');
    },
    [fetch],
  );

  return [getLocalAuthorityWithFeature, response] as const;
};
