/* A collection of checkboxes, whose value will consist of an array of selected values for each individual checkbox */
import React from 'react';
import {
  Checkbox as MaterialCheckbox,
  FormControl as MaterialFormControl,
  FormControlLabel as MaterialFormControlLabel,
  FormControlLabelProps as MaterialFormControlLabelProps,
  FormHelperText as MaterialFormHelperText,
  FormLabel as MaterialFormLabel,
} from '@material-ui/core';
import { Controller, useFormContext } from 'react-hook-form';
import { msgChooseOption } from '@Helpers/errorMessages';
import styles from './CheckboxGroup.module.scss';

export interface CheckboxGroupItem {
  value: string;
  label: MaterialFormControlLabelProps['label'];
  hintText?: string;
}

export interface CheckboxGroupProps {
  items: CheckboxGroupItem[];
  name: string;
  required?: boolean;
  label?: string;
  disabled?: boolean;
}

const checkboxOptionsFromValue = (
  items: CheckboxGroupProps['items'],
  value?: string[],
): boolean[] => {
  // Swap a list of ['optionA', 'optionC'], to a list of [true, false, true], where 'items' contains 'optionA', 'optionB', and 'optionC'
  if (Array.isArray(value)) {
    return items.map((item) => {
      return value && value.includes(item.value);
    });
  } else {
    return [];
  }
};

const valueFromCheckboxOptions = (items: CheckboxGroupProps['items'], value: boolean[]) => {
  // Swap a list of [true, false, true], to a list of ['optionA', 'optionC'] where 'items' contains 'optionA', 'optionB', and 'optionC'
  const result: string[] = [];
  value.forEach((valueItemSelected, index) => {
    if (valueItemSelected) {
      result.push(items[index].value);
    }
  });
  if (result.length) {
    return result;
  } else {
    return [];
  }
};

const BareCheckboxGroup: React.FC<
  CheckboxGroupProps & {
    fieldErrors: any;
    setValue: any;
    value: string[];
    inputRef: React.MutableRefObject<any>;
  }
> = ({ items, name, label, value, setValue, fieldErrors, inputRef, disabled }) => {
  const itemValues = checkboxOptionsFromValue(items, value);

  const onValueChange = (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const newItemValues = [...itemValues];
    newItemValues[index] = event.target.checked;
    const newExternalValue = valueFromCheckboxOptions(items, newItemValues);
    setValue(name, newExternalValue);
  };

  const checkboxes = items.map((item, index) => {
    const checked = itemValues[index] || false;
    return (
      <MaterialFormControlLabel
        key={index}
        label={
          <div>
            <p className={styles.label}>{item.label}</p>
            {item.hintText && <MaterialFormHelperText>{item.hintText}</MaterialFormHelperText>}
          </div>
        }
        control={
          <MaterialCheckbox
            disabled={disabled}
            name={name}
            value={item.value}
            checked={checked}
            onChange={(event) => {
              onValueChange(event, index);
            }}
            inputRef={index === 0 ? inputRef : undefined}
            color="primary"
          />
        }
      />
    );
  });
  return (
    <MaterialFormControl error={!!fieldErrors[name]} fullWidth>
      {label && <MaterialFormLabel>{label}</MaterialFormLabel>}
      {checkboxes}
      <MaterialFormHelperText>{fieldErrors[name]?.message}</MaterialFormHelperText>
    </MaterialFormControl>
  );
};

export const CheckboxGroup: React.FC<CheckboxGroupProps> = (props) => {
  // react-hook-form's onChange expects an event with a string value, but since we deal in arrays we have to short-circuit that and use setValue instead.
  const { control, errors: fieldErrors, setValue } = useFormContext();
  const validate = (value: string) => {
    if (props.required && (!value || !value.length)) {
      return msgChooseOption;
    } else {
      return true;
    }
  };
  return (
    <Controller
      name={props.name}
      control={control}
      rules={{ validate }}
      render={({ ref, onChange, value }) => (
        <BareCheckboxGroup
          {...props}
          fieldErrors={fieldErrors}
          setValue={setValue}
          value={value}
          inputRef={ref}
          disabled={props.disabled}
        />
      )}
    />
  );
};
