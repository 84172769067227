import { useCallback } from 'react';
import { API } from '@Services';
import { Fund, FundStatusEnum } from 'Types';
import { usePaginatedRequest } from '@Hooks/usePaginatedRequest';

export const useGetFundsList = () => {
  const [fetch, response] = usePaginatedRequest<Fund>({ perPage: 10 });

  const fetchFundsList = useCallback(
    (
      organizationId?: number,
      status?: FundStatusEnum[],
      doesntMatchWithGroupOrganizationId?: number,
    ) => {
      fetch(API.paths.funds, {
        organization: organizationId ? organizationId.toString() : undefined,
        status: status ? status.join(',') : undefined,
        doesnt_match_with_group_organization_id: doesntMatchWithGroupOrganizationId
          ? doesntMatchWithGroupOrganizationId.toString()
          : undefined,
      });
    },
    [fetch],
  );

  return [fetchFundsList, response] as const;
};
