import { useCallback } from 'react';
import { useRequest } from '@Hooks/useRequest';
import { API } from '@Services';
import { JobStatusCheckResult } from '@Types';

export const useCheckJobStatus = () => {
  const [fetch, response] = useRequest<JobStatusCheckResult>();

  const checkJobStatus = useCallback(
    (jobId: string) => fetch(API.paths.check_job_status(jobId), 'get'),
    [fetch],
  );

  return [checkJobStatus, response] as const;
};
