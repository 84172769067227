import React from 'react';
import styles from './NavItem.module.scss';

import { Link } from '@Components/common';
import classnames from 'classnames';

export interface NavItemProps {
  name: string;
  icon: JSX.Element;
  link: string;
  active?: boolean;
}

export const NavItem = ({ name, icon, link, active }: NavItemProps) => {
  return (
    <Link to={link} className={classnames(styles.navItem, active && styles.active)}>
      {icon}
      {name}
    </Link>
  );
};
