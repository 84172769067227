import { usePaginatedRequest } from '@Hooks/usePaginatedRequest';
import { API } from '@Services';
import { ProjectUpdate } from '@Types';
import { useCallback } from 'react';

export const useGetFundProjectUpdates = () => {
  const [fetch, response] = usePaginatedRequest<ProjectUpdate>({ perPage: 10 });

  const getFundProjectUpdates = useCallback(
    (fundId: number) => {
      fetch(API.paths.all_project_updates, {
        fund_id: fundId,
        is_hidden: false,
      });
    },
    [fetch],
  );

  return [getFundProjectUpdates, response] as const;
};
