import { useCallback } from 'react';
import { API } from '@Services';
import { Project, ProjectStatusEnum } from 'Types';
import { PaginationConfiguration, usePaginatedRequest } from '@Hooks/usePaginatedRequest';

export const useProjectsList = (paginationConfiguration?: PaginationConfiguration) => {
  const [fetch, response] = usePaginatedRequest<Project>(paginationConfiguration);

  const fetchProjectsList = useCallback(
    (organizationId: number, status?: ProjectStatusEnum, statuses?: ProjectStatusEnum[]) => {
      let finalStatus: string | undefined = status;

      if (statuses && statuses.length > 0) {
        finalStatus = statuses.join();
      }

      return fetch(API.paths.projects, {
        organization: organizationId,
        status: finalStatus,
      });
    },
    [fetch],
  );

  return [fetchProjectsList, response] as const;
};
