import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { TitledTile } from '@Components/common/TitledTile/TitledTile';
import { Project } from '@Types';
import styles from './EstimatedBeneficiaries.module.scss';
import { formatNumber } from '@Helpers';

interface EstimatedBeneficiariesProps {
  fundedProjectsList: Project[];
}

export const EstimatedBeneficiaries = ({ fundedProjectsList }: EstimatedBeneficiariesProps) => {
  const beneficiariesCount = fundedProjectsList.reduce(
    (acc, { beneficiaries }) => (beneficiaries ? acc + beneficiaries : acc),
    0,
  );
  const { t } = useTranslation('fund');

  return (
    <TitledTile title={t('report.titles.beneficiaries')}>
      <Trans
        ns="fund"
        i18nKey="report.estimated_beneficiaries"
        components={[<div />, <h3 className={styles.count} />]}
        values={{ amount: formatNumber(beneficiariesCount) }}
      />
    </TitledTile>
  );
};
