// @ts-nocheck
import React from 'react';
import { SelectInput } from '@Components/common';
import {
  Chip as MaterialChip,
  InputLabel as MaterialInputLabel,
  FormControl as MaterialFormControl,
  FormHelperText as MaterialFormHelperText,
  FormHelperTextProps as MaterialFormHelperTextProps,
  MenuItem as MaterialMenuItem,
  SelectProps as MaterialSelectProps,
  makeStyles,
} from '@material-ui/core';
import styleVariables from '@Styles/_variables.module.scss';

const useStyles = makeStyles({
  chip: {
    color: styleVariables.colorT3,
    backgroundColor: styleVariables.colorT3Light,
    margin: '2px',
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
});

interface SelectWithChipsProps extends MaterialSelectProps {
  items: {
    value: string;
    text: string;
  }[];
  helperText: MaterialFormHelperTextProps['children'];
}

const findLabel = (value: string, items: SelectWithChipsProps['items']) => {
  return items.find((item) => item.value.id === value.id && item.value.type === value.type)?.text;
};

export const SelectWithChips: React.FC<SelectWithChipsProps> = ({
  items,
  helperText,
  ...props
}) => {
  const classes = useStyles();

  return (
    <MaterialFormControl error={props.error}>
      <MaterialInputLabel>{props.label}</MaterialInputLabel>
      <SelectInput
        multiple
        value={props.value}
        renderValue={(selected) => {
          return (
            <div className={classes.chips}>
              {(selected as string[]).map((o) => {
                return (
                  <MaterialChip key={o} label={findLabel(o, items)} className={classes.chip} />
                );
              })}
            </div>
          );
        }}
        {...props}
      >
        {items.map(({ value, text }) => {
          return (
            <MaterialMenuItem key={value} value={value}>
              {text}
            </MaterialMenuItem>
          );
        })}
      </SelectInput>
      <MaterialFormHelperText>{helperText}</MaterialFormHelperText>
    </MaterialFormControl>
  );
};
