import { useCallback } from 'react';
import { useRequest } from '@Hooks';
import { API } from '@Services';
import { Project } from '@Types';

export const useUpdateProject = () => {
  const [fetch, response] = useRequest<Project>();

  const updateProject = useCallback(
    (project: Project) =>
      fetch(API.paths.project(project.id), 'patch', {
        data: {
          delivery_plan: project.delivery_plan,
          volunteering_opportunity: project.volunteering_opportunity,
        },
      }),
    [fetch],
  );

  return [updateProject, response] as const;
};
