import { useCallback } from 'react';
import { useRequest } from '@Hooks';
import { API } from '@Services';
import { Selection } from '@Types';

export const useCreateSelection = () => {
  const [fetch, response] = useRequest<Selection>();

  const createSelection = useCallback(
    (fundId: number, projectId: number) =>
      fetch(API.paths.fund_selections(fundId), 'post', {
        data: { project_id: projectId },
      }),
    [fetch],
  );

  return [createSelection, response] as const;
};
