import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FullReport, ReportSummary } from './Components';
import { SurveyResponses } from './Components/SurveyResponses';
import { Fund, Project, ProjectUpdate } from '@Types';
import { Loader, OldTabs } from '@Components';
import { Grid } from '@material-ui/core';
import { useGetLocalAuthorityWithFeature, useGetResponsesCount } from '@Hooks/Api';
import { useRouter } from '@Helpers/useRouter';
import router from 'next/router';
import { useGetCurrentTab } from '@Hooks/useGetCurrentTab';
import { PaginatedResponse } from '@Hooks/usePaginatedRequest';

interface FundResultsProps {
  fundId: number;
  fundData: Fund;
  fundedProjectsList: Project[];
  updatesPaginatedResponse: PaginatedResponse<ProjectUpdate>;
}

export const FundResults: React.FC<FundResultsProps> = ({
  fundId,
  fundData,
  fundedProjectsList,
  updatesPaginatedResponse,
}) => {
  const { t } = useTranslation('fund');
  const hasEnhancedReporting = Boolean(fundData.user_has_enhanced_reporting);
  const hasIndividualResponsesAccess = Boolean(fundData.user_has_individual_responses);

  const [getLocalAuthority, { data: localAuthorityData, loading: localAuthorityLoading }] =
    useGetLocalAuthorityWithFeature();

  useEffect(() => {
    if (fundData.details.local_authority) {
      getLocalAuthority(fundData.details.local_authority);
    }
  }, [fundData.details.local_authority, getLocalAuthority]);

  const [getResponsesCount, { loading: isGetResponsesCountLoading, data: responsesCount }] =
    useGetResponsesCount();
  useEffect(() => {
    getResponsesCount(fundId);
  }, [getResponsesCount, fundId]);
  const { params } = useRouter();
  const tabs = useMemo(
    () => [
      {
        title: `${t('management.results.tabs.summary')}`,
        slug: 'summary',
      },
      {
        title: `${t('management.results.tabs.responses')}`,
        slug: 'responses',
        disabled: !hasIndividualResponsesAccess || !responsesCount,
      },
      {
        title: `${t('management.results.tabs.report')}`,
        slug: 'report',
        disabled: !hasEnhancedReporting || !responsesCount,
      },
    ],
    [hasEnhancedReporting, hasIndividualResponsesAccess, responsesCount, t],
  );

  let currentTab = useGetCurrentTab(params.fundManageSlug?.[1], tabs);

  const setTab = useCallback(
    (tabNumber: number) => {
      return router.push(`/fund/${fundId}/manage/results/${tabs[tabNumber].slug}`, undefined, {
        shallow: true,
      });
    },

    [fundId, tabs],
  );

  if (localAuthorityLoading) {
    return <Loader />;
  }

  return (
    <Grid container spacing={3} style={{ marginTop: 0 }}>
      <Grid
        item
        xs={12}
        style={{ padding: 0, marginBottom: '12px', display: 'flex', justifyContent: 'center' }}
      >
        <OldTabs tabs={tabs} currentTab={currentTab} onTabChange={setTab} subTab={true} />
      </Grid>
      {currentTab === 0 && (
        <Grid item xs={12}>
          <ReportSummary
            fundId={fundId}
            fundData={fundData}
            localAuthority={localAuthorityData}
            fundedProjectsList={fundedProjectsList}
            updatesPaginatedResponse={updatesPaginatedResponse}
          />
        </Grid>
      )}
      {currentTab === 1 && (
        <Grid item xs={12}>
          <SurveyResponses fundId={fundId} />
        </Grid>
      )}
      {currentTab === 2 && (
        <Grid item xs={12}>
          <FullReport
            fundId={fundId}
            fundData={fundData}
            localAuthority={localAuthorityData}
            fundedProjectsList={fundedProjectsList}
            updatesPaginatedResponse={updatesPaginatedResponse}
          />
        </Grid>
      )}
    </Grid>
  );
};
