import { useCallback } from 'react';
import { useInfiniteQuery } from 'react-query';
import { useRequest } from '@Hooks/useRequest';
import { API } from '@Services';
import { Fund, PagedResponse } from 'Types';

export const useViewMoreFundsList = () => {
  const [fetch] = useRequest<PagedResponse<Fund>>();

  const fetchFundsList = useCallback(
    ({ pageParam }) => {
      if (pageParam) {
        return fetch(pageParam, 'get').then((res) => res.data);
      }
      return fetch(API.paths.funds, 'get', {
        params: { limit: 11 },
      }).then((res) => res.data);
    },
    [fetch],
  );

  const { data, fetchNextPage, hasNextPage, isFetchingNextPage, status } = useInfiniteQuery(
    'viewMoreFundsList',
    fetchFundsList,
    {
      getNextPageParam: (res) => res.next,
    },
  );

  const fundsList = data?.pages.reduce((acc: Fund[], curr) => [...acc, ...curr.results], []) || [];

  return {
    data: fundsList,
    fetchMoreFunds: fetchNextPage,
    isFetchMoreLoading: isFetchingNextPage,
    hasNextPage,
    loading: status === 'loading',
  } as const;
};
