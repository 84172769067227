import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import { Modal } from '@material-ui/core';

import {
  OrganizationAvatar,
  ProjectUpdateImageUpload,
  ProjectUpdateVideoUpload,
} from '@Components';
import { Button, TextInput } from '@Components/common';
import { msgFieldRequired } from '@Helpers/errorMessages';
import { useUserContext } from '@Contexts';
import styleVariables from '@Styles/_variables.module.scss';

import styles from './CreateProjectUpdateModal.module.scss';
import { Project } from '@Types';

const POST_MAX_LENGTH = 1200;

export interface CreateProjectUpdateModalProps {
  isOpen: boolean;
  onClose: () => void;
  project: Project;
  organizationName?: string;
  isOrganizationDataLoading?: boolean;
  isProjectUpdateCreating?: boolean;
  isVideoActive: boolean;
  onOpenVideo: () => void;
  onCloseVideo: () => void;
  onSubmit: (e?: React.FormEvent<HTMLFormElement | HTMLButtonElement>) => Promise<void>;
}

export const CreateProjectUpdateModal = ({
  isOpen,
  onClose,
  project,
  organizationName,
  isProjectUpdateCreating,
  isVideoActive,
  onOpenVideo,
  onCloseVideo,
  onSubmit,
}: CreateProjectUpdateModalProps) => {
  const { t } = useTranslation('projectUpdate');
  const { register, errors: fieldErrors, getValues } = useFormContext();
  const { image: isImageFilled } = getValues();
  const { userData } = useUserContext();

  return (
    <Modal
      open={isOpen}
      className={styles.container}
      onClose={onClose}
      style={{ zIndex: Number(styleVariables.zIndexUpdateModal) }}
      keepMounted
    >
      <form className={styles.modal} onSubmit={onSubmit}>
        <div className={styles.header}>
          <OrganizationAvatar
            className={styles.avatar}
            avatarImage={userData?.organization_avatar}
          />
          <div className={styles.headerTextWrapper}>
            <div className={styles.headerTextMain}>
              <span>{t('update_for')}</span>
              <b className={styles.singleProjectName}>{project.details.name}</b>
            </div>
            <span className={styles.headerSubtext}>{`${t('by')} ${organizationName}`}</span>
          </div>
        </div>
        <div className={styles.content}>
          <div className={styles.mediaWrapper}>
            {!isVideoActive && (
              <div className={styles.mediaChoice}>
                <ProjectUpdateImageUpload disabled={isProjectUpdateCreating} isModalOpen={isOpen} />
              </div>
            )}
            {!isImageFilled && (
              <div className={styles.mediaChoice}>
                <ProjectUpdateVideoUpload
                  isVideoActive={isVideoActive}
                  handleOpenVideo={onOpenVideo}
                  handleCloseVideo={onCloseVideo}
                />
              </div>
            )}
          </div>
          <TextInput
            multiline
            minRows={3}
            maxRows={10}
            placeholder={t('update_text_input_placeholder')}
            displayCharLimit={POST_MAX_LENGTH}
            name="description"
            inputRef={register({
              required: msgFieldRequired,
              validate: (value) =>
                value.length <= POST_MAX_LENGTH ||
                (t('update_text_too_long', {
                  charCount: value.length,
                  maxChars: POST_MAX_LENGTH,
                }) as string),
            })}
            error={!!fieldErrors.description}
            helperText={fieldErrors.description?.message}
            className={styles.textInput}
            disabled={isProjectUpdateCreating}
          />
        </div>
        <div className={styles.footer}>
          <Button
            buttonType="tertiary"
            onClick={onClose}
            type="button"
            disabled={isProjectUpdateCreating}
          >
            {t('cancel')}
          </Button>
          <Button
            buttonType="primary"
            type="submit"
            onClick={onSubmit}
            disabled={isProjectUpdateCreating}
            loading={isProjectUpdateCreating}
          >
            {t('post_update')}
          </Button>
        </div>
      </form>
    </Modal>
  );
};
