import { useRequest } from '@Hooks/useRequest';
import { API } from '@Services';
import { FundSurveyReportData } from '@Types';
import { useCallback } from 'react';

export const useGetFundReportData = () => {
  const [fetch, response] = useRequest<FundSurveyReportData>();

  const getFundReportData = useCallback(
    (fundId: number) => fetch(API.paths.fund_report_data(fundId), 'get'),

    [fetch],
  );

  return [getFundReportData, response] as const;
};
