import React, { useCallback, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  withStyles,
  createStyles,
} from '@material-ui/core';

import AnnouncementIcon from '@material-ui/icons/Announcement';
import HelpIcon from '@material-ui/icons/Help';
import PeopleIcon from '@material-ui/icons/People';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter';
import ImageIcon from '@material-ui/icons/Image';
import CancelIcon from '@material-ui/icons/Cancel';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ScheduleIcon from '@material-ui/icons/Schedule';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled';
import EmojiPeople from '@material-ui/icons/EmojiPeople';

import { ConfirmModal, Tooltip, StatusChip } from '@Components';
import { Link, Button } from '@Components/common';
import { InvitationStatusChip } from '@Views/FundManagement/InvitationStatusChip';
import {
  ProjectDeliveryPlan,
  ProjectStatusEnum,
  SelectionStatusEnum,
  TimeframeEstimateUnitEnum,
} from '@Types';
import { useRouter } from '@Helpers/useRouter';
import styleVariables from '@Styles/_variables.module.scss';

import styles from './ManagementTile.module.scss';
import { getDeadlineDateInformationBreakdown } from '@Helpers/getDeadlineDateInformationBreakdown';

const StyledAccordion = withStyles(() =>
  createStyles({
    root: {
      padding: '16px',
      borderColor: styleVariables.colorT2Pale,
      [`@media screen and (max-width: ${styleVariables.screenWidthSm})`]: {
        width: '450px',
        maxWidth: '100%',
      },
      [`@media screen and (max-width: ${styleVariables.screenWidthXs})`]: {
        padding: '8px 8px 16px',
      },
    },
  }),
)(Accordion);

const StyledAccordionSummary = withStyles(() =>
  createStyles({
    root: {
      padding: 0,
    },
    content: {
      margin: 0,
      cursor: 'auto',
      maxWidth: '100%',
    },
  }),
)(AccordionSummary);

const StyledAccordionDetails = withStyles(() =>
  createStyles({
    root: {
      padding: 0,
      marginBottom: '16px',
      maxWidth: '100%',
    },
  }),
)(AccordionDetails);

interface FundManagementTileProps {
  projectId?: number;
  fundId?: number;
  updatesCount?: number;
  videoThumbnail?: string;
  projectImage?: string;
  projectName?: string;
  voteCount?: number;
  showVotes?: boolean;
  fundName?: string;
  organizationName: string;
  location?: string;
  fundingNeeded?: string;
  fundAmount?: string;
  fundingOffered?: string;
  fundDeadline?: string;
  positiveBtn?: boolean;
  positiveBtnDisabled?: boolean;
  positiveBtnText?: string | JSX.Element;
  negativeBtnText?: string | JSX.Element;
  positiveBtnStartIcon?: JSX.Element;
  positiveBtnEndIcon?: JSX.Element;
  onPositiveBtnClick?: () => void | Promise<void>;
  confirmPositiveAction?: boolean;
  confirmPositiveActionContent?: JSX.Element;
  negativeBtn?: boolean;
  onNegativeBtnClick?: () => void | Promise<void>;
  confirmNegativeAction?: boolean;
  confirmNegativeActionContent?: JSX.Element;
  isActionLoading?: boolean;
  deliveryPlan?: ProjectDeliveryPlan;
  status?: ProjectStatusEnum;
  selectionStatus?: SelectionStatusEnum;
  withStatusChip?: boolean;
  withFundLink?: boolean;
  badge?: string;
  toolTipText?: string;
  hasVolunteeringOpportunity?: boolean;
  showVolunteering?: boolean;
}

export const ManagementTile: React.FC<FundManagementTileProps> = ({
  projectId,
  fundId,
  updatesCount,
  videoThumbnail,
  projectImage,
  projectName,
  voteCount,
  showVotes,
  fundName,
  organizationName,
  location,
  fundingNeeded,
  fundAmount,
  fundingOffered,
  fundDeadline,
  positiveBtn,
  positiveBtnDisabled,
  positiveBtnText,
  negativeBtnText,
  positiveBtnStartIcon,
  positiveBtnEndIcon,
  onPositiveBtnClick,
  confirmPositiveAction,
  confirmPositiveActionContent,
  negativeBtn,
  onNegativeBtnClick,
  confirmNegativeAction,
  confirmNegativeActionContent,
  isActionLoading,
  deliveryPlan,
  status,
  selectionStatus,
  withStatusChip,
  withFundLink,
  badge,
  toolTipText,
  hasVolunteeringOpportunity,
  showVolunteering,
}) => {
  const { t } = useTranslation('fund');
  const [isExpanded, setIsExpaned] = useState(false);
  const [isConfirmPositiveModalVisible, setIsConfirmPositiveModalVisible] = useState(false);
  const [isConfirmNegativeModalVisible, setIsConfirmNegativeModalVisible] = useState(false);
  const { push } = useRouter();

  const toggleIsExpanded = useCallback(() => {
    setIsExpaned(!isExpanded);
  }, [isExpanded]);

  const showConfirmPositiveModal = useCallback(() => {
    setIsConfirmPositiveModalVisible(true);
  }, []);

  const hideConfirmPositiveModal = useCallback(() => {
    setIsConfirmPositiveModalVisible(false);
  }, []);

  const handleConfirmPositive = useCallback(() => {
    if (onPositiveBtnClick) {
      hideConfirmPositiveModal();
      onPositiveBtnClick();
    }
  }, [hideConfirmPositiveModal, onPositiveBtnClick]);

  const showConfirmNegativeModal = useCallback(() => {
    setIsConfirmNegativeModalVisible(true);
  }, []);

  const hideConfirmNegativeModal = useCallback(() => {
    setIsConfirmNegativeModalVisible(false);
  }, []);

  const handleConfirmNegative = useCallback(() => {
    if (onNegativeBtnClick) {
      hideConfirmNegativeModal();
      onNegativeBtnClick();
    }
  }, [hideConfirmNegativeModal, onNegativeBtnClick]);

  const handleViewFundClick = useCallback(() => {
    if (fundId) {
      push(`/fund/${fundId}`);
    }
  }, [fundId, push]);

  const { isBeforeDeadline, distanceToDeadline } =
    getDeadlineDateInformationBreakdown(fundDeadline);

  negativeBtnText = negativeBtnText || t('management.withdraw_application_button');

  return (
    <StyledAccordion elevation={0} expanded={isExpanded}>
      <StyledAccordionSummary>
        <div className={styles.container}>
          <div
            className={styles.thumbnail}
            style={{ backgroundImage: `url(${projectImage || videoThumbnail})` }}
          >
            {projectId ? (
              !videoThumbnail && !projectImage && <ImageIcon style={{ fontSize: '32px' }} />
            ) : (
              <AccountBalanceIcon style={{ fontSize: '32px' }} />
            )}
            {videoThumbnail && <PlayCircleFilledIcon className={styles.videoWatermark} />}
            {badge && <span className={styles.badge}>{badge}</span>}
          </div>
          <div className={styles.infoSection}>
            <Link
              to={projectId ? `/project/${projectId}` : `/fund/${fundId}`}
              className={styles.tileName}
            >
              {projectName && projectName}
              {fundName && fundName}
            </Link>
            {voteCount != null && showVotes && (
              <div className={styles.voteCount}>
                {t('management.vote_count', { count: voteCount })}
              </div>
            )}
            <div className={styles.textWithIconContainer}>
              <div className={styles.textWithIcon}>
                <PeopleIcon style={{ fontSize: '20px' }} />
                <span>{organizationName}</span>
              </div>
              {location && (
                <div className={styles.textWithIcon}>
                  <LocationOnIcon style={{ fontSize: '20px' }} />
                  <span>{location}</span>
                </div>
              )}
              <div className={styles.textWithIcon}>
                <BusinessCenterIcon style={{ fontSize: '20px' }} />
                {fundingNeeded && (
                  <span>
                    {t('management.funding_needed')} <b>{fundingNeeded}</b>
                  </span>
                )}
                {fundAmount && (
                  <span>
                    <Trans
                      ns="project"
                      i18nKey="management.fund_amount"
                      components={[<b />]}
                      values={{ amount: fundAmount }}
                    />
                  </span>
                )}
                {fundingOffered && (
                  <span>
                    <Trans
                      ns="project"
                      i18nKey="management.funding_offered"
                      components={[<b />]}
                      values={{ amount: fundingOffered }}
                    />
                  </span>
                )}
              </div>
              {typeof updatesCount === 'number' && (
                <div className={styles.textWithIcon}>
                  <AnnouncementIcon style={{ fontSize: '20px' }} />
                  <span>
                    <Trans
                      ns="project"
                      i18nKey="management.project_updates"
                      values={{ amount: updatesCount }}
                    />
                  </span>
                </div>
              )}
              {fundDeadline && (
                <div className={styles.textWithIcon}>
                  <ScheduleIcon style={{ fontSize: '20px' }} />
                  <span>
                    {isBeforeDeadline
                      ? `${t('project:management.fund_deadline')} ${distanceToDeadline}`
                      : t('project:management.fund_closed')}
                  </span>
                </div>
              )}
            </div>
            {hasVolunteeringOpportunity && showVolunteering && (
              <StatusChip
                icon={<EmojiPeople />}
                label={t(`project:volunteering.header`)}
                color="primary"
              />
            )}
          </div>
          <div className={styles.buttonsSection}>
            {withStatusChip && selectionStatus ? (
              <InvitationStatusChip status={selectionStatus} />
            ) : (
              withStatusChip && status && <InvitationStatusChip status={status} />
            )}
            {deliveryPlan && (
              <Button
                buttonType="tertiary"
                endIcon={isExpanded ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                onClick={toggleIsExpanded}
              >
                {t('management.delivery_plan_button')}
              </Button>
            )}
            {withFundLink && fundId && (
              <Button buttonType="tertiary" onClick={handleViewFundClick}>
                {t('project:management.view_fund')}
              </Button>
            )}
            {negativeBtn && (
              <Button
                buttonType="danger"
                startIcon={<CancelIcon />}
                disabled={selectionStatus === SelectionStatusEnum.UNAVAILABLE || isActionLoading}
                onClick={confirmNegativeAction ? showConfirmNegativeModal : onNegativeBtnClick}
              >
                {negativeBtnText}
              </Button>
            )}
            {positiveBtn && (
              <>
                <Button
                  buttonType="success"
                  startIcon={positiveBtnStartIcon}
                  endIcon={positiveBtnEndIcon}
                  disabled={
                    selectionStatus === SelectionStatusEnum.UNAVAILABLE ||
                    isActionLoading ||
                    positiveBtnDisabled
                  }
                  onClick={confirmPositiveAction ? showConfirmPositiveModal : onPositiveBtnClick}
                >
                  {positiveBtnText}
                </Button>
                {toolTipText && (
                  <Tooltip disableFocusListener title={toolTipText}>
                    <HelpIcon />
                  </Tooltip>
                )}
              </>
            )}
          </div>
          <ConfirmModal
            isOpen={isConfirmPositiveModalVisible}
            onClose={hideConfirmPositiveModal}
            onConfirm={handleConfirmPositive}
          >
            {confirmPositiveActionContent}
          </ConfirmModal>
          <ConfirmModal
            isOpen={isConfirmNegativeModalVisible}
            onClose={hideConfirmNegativeModal}
            onConfirm={handleConfirmNegative}
            isConfirmBtnDanger
          >
            {confirmNegativeActionContent}
          </ConfirmModal>
        </div>
      </StyledAccordionSummary>
      <StyledAccordionDetails>
        <div className={styles.deliveryPlanContainer}>
          <hr className={styles.divider} />
          <div className={styles.deliveryPlanContent}>
            <div>
              <p className={styles.sectionHeader}>{t('management.budget_breakdown')}</p>
              <table>
                <tbody>
                  {deliveryPlan?.budget_breakdown.map(({ amount, cost_description }, i) => (
                    <tr key={i} className={styles.budgetTableRow}>
                      <td>{t('common:currency_format', { amount })}</td>
                      <td>{cost_description}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <p className={styles.sectionHeader}>{t('management.delivery_timeframe')}</p>
              {deliveryPlan?.timeframe_estimate_unit === TimeframeEstimateUnitEnum.WEEK &&
                t('management.delivery_timeframe_text_week', {
                  count: deliveryPlan.timeframe_estimate_number,
                })}
              {deliveryPlan?.timeframe_estimate_unit === TimeframeEstimateUnitEnum.MONTH &&
                t('management.delivery_timeframe_text_month', {
                  count: deliveryPlan.timeframe_estimate_number,
                })}
            </div>
            <div>
              <p className={styles.sectionHeader}>{t('management.impact_size')}</p>
              <span>
                {t('management.impact_size_text', { count: deliveryPlan?.beneficiaries })}
              </span>
              <p className={styles.sectionHeader}>{t('management.expected_impact')}</p>
              <span>{deliveryPlan?.expected_impact}</span>
            </div>
          </div>
        </div>
      </StyledAccordionDetails>
    </StyledAccordion>
  );
};
