import { useCallback } from 'react';
import { useRequest } from '@Hooks';
import { API } from '@Services';
import { ApiErrorObject } from '@Types';

export const useProjectRejectsInvitation = () => {
  const [fetch, response] = useRequest<null | ApiErrorObject>();

  const projectRejectsInvitation = useCallback(
    (projectId: number, selectionId: number) =>
      fetch(API.paths.project_rejects_invitation(projectId, selectionId), 'patch', {
        data: null,
      }),
    [fetch],
  );

  return [projectRejectsInvitation, response] as const;
};
