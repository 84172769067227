import React, { useCallback, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import ErrorIcon from '@material-ui/icons/Error';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { Tooltip } from '@Components';
import { TextInput } from '@Components/common';
import styleVariables from '@Styles/_variables.module.scss';
import styles from './BudgetBreakdown.module.scss';
import { msgFieldRequired, msgFieldInvalid } from '@Helpers/errorMessages';

interface BudgetBreakdownProps {
  grantAmount?: number;
  elementsCount?: number;
}

export const BudgetBreakdown: React.FC<BudgetBreakdownProps> = ({ grantAmount, elementsCount }) => {
  const [fieldsCount, setFieldsCount] = useState(elementsCount ? Math.max(elementsCount, 3) : 3);
  const [totalAmount, setTotalAmount] = useState(0);
  const { register, getValues, errors: fieldErrors, setValue, trigger } = useFormContext();
  const { t } = useTranslation('project');

  const handleAddCost = () => {
    setFieldsCount(fieldsCount + 1);
    summarizeAmounts();
  };
  const summarizeAmounts = useCallback(() => {
    setTotalAmount(
      getValues().delivery_plan.budget_breakdown.reduce(
        (acc: number, { amount }: { amount: number }) => Number(acc) + Number(amount),
        0,
      ),
    );
  }, [getValues]);

  useEffect(() => {
    summarizeAmounts();
  }, [summarizeAmounts, fieldsCount]);

  const handleAmountChange = useCallback(() => {
    summarizeAmounts();
  }, [summarizeAmounts]);

  const handleAmountBlur = useCallback(
    (fieldName: string) => () => {
      const currValue = getValues(fieldName);
      if (currValue) {
        setValue(fieldName, Math.abs(Number(currValue)));
        trigger(fieldName);
      }

      summarizeAmounts();
    },
    [getValues, setValue, summarizeAmounts, trigger],
  );

  const isTotalEqualGrant = totalAmount === grantAmount;
  const costDescriptionMaxLength = 200;

  return (
    <div className={styles.budgetWrapper}>
      {new Array(fieldsCount).fill('').map((_, index) => {
        const fieldName = `delivery_plan.budget_breakdown[${index}]`;
        return (
          <fieldset key={fieldName} name={fieldName} className={styles.fieldset}>
            <TextInput
              label={t('delivery.cost_description')}
              name={`${fieldName}.cost_description`}
              inputRef={register({
                required: index === 0 && msgFieldRequired,
                maxLength: costDescriptionMaxLength,
              })}
              inputProps={{
                maxLength: costDescriptionMaxLength,
              }}
              displayCharLimit={costDescriptionMaxLength}
              error={!!fieldErrors.delivery_plan?.budget_breakdown?.[index]?.cost_description}
              helperText={
                fieldErrors.delivery_plan?.budget_breakdown?.[index]?.cost_description?.message
              }
            />
            <TextInput
              type="number"
              label={t('delivery.amount', { currency: t('common:global_currency') })}
              name={`${fieldName}.amount`}
              inputRef={register({
                required: index === 0 && msgFieldRequired,
                pattern: {
                  value: /^0*[1-9]\d*(.00)?$/,
                  message: t('common:whole_number_error'),
                },
              })}
              onChange={handleAmountChange}
              onBlur={handleAmountBlur(`${fieldName}.amount`)}
              error={!!fieldErrors.delivery_plan?.budget_breakdown?.[index]?.amount}
              helperText={fieldErrors.delivery_plan?.budget_breakdown?.[index]?.amount?.message}
            />
          </fieldset>
        );
      })}
      <div className={styles.fieldset}>
        <div className={styles.addBtnTotalWrapper}>
          <button type="button" onClick={handleAddCost} className={styles.addButton}>
            <AddCircleIcon style={{ color: styleVariables.colorT3 }} />
            {t('delivery.add_cost')}
          </button>
          <span className={styles.total}>{t('delivery.total')}:</span>
        </div>
        <div>
          <span
            className={classnames(
              styles.totalField,
              isTotalEqualGrant && styles.totalFieldSuccess,
              !isTotalEqualGrant && styles.totalFieldError,
            )}
          >
            <span>{t('common:currency_format', { amount: totalAmount })}</span>
            {isTotalEqualGrant ? (
              <CheckCircleIcon />
            ) : (
              <Tooltip title={t('delivery.total_error') as string}>
                <ErrorIcon />
              </Tooltip>
            )}
          </span>
          <span className={styles.totalHelper}>
            {t('delivery.total_helper', { amount: grantAmount })}
          </span>
        </div>
      </div>
    </div>
  );
};
