import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation, Trans } from 'react-i18next';
import classnames from 'classnames';

import DeleteIcon from '@material-ui/icons/Delete';
import EmojiObjectsOutlinedIcon from '@material-ui/icons/EmojiObjectsOutlined';

import { StatusChip, FieldNote, ImageUploadDropzone } from '@Components';
import { Link } from '@Components/common';

import styles from './WrittenPitchImageUpload.module.scss';
import { DEFAULT_UPLOAD_CONFIG } from '@Helpers/images';
import { useImageUpload } from '@Hooks';

const FIELD_NAME = 'image';

export const WrittenPitchImageUpload: React.FC = () => {
  const { t } = useTranslation('project');
  const { errors: fieldErrors } = useFormContext();

  const { file, deleteFile, dropzoneInputProps, dropzoneRootProps } = useImageUpload({
    fieldName: FIELD_NAME,
    uploadConfig: DEFAULT_UPLOAD_CONFIG,
  });

  const isDropzoneHidden = Array.isArray(file) || typeof file === 'string';

  return (
    <div>
      <ImageUploadDropzone
        fieldName={FIELD_NAME}
        dropzoneInputProps={dropzoneInputProps}
        dropzoneRootProps={dropzoneRootProps}
        subtext={
          <Trans
            ns="project"
            i18nKey="pitch.upload_image_hint"
            components={[<strong className={styles.textBold} />]}
          />
        }
        className={classnames(isDropzoneHidden && styles.hidden)}
        chip={<StatusChip color="primary" label={t('pitch.upload_required')} />}
        required
      />

      <FieldNote
        className={classnames(styles.note, isDropzoneHidden && styles.hidden)}
        icon={<EmojiObjectsOutlinedIcon style={{ fontSize: 15 }} />}
      >
        <Trans
          ns="project"
          i18nKey="pitch.upload_field_note"
          components={[
            <strong className={styles.textBold} />,
            <Link href="https://www.birme.net" primary />,
          ]}
        />
      </FieldNote>

      {isDropzoneHidden && (
        <div className={styles.previewWrapper}>
          <img
            className={styles.image}
            src={typeof file === 'string' ? file : file && file[0].preview}
            alt=""
          />

          <button onClick={deleteFile} className={styles.deleteIcon}>
            <DeleteIcon style={{ fontSize: 20 }} />
          </button>
        </div>
      )}

      {fieldErrors.image?.message && (
        <p className={styles.errorMessage}>{fieldErrors.image.message}</p>
      )}
    </div>
  );
};
