import React from 'react';
import { Trans } from 'react-i18next';
import { ResponsesDownloadButton } from './ResponsesDownloadButton';
import { TitledTile } from '@Components/common/TitledTile/TitledTile';
import styles from './SurveyResponses.module.scss';

interface SurveyResponsesProps {
  fundId: number;
}

export const SurveyResponses = ({ fundId }: SurveyResponsesProps) => {
  return (
    <TitledTile>
      <div className={styles.buttonContainer}>
        <Trans ns="fund" i18nKey="report.responses_display_unavailable" />
        <ResponsesDownloadButton fundId={fundId} />
      </div>
    </TitledTile>
  );
};
