import React from 'react';
import { NavItem, NavItemProps } from '@Components';
import styles from './Nav.module.scss';

import Drawer from '@material-ui/core/Drawer';
import { makeStyles, useMediaQuery, useTheme } from '@material-ui/core';
import styleVariables from '@Styles/_variables.module.scss';
import { itemIsSelected } from '@Helpers/itemIsSelected';
import router from 'next/router';
import { UserTypeEnum } from '@Types';
import classnames from 'classnames';
import { useUserContext } from '@Contexts';

interface NavProps {
  navItems: NavItemProps[];
  userType?: UserTypeEnum;
}

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  drawerPaper: {
    width: `${styleVariables.navWidth}`,
    backgroundColor: `${styleVariables.AFNavyBlue500}`,
    marginRight: '20px',
    [`@media screen and (max-width: ${styleVariables.screenWidthXs})`]: {
      width: '100vw',
      backgroundColor: `${styleVariables.AFNavyBlue500}`,
    },
  },
});

export const Nav = ({ navItems, userType }: NavProps) => {
  const classes = useStyles();
  const theme = useTheme();
  const screenXs = useMediaQuery(theme.breakpoints.down('xs'));
  const { userData, isUserLoading } = useUserContext();

  return (
    <Drawer
      variant="permanent"
      anchor={screenXs ? 'bottom' : 'left'}
      classes={{
        paper: classes.drawerPaper,
      }}
    >
      <div className={styles.navContainer}>
        <img className={styles.logo} src={'/assets/Logo/logoSymbol.svg'} alt="ActionFunder logo" />
        <div className={styles.navButtonsContainer}>
          <div
            className={classnames(
              styles.variableNavItemsContainer,
              userType === UserTypeEnum.FUNDER &&
                userData?.messaging_enabled &&
                styles.funderNavItems,
            )}
          >
            {navItems.map(({ name, icon, link }, i) => {
              return (
                <NavItem
                  key={i}
                  name={name}
                  icon={icon}
                  link={link}
                  active={itemIsSelected(router.asPath, link, false)}
                />
              );
            })}
          </div>
        </div>
      </div>
    </Drawer>
  );
};
