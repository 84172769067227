import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useRequest } from '@Hooks';
import { API } from '@Services';
import { useNotificationsContext, useUserContext } from '@Contexts';
import isServer from '@Helpers/isServer';
import { UserTypeEnum } from '@Types';

interface VerifyEmailResponse {
  key: string;
}

export const useVerifyEmail = (verifyKey: string) => {
  const [fetch, response] = useRequest<VerifyEmailResponse>();
  const { t } = useTranslation('createAccount');
  const { __insecureUserPatch, userData } = useUserContext();
  const { success } = useNotificationsContext();

  useEffect(() => {
    fetch(API.paths.verify_email, 'post', {
      data: {
        key: verifyKey,
      },
    }).then(() => {
      if (!isServer && window.dataLayer && userData) {
        window.dataLayer.push({
          event: userData.user_type === UserTypeEnum.FUNDER ? 'funderSignups' : 'groupSignups',
        });
      }
    });
  }, [fetch, userData, verifyKey]);

  useEffect(() => {
    if (response.data && !response.isError && !response.loading) {
      __insecureUserPatch({ email_confirmed: true });
      success(t('signing.email_verified_message'));
    }
  }, [response, success, t, __insecureUserPatch]);

  return response;
};
