import Script from 'next/script';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { HelpCenterRoutes, Routes, RoutingPaths } from '@App/paths';
import { Header, Loader, NotFound404, NotificationPopup } from '@Components';
import { Nav, NavItemProps } from '@Components/AppLayout';
import {
  useUserContext,
  useAppLayoutContext,
  useNotificationsContext,
  useRedirectContext,
} from '@Contexts';
import * as authToken from '@Helpers/authToken';
import { useRouter } from '@Helpers/useRouter';
import {
  RiMoneyPoundCircleFill,
  RiFolder5Fill,
  RiLifebuoyLine,
  RiHome4Fill,
  RiPieChart2Fill,
  RiMailFill,
} from 'react-icons/ri';

import styles from './AppLayout.module.scss';
import classnames from 'classnames';
import { IncompleteProfileCard } from '@Components/ProfileCard/IncompleteProfileCard';
import { getHelpCenterURL } from '@Helpers/helpCenter';
import { useMediaQuery, useTheme } from '@material-ui/core';

export interface MainLayoutProps {
  children: React.ReactNode;
  noTopPadding?: boolean;
}

export const MainLayout = ({ children, noTopPadding }: MainLayoutProps) => {
  const { t } = useTranslation();
  const { subheader } = useAppLayoutContext();
  const { notificationProps } = useNotificationsContext();
  const { userData, isUserLoading } = useUserContext();
  const theme = useTheme();
  const screenXs = useMediaQuery(theme.breakpoints.down('xs'));

  const groupNavItems: NavItemProps[] = [
    {
      name: t('common:nav.home'),
      icon: <RiHome4Fill className={styles.navIcon} />,
      link: Routes.DASHBOARD_GROUP,
    },
    {
      name: t('common:nav.funding'),
      icon: <RiMoneyPoundCircleFill className={styles.navIcon} />,
      link: Routes.FUNDING_OPPORTUNITIES_NAV,
    },
    {
      name: t('common:nav.projects'),
      icon: <RiFolder5Fill className={styles.navIcon} />,
      link: Routes.MY_PROJECTS,
    },
  ];
  const funderNavItems: NavItemProps[] = [
    {
      name: t('common:nav.home'),
      icon: <RiHome4Fill className={styles.navIcon} />,
      link: Routes.DASHBOARD_FUNDER,
    },
    {
      name: t('common:nav.funds'),
      icon: <RiFolder5Fill className={styles.navIcon} />,
      link: Routes.MY_FUNDS,
    },
    {
      name: t('common:nav.impact'),
      icon: <RiPieChart2Fill className={styles.navIcon} />,
      link: Routes.FUNDER_IMPACT_DASHBOARD_NAV,
    },
  ];

  const navItems =
    !isUserLoading && userData?.user_type == 'GROUP_USER' ? groupNavItems : funderNavItems;

  if (userData?.messaging_enabled) {
    navItems.push({
      name: t('common:nav.messages'),
      icon: <RiMailFill className={styles.navIcon} />,
      link: Routes.MESSAGES,
    });
  }

  navItems.push({
    name: screenXs ? t('common:nav.help_centre_small') : t('common:nav.help_centre'),
    icon: <RiLifebuoyLine className={styles.navIcon} />,
    link: userData
      ? getHelpCenterURL({
          path:
            userData.user_type == 'GROUP_USER' ? HelpCenterRoutes.GROUPS : HelpCenterRoutes.FUNDERS,
          user: userData,
        })
      : '',
  });

  const isLoggedIn = userData && !isUserLoading;

  return (
    <>
      <div className={styles.container}>
        <Header publicLayout={!isLoggedIn} />
        <NotificationPopup {...notificationProps} />
        <div className={classnames(styles.content, !isLoggedIn && styles.publicContent)}>
          {isLoggedIn && <Nav navItems={navItems} userType={userData?.user_type} />}
          {subheader}
          <div
            className={classnames(
              styles.childrenWrapper,
              noTopPadding && styles.childrenWrapperNoTopPadding,
            )}
          >
            {children}
          </div>
        </div>
        <footer className={styles.footer}>{t('common:copyrights')}</footer>
        <Script
          strategy="afterInteractive"
          dangerouslySetInnerHTML={{
            __html: `
          (function(e,t,o,n,p,r,i){e.visitorGlobalObjectAlias=n;e[e.visitorGlobalObjectAlias]=e[e.visitorGlobalObjectAlias]||function(){(e[e.visitorGlobalObjectAlias].q=e[e.visitorGlobalObjectAlias].q||[]).push(arguments)};e[e.visitorGlobalObjectAlias].l=(new Date).getTime();r=t.createElement("script");r.src=o;r.async=true;i=t.getElementsByTagName("script")[0];i.parentNode.insertBefore(r,i)})(window,document,"https://diffuser-cdn.app-us1.com/diffuser/diffuser.js","vgo");
          vgo('setAccount', '609968956');
          vgo('setTrackByDefault', true);
          
          vgo('process');
          `,
          }}
        />
      </div>
    </>
  );
};

enum ProtectedRouteState {
  SUCCESS,
  LOADING,
  NO_AUTH_TOKEN,
  NOT_CONFIRMED,
  NO_PROFILE,
}

/*
Some pages needs to be rendered regarding user object state
This includes such cases like user in confirming email state, group/funding not created
Rule of thumb is: if we get any additional rules in letting user in,
then probably we should add handling page here.
email_confirmed -> CONFIRM_EMAIL
has_organization_profile -> CREATE_GROUP, CREATE_FUNDING
*/
const NOT_CONFIRMED_EXCEPTIONS: string[] = [
  RoutingPaths.CONFIRM_EMAIL,
  Routes.GROUP_PROFILE_CREATE,
  Routes.FUNDER_PROFILE_CREATE,
];

const NO_PROFILE_EXCEPTIONS: string[] = [
  RoutingPaths.DASHBOARD,
  RoutingPaths.DASHBOARD_FUNDER,
  RoutingPaths.DASHBOARD_GROUP,
  Routes.GROUP_PROFILE_CREATE,
  Routes.FUNDER_PROFILE_CREATE,
  RoutingPaths.CONFIRM_EMAIL,
];

/*
  Main purpose of this component is to guarantee that children component
  have access to `useUserContext::userData`, so *probably* we can assume
  in any child component (*Controller), that user is logged in and `userData`
  is available (not `undefined`) 🤞
  This layout should protect also `User::email_confirmed === false`
 */
export const MainLayoutProtected = ({ children, ...extraProps }: MainLayoutProps) => {
  const [state, setState] = useState(ProtectedRouteState.LOADING);
  const { userData, isUserLoading } = useUserContext();
  const { pathname, params, push, path } = useRouter();
  const { setRedirectedFrom } = useRedirectContext();

  // If we don't have a token, then we're probably not logged
  // If there is token, then we're probably at the middle of login transaction, so please wait
  useEffect(() => {
    const isLoading = isUserLoading && !userData;
    const emailConfirmed = userData?.email_confirmed;
    const hasOrganizationProfile = userData?.has_organization_profile;

    if (!authToken.hasToken()) {
      setState(ProtectedRouteState.NO_AUTH_TOKEN);
      setRedirectedFrom(path);
    } else if (isLoading) {
      setState(ProtectedRouteState.LOADING);
    } else if (!emailConfirmed) {
      if (NOT_CONFIRMED_EXCEPTIONS.includes(pathname)) {
        // Edge case for logged in and confirming! Do not redirect this to RoutingPaths.NOT_LOGGED!
        setState(ProtectedRouteState.SUCCESS);
      } else {
        setState(ProtectedRouteState.NOT_CONFIRMED);
      }
    } else if (!hasOrganizationProfile) {
      if (NO_PROFILE_EXCEPTIONS.includes(pathname)) {
        // Edge case for logged in and confirming! Do not redirect this to RoutingPaths.NOT_LOGGED!
        setState(ProtectedRouteState.SUCCESS);
      } else {
        setState(ProtectedRouteState.NO_PROFILE);
      }
    } else {
      setState(ProtectedRouteState.SUCCESS);
    }
  }, [isUserLoading, path, pathname, setRedirectedFrom, userData]);

  switch (state) {
    case ProtectedRouteState.LOADING:
      return (
        <MainLayout>
          <Loader />
        </MainLayout>
      );
    case ProtectedRouteState.SUCCESS:
      return <MainLayout {...extraProps}>{children}</MainLayout>;
    case ProtectedRouteState.NOT_CONFIRMED:
      if (params.verifyKey) {
        return <MainLayout>{children}</MainLayout>;
      } else {
        push(RoutingPaths.CONFIRM_EMAIL);
        return null;
      }
    case ProtectedRouteState.NO_PROFILE:
      if (userData) {
        return (
          <MainLayout>
            <div className={styles.noProfileContainer}>
              <IncompleteProfileCard userType={userData.user_type} />
            </div>
          </MainLayout>
        );
      } else {
        return <NotFound404 />;
      }

    case ProtectedRouteState.NO_AUTH_TOKEN:
      push(RoutingPaths.SIGN_IN);
      return null;
  }
};
