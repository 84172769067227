import React from 'react';
import { VideoPlayer } from '@Components';
import { Link } from '@Components/common';
import styles from './FeaturedMediaItem.module.scss';

interface FeaturedMediaItemProps {
  type: string;
  url: string;
  updateID: number;
  fundID: number;
}

export const FeaturedMediaItem = ({ type, url, fundID, updateID }: FeaturedMediaItemProps) => {
  return (
    <>
      <Link to={`/fund/${fundID}/manage/results/report?projectUpdate=${updateID}`} key={updateID}>
        {type === 'image' && <img src={url} className={styles.image} alt="" />}
        {type === 'video' && <VideoPlayer videoUrl={url} />}
      </Link>
    </>
  );
};
