import * as storage from '@Helpers/storage';

const TOKEN_KEY = 'actionfunder-2fa-token';

export const get = () => {
  return storage.getItem(TOKEN_KEY);
};

export const set = (value: string) => {
  storage.setItem(TOKEN_KEY, value);
};

export const remove = () => {
  return storage.removeItem(TOKEN_KEY);
};

export const hasToken = () => {
  return Boolean(get());
};
