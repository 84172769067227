import styles from './CopyToClipboard.module.scss';
import { useNotificationsContext } from '@Contexts';
import { useTranslation } from 'react-i18next';

export interface CopyToClipboardProps {
  valueToCopy: string;
  buttonText?: string;
  successText?: string;
}

export const CopyToClipboard = ({
  valueToCopy,
  buttonText: buttonTextOverride,
  successText: successTextOverride,
}: CopyToClipboardProps) => {
  const { t } = useTranslation('common');
  const { success: successNotification, error: errorNotification } = useNotificationsContext();
  const buttonText = buttonTextOverride ? buttonTextOverride : t('copy_link');
  const successText = successTextOverride ? successTextOverride : t('copy_to_clipboard_success');

  const handleCopy = () => {
    if (navigator && valueToCopy) {
      navigator.clipboard.writeText(valueToCopy).then(
        () => {
          successNotification(successText);
        },
        () => {
          errorNotification(t('copy_to_clipboard_fail'));
        },
      );
    } else {
      errorNotification(t('copy_to_clipboard_fail'));
    }
  };
  return (
    <div className={styles.linkWrapper}>
      <div className={styles.link}>{valueToCopy}</div>
      <button className={styles.copy} type="button" onClick={handleCopy}>
        {buttonText}
      </button>
    </div>
  );
};
