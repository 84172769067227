import { useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useRequest } from '@Hooks';
import { UserTypeEnum } from '@Types';
import * as authToken from '@Helpers/authToken';
import { API } from '@Services';
import { useRouter } from '@Helpers/useRouter';
import { RoutingPaths } from '@App/paths';
import { useNotificationsContext, useUserContext } from '@Contexts';
import { recordDataLayerEvent } from '@Helpers/analytics';

export interface SignUpVariables {
  name: string;
  email: string;
  organisation_name: string;
  password: string;
  user_type: UserTypeEnum;
  tos_agreement: boolean;
  campaign_affiliate_code?: string;
  nomination?: string;
}

interface SignUpResponse {
  key: string;
}

export const useSignUp = () => {
  const [fetch, response] = useRequest<SignUpResponse>();
  const { push } = useRouter();
  const { success } = useNotificationsContext();
  const { t } = useTranslation('createAccount');
  const { fetchUserData } = useUserContext();

  const signUp = useCallback(
    (data: SignUpVariables) =>
      fetch(API.paths.sign_up, 'post', {
        data,
      }).then(() => {
        if (data) {
          recordDataLayerEvent(
            data.user_type === UserTypeEnum.FUNDER ? 'funderSignup' : 'groupSignup',
          );
        }
      }),
    [fetch],
  );

  useEffect(() => {
    if (response.data?.key) {
      authToken.set(response.data.key);
      fetchUserData();
      success(t('signing.welcome_info'));
      push(RoutingPaths.CONFIRM_EMAIL);
    }
  }, [response.data, fetchUserData, push, success, t]);

  return { signUp, response };
};
