import React, { Fragment } from 'react';
import classnames from 'classnames';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';

import { CategoryTile } from '@Components/common';
import { Category, UserTypeEnum } from '@Types';
import { useUserContext } from '@Contexts';

import styles from './CategoriesList.module.scss';

const DEFAULT_VALUE: never[] = [];

interface CategoriesListProps {
  name: string;
  categoriesList: Category[];
  disabled?: boolean;
  required?: boolean;
  changeSideEffect?: () => void;
}

export const CategoriesList: React.FC<CategoriesListProps> = ({
  name,
  categoriesList,
  disabled,
  required,
  changeSideEffect,
}) => {
  const { t } = useTranslation('common');
  const { control, errors: fieldErrors, getValues } = useFormContext();
  const { userData } = useUserContext();

  const handleCheck = (checkedId: number) => {
    const { categories } = getValues();
    return categories?.includes(checkedId)
      ? categories?.filter((id: any) => id !== checkedId)
      : [...(categories ?? []), checkedId];
  };

  const getCategoryDescription = (category: Category) =>
    userData?.user_type === UserTypeEnum.FUNDER
      ? category.description_for_funders
      : category.description_for_groups;
  return (
    <Fragment>
      <Controller
        name={name}
        control={control}
        defaultValue={DEFAULT_VALUE}
        rules={{
          validate: (checked: number[]) =>
            (required && checked.length > 0) || (t('errors.no_category') as string),
        }}
        render={({ value, onChange, ref }) => {
          return (
            <Grid
              container
              spacing={2}
              className={classnames({ [styles.categoriesError]: fieldErrors[name] })}
            >
              {categoriesList.map((cat) => (
                <Grid item xs={12} key={cat.id}>
                  <CategoryTile
                    id={cat.id}
                    name={cat.name}
                    checked={value.includes(cat.id)}
                    description={getCategoryDescription(cat)}
                    image={cat.image}
                    disabled={disabled}
                    onChange={() => {
                      onChange(handleCheck(cat.id));
                      if (changeSideEffect) {
                        changeSideEffect();
                      }
                    }}
                    inputRef={ref}
                  />
                </Grid>
              ))}
            </Grid>
          );
        }}
      />
      {fieldErrors[name] && (
        <p className={styles.errorMessage}>{fieldErrors.categories?.message}</p>
      )}
    </Fragment>
  );
};
