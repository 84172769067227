import React, { useEffect } from 'react';
import { Loader } from '@Components';
import { useCategories } from '@Hooks/Api';
import { CategoriesList } from './CategoriesList';

interface CategoriesListControllerProps {
  name: string;
  disabled?: boolean;
  parentOnly?: boolean;
  required?: boolean;
  changeSideEffect?: () => void;
}

export const CategoriesListController: React.FC<CategoriesListControllerProps> = ({
  name,
  disabled,
  parentOnly,
  required,
  changeSideEffect,
}) => {
  const [fetchCategories, { data: categoriesData, loading: isCategoriesLoading }] = useCategories(
    Boolean(parentOnly),
  );

  useEffect(() => {
    fetchCategories();
  }, [fetchCategories]);

  if (isCategoriesLoading || !categoriesData) return <Loader />;

  return (
    <CategoriesList
      categoriesList={categoriesData}
      name={name}
      disabled={disabled}
      required={required}
      changeSideEffect={changeSideEffect}
    />
  );
};
