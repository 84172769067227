/*
Given a list of nonprofit types for projects that have been funded, graph the frequency of different types of nonprofit in that list.
*/
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { StackedColumn } from '@Components/Charts/StackedColumn';

export interface NonprofitTypeStackedColumnProps {
  nonprofitTypes: string[];
}

export const NonprofitTypeStackedColumn = ({ nonprofitTypes }: NonprofitTypeStackedColumnProps) => {
  const { t } = useTranslation('common');
  const organizationTypeCounts = useMemo(() => {
    const result: { [key: string]: number } = {};
    nonprofitTypes.forEach((nonprofitType: string) => {
      if (typeof result[nonprofitType] === 'undefined') {
        result[nonprofitType] = 1;
      } else {
        result[nonprofitType] += 1;
      }
    });
    return result;
  }, [nonprofitTypes]);

  const formattedData = Object.entries(organizationTypeCounts).map(([type, value]) => {
    return {
      name: t(`group_types.${type}`),
      data: [value],
    };
  });
  return <StackedColumn yLabel={t('number_of_organizations')} data={formattedData} />;
};
