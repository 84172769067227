import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';
import * as Sentry from '@sentry/react';
import { useUserData } from '@Hooks/Api';
import { User } from '@Types';
import * as authToken from '@Helpers/authToken';
import { useRouter } from '@Helpers/useRouter';
import { RoutingPaths } from '@App/paths';

export const useUserContextController = () => {
  const [currentUserData, setCurrentUserDataState] = useState<User>();
  const { userData, isUserLoading, fetchUserData, userError, isUserError } = useUserData();
  const { push } = useRouter();
  const [isInitialState, setIsInitialState] = useState(true);

  const setSentryUserData = useCallback((userData: User | undefined) => {
    if (userData && userData.id) {
      Sentry.setUser({
        id: userData.id.toString(),
        email: userData.email,
        // Let Sentry figure out the IP address, see https://docs.sentry.io/platforms/javascript/guides/nextjs/enriching-events/identify-user/#ip_address
        ip_address: '{{auto}}',
      });
    } else {
      Sentry.setUser(null);
    }
  }, []);

  const setCurrentUserData = useCallback(
    (userData: User | undefined) => {
      setSentryUserData(userData);
      setCurrentUserDataState(userData);
    },
    [setCurrentUserDataState, setSentryUserData],
  );

  useEffect(() => {
    setIsInitialState(false);
    if (authToken.hasToken()) {
      fetchUserData();
    }
  }, [fetchUserData]);

  useEffect(() => {
    setCurrentUserData(userData);
  }, [userData, setCurrentUserData]);

  // Occasionally there is a need to update `userData` without re-fetching it from the server.
  // This function can be used to do that.
  const __insecureUserPatch = useCallback(
    (patchObject: Partial<User>) => {
      setCurrentUserDataState((user) => {
        // Note that we return a new copy here - we can't safely mutate the existing user data.
        const updatedUser = { ...user, ...patchObject } as User;
        setSentryUserData(updatedUser);
        return updatedUser;
      });
    },
    [setCurrentUserDataState, setSentryUserData],
  );

  const signOut = useCallback(() => {
    authToken.remove();
    setCurrentUserData(undefined);
    push(RoutingPaths.SIGN_IN);
  }, [push, setCurrentUserData]);

  // Handle token expiration/bad token
  useEffect(() => {
    if (isUserError) {
      console.warn(`Problems with token: ${userError?.code}:${userError?.detail?.join(',')}`);
      signOut();
    }
  }, [isUserError, userError, signOut]);

  return {
    userData: currentUserData,
    isUserLoading: isUserLoading || isInitialState,
    fetchUserData,
    signOut,
    __insecureUserPatch,
  } as const;
};

export const UserContext = createContext<ReturnType<typeof useUserContextController> | null>(null);

export const useUserContext = () => {
  const value = useContext(UserContext);
  if (value === null) {
    throw new Error(`[User Context] useUserContext must be called within UserContext tree`);
  }
  return value;
};

export const UserContextProvider: React.FC = ({ children }) => {
  const contextController = useUserContextController();

  return <UserContext.Provider value={contextController}>{children}</UserContext.Provider>;
};
