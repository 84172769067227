var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"843e5e3196a01c5d23cb3679bf5b5da1cbae8fc8"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import * as Sentry from '@sentry/nextjs';
import getConfig from 'next/config';

const { publicRuntimeConfig: config } = getConfig();

if (process.env.NEXT_PUBLIC_ENV !== 'dev') {
  Sentry.init({
    dsn: config.SENTRY_DSN,
    environment: config.ENVIRONMENT,
    // We avoid sampling 100% of events on prod, as it avoids hitting a more costly Sentry tier,
    // and we don't particularly need 100% of requests to be sampled.
    tracesSampleRate: parseFloat(config.SENTRY_PERFORMANCE_SAMPLE_RATE || '1.0'),
    // We don't currently record non-error sessions, as they'd eat into our error session allowance.
    replaysSessionSampleRate: 0,
    // As our errors are quite busy just now, only sample 10% of errors for replay to avoid breaking our quota.
    // When these errors are tidied up, we should switch to 100%.
    replaysOnErrorSampleRate: 0.1,

    integrations: [
      new Sentry.Replay({
        // There's not much private data in our system, so
        // we can safely show stuff that isn't passwords.
        blockAllMedia: false,
        maskAllText: false,
        // This masks the password fields on the sign in, sign up, and password reset processes.
        mask: ['#password'],
      }),
    ],
  });
}
