import { useRequest } from '@Hooks/useRequest';
import { API } from '@Services';
import { Fund } from '@Types';
import { useCallback } from 'react';

export const useGetFundsByGroup = () => {
  const [fetch, response] = useRequest<Fund[]>();

  const getFundsByGroup = useCallback(
    (organizationId: number) => {
      fetch(API.paths.funds_by_group(organizationId), 'get');
    },
    [fetch],
  );

  return [getFundsByGroup, { ...response, data: response.data ? response.data : [] }] as const;
};
