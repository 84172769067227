import React from 'react';
import { Header, Footer } from '@Components';
import styles from './AppLayout.module.scss';
import classnames from 'classnames';

export const PublicLayout: React.FC = ({ children }) => {
  return (
    <div className={styles.container}>
      <Header publicLayout />
      <div className={classnames(styles.content, styles.publicContent)}>{children}</div>
      <Footer />
    </div>
  );
};
