export enum GroupTypes {
  'COMMUNITY_GROUP' = 'COMMUNITY_GROUP',
  'REGISTERED_CHARITY' = 'REGISTERED_CHARITY',
  'SOCIAL_ENTERPRISE' = 'SOCIAL_ENTERPRISE',
}

export enum FundingTypes {
  'COMPANY' = 'COMPANY',
  'FOUNDATION' = 'FOUNDATION',
  'LOCAL_AUTHORITY' = 'LOCAL_AUTHORITY',
  'PHILANTHROPIST' = 'PHILANTHROPIST',
}
