// Circular progress indicator with a percentage value, based on https://mui.com/material-ui/react-progress/#circular-with-label
import * as React from 'react';
import { CircularProgress, CircularProgressProps } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import styles from './CircularProgressWithLabel.module.scss';

export interface CircularProgressWithLabelProps extends CircularProgressProps {
  value: number;
}

export const CircularProgressWithLabel: React.FC<CircularProgressWithLabelProps> = (props) => {
  return (
    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
      <CircularProgress
        className={styles.background}
        variant="determinate"
        {...props}
        value={100}
        thickness={5}
      />
      <CircularProgress className={styles.circle} variant="determinate" {...props} thickness={5} />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
        }}
      >
        <div className={styles.figure}>{`${Math.round(props.value)}%`}</div>
        <div className={styles.text}>{`Complete`}</div>
      </Box>
    </Box>
  );
};
