import React, { Fragment, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { AccordionProps as MaterialAccordionProps } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';

import { Accordion, AccordionProps } from '@Components/common';

import styles from './PitchAccordion.module.scss';

export interface AccordionData {
  title: string;
  subText: string;
  hint?: string;
  error?: boolean;
  success?: boolean;
  content?: React.ReactNode;
  accordionProps?: MaterialAccordionProps;
}

export interface PitchAccordionProps {
  accordionData: AccordionData[];
}

export const PitchAccordion: React.FC<PitchAccordionProps> = ({ accordionData }) => {
  const { t } = useTranslation('project');

  const createAccordionItem = useCallback(
    ({ title, subText, hint, error, success, content, accordionProps }: AccordionData) => ({
      title: (
        <span className={styles.title}>
          {error && <ErrorIcon className={styles.iconError} />}
          {success && <CheckCircleIcon className={styles.iconCheck} />}
          {title}
        </span>
      ),
      content: (
        <Fragment>
          <div className={styles.content}>
            <span className={styles.subText}>{subText}</span>
            {hint && (
              <span className={styles.hint}>
                <b>{t('pitch.hint')}: </b>
                {hint}
              </span>
            )}
          </div>
          {content}
        </Fragment>
      ),
      accordionProps,
    }),
    [t],
  );

  const accordionItems: AccordionProps['items'] = useMemo(
    () => accordionData.map((item) => createAccordionItem(item)),
    [accordionData, createAccordionItem],
  );

  return <Accordion items={accordionItems} />;
};
