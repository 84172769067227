import { useRequest } from '@Hooks/useRequest';
import { API } from '@Services';
import { SelectionsApiResponse } from '@Types';
import { useCallback } from 'react';

export const useGetFundSelections = () => {
  const [fetch, response] = useRequest<SelectionsApiResponse>();

  const getFundSelections = useCallback(
    (fundId: number) => {
      fetch(API.paths.fund_selections(fundId), 'get');
    },
    [fetch],
  );

  return [
    getFundSelections,
    { ...response, data: response.data ? response.data.results : [] },
  ] as const;
};
