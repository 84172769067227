import { useCallback } from 'react';
import { useRequest } from '@Hooks';
import { API } from '@Services';
import { ApiErrorObject } from '@Types';

export const useSetNewPassword = () => {
  const [fetch, response] = useRequest<ApiErrorObject | { detail: 'ok' }>();

  const setNewPassword = useCallback(
    (password: string, token: string) =>
      fetch(API.paths.set_new_password, 'post', {
        data: { password, token },
      }),
    [fetch],
  );

  return [setNewPassword, response] as const;
};
