import { useRouter as useNextRouter } from 'next/router';
import { isArray } from 'lodash';

// Note that in Storybook, this will return a router from https://www.npmjs.com/package/storybook-addon-next-router
export const useRouter = () => {
  const router = useNextRouter();
  const { push, pathname, query, events, asPath } = router;
  const getSingleValueParam = (paramName: string) => {
    // Because parameters can have multiple values, they're a bit annoying to handle type-wise when you just want one value
    // - the type always comes out as string | string[] | undefined.
    // When you expect a single value, this lets you get the parameter value as just string.
    const value = query[paramName];
    if (isArray(value)) {
      return value[0];
    } else {
      return value!;
    }
  };
  return {
    push,
    pathname,
    params: query,
    events,
    path: asPath,
    getSingleValueParam,
  };
};
