import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import highchartsVariablePie from 'highcharts/modules/variable-pie';
import highchartsExporting from 'highcharts/modules/exporting';
import styleVariables from '@Styles/_variables.module.scss';
import { formatChartTheme } from '@Helpers/formatChartTheme';

// Only adds module in browser
if (typeof Highcharts === 'object') {
  highchartsVariablePie(Highcharts);
  highchartsExporting(Highcharts);
}

interface DoughnutProps {
  title: string;
  dataSet: { name: string; y: number }[];
  displayCenteredTitle?: boolean;
  prefix?: string;
  suffix?: string;
}

export const Doughnut = ({
  title,
  dataSet,
  displayCenteredTitle,
  prefix = '',
  suffix = '',
}: DoughnutProps) => {
  Highcharts.setOptions({
    lang: {
      thousandsSep: ',',
    },
  });

  const options = {
    colors: formatChartTheme(styleVariables.chartsThemeReport),
    chart: {
      type: 'variablepie',
      styledMode: false,
      style: {
        fontFamily: 'Roboto Slab',
      },
      events: {
        render: function () {
          var title = (this as any).title;
          if (displayCenteredTitle) {
            title.attr({
              y:
                (this as any).plotTop +
                (this as any).plotHeight / 2 +
                title.getBBox().height / 2 -
                5,
            });
          }
        },
      },
    },
    title: {
      text: displayCenteredTitle
        ? '<span style="fontSize: 2rem">' + prefix + title + '</span>'
        : null,
      align: 'center',
      verticalAlign: displayCenteredTitle ? 'middle' : '',
      style: {
        fontFamily: 'Roboto Slab',
      },
    },
    tooltip: {
      headerFormat: '',
      pointFormat: '<b> {point.name}</b><br/>' + prefix + '{point.y:,.0f}' + suffix + '<br/>',
    },
    series: [
      {
        minPointSize: 20,
        innerSize: '65%',
        data: dataSet,
      },
    ],
    plotOptions: {
      variablepie: { size: 200 },
      series: {
        dataLabels: {
          enabled: false,
        },
        showInLegend: true,
      },
    },
    legend: {
      labelFormat: '{name}',
      alignColumns: false,
      itemMarginBottom: 4,
    },
    credits: {
      enabled: false,
    },
  };

  return (
    <>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </>
  );
};
