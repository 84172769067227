import { useCallback } from 'react';
import { useRequest } from '@Hooks/useRequest';
import { API } from '@Services';
import { FundsApiResponse, FundStatusEnum } from 'Types';

export const useMyFundsList = () => {
  const [fetch, response] = useRequest<FundsApiResponse>();

  const fetchFundsList = useCallback(
    (status?: FundStatusEnum[]) =>
      fetch(`${API.paths.my_funds}?${status ? `&status=${status.join(',')}` : ''}`, 'get'),
    [fetch],
  );

  return [
    fetchFundsList,
    { ...response, data: response.data ? response.data.results : [] },
  ] as const;
};
