import React from 'react';
import Modal from '@material-ui/core/Modal';

import { PdfViewer } from '@Components';
import styles from './PdfModal.module.scss';

interface PdfModalProps {
  isOpen: boolean;
  onClose?: () => void;
  file: any;
}

export const PdfModal: React.FC<PdfModalProps> = ({ isOpen, onClose, file }) => {
  return (
    <Modal open={isOpen} className={styles.container} onClose={onClose}>
      <div className={styles.modal}>
        <PdfViewer file={file} />
      </div>
    </Modal>
  );
};
