import React, { useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';

import SettingsIcon from '@material-ui/icons/Settings';
import EditIcon from '@material-ui/icons/Edit';
import { RiDeleteBinLine } from 'react-icons/ri';

import { StatusChip, ConfirmModal } from '@Components';
import { Button } from '@Components/common/Button/Button';
import { IconButton } from '@Components/common/Button/IconButton';
import { Project, ProjectStatusEnum } from '@Types';
import { useRouter } from '@Helpers/useRouter';

import styles from './ProjectCard.module.scss';
import { Link } from '@Components/common';
import { HeaderImage } from '@Components/FeaturedCard/Components/HeaderImage';
import { getHeaderImage } from '@Helpers/getHeaderImage';

export interface ProjectCardProps {
  project: Project;
  className?: string;
  matchesCount?: number;
  archiveProject: (projectId: number) => any;
}

export const ProjectCard: React.FC<ProjectCardProps> = ({ className, project, archiveProject }) => {
  const [isEditProjectModalVisible, setIsEditProjectModalVisible] = useState(false);
  const [isArchiveProjectModalVisible, setIsArchiveProjectModalVisible] = useState(false);
  const { t } = useTranslation('dashboard');
  const { t: tProject } = useTranslation('project');
  const { t: tSurveys } = useTranslation('surveys');
  const { push } = useRouter();

  const {
    id,
    status,
    matches_count: matchesCount,
    funding_offers_count: fundingOffersCount,
    written_pitch,
    video_pitch,
    group_profile: { avatar_thumbnail },
  } = { ...project };
  const { name, amount } = { ...project.details };
  const userCanManage = project.user_can_manage;

  const handleEditClick = () => {
    const confirmationNeeded = status === ProjectStatusEnum.PUBLISHED && (matchesCount || 0) > 0;
    if (confirmationNeeded) {
      handleEditModalShow();
    } else {
      handleEditModalConfirm();
    }
  };

  const handleManageClick = () => {
    push(`/project/${id}/manage`);
  };

  const handleEditModalShow = useCallback(() => {
    setIsEditProjectModalVisible(true);
  }, []);

  const handleEditModalHide = useCallback(() => {
    setIsEditProjectModalVisible(false);
  }, []);

  const handleEditModalConfirm = useCallback(() => {
    setIsEditProjectModalVisible(false);
    push(`/project/${id}/edit`);
  }, [setIsEditProjectModalVisible, push, id]);

  const handleArchiveModalShow = useCallback(() => {
    setIsArchiveProjectModalVisible(true);
  }, []);

  const handleArchiveModalHide = useCallback(() => {
    setIsArchiveProjectModalVisible(false);
  }, []);

  const handleArchiveModalConfirm = useCallback(() => {
    archiveProject(project.id);
    handleArchiveModalHide();
  }, [archiveProject, handleArchiveModalHide, project]);

  const primaryButtons = [];
  const secondaryButtons = [];
  // The following actions all require permission to edit, so won't be visible to 'Viewer' user.
  if (userCanManage) {
    if (status === ProjectStatusEnum.DRAFT && project.user_can_edit) {
      primaryButtons.push({
        label: t('group.edit_project'),
        icon: <EditIcon />,
        onClick: handleEditClick,
      });
    } else if (status === ProjectStatusEnum.PUBLISHED && project.user_can_edit) {
      secondaryButtons.push({
        label: t('group.edit_project'),
        icon: <EditIcon />,
        onClick: handleEditClick,
      });
    }
  }

  if ([ProjectStatusEnum.PUBLISHED, ProjectStatusEnum.FUNDED].includes(status)) {
    primaryButtons.push({
      label: t('group.manage_project'),
      icon: <SettingsIcon />,
      onClick: handleManageClick,
    });
  } else if ([ProjectStatusEnum.COMPLETE].includes(status)) {
    secondaryButtons.push({
      label: t('group.manage_project'),
      icon: <SettingsIcon />,
      onClick: handleManageClick,
    });
  }

  const showArchiveButton = project.user_can_archive;
  const surveyStatus = project.impact_survey_response?.status;
  const headerImage = getHeaderImage(written_pitch?.image, video_pitch?.video_url_1);

  return (
    <>
      <div className={classnames(styles.container, className)}>
        <HeaderImage
          project
          avatar={avatar_thumbnail}
          status={t(`project:status.${status}`)}
          image={headerImage}
          video={Boolean(video_pitch)}
        />
        <div className={styles.header}>
          <div className={styles.chipsWrapper}>
            {surveyStatus === 'COMPLETED' && (
              <StatusChip label={`Survey ${tSurveys(`status.${surveyStatus}`)}`} color="primary" />
            )}
            {matchesCount !== undefined &&
              matchesCount > 0 &&
              status !== ProjectStatusEnum.FUNDED &&
              status !== ProjectStatusEnum.COMPLETE &&
              fundingOffersCount === 0 && (
                <Link to={`/project/${id}/manage`}>
                  <StatusChip
                    label={tProject('card.applied_count', { count: matchesCount })}
                    color="primary"
                    className={styles.matchesChip}
                  />
                </Link>
              )}
            {fundingOffersCount !== undefined &&
              fundingOffersCount > 0 &&
              status !== ProjectStatusEnum.FUNDED &&
              status !== ProjectStatusEnum.COMPLETE && (
                <Link to={`/project/${id}/manage/funding-offers`}>
                  <StatusChip
                    label={tProject('card.funding_offers_count', { count: fundingOffersCount })}
                    color="primary"
                    className={styles.matchesChip}
                  />
                </Link>
              )}
          </div>
        </div>

        {status === ProjectStatusEnum.DRAFT ? (
          <span className={styles.name}>{name}</span>
        ) : (
          <Link className={styles.name} to={`/project/${id}`}>
            {name}
          </Link>
        )}
        <span className={styles.amountWrapper}>
          {t('group.project_amount')}:{' '}
          <b className={styles.amount}>{t('common:currency_format', { amount: amount })}</b>
        </span>
        <div className={styles.buttonContainer}>
          {primaryButtons.map(({ label, icon, onClick }, index) => {
            return (
              <Button
                key={index}
                buttonType="primary"
                onClick={onClick}
                startIcon={icon}
                size="small"
                className={styles.button}
              >
                <span className={styles.projectCtaButton}>{label}</span>
              </Button>
            );
          })}
          {showArchiveButton && secondaryButtons.length === 0 && (
            <IconButton buttonType="danger" onClick={handleArchiveModalShow} size="small">
              <RiDeleteBinLine />
            </IconButton>
          )}
        </div>
        <div className={styles.secondaryButtonContainer}>
          {secondaryButtons.map(({ label, icon, onClick }, index) => {
            return (
              <Button
                key={index}
                onClick={onClick}
                startIcon={icon}
                size="small"
                className={styles.button}
                buttonType="secondary"
              >
                <span className={styles.projectCtaButton}>{label}</span>
              </Button>
            );
          })}
          {showArchiveButton && secondaryButtons.length > 0 && (
            <IconButton buttonType="danger" onClick={handleArchiveModalShow} size="small">
              <RiDeleteBinLine />
            </IconButton>
          )}
        </div>
      </div>

      <ConfirmModal
        isOpen={isEditProjectModalVisible}
        onClose={handleEditModalHide}
        title={t('group.edit_modal.title')}
        cancelText={t('group.edit_modal.cancel')}
        onCancel={handleEditModalHide}
        confirmText={t('group.edit_modal.confirm')}
        onConfirm={handleEditModalConfirm}
      >
        {t('group.edit_modal.text')}
      </ConfirmModal>

      <ConfirmModal
        isOpen={isArchiveProjectModalVisible}
        onClose={handleArchiveModalHide}
        title={t('group.archive_modal.title')}
        cancelText={t('group.archive_modal.cancel')}
        onCancel={handleArchiveModalHide}
        confirmText={t('group.archive_modal.confirm')}
        onConfirm={handleArchiveModalConfirm}
        isConfirmBtnDanger
      >
        {t('group.archive_modal.text')}
      </ConfirmModal>
    </>
  );
};
