import { useCallback, useEffect, useState } from 'react';
import { useRequest } from '@Hooks/useRequest';
import { PagedResponse } from '@Types';

export type PaginationNextPreviousFunction = () => void;

export interface PaginationConfiguration {
  perPage: number;
}

export interface PaginatedResponse<ObjectType> {
  loading: boolean;
  hasNext: boolean;
  hasPrevious: boolean;
  page: ObjectType[];
  paginationOffset: number;
  moveToNextPage?: PaginationNextPreviousFunction;
  moveToPreviousPage?: PaginationNextPreviousFunction;
  resetPagination: () => void;
  totalCount?: number;
}

export const usePaginatedRequest = <
  ObjectType,
  ResponseType extends PagedResponse<ObjectType> = PagedResponse<ObjectType>,
>(
  paginationConfiguration?: PaginationConfiguration,
) => {
  // const [fetch, response] = usePaginatedRequest<Project>({perPage: 10}) -> Make a paginated GET request to a given API, returning 10 Projects at a time
  // const [fetch, response] = usePaginatedRequest<Project, MyCustomResponseType<Project>>({perPage: 10}) -> Make a paginated GET request to a given API,
  // with a custom response type rather than the default PagedResponse, returning 10 Projects at a time.
  const [paginationOffset, setPaginationOffset] = useState(0);
  const perPage = paginationConfiguration?.perPage || 10;
  const [fetchPromise, methods] = useRequest<ResponseType>();

  const response = methods.data;

  const page = response?.results || [];
  const hasNext = response?.next != null;
  const hasPrevious = response?.previous != null;
  const totalCount = response?.count;

  const moveToNextPage: PaginationNextPreviousFunction = useCallback(() => {
    if (hasNext) {
      setPaginationOffset(paginationOffset + perPage);
    }
  }, [paginationOffset, perPage, setPaginationOffset, hasNext]);

  const moveToPreviousPage: PaginationNextPreviousFunction = useCallback(() => {
    if (hasPrevious) {
      setPaginationOffset(paginationOffset - perPage);
    }
  }, [paginationOffset, perPage, setPaginationOffset, hasPrevious]);

  const resetPagination = useCallback(() => {
    setPaginationOffset(0);
  }, [setPaginationOffset]);

  const paginatedFetch = useCallback(
    (path: string, params?) => {
      const paginatedParams = {
        ...params,
        limit: perPage,
        offset: paginationOffset,
      };
      fetchPromise(path, 'get', { params: paginatedParams });
    },
    [fetchPromise, paginationOffset, perPage],
  );

  useEffect(() => {
    if (totalCount && paginationOffset >= totalCount) {
      // If we're past the final page, jump back to the start of the final page.
      // Generally this is caused by e.g. deleting the last item on the final page, and suddenly
      // ending up past the final page when the data refreshes.
      setPaginationOffset(totalCount - perPage);
    }
  }, [paginationOffset, totalCount, perPage]);

  const paginatedData: PaginatedResponse<ObjectType> = {
    // It's useful to have a copy of this on here, so pagination-related stuff can see loading state.
    loading: methods.loading,
    hasNext,
    hasPrevious,
    page,
    paginationOffset,
    moveToNextPage: hasNext ? moveToNextPage : undefined,
    moveToPreviousPage: hasPrevious ? moveToPreviousPage : undefined,
    resetPagination,
    totalCount,
  };
  return [
    paginatedFetch,
    {
      ...methods,
      paginatedData,
    },
  ] as const;
};
