import React from 'react';
import { useTranslation } from 'react-i18next';

import LocationSearchingIcon from '@material-ui/icons/LocationSearching';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

import { useUserContext } from '@Contexts';
import { UserTypeEnum } from '@Types';
import styleVariables from '@Styles/_variables.module.scss';

import styles from './MatchesTile.module.scss';

interface MatchesTileProps {
  matchesCount: number;
}

export const MatchesTile = ({ matchesCount }: MatchesTileProps) => {
  const { t } = useTranslation('matchesTile');
  const { userData } = useUserContext();

  if (!userData || (userData?.user_type === UserTypeEnum.FUNDER && matchesCount === 0)) {
    return null;
  }

  const { user_type } = userData;

  return (
    <div className={styles.container}>
      {user_type === UserTypeEnum.GROUP && <LocationSearchingIcon />}
      {user_type === UserTypeEnum.FUNDER && (
        <CheckCircleIcon style={{ color: styleVariables.colorT1 }} />
      )}
      <span className={styles.text}>
        {user_type === UserTypeEnum.GROUP && t('matches_count_group', { count: matchesCount })}
        {user_type === UserTypeEnum.FUNDER && t('matches_count_funder', { count: matchesCount })}
      </span>
    </div>
  );
};
