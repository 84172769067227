import React from 'react';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import { makeStyles } from '@material-ui/core';

import CloseIcon from '@material-ui/icons/Close';

import Modal from '@material-ui/core/Modal';
import { Button, ButtonProps } from '@Components/common';
import styleVariables from '@Styles/_variables.module.scss';

import styles from './ConfirmModal.module.scss';
import { IconButton } from '@Components/common/Button/IconButton';

const useStyles = makeStyles({
  btnRoot: {
    margin: `0 10px`,
    [`@media screen and (max-width: ${styleVariables.screenWidthXs})`]: {
      width: '100%',
      margin: '8px 0',
      padding: '12px 20px',
    },
  },
  closeBtn: {
    backgroundColor: 'transparent',
    minWidth: 'unset',
    position: 'absolute',
    right: 0,
    top: 0,
    [`@media screen and (max-width: ${styleVariables.screenWidthSm})`]: {
      padding: '12px 20px',
    },
  },
});

interface ConfirmModalProps {
  isOpen: boolean;
  onClose?: () => void;
  isConfirmBtnDanger?: boolean;
  onConfirm?: () => void | Promise<void>;
  confirmText?: string | JSX.Element;
  confirmButtonProps?: ButtonProps;
  onCancel?: () => void | Promise<void>;
  cancelText?: string | JSX.Element;
  cancelButtonProps?: ButtonProps;
  title?: string;
  subtitle?: string;
  noHeader?: boolean;
  info?: boolean;
}

export const ConfirmModal: React.FC<ConfirmModalProps> = ({
  isOpen,
  onClose,
  children,
  isConfirmBtnDanger,
  onConfirm,
  confirmText,
  confirmButtonProps,
  onCancel,
  cancelText,
  cancelButtonProps,
  title,
  subtitle,
  noHeader,
  info,
}) => {
  const { t } = useTranslation('common');
  const classes = useStyles();

  return (
    <Modal open={isOpen} className={styles.container} onClose={onClose}>
      <div className={styles.modal}>
        <IconButton buttonType="text" className={classes.closeBtn} onClick={onClose}>
          <CloseIcon />
        </IconButton>
        {!noHeader && (
          <div className={styles.header}>
            <h2 className={styles.title}>{title || t('confirm_modal.header')}</h2>
            {subtitle && <p className={styles.subtitle}>{subtitle}</p>}
          </div>
        )}
        <div className={styles.content}>{children}</div>
        <div className={styles.footer}>
          {!info && (
            <Button
              buttonType="tertiary"
              onClick={onCancel || onClose}
              className={classes.btnRoot}
              {...cancelButtonProps}
            >
              {cancelText || t('confirm_modal.cancel')}
            </Button>
          )}
          <Button
            buttonType={isConfirmBtnDanger ? 'danger' : 'primary'}
            className={classnames(classes.btnRoot)}
            onClick={onConfirm}
            {...confirmButtonProps}
          >
            {confirmText || t('confirm_modal.confirm')}
          </Button>
        </div>
      </div>
    </Modal>
  );
};
