import React, { useCallback, useEffect, useRef, useState, MutableRefObject } from 'react';
import { useTranslation } from 'react-i18next';
import { Settings as SliderSettings } from 'react-slick';

import BusinessCenterIcon from '@material-ui/icons/BusinessCenter';
import CreateIcon from '@material-ui/icons/Create';
import PeopleIcon from '@material-ui/icons/People';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import VideocamIcon from '@material-ui/icons/Videocam';

import { RiAlertFill } from 'react-icons/ri';

import {
  StatusChip,
  OldTabs,
  OldTabProps,
  Loader,
  CategoryTilesController,
  Masonry,
  UpdatesListItem,
  FundDateProgress,
  IconTile,
  OrganizationTile,
  ShareTile,
  GroupEligibilityInformation,
  Divider,
} from '@Components';
import { Pagination } from '@Components/common';
import { useUserContext } from '@Contexts';
import {
  FundStatusEnum,
  GroupTypes,
  Project,
  Category,
  SelectionStatusEnum,
  LatLngExpression,
  ProjectUpdate,
  Map2Props,
  InitialFocus,
  DrawingMode,
  LocalAuthorityWithFeature,
  GroupEligibilityForFund,
  UserTypeEnum,
} from '@Types';
import * as storage from '@Helpers/storage';

import { CtaButton, PopupModal, POPUP_CLOSED_STORAGE_KEY } from './Components';
import { ProjectSlides } from '@Views/Public/Featured/Components';
import styles from './FundDetailsPage.module.scss';
import { MultiPolygon } from 'geojson';
import dynamic from 'next/dynamic';
import Linkify from 'linkify-react';
import { FundCatchmentArea } from '@Components/FundCatchementArea/FundCatchmentArea';
import { PaginatedResponse } from '@Hooks/usePaginatedRequest';
import { useGetLocalAuthorities } from '@Hooks/Api';
import { SelectionDeadlineCard } from '@Components/SelectionDeadlineCard/SelectionDeadlineCard';

const Map2 = dynamic<Map2Props>(
  () => import('@Components/Map2/Map2').then((mod) => mod.Map2) as any,
  {
    ssr: false,
  },
);

const POPUP_DELAY = 30000;
const getSettings = (slidesCount: number) => {
  const settings: SliderSettings = {
    dots: true,
    infinite: true,
    autoplay: true,
    arrows: false,
    speed: 500,
    slidesToShow: Math.min(slidesCount, 2),
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1279,
        settings: {
          slidesToShow: Math.min(slidesCount, 3),
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: Math.min(slidesCount, 2),
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 660,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return settings;
};

interface FundDetailsPageProps {
  fund: boolean;
  fundId: number;
  status: string;
  title: string;
  totalAmount?: string;
  maximumGrantAmount?: string;
  minimumGrantAmount?: string;
  purpose?: string;
  groupTypes: GroupTypes[];
  postcode?: string;
  radius?: number;
  catchment_area_type: DrawingMode;
  areas?: MultiPolygon;
  coordinates?: LatLngExpression;
  launchFrom?: string;
  deadlineTo?: string;
  organizationName: string;
  organizationType: string;
  location?: string;
  deliveryDeadline?: string;
  deliveryTimeframe?: string;
  likeCount?: number;
  categoriesIds: number[];
  userCanManage: boolean;
  selectionId?: number;
  selectionStatus?: SelectionStatusEnum;
  orgWebsiteUrl?: string;
  orgFacebookUrl?: string;
  orgLinkedinUrl?: string;
  orgInstagramUrl?: string;
  orgTwitterUrl?: string;
  avatarImage?: string;
  organizationId?: number;
  isHideUpdatesLoading?: boolean;
  hideProjectUpdate?: (projectUpdateId: number) => Promise<void>;
  categoriesList?: Category[];
  isCategoriesListLoading?: boolean;
  tabs?: OldTabProps[];
  currentTab?: number;
  onTabChange?: (tabIndex: number) => void;
  aboutRef?: MutableRefObject<HTMLDivElement | null>;
  fundedProjectsRef?: MutableRefObject<HTMLHeadingElement | null>;
  fundUpdatesRef?: MutableRefObject<HTMLHeadingElement | null>;
  hashtag?: string | null;
  video_pitch_required?: boolean;
  localAuthority?: LocalAuthorityWithFeature;
  updatesPaginatedResponse: PaginatedResponse<ProjectUpdate>;
  projectsPaginatedResponse: PaginatedResponse<Project>;
  groupEligibilityForFundData?: GroupEligibilityForFund;
  groupEligibilityForFundDataLoading?: boolean;
  groupHasProjectMatched?: boolean;
  selection_deadline: string;
}

export const FundDetailsPage: React.FC<FundDetailsPageProps> = ({
  fund,
  fundId,
  status,
  title,
  totalAmount,
  maximumGrantAmount,
  minimumGrantAmount,
  purpose,
  groupTypes,
  postcode,
  radius,
  catchment_area_type,
  areas,
  coordinates,
  launchFrom,
  deadlineTo,
  organizationName,
  organizationType,
  location,
  deliveryDeadline,
  deliveryTimeframe,
  categoriesIds,
  userCanManage,
  selectionId,
  selectionStatus,
  orgWebsiteUrl,
  orgFacebookUrl,
  orgLinkedinUrl,
  orgInstagramUrl,
  orgTwitterUrl,
  avatarImage,
  organizationId,
  isHideUpdatesLoading,
  hideProjectUpdate,
  categoriesList,
  isCategoriesListLoading,
  tabs,
  currentTab,
  onTabChange,
  aboutRef,
  fundedProjectsRef,
  fundUpdatesRef,
  hashtag,
  video_pitch_required,
  localAuthority,
  updatesPaginatedResponse,
  projectsPaginatedResponse,
  groupEligibilityForFundData,
  groupEligibilityForFundDataLoading,
  groupHasProjectMatched,
  selection_deadline,
}) => {
  const { t: tFund } = useTranslation('fund');
  const { t: tFundingOpportunities } = useTranslation('fundingOpportunities');
  const headerRef = useRef(null);
  const [isPopupModalOpen, setIsPopupModalOpen] = useState(false);
  const pendingTimeout = useRef<NodeJS.Timeout>();
  const { userData } = useUserContext();

  const openPopupModal = useCallback(() => {
    setIsPopupModalOpen(true);
  }, []);
  const closePopupModal = useCallback(() => {
    setIsPopupModalOpen(false);
    storage.setItem(POPUP_CLOSED_STORAGE_KEY, 'true');
  }, []);

  const clearPendingTimeout = useCallback(() => {
    if (typeof pendingTimeout?.current !== 'undefined') {
      clearTimeout(pendingTimeout.current);
    }
  }, []);

  const scheduleShowPopup = useCallback(() => {
    clearPendingTimeout();
    pendingTimeout.current = setTimeout(openPopupModal, POPUP_DELAY);
  }, [clearPendingTimeout, openPopupModal]);

  useEffect(() => {
    if (
      fund &&
      !userData &&
      !storage.getItem(POPUP_CLOSED_STORAGE_KEY) &&
      status === tFund(`fund_status.${FundStatusEnum.LIVE}`)
    ) {
      scheduleShowPopup();
    }

    return () => {
      clearPendingTimeout();
    };
  }, [clearPendingTimeout, fund, scheduleShowPopup, status, tFund, userData]);

  const {
    loading: isUpdatesListLoading,
    totalCount: totalUpdatesCount,
    page: updatesList,
  } = updatesPaginatedResponse;

  const {
    loading: isFundedProjectsLoading,
    totalCount: totalProjectsCount,
    page: fundedProjectsList,
  } = projectsPaginatedResponse;

  const fundLocation = (
    <FundCatchmentArea
      catchmentAreaType={catchment_area_type}
      radius={radius}
      postcode={postcode}
      localAuthority={localAuthority?.name}
    />
  );

  const showGroupEligibiltyForFundData =
    (!groupEligibilityForFundData?.matched_categories ||
      !groupEligibilityForFundData?.matched_group_type ||
      !groupEligibilityForFundData?.matched_location) &&
    userData?.user_type == UserTypeEnum.GROUP;

  return (
    <>
      <PopupModal isOpen={isPopupModalOpen} onClose={closePopupModal} />

      <StatusChip label={status} color="secondary" />
      <div className={styles.header} ref={headerRef}>
        <h1 className={styles.title}>{title}</h1>
        <div className={styles.headerInfo}>
          <CtaButton
            headerRef={headerRef}
            fund={fund}
            fundId={fundId}
            userCanManage={userCanManage}
            groupHasProjectMatched={groupHasProjectMatched}
            selectionId={selectionId}
            selectionStatus={selectionStatus}
            status={status}
          />
        </div>
      </div>
      <div className={styles.content} ref={aboutRef}>
        <div className={styles.mainContent}>
          {showGroupEligibiltyForFundData && !groupEligibilityForFundDataLoading && (
            <div className={styles.eligibility}>
              <p className={styles.eligibilityText}>
                <span>{<RiAlertFill size={22} />}</span>
                {tFundingOpportunities('eligibility')}
              </p>
              <GroupEligibilityInformation
                groupEligibilityForFundData={groupEligibilityForFundData}
                fundLocation={fundLocation}
                categoryIDs={categoriesIds}
                groupTypes={groupTypes}
              />
              <Divider />
            </div>
          )}

          {tabs && currentTab !== undefined && (
            <div className={styles.tabsWrapper}>
              <OldTabs
                tabs={tabs}
                currentTab={currentTab}
                onTabChange={onTabChange}
                fullWidth={false}
              />
            </div>
          )}

          <div className={styles.iconTiles}>
            {totalAmount && (
              <IconTile
                labelText={tFund('details_page.total_amount')}
                valueText={tFund('common:currency_format', { amount: totalAmount })}
              >
                <BusinessCenterIcon />
              </IconTile>
            )}
            {maximumGrantAmount && (
              <IconTile
                labelText={tFund('details_page.maximum_grant_amount')}
                valueText={tFund('common:currency_format', { amount: maximumGrantAmount })}
              >
                <PeopleIcon />
              </IconTile>
            )}
            {minimumGrantAmount && (
              <IconTile
                labelText={tFund('details_page.minimum_grant_amount')}
                valueText={tFund('common:currency_format', { amount: minimumGrantAmount })}
              >
                <ArrowUpward />
              </IconTile>
            )}
          </div>
          <>
            <h2 className={styles.contentHeader}>{tFund('details_page.fund_details_header')}</h2>

            <h3 className={styles.sectionHeader}>{tFund('details_page.purpose_header')}</h3>
            <p className={styles.textParagraph}>
              <Linkify>{purpose}</Linkify>
            </p>

            <h3 className={styles.sectionHeader}>
              {tFund('details_page.project_application_format')}
            </h3>
            <div>
              <StatusChip
                icon={<VideocamIcon />}
                label={tFund(`details_page.project_application_format_video`)}
                color="primary"
              />
              {!video_pitch_required && (
                <StatusChip
                  icon={<CreateIcon />}
                  label={tFund(`details_page.project_application_format_written`)}
                  color="primary"
                />
              )}
            </div>

            <h3 className={styles.sectionHeader}>{tFund('details_page.group_types_header')}</h3>
            <div>
              {groupTypes &&
                groupTypes.map((group) => (
                  <StatusChip
                    key={group}
                    label={tFund(`common:group_types.${group}`)}
                    color="primary"
                  />
                ))}
            </div>

            <h3 className={styles.sectionHeader}>{tFund('common:location.header')}</h3>
            <span className={styles.locationText}>
              {catchment_area_type && (
                <FundCatchmentArea
                  catchmentAreaType={catchment_area_type}
                  radius={radius}
                  postcode={postcode}
                  localAuthority={localAuthority?.name || ''}
                />
              )}
            </span>
            {catchment_area_type === DrawingMode.RADIUS && radius && coordinates && (
              <Map2
                circles={[{ center: coordinates, radius: radius }]}
                height={200}
                initialFocus={InitialFocus.DATA}
              />
            )}
            {catchment_area_type === DrawingMode.UK_WIDE && (
              <Map2 height={200} initialFocus={InitialFocus.UK_WIDE} />
            )}
            {catchment_area_type === DrawingMode.POLYGON && areas && (
              <Map2
                featureCollection={{
                  type: 'FeatureCollection',
                  features: [
                    {
                      type: 'Feature',
                      geometry: areas,
                      properties: {},
                    },
                  ],
                }}
                height={200}
                initialFocus={InitialFocus.DATA}
              />
            )}
            {catchment_area_type === DrawingMode.LOCAL_AUTHORITY && localAuthority && (
              <Map2
                featureCollection={{
                  type: 'FeatureCollection',
                  features: [localAuthority.feature],
                }}
                height={200}
                initialFocus={InitialFocus.DATA}
              />
            )}

            <h3 className={styles.sectionHeader}>
              {tFund('details_page.project_delivery_deadline')}
            </h3>
            <div>
              <StatusChip label={deliveryDeadline} color="primary" />
            </div>

            <h3 className={styles.sectionHeader}>{tFund('details_page.categories_header')}</h3>
            <CategoryTilesController categoriesIds={categoriesIds} />
          </>
          {(tFund(`fund_status.${FundStatusEnum.IN_DELIVERY}`) === status ||
            tFund(`fund_status.${FundStatusEnum.COMPLETE}`) === status ||
            tFund(`fund_status.${FundStatusEnum.CLOSED}`) === status) && (
            <>
              {(isFundedProjectsLoading || isCategoriesListLoading) && <Loader />}
              {!isFundedProjectsLoading && !isCategoriesListLoading && (
                <>
                  {fundedProjectsList && fundedProjectsList.length > 0 && (
                    <>
                      <h2 className={styles.contentHeader} ref={fundedProjectsRef}>
                        {tFund('details_page.funded_projects_header', {
                          projectsCount: totalProjectsCount || 0,
                        })}
                      </h2>
                      <p className={styles.contentSubheader}>
                        {tFund('details_page.funded_projects_subheader')}
                      </p>

                      <div className={styles.projectsWrapper}>
                        <div className={styles.projectsWrapperInner}>
                          <ProjectSlides
                            sliderSettings={getSettings(fundedProjectsList.length)}
                            featuredProjects={fundedProjectsList}
                            categoriesList={categoriesList}
                            className={styles.cardWide}
                          />
                        </div>
                      </div>
                    </>
                  )}
                </>
              )}

              {(isUpdatesListLoading || isHideUpdatesLoading) && <Loader />}
              {!isUpdatesListLoading && !isHideUpdatesLoading && (
                <>
                  {updatesList && updatesList.length > 0 && (
                    <>
                      <h2 className={styles.contentHeader} ref={fundUpdatesRef}>
                        {tFund('details_page.updates_header', {
                          updatesCount: totalUpdatesCount || 0,
                        })}
                      </h2>

                      <Masonry className={styles.masonry}>
                        {updatesList.map(
                          ({
                            id,
                            image,
                            description,
                            created,
                            likes,
                            video_url,
                            organization_id,
                            organization_name,
                            avatar_thumbnail,
                            project_id,
                            project_name,
                          }) => (
                            <UpdatesListItem
                              key={id}
                              id={id}
                              image={image}
                              video={video_url}
                              description={description}
                              createdAt={created}
                              likes={likes}
                              likeCount={likes.length}
                              organizationId={organization_id}
                              organizationName={organization_name}
                              organizationAvatar={avatar_thumbnail}
                              projectId={project_id}
                              projectName={project_name}
                              onHideProjectUpdate={hideProjectUpdate}
                              user={userData}
                            />
                          ),
                        )}
                      </Masonry>
                      <Pagination paginatedResponse={updatesPaginatedResponse} />
                    </>
                  )}
                </>
              )}
            </>
          )}
        </div>
        <div className={styles.sideContent}>
          <FundDateProgress tile startDate={launchFrom!} endDate={deadlineTo!} status={status} />
          {selection_deadline && <SelectionDeadlineCard selectionDeadline={selection_deadline} />}
          <OrganizationTile
            fund={fund}
            subtitle={tFund('details_page.funded_by')}
            organizationName={organizationName}
            organizationType={organizationType}
            avatarImage={avatarImage}
            orgWebsiteUrl={orgWebsiteUrl}
            orgFacebookUrl={orgFacebookUrl}
            orgLinkedinUrl={orgLinkedinUrl}
            orgInstagramUrl={orgInstagramUrl}
            orgTwitterUrl={orgTwitterUrl}
            organizationId={organizationId}
          />
          <ShareTile hashtag={hashtag} />
        </div>
      </div>
    </>
  );
};
