import { useRequest } from '@Hooks/useRequest';
import { Category } from '@Types';
import { useCallback } from 'react';
import { API } from '@Services';

export const useCategories = (parentOnly = false) => {
  const [fetch, response] = useRequest<Category[]>();
  const fetchCategories = useCallback(
    () => fetch(API.paths.categories, 'get', { params: { is_parent: parentOnly } }),
    [parentOnly, fetch],
  );

  return [fetchCategories, response] as const;
};
