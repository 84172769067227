import { usePaginatedRequest } from '@Hooks/usePaginatedRequest';
import { API } from '@Services';
import { Match } from '@Types';
import { useCallback } from 'react';

export const useGetProjectMatches = () => {
  const [fetch, response] = usePaginatedRequest<Match>({ perPage: 10 });

  const getProjectMatches = useCallback(
    (projectId: number) => {
      fetch(API.paths.project_matches(projectId));
    },
    [fetch],
  );

  return [getProjectMatches, response] as const;
};
