import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { WrittenPitchAccordion, SectionHeader, WrittenPitchImageUpload } from '@Components';

export const WrittenPitch: React.FC = () => {
  const { t } = useTranslation('project');

  return (
    <Fragment>
      <SectionHeader centerHeader title={t('pitch.describe_header')} />

      <WrittenPitchAccordion />

      <SectionHeader centerHeader title={t('pitch.project_image')} />

      <WrittenPitchImageUpload />
    </Fragment>
  );
};
