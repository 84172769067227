import { useCallback } from 'react';
import { useRequest } from '@Hooks';
import { API } from '@Services';
import { MemberDetails } from '@Types';

export const useListAvatarURLs = () => {
  const [fetch, response] = useRequest<Array<MemberDetails>>();
  const {
    data: avatarURLs,
    loading: isURLsLoading,
    isError: isURLsError,
    error: urlsError,
  } = response;

  const listAvatarURLs = useCallback(() => {
    fetch(API.paths.avatar_urls, 'get');
  }, [fetch]);

  return [listAvatarURLs, avatarURLs] as const;
};
