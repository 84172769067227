import LocationOnIcon from '@material-ui/icons/LocationOn';
import PhoneIcon from '@material-ui/icons/Phone';

import { Link } from '@Components/common';
import { LatLngExpression } from '@Types';

import styles from './LocationTile.module.scss';

import dynamic from 'next/dynamic';

const Map = dynamic<{
  coordinates: LatLngExpression;
  zoom: number;
  height: number;
  marker: boolean;
}>(() => (import('@Components/Map/Map') as any).then((mod: any) => mod.Map), {
  ssr: false,
});

export interface LocationTileProps {
  coordinates?: LatLngExpression;
  city?: string;
  phoneNumber?: string;
  address1?: string;
  address2?: string;
  address3?: string;
  county?: string;
  postcode?: string;
}

export const LocationTile: React.FC<LocationTileProps> = ({
  coordinates,
  city,
  phoneNumber,
  address1,
  address2,
  address3,
  county,
  postcode,
}) => {
  const addressData = [address1, address2, address3, city, county, postcode]
    .join(' ')
    .replace(/ /g, '+');
  const mapLink = `https://www.google.com/maps/search/${addressData}`;

  return (
    <div className={styles.container}>
      {coordinates && <Map coordinates={coordinates} zoom={12} height={278} marker={true} />}
      {city && (
        <div className={styles.textWithIcon}>
          <LocationOnIcon />
          {/* TODO: In future use actual country data */}
          <span>
            <Link secondary href={`${mapLink}`}>
              {`${city}, UK`}
            </Link>
          </span>
        </div>
      )}
      {phoneNumber && (
        <div className={styles.textWithIcon}>
          <PhoneIcon />
          <span>
            <Link secondary href={`tel:${phoneNumber}`}>
              {phoneNumber}
            </Link>
          </span>
        </div>
      )}
    </div>
  );
};
