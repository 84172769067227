import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Category } from '@Types';
import { Button, RadioGroup, TextInput } from '@Components/common';
import styles from './FilterModal.module.scss';
import { Divider } from '@Components/Divider/Divider';
import { InputAdornment } from '@material-ui/core';
import { msgFieldRequired } from '@Helpers/errorMessages';
import { RiLockFill, RiStarFill, RiMapPin2Fill } from 'react-icons/ri';
import { ApiErrorObject } from '@Types';
import { useSetFieldErrors } from '@Hooks/useSetFieldErrors';
import { VALIDATE_POSTCODE_REGEX } from '@Helpers/fieldValidation';

interface FilterModalProps {
  categoriesData: Category[] | undefined;
  onSubmit: () => void;
  currentTab: number;
  handlePostcodeChange: (event: any) => void;
  postcode: string | null;
  category: number[] | null;
  valueFromString: (value: any) => void;
  resultsLoading: boolean;
  errors?: ApiErrorObject;
}

export const FilterModal = ({
  onSubmit,
  currentTab,
  categoriesData,
  handlePostcodeChange,
  postcode,
  category,
  valueFromString,
  errors,
  resultsLoading,
}: FilterModalProps) => {
  const methods = useForm();
  const { register, errors: fieldErrors, handleSubmit } = methods;

  const { t } = useTranslation('landingPage');
  const { t: tCommon } = useTranslation('common');
  const categoryItems =
    categoriesData?.map((category) => ({
      value: String(category.id),
      label: category.name,
    })) || [];
  if (currentTab === 0) {
    categoryItems.unshift({ value: '0', label: 'All' });
  }

  const nonProfitTypeItems = [
    { value: '0', label: 'All' },
    { value: '1', label: 'Registered charities' },
    { value: '2', label: 'Community groups' },
    { value: '3', label: 'Social enterprises' },
  ];

  useSetFieldErrors({
    fieldErrors: errors?.field_errors,
    setError: methods.setError,
    loading: resultsLoading,
  });

  const submit = handleSubmit(onSubmit);

  return (
    <FormProvider {...methods}>
      <form onSubmit={onSubmit} autoComplete="off">
        <div className={styles.lockedMessage}>
          <RiLockFill />
          <p>{t('filter.locked_message')}</p>
        </div>
        <h2>{t('filter.title')}</h2>
        <p>{t('filter.subtitle')}</p>
        <Divider />

        <div className={styles.filterContainer}>
          <div className={styles.title}>
            <RiMapPin2Fill size={16} />
            <h4>{t('filter.catchment_area')}</h4>
            {currentTab === 1 && <RiLockFill size={16} />}
          </div>
          <div className={styles.postcodeContainer}>
            <TextInput
              variant="outlined"
              fullWidth
              name="postcode"
              onChange={handlePostcodeChange}
              defaultValue={postcode}
              disabled={currentTab === 1}
              label={t('filter.postcode')}
              inputRef={register({
                required: currentTab === 0 && msgFieldRequired,
                pattern: {
                  value: VALIDATE_POSTCODE_REGEX,
                  message: tCommon('invalid_postcode'),
                },
              })}
              error={!!fieldErrors.postcode}
              helperText={fieldErrors.postcode?.message}
            />
            <TextInput
              variant="outlined"
              fullWidth
              name="radius"
              disabled
              label={t('filter.radius')}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <RiLockFill />
                  </InputAdornment>
                ),
              }}
            />
          </div>

          <Divider />
          <div className={styles.title}>
            <RiStarFill size={16} />
            <h4>{t('filter.category')}</h4>
            {currentTab === 0 && <RiLockFill size={16} />}
          </div>

          <RadioGroup
            items={categoryItems}
            name="category"
            row
            defaultValue={currentTab === 0 ? '0' : String(category)}
            valueFromString={valueFromString}
            disabled={currentTab === 0}
            required={currentTab === 1}
          />

          <Divider />
          <div className={styles.title}>
            <h4>{t('filter.non_profit_type')}</h4>
            <RiLockFill size={16} />
          </div>
          <RadioGroup
            items={nonProfitTypeItems}
            name="nonProfitType"
            row
            defaultValue={'0'}
            disabled
          />
          <Divider />

          <Button onClick={submit} buttonType="primary">
            {t('filter.apply')}
          </Button>
        </div>
      </form>
    </FormProvider>
  );
};
