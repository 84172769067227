// @ts-nocheck
import { useCallback } from 'react';
import { useRequest } from '@Hooks';
import { API } from '@Services';
import { Project, CreateMessage } from '@Types';

export const useSendMessageForFunder = () => {
  const [fetch, response] = useRequest<Project>();

  const sendMessage = useCallback(
    (data: CreateMessage) => {
      let finalData = {
        subject: data.subject,
        message: data.message,
        recipients: data.recipients,
        members: data.members,
      };

      if (data.label) {
        finalData['label'] = data.label;
      }

      fetch(API.paths.send_message, 'post', {
        data: finalData,
      });
    },
    [fetch],
  );

  return [sendMessage, response] as const;
};
