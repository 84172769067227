import React from 'react';
import { useTranslation } from 'react-i18next';
import { ActionsSection, FundCardController, OldTabProps } from '@Components';
import styles from './FundsSection.module.scss';
import { Fund } from 'Types/Fund';

interface FundsSectionProps {
  fundsList?: Fund[];
  currentTab: number;
  tabs?: OldTabProps[];
  viewAsList: boolean;
  onTabChange: (newTab: number) => void;
  archiveFund: (fundId: number) => unknown;
}

export const FundsSection: React.FC<FundsSectionProps> = ({
  fundsList,
  currentTab,
  tabs,
  viewAsList,
  onTabChange,
  archiveFund,
}) => {
  const { t } = useTranslation('dashboard');

  return (
    <ActionsSection
      title={t('funder.funds')}
      viewAsList={viewAsList}
      tabs={tabs}
      currentTab={currentTab}
      onTabChange={onTabChange}
    >
      {viewAsList &&
        fundsList &&
        fundsList.length > 0 &&
        fundsList.map((fundData) => (
          <FundCardController key={fundData.id} fundData={fundData} archiveFund={archiveFund} />
        ))}
      {viewAsList && fundsList?.length === 0 && (
        <span className={styles.noFunds}>{t('funder.no_funds')}</span>
      )}
    </ActionsSection>
  );
};
