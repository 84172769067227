/**
 * Please add here any additional language bundles.
 */
import translationEN from './en';

const resources = {
  en: translationEN,
};

export default resources;
