import { useRequest } from '@Hooks/useRequest';
import { API } from '@Services';
import { ProjectFundingSourceResponse } from '@Types';
import { useCallback } from 'react';

export const useGetProjectFundingSource = () => {
  const [fetch, response] = useRequest<ProjectFundingSourceResponse>();

  const getProjectFundingSource = useCallback(
    (projectId: number) => fetch(API.paths.project_funding_source(projectId), 'get'),
    [fetch],
  );

  return [getProjectFundingSource, response] as const;
};
