import { DropzoneInputProps, DropzoneRootProps } from 'react-dropzone';
import classnames from 'classnames';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import PublishIcon from '@material-ui/icons/Publish';
import { msgFieldRequired } from '@Helpers/errorMessages';

import styles from './ImageUploadDropzone.module.scss';

interface ImageUploadDropzoneProps {
  fieldName: string;
  dropzoneRootProps: DropzoneRootProps;
  dropzoneInputProps: DropzoneInputProps;
  header?: string;
  subtext?: string | JSX.Element;
  chip?: JSX.Element;
  className?: string;
  disabled?: boolean;
  required?: boolean;
}

export const ImageUploadDropzone: React.FC<ImageUploadDropzoneProps> = ({
  fieldName,
  dropzoneRootProps,
  dropzoneInputProps,
  header,
  subtext,
  chip,
  className,
  disabled,
  required,
}) => {
  const { t } = useTranslation('common');
  const { errors: fieldErrors } = useFormContext();

  return (
    <div
      className={classnames(styles.inputWrapper, className, disabled && styles.disabled)}
      {...dropzoneRootProps}
    >
      <Controller
        name={fieldName}
        defaultValue={null}
        render={() => <input name={fieldName} {...dropzoneInputProps} />}
        error={!!fieldErrors}
        helperText={fieldErrors?.message}
        rules={{ required: required && msgFieldRequired }}
      />
      <div className={styles.textWrapper}>
        <PublishIcon />
        <div className={styles.text}>
          <span>{header || t('image_upload.header')}</span>
          {subtext && <span className={styles.hint}>{subtext}</span>}
        </div>
      </div>
      {chip}
    </div>
  );
};
