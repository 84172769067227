import { useCallback, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ConfirmModal } from '@Components';
import { TextInput, Button } from '@Components/common';
import { msgFieldRequired } from '@Helpers/errorMessages';
import { useNotificationsContext } from '@Contexts';
import styles from './NewsletterForm.module.scss';
import { makeStyles } from '@material-ui/core';
import styleVariables from '@Styles/_variables.module.scss';

export const NewsletterForm = () => {
  const methods = useForm();
  const { register, errors: fieldErrors, handleSubmit } = methods;
  const { t } = useTranslation('landingPage');
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { error: errorNotification } = useNotificationsContext();

  const showModal = useCallback(() => {
    setIsModalVisible(true);
  }, []);

  const hideModal = useCallback(() => {
    setIsModalVisible(false);
  }, []);

  const onSubmit = useCallback(
    (data: { name: string; email: string }) => {
      setIsLoading(true);
      fetch(
        `https://semble.activehosted.com/proc.php?u=37&f=37&s=&c=0&m=0&act=sub&v=2&fullname=${encodeURIComponent(
          data.name,
        )}&email=${encodeURIComponent(data.email)}&jsonp=true`,
        { mode: 'no-cors' },
      )
        .then(() => {
          showModal();
        })
        .catch(() => {
          errorNotification(t('newsletter_form.general_error'));
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    [errorNotification, showModal, t],
  );

  const submit = handleSubmit(onSubmit);

  return (
    <div className={styles.container}>
      <h6 className={styles.title}>{t('newsletter_form.title')}</h6>
      <span className={styles.subtitle}>{t('newsletter_form.subtitle')}</span>
      <FormProvider {...methods}>
        <form className={styles.form} onSubmit={submit}>
          <TextInput
            label={t('newsletter_form.name')}
            inputRef={register({ required: msgFieldRequired })}
            name="name"
            error={!!fieldErrors.name}
            helperText={fieldErrors.name?.message}
            className={styles.input}
          />
          <TextInput
            label={t('newsletter_form.email')}
            inputRef={register({
              required: msgFieldRequired,
              pattern: {
                value:
                  /^[\+_a-z0-9-'&=]+(\.[\+_a-z0-9-']+)*@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,})$/i,
                message: t('newsletter_form.invalid_email'),
              },
            })}
            type="email"
            name="email"
            error={!!fieldErrors.email}
            helperText={fieldErrors.email?.message}
            className={styles.input}
          />

          <Button buttonType="primary" onClick={submit} disabled={isLoading}>
            {t('newsletter_form.submit')}
          </Button>
        </form>
      </FormProvider>
      <ConfirmModal
        isOpen={isModalVisible}
        onClose={hideModal}
        title={t('newsletter_form.modal_title')}
        confirmText={t('newsletter_form.modal_confirm_button')}
        onConfirm={hideModal}
        info
      >
        {t('newsletter_form.modal_text')}
      </ConfirmModal>
    </div>
  );
};
