import { ShareButton, StatusChip } from '@Components';
import { useTranslation } from 'react-i18next';
import styles from './ShareTile.module.scss';

interface ShareTileProps {
  hashtag?: string | null;
}

export const ShareTile = ({ hashtag }: ShareTileProps) => {
  const { t } = useTranslation('shareTile');
  return (
    <div className={styles.container}>
      <h4 className={styles.title}>{t('title')}</h4>
      {hashtag && <span className={styles.hashtagHeader}>{t('hashtag_header')}</span>}
      {hashtag && <StatusChip label={hashtag} color="secondary" className={styles.hashtagChip} />}
      <ShareButton title={t('share_fund')} />
    </div>
  );
};
