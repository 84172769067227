import { usePaginatedRequest } from '@Hooks/usePaginatedRequest';
import { API } from '@Services';
import { Match } from '@Types';
import { useCallback } from 'react';

export const useGetFundMatches = () => {
  const [fetch, response] = usePaginatedRequest<Match>({ perPage: 10 });

  const getFundMatches = useCallback(
    (fundId: number) => {
      fetch(API.paths.fund_matches(fundId));
    },
    [fetch],
  );

  return [getFundMatches, response] as const;
};
