import classnames from 'classnames';
import styles from './Card.module.scss';

interface CardProps {
  className?: string;
}

export const Card: React.FC<CardProps> = ({ children, className }) => {
  return (
    <div className={classnames(styles.containerWrapper, className)}>
      <div className={styles.container}>{children}</div>
    </div>
  );
};
