import React from 'react';
import classnames from 'classnames';
import styles from './SembleLogo.module.scss';

interface SembleLogoProps {
  bigLogo?: boolean;
  hideMobile?: boolean;
  reverse?: boolean;
  colorReverse?: boolean;
}

export const SembleLogo: React.FC<SembleLogoProps> = ({
  hideMobile,
  bigLogo,
  reverse,
  colorReverse,
}) => {
  return (
    <span
      className={classnames(
        styles.logoWrapper,
        bigLogo && styles.logoBig,
        reverse && styles.logoReverse,
      )}
    >
      <img
        className={styles.logo}
        src={
          colorReverse ? '/assets/Logo/logoSymbolColorReverse.svg' : '/assets/Logo/logoSymbol.svg'
        }
        alt="Logo"
      />
      <img
        src={colorReverse ? '/assets/Logo/logoTextColorReverse.svg' : '/assets/Logo/logoText.svg'}
        className={classnames(styles.logoText, hideMobile && styles.hideMobile)}
        alt="Logo text"
      />
    </span>
  );
};
