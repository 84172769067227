import { useCallback } from 'react';
import { useRequest } from '@Hooks/useRequest';
import { API } from '@Services';
import { User } from 'Types/User';

export const useUserData = () => {
  const [fetch, response] = useRequest<User>();
  const {
    data: userData,
    loading: isUserLoading,
    isError: isUserError,
    error: userError,
  } = response;

  const fetchUserData = useCallback(() => fetch(API.paths.user, 'get'), [fetch]);

  return { fetchUserData, userData, isUserLoading, isUserError, userError };
};
