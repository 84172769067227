import { useCallback } from 'react';
import { useRequest } from '@Hooks/useRequest';
import { API } from '@Services';
import { GroupProfileData } from '@Types';
import { SpecialUsers, UserId } from '@Services/Api';

export const useGroupProfile = () => {
  const [fetch, response] = useRequest<GroupProfileData>();

  const updateGroupProfile = useCallback(
    (userId: UserId = SpecialUsers.ME) => fetch(API.paths.group_profile(userId), 'get'),
    [fetch],
  );

  return [updateGroupProfile, response] as const;
};
