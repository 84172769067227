import { useEffect, useState } from 'react';

export const useGetCurrentTab = (
  slug: string | undefined,
  tabs:
    | (
        | {
            title: string;
            slug: string;
            disabled?: undefined;
          }
        | {
            title: string;
            slug: string;
            disabled: boolean;
          }
      )[]
    | undefined,
) => {
  const [currentTab, setCurrentTab] = useState(0);
  useEffect(() => {
    if (slug && tabs) {
      setCurrentTab(tabs.findIndex((tab) => tab.slug === slug));
    } else {
      setCurrentTab(0);
    }
  }, [slug, tabs]);

  return currentTab;
};
