// When adding a new route with parameters, add the parameter names here, so you can easily look up the parameters, like:
// const { params } = useRouter();
// const fundId = params[PathParams.FUND_ID];
export const PathParams = {
  VERIFY_KEY: 'verifyKey',
  FUND_ID: 'fundId',
  PROJECT_ID: 'projectId',
  ORGANIZATION_ID: 'organizationId',
  SURVEY_ID: 'surveyId',
  CAMPAIGN_CODE: 'campaignCode',
  USER_ID: 'userId',
  PASSWORD_RESET_TOKEN: 'passwordResetToken',
  PROJECT_MANAGE_SLUG: 'projectManageSlug',
  FUND_MANAGE_SLUG: 'fundManageSlug',
  GROUP_PROFILE_SLUG: 'groupProfileSlug',
  FUNDER_PROFILE_SLUG: 'funderProfileSlug',
  FUND_PAGE_SLUG: 'fundSlug',
  NOMINATION_SLUG: 'slug',
  SHARING_TOKEN: 'sharingToken',
  ORGANIZATION_IMPACT_SLUG: 'orgImpactSlug',
  FUNDING_OPPORTUNITIES_SLUG: 'fundingOpportunitiesSlug',
  SLUG: 'slug',
} as const;

export const QueryParams = {
  PROJECT_UPDATE: 'projectUpdate',
  CAMPAIGN_CODE: 'campaignCode',
};

// When adding a new route, provide it here so we can find the URL for it.
// These entries should be strings if the URL has no parameters,
// or a function with arguments for each parameter if there are parameters.
export const Routes = {
  MARKETING_SITE: 'https://www.actionfunder.org',
  DASHBOARD_FUNDER: '/dashboard/funder',
  DASHBOARD_GROUP: '/dashboard/group',
  NOMINATION_FORM: (slug: string) => `/nominate/${slug}/`,
  NOMINATION_FORM_THANKS: (slug: string) => `/nominate/${slug}/thanks`,
  NOMINATION_FORM_CLOSED: (slug: string) => `/nominate/${slug}/closed`,
  TWO_FACTOR_VERIFICATION: '/sign-in/verify',
  PROJECT_DETAILS: (projectId: string) => `/project/${projectId}`,
  PROJECT_MANAGEMENT: (projectId: number) => `/project/${projectId}/manage`,
  PROJECT_EDIT: (projectId: number) => `/project/${projectId}/edit`,
  PROJECT_CREATE: '/project/create',
  VOTING_THANK_YOU: (fundId: string) => `/fund/${fundId}/vote/thanks`,
  FUNDER_IMPACT_DASHBOARD: (slug: string) => `/dashboard/impact/${slug}`,
  FUNDER_IMPACT_DASHBOARD_NAV: `/dashboard/impact`,
  FUNDING_OPPORTUNITIES: (slug: string) => `/funding-opportunities/${slug}`,
  FUNDING_OPPORTUNITIES_NAV: `/funding-opportunities`,
  MY_PROJECTS: '/my-projects',
  MY_FUNDS: '/my-funds',
  FUND_EDIT: (fundId: number) => `/fund/${fundId}/edit`,
  FUND_DETAILS: (fundId: number) => `/fund/${fundId}`,
  FUND_MANAGEMENT: (fundId: number) => `/fund/${fundId}/manage`,
  FUND_CREATE: '/fund/create',
  FUND_IMPACT: (fundId: number) => `/fund/${fundId}/manage/results`,
  GROUP_PROFILE_PAGE_ABOUT_TAB: (organizationId: number) =>
    `/group-profile/${organizationId}/about`,
  GROUP_PROFILE_CREATE: '/profile/create/group',
  FUNDER_PROFILE_CREATE: '/profile/create/funder',
  GROUP_PROFILE_EDIT: '/profile/edit/group',
  FUNDER_PROFILE_EDIT: '/profile/edit/funder',
  MESSAGES: '/messages',
  ACCEPTED_FUNDING: (projectId: number, selectionId: number) =>
    `/project/${projectId}/manage/funding-offers/${selectionId}/accepted`,
};

// Help Center
// When using one of these excternal help center link you must use getHelpCenterURL
// As that hooks adds the required baseURL and parameters to allow the user to get access
export const HelpCenterRoutes = {
  GROUPS: '/non-profits',
  FUNDERS: '/funders',
  MATCH_CRITERIA: '/non-profits/help/match-criteria/',
  HOW_TO_APPLY_TO_FUND: '/non-profits/help/how-do-i-apply-to-a-fund/',
  WHY_PITCH_WITH_A_VIDEO: '/non-profits/help/why-pitch-with-a-video/',
  VIDEO_PITCH_GUIDE: '/non-profits/help/guide-to-video-pitching/',
  BUDGET_BREAKDOWN_GUIDE: '/non-profits/help/what-should-i-include-in-my-budget-breakdown/',
  IMPACT_REPORTING_GUIDE: '/non-profits/help/how-do-i-demonstrate-my-projects-impact/',
  PROJECT_GUIDE_STEP_1: '/non-profits/help/what-makes-a-good-project/',
  PROJECT_GUIDE_STEP_2: '/non-profits/help/what-makes-a-good-project/#the-pitch',
  PROJECT_GUIDE_STEP_3: '/non-profits/help/what-makes-a-good-project/#the-delivery-plan',
  UPDATES: '/non-profits/help/what-are-updates/',
  POST_FUNDING_REQUIREMENTS: '/non-profits/af-category/post-funding-requirements/',
};

// DEPRECATED: This format for routes isn't very useful, as we can't find URLs
// without manually replacing the relevant path parameter.
// If adding or referencing routes, please use Routes above instead.
export const RoutingPaths = {
  HOW_IT_WORKS_FUNDERS: 'https://www.actionfunder.org/business-solutions/how-it-works/',
  HOW_IT_WORKS_GROUPS: 'https://www.actionfunder.org/non-profits/how-it-works',
  ABOUT_MEET_THE_TEAM: 'https://www.actionfunder.org/meet-the-team/',
  FAQS: 'https://www.actionfunder.org/faqs/',

  BUSINESS_SOLUTIONS: 'https://www.actionfunder.org/business-solutions',
  NON_PROFITS: 'https://www.actionfunder.org/non-profits',
  EXPLORE: 'https://www.actionfunder.org/explore',
  ABOUT: 'https://www.actionfunder.org/about',
  RESOURCES: 'https://www.actionfunder.org/resources',
  CASE_STUDIES: 'https://www.actionfunder.org/case-studies',
  PRICES_AND_FEATURES: 'https://www.actionfunder.org/prices-and-features',
  REQUEST_DEMO: 'https://www.actionfunder.org/request-demo',
  CONTACT: 'https://www.actionfunder.org/contact',

  SIGN_IN: '/sign-in',
  SIGNUP: '/sign-up',
  SIGNUP_FUNDER: '/sign-up/?type=funder',
  SIGNUP_GROUP: '/sign-up/?type=group',

  PROJECT_PREVIEW: 'project-preview',

  CONFIRM_EMAIL: '/confirm-email',
  VERIFY_EMAIL: `/confirm-email/${PathParams.VERIFY_KEY}`,

  PASSWORD_RESET: '/password-reset',
  PASSWORD_RESET_FORM: `/password-reset/${PathParams.PASSWORD_RESET_TOKEN}`,

  JOIN_CAMPAIGN: `/join-campaign/[${PathParams.CAMPAIGN_CODE}]`,

  GROUP_PROFILE_PAGE: `/group-profile/[${PathParams.ORGANIZATION_ID}]/[[...${PathParams.GROUP_PROFILE_SLUG}]]`,
  FUNDER_PROFILE_PAGE: `/funder-profile/[${PathParams.ORGANIZATION_ID}]/[[...${PathParams.FUNDER_PROFILE_SLUG}]]`,

  PROJECTS_LIST: '/projects',
  CREATE_PROJECT: '/project/create',
  EDIT_PROJECT: `/project/[${PathParams.PROJECT_ID}]/edit`,
  PROJECT_DETAILS: `/project/[${PathParams.PROJECT_ID}]`,
  PROJECT_FUNDING_OFFERS: `/project/[${PathParams.PROJECT_ID}]/manage/funding-offers`,
  PROJECT_FUNDING_OFFER_ACCEPT: `/project/[${PathParams.PROJECT_ID}]/manage/funding-offers/[${PathParams.FUND_ID}]/accept`,
  PROJECT_FUNDING_OFFER_REJECT: `/project/[${PathParams.PROJECT_ID}]/manage/funding-offers/[${PathParams.FUND_ID}]/reject`,

  DASHBOARD: '/dashboard',
  DASHBOARD_FUNDER: '/dashboard/funder',
  DASHBOARD_GROUP: '/dashboard/group',

  FUNDS_LIST: '/funds',
  CREATE_FUND: '/fund/create',
  FUND_DETAILS: `/fund/[${PathParams.FUND_ID}]/[[...${PathParams.FUND_PAGE_SLUG}]]`,
  FUND_MANAGEMENT: `/fund/[${PathParams.FUND_ID}]/manage/[[...${PathParams.FUND_MANAGE_SLUG}]]`,

  PRIVACY_POLICY: 'https://www.actionfunder.org/privacy-policy',
  TERMS_OF_USE: 'https://www.actionfunder.org/terms-of-use',
  COOKIE_POLICY: 'https://www.actionfunder.org/cookies-policy',
  COMMERCIAL_TERMS: 'https://www.actionfunder.org/commercial-terms/',

  NOT_LOGGED: '/not-logged',
} as const;
