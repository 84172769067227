export const getYoutubeThumbnailUrl = (url?: string | null, highQuality?: boolean) => {
  if (url) {
    const splitUrl = url.split('/');
    const lastItem = splitUrl[splitUrl.length - 1];
    const idxOfEqualSign = lastItem.indexOf('=');
    const videoId = lastItem.slice(idxOfEqualSign + 1);

    return `https://img.youtube.com/vi/${videoId}/${highQuality ? 'hqdefault' : 'mqdefault'}.jpg`;
  }
  return;
};
