import React from 'react';
import styles from './CategoryTiles.module.scss';

interface CategoryTilesProps {
  items?: {
    id: number;
    image: string;
    name: string;
  }[];
}

export const CategoryTiles = ({ items }: CategoryTilesProps) => {
  return (
    <div className={styles.container}>
      {items &&
        items.map(({ id, image, name }) => (
          <div key={id} className={styles.tile}>
            <div className={styles.imageWrapper} style={{ backgroundImage: `url(${image})` }} />
            <p className={styles.text}>{name}</p>
          </div>
        ))}
    </div>
  );
};
