import { CircularProgressWithLabel } from '@Components/common';
import classnames from 'classnames';
import { Trans, useTranslation } from 'react-i18next';
import styles from './SurveyResponseIndicator.module.scss';

export interface SurveyResponseIndicatorProps {
  responsesCount: number;
  projectsCount: number;
  stickied?: boolean;
}

export const SurveyResponseIndicator = ({
  responsesCount,
  projectsCount,
  stickied,
}: SurveyResponseIndicatorProps) => {
  const normalise = (value: number) => ((value - 0) * 100) / (projectsCount - 0);
  const { t } = useTranslation('fund');

  return (
    <div className={classnames(styles.container, stickied && styles.stickiedContainer)}>
      <CircularProgressWithLabel
        variant="determinate"
        value={normalise(responsesCount)}
        size="8em"
      />
      {stickied && (
        <>
          <p className={styles.responsesCount}>
            <Trans
              ns="fund"
              i18nKey="report.responses_count"
              components={[<strong />]}
              values={{ responsesCount, projectsCount }}
            />
          </p>
          {responsesCount == projectsCount && (
            <strong className={styles.responsesCount}>{t(`report.share`)}</strong>
          )}
        </>
      )}
    </div>
  );
};
