let fb;
let fbLoaded = false;
export default function facebookPixelPlugin(userConfig = {}) {
  return {
    name: 'facebook-ads',
    config: {
      ...userConfig,
    },
    initialize: async ({ config }) => {
      const { pixelId } = config;
      await import('react-facebook-pixel')
        .then((module) => (fb = module.default))
        .then(() => {
          if (!fbLoaded) {
            fb.init(pixelId, {
              autoConfig: true,
              debug: true,
            });
            fbLoaded = true;
          }
        });
    },
    page: () => {
      fb.pageView();
    },
    track: ({ payload }) => {
      fb.track(payload.event, payload.properties);
    },
    loaded: () => {
      return fbLoaded;
    },
  };
}
