import { DropzoneOptions } from 'react-dropzone';

// WARNING: These need to match the corresponding values on the backend
export const IMAGE_UPLOAD_MAX_SIZE = 5 * 1024 * 1024; // 5MB
export const AVATAR_UPLOAD_MAX_SIZE = 1024 * 1024; // 1MB

export const DEFAULT_UPLOAD_CONFIG: DropzoneOptions = {
  accept: ['image/jpeg', 'image/png', 'image/svg+xml'],
  maxFiles: 1,
  multiple: false,
  maxSize: IMAGE_UPLOAD_MAX_SIZE,
};

export const AVATAR_UPLOAD_CONFIG: DropzoneOptions = {
  ...DEFAULT_UPLOAD_CONFIG,
  maxSize: AVATAR_UPLOAD_MAX_SIZE,
};
