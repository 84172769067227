import { useRequest } from '@Hooks/useRequest';
import { API } from '@Services';
import { ProjectUpdate } from '@Types';
import { useCallback } from 'react';

export const useGetProjectUpdate = () => {
  const [fetch, response] = useRequest<ProjectUpdate>();

  const getProjectUpdate = useCallback(
    (updateId: number) => {
      fetch(API.paths.project_update(updateId), 'get');
    },
    [fetch],
  );

  return [getProjectUpdate, response] as const;
};
