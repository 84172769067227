import { useCallback } from 'react';
import styles from './QuoteAuthor.module.scss';
import LocationCityIcon from '@material-ui/icons/LocationCity';
import classnames from 'classnames';
import { Trans } from 'react-i18next';

interface QuoteAuthorProps {
  quotes: { quote: string; project_id: number }[];
  projects: { id: number; group_name: string; name: string; image_url: string; url: string }[];
  activeStep: number;
}

export const QuoteAuthor = ({ projects, activeStep, quotes }: QuoteAuthorProps) => {
  const AvatarItem = useCallback(
    ({ name, image }) => (
      <div className={classnames(styles.avatar)}>
        {image ? <img src={image} alt={name} /> : <LocationCityIcon style={{ fontSize: '24px' }} />}
      </div>
    ),
    [],
  );

  return (
    <div className={styles.authorContainer}>
      {projects.map(
        (project) =>
          project.id == quotes[activeStep].project_id && (
            <div className={styles.authorLayout} key={project.id}>
              <AvatarItem name={project.group_name} image={project.image_url} />
              <div className={styles.author}>
                <Trans
                  ns="fund"
                  i18nKey="report.quote_author"
                  components={[<div />, <strong />]}
                  values={{ project_name: project.name, group_name: project.group_name }}
                />
              </div>
            </div>
          ),
      )}
    </div>
  );
};
