import React, { useRef, useState, MutableRefObject } from 'react';
import { useTranslation } from 'react-i18next';
import { AxiosResponse } from 'axios';
import Linkify from 'linkify-react';
import AnnouncementIcon from '@material-ui/icons/Announcement';
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter';
import DateRangeIcon from '@material-ui/icons/DateRange';
import FavoriteIcon from '@material-ui/icons/Favorite';
import RateReviewIcon from '@material-ui/icons/RateReview';
import RoomIcon from '@material-ui/icons/Room';
import EmojiPeople from '@material-ui/icons/EmojiPeople';

import {
  CreateProjectUpdateModalController,
  StatusChip,
  OldTabs,
  OldTabProps,
  Loader,
  VideoPlayer,
  CategoryTilesController,
  MatchesTile,
  Timeline,
  Tooltip,
  ShareButton,
  UpdatesListItem,
} from '@Components';
import { Pagination } from '@Components/common';
import { Button } from '@Components/common';
import styleVariables from '@Styles/_variables.module.scss';
import { useUserContext } from '@Contexts';
import { Project, ProjectFundingSourceResponse, ProjectUpdate, ProjectStatusEnum } from '@Types';
import { formatNumber } from '@Helpers';

import { IconTile, OrganizationTile } from '@Components';
import { CtaButton } from './Components';
import styles from './ProjectDetailsPage.module.scss';
import classNames from 'classnames';
import { PaginatedResponse } from '@Hooks/usePaginatedRequest';

interface ProjectDetailsPageProps {
  project: Project;
  updateProjectData?: (projectId: number) => Promise<AxiosResponse<Project>>;
  updateProjectUpdates?: (projectId: number) => any;
  deleteProjectUpdate?: (projectUpdateId: number) => Promise<void>;
  isDeleteUpdatesLoading?: boolean;
  tabs?: OldTabProps[];
  currentTab?: number;
  onTabChange?: (tabIndex: number) => void;
  aboutRef?: MutableRefObject<HTMLDivElement | null>;
  isFundingSourceLoading: boolean;
  projectFundingSource?: ProjectFundingSourceResponse;
  projectUpdatePaginatedResponse: PaginatedResponse<ProjectUpdate>;
}

export const ProjectDetailsPage: React.FC<ProjectDetailsPageProps> = ({
  project,
  updateProjectData,
  updateProjectUpdates,
  deleteProjectUpdate,
  isDeleteUpdatesLoading,
  tabs,
  currentTab,
  onTabChange,
  aboutRef,
  isFundingSourceLoading,
  projectFundingSource,
  projectUpdatePaginatedResponse,
}) => {
  const { t: tFund } = useTranslation('fund');
  const { t: tProject } = useTranslation('project');
  const { t: tBeneficiaryTypes } = useTranslation('beneficiaryTypes');
  const headerRef = useRef(null);
  const { userData } = useUserContext();
  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);

  const {
    totalCount: projectUpdatesTotalCount,
    loading: isUpdatesListLoading,
    page: updatesList,
  } = projectUpdatePaginatedResponse;

  const {
    id: projectId,
    details: { name: title, amount: fundingRequired, city: location, categories: categoriesIds },
    status: rawStatus,
    video_pitch,
    written_pitch: writtenPitch,
    delivery_plan,
    like_count: likeCount,
    matches_count,
    group_profile: {
      name: organizationName,
      type: rawOrganizationType,
      website_url: orgWebsiteUrl,
      facebook_url: orgFacebookUrl,
      linkedin_url: orgLinkedinUrl,
      instagram_url: orgInstagramUrl,
      twitter_url: orgTwitterUrl,
      avatar_image: avatarImage,
      organization_id: organizationId,
    },
    user_can_manage: userCanManage,
    user_can_manage_updates: userCanManageUpdates,
    user_can_view_volunteering_opportunities: showVolunteering,
    matches_list,
    selections,
    impact_survey_response: impactSurveyResponse,
    volunteering_opportunity,
  } = project;

  const status = tProject(`status.${rawStatus}`);
  const videoPitch = video_pitch?.video_url_1;
  const budgetBreakdown = delivery_plan?.budget_breakdown;
  const beneficiariesCount = delivery_plan?.beneficiaries;
  const expectedImpact = delivery_plan?.expected_impact;
  const trackRecord = delivery_plan?.track_record;
  const deliveryTimeframe = delivery_plan
    ? tProject(
        `delivery.estimate_timeframe_count_${delivery_plan.timeframe_estimate_unit.toLowerCase()}`,
        {
          count: delivery_plan.timeframe_estimate_number,
        },
      )
    : '';
  const fundId = matches_list?.length === 1 ? matches_list[0].fund.id : undefined;
  const organizationType = tProject(`details_page.group_types.${rawOrganizationType}`);
  const matchesCount = matches_count ?? matches_list?.length;
  const selectionId = selections?.length === 1 ? selections[0].id : undefined;
  const selectionStatus = selections?.length === 1 ? selections[0].status : undefined;
  const writtenPitch1 = writtenPitch?.answer_1;
  const writtenPitch2 = writtenPitch?.answer_2;
  const writtenPitch3 = writtenPitch?.answer_3;
  const writtenPitchImage = writtenPitch?.image;
  const {
    number_of_volunteers: numberOfVolunteers,
    description: volunteeringOpportunityDescription,
  } = volunteering_opportunity || {};

  const [localLikeCount, setLocalLikeCount] = useState(likeCount);

  const onLike = () => {
    if (localLikeCount !== undefined) {
      setLocalLikeCount(localLikeCount + 1);
    }
  };

  const onUnlike = () => {
    if (localLikeCount !== undefined) {
      setLocalLikeCount(localLikeCount - 1);
    }
  };

  const openUpdateModal = () => {
    setIsUpdateModalOpen(true);
  };

  const closeUpdateModal = () => {
    setIsUpdateModalOpen(false);
  };

  const to = encodeURIComponent(project.group_profile.owner_email);
  const cc = encodeURIComponent(
    'hello@actionfunder.org,' +
      project.group_profile.member_email_1 +
      ',' +
      project.group_profile.member_email_2,
  );
  const subject = encodeURIComponent(
    'You have been offered volunteers by ' + projectFundingSource?.details.name,
  );
  const body = encodeURIComponent(
    'Hello, We’ve seen your volunteering opportunity on the ActionFunder platform and wanted to offer our support.',
  );

  const volunteeringMailtoLink =
    'mailto:' + to + '?cc=' + cc + '&subject=' + subject + '&body=' + body;

  const projectIsComplete = rawStatus === 'COMPLETE';
  const canMakeUpdates = userCanManageUpdates && (projectIsComplete || rawStatus === 'FUNDED');
  const surveyUrl = impactSurveyResponse?.url;
  const isProjectFunder = userData?.organization_name == projectFundingSource?.organization.name;

  return (
    <>
      <div className={styles.chipContainer}>
        <StatusChip label={status} color="secondary" />
        {volunteering_opportunity && showVolunteering && (
          <StatusChip
            icon={<EmojiPeople />}
            label={tProject(`volunteering.header`)}
            color="primary"
          />
        )}
      </div>
      <div className={styles.header} ref={headerRef}>
        <h1 className={styles.title}>{title}</h1>
        <div className={styles.headerInfo}>
          {localLikeCount ? (
            <Tooltip title={tProject('details_page.like_count') as string}>
              <div className={styles.headerInfoLikeCount}>
                <FavoriteIcon htmlColor={styleVariables.colorP2} />
                <p>{localLikeCount}</p>
              </div>
            </Tooltip>
          ) : null}
          {canMakeUpdates && (
            <div className={styles.postAnUpdateButtonCompleted}>
              <Button
                buttonType="tertiary"
                onClick={openUpdateModal}
                startIcon={<AnnouncementIcon />}
                aria-label={'project-page-cta-button'}
              >
                {tProject('details_page.post_update')}
              </Button>
            </div>
          )}
          {/*
          All the actions for before a project is complete are in this crazy multi-function button.
          */}
          {!projectIsComplete && (
            <CtaButton
              headerRef={headerRef}
              project
              fundId={fundId}
              projectId={projectId}
              userCanManage={userCanManage}
              matchesCount={matchesCount}
              selectionId={selectionId}
              selectionStatus={selectionStatus}
              updateProjectData={updateProjectData}
              status={status}
            />
          )}
          {surveyUrl && (
            <Button
              buttonType="primary"
              variant="contained"
              href={surveyUrl}
              startIcon={<RateReviewIcon />}
              aria-label={'project-page-cta-button'}
            >
              {tProject('details_page.submit_survey')}
            </Button>
          )}
        </div>
      </div>
      <div className={styles.content} ref={aboutRef}>
        <div className={styles.mainContent}>
          {tabs && currentTab !== undefined && (
            <div className={styles.tabsWrapper}>
              <OldTabs
                tabs={tabs}
                currentTab={currentTab}
                onTabChange={onTabChange}
                fullWidth={false}
              />
            </div>
          )}

          <div className={styles.iconTiles}>
            <IconTile
              labelText={tProject('details_page.funding_required')}
              valueText={tFund('common:currency_format', { amount: fundingRequired })}
            >
              <BusinessCenterIcon />
            </IconTile>
            <IconTile
              labelText={tProject('details_page.delivery_timeframe')}
              valueText={deliveryTimeframe}
            >
              <DateRangeIcon />
            </IconTile>
            <IconTile labelText={tProject('details_page.location')} valueText={location}>
              <RoomIcon />
            </IconTile>
          </div>
          <h2 className={styles.contentHeader}>{tProject('details_page.pitch_header')}</h2>
          {videoPitch && <VideoPlayer videoUrl={videoPitch} />}
          {writtenPitchImage && (
            <img src={writtenPitchImage} className={styles.pitchImage} alt="" />
          )}
          {writtenPitch1 && (
            <>
              <h3 className={styles.sectionHeader}>{tProject('details_page.problem_statement')}</h3>
              <p className={styles.textParagraph}>
                <Linkify>{writtenPitch1}</Linkify>
              </p>

              <h3 className={styles.sectionHeader}>{tProject('details_page.solution')}</h3>
              <p className={styles.textParagraph}>
                <Linkify>{writtenPitch2}</Linkify>
              </p>

              <h3 className={styles.sectionHeader}>{tProject('details_page.best_to_deliver')}</h3>
              <p className={styles.textParagraph}>
                <Linkify>{writtenPitch3}</Linkify>
              </p>
            </>
          )}

          <h2 className={styles.contentHeader}>{tProject('details_page.focus_areas')}</h2>
          <CategoryTilesController categoriesIds={categoriesIds} />

          {budgetBreakdown && (
            <>
              <h2 className={styles.contentHeader}>
                {tProject('details_page.delivery_plan_header')}
              </h2>
              <h3 className={styles.sectionHeader}>{tProject('details_page.budget_breakdown')}</h3>
              <table className={styles.budgetTable}>
                <tbody>
                  {budgetBreakdown &&
                    budgetBreakdown.map(({ cost_description, amount }, i) => (
                      <tr key={i}>
                        <td>{cost_description}</td>
                        <td className={styles.budgetAmountCell}>
                          <b>{tProject('common:currency_format', { amount })}</b>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>

              <h3 className={styles.sectionHeader}>{tProject('details_page.beneficiaries')}</h3>
              <p className={styles.textParagraph}>
                {tProject('details_page.beneficiaries_count', {
                  count: beneficiariesCount,
                  formattedCount: formatNumber(beneficiariesCount),
                })}
              </p>
              {project.delivery_plan?.beneficiary_types_people && (
                <>
                  <h3 className={styles.sectionHeader}>
                    {tProject('details_page.beneficiaries_types')}
                  </h3>
                  <div className={styles.chipContainer}>
                    {project.delivery_plan?.beneficiary_types_people.map((beneficiary_type, i) => (
                      <div key={i}>
                        {beneficiary_type != 'other' ? (
                          <StatusChip label={tBeneficiaryTypes(`people.${beneficiary_type}`)} />
                        ) : (
                          <Tooltip
                            title={project.delivery_plan?.beneficiary_types_people_other as string}
                          >
                            <span>
                              <StatusChip
                                className={styles.beneficiaryTypesOtherChip}
                                label={project.delivery_plan?.beneficiary_types_people_other}
                              />
                            </span>
                          </Tooltip>
                        )}
                      </div>
                    ))}
                  </div>
                </>
              )}

              <h3 className={styles.sectionHeader}>{tProject('details_page.expected_impact')}</h3>
              <p className={styles.textParagraph}>
                <Linkify>{expectedImpact}</Linkify>
              </p>

              {trackRecord && (
                <h3 className={styles.sectionHeader}>{tProject('details_page.track_record')}</h3>
              )}
              <p className={styles.textParagraph}>
                <Linkify>{trackRecord}</Linkify>
              </p>
            </>
          )}

          {volunteering_opportunity && showVolunteering && (
            <div className={styles.volunteeringContainer}>
              <h2 className={classNames(styles.contentHeader, styles.minMargin)}>
                {tProject('volunteering.header')}
              </h2>
              <p className={styles.textParagraph}>{tProject('volunteering.sub_header')}</p>

              <h3 className={styles.sectionHeader}>
                {tProject('volunteering.maximum_number_of_volunteers')}
              </h3>
              <p className={styles.textParagraph}>{numberOfVolunteers}</p>
              <h3 className={styles.sectionHeader}>{tProject('volunteering.brief_description')}</h3>
              <p className={styles.textParagraph}>{volunteeringOpportunityDescription}</p>
              <h3 className={styles.sectionHeader}>
                {tProject('volunteering.public_liability_insurance')}
              </h3>
              <p className={classNames(styles.textParagraph, styles.bottomMargin)}>
                {tProject('volunteering.insurance_statement')}
              </p>
              {!isFundingSourceLoading && isProjectFunder && (
                <Button
                  buttonType="primary"
                  className={styles.enquireButton}
                  to={volunteeringMailtoLink}
                >
                  {tProject('volunteering.enquire_now_button')}
                </Button>
              )}
            </div>
          )}

          {(tProject(`status.${ProjectStatusEnum.FUNDED}`) === status ||
            tProject(`status.${ProjectStatusEnum.COMPLETE}`) === status) && (
            <>
              {(isUpdatesListLoading || isDeleteUpdatesLoading) && <Loader />}
              {!isUpdatesListLoading && !isDeleteUpdatesLoading && (
                <>
                  <hr className={styles.contentHeaderBorder} />

                  <h2 className={styles.contentHeader}>
                    {tFund('details_page.updates_header', {
                      updatesCount: projectUpdatesTotalCount || 0,
                    })}
                  </h2>

                  {updatesList && updatesList.length > 0 && (
                    <>
                      <Timeline>
                        {updatesList.map(
                          ({
                            id,
                            image,
                            description,
                            created,
                            likes,
                            video_url,
                            project_id,
                            project_name,
                            avatar_thumbnail,
                            organization_id,
                            organization_name,
                          }) => ({
                            id,
                            date: created,
                            update: (
                              <UpdatesListItem
                                key={id}
                                id={id}
                                image={image}
                                video={video_url}
                                description={description}
                                createdAt={created}
                                likes={likes}
                                likeCount={likes.length}
                                organizationId={organization_id}
                                organizationName={organization_name}
                                organizationAvatar={avatar_thumbnail}
                                projectId={project_id}
                                projectName={project_name}
                                onDeleteProjectUpdate={deleteProjectUpdate}
                                onLike={onLike}
                                onUnlike={onUnlike}
                                user={userData}
                              />
                            ),
                          }),
                        )}
                      </Timeline>
                      <Pagination paginatedResponse={projectUpdatePaginatedResponse} />
                    </>
                  )}
                </>
              )}
            </>
          )}
        </div>
        <div className={styles.sideContent}>
          {matchesCount !== undefined && <MatchesTile matchesCount={matchesCount} />}
          <OrganizationTile
            subtitle={tProject('details_page.published_by')}
            organizationName={organizationName}
            organizationType={organizationType}
            avatarImage={avatarImage}
            orgWebsiteUrl={orgWebsiteUrl}
            orgFacebookUrl={orgFacebookUrl}
            orgLinkedinUrl={orgLinkedinUrl}
            orgInstagramUrl={orgInstagramUrl}
            orgTwitterUrl={orgTwitterUrl}
            organizationId={organizationId}
          />
          {isFundingSourceLoading && <Loader />}
          {!isFundingSourceLoading && projectFundingSource && (
            <OrganizationTile
              subtitle={tProject('details_page.funded_by')}
              fundName={projectFundingSource.details.name}
              avatarImage={projectFundingSource.organization.profile.avatar_image}
              fundID={projectFundingSource.id}
              funderName={projectFundingSource.organization.name}
            />
          )}
          <ShareButton
            title={tProject('details_page.share_project')}
            className={styles.shareButton}
          />
        </div>
      </div>
      {isUpdateModalOpen && (
        <CreateProjectUpdateModalController
          isOpen={isUpdateModalOpen}
          onClose={closeUpdateModal}
          project={project}
          updateProjectUpdates={updateProjectUpdates}
          onOpen={openUpdateModal}
        />
      )}
    </>
  );
};
