import { createTheme, makeStyles } from '@material-ui/core/styles';
import styleVariables from '@Styles/_variables.module.scss';

export const useErrorStateStyles = makeStyles({
  nonError: {
    marginBottom: '24px',
  },
  errorState: {
    marginBottom: '10px',
  },
});

export const globalOverrides = createTheme({
  overrides: {
    MuiFormControl: {
      root: {
        width: '100%',
      },
    },
    MuiFormControlLabel: {
      root: {
        alignItems: 'flex-start',
      },
    },
    MuiSelect: {
      select: {
        '&:focus': {
          backgroundColor: 'transparent',
        },
      },
      icon: {
        color: styleVariables.colorT2Light,
      },
    },
    MuiOutlinedInput: {
      root: {
        '&:hover $notchedOutline': {
          borderColor: styleVariables.colorP1,
        },
        '&$focused $notchedOutline': {
          borderColor: styleVariables.colorP1,
        },
        '&$disabled $notchedOutline': {
          opacity: '0.6',
        },
      },
      input: {
        padding: '18px 16px',
      },
      notchedOutline: {
        borderColor: styleVariables.colorT2Pale,
      },
    },
    MuiFormLabel: {
      root: {
        color: styleVariables.colorT2Light,
        '&$focused': {
          color: styleVariables.colorT2Light,
        },
      },
    },
    MuiInputBase: {
      input: {
        color: styleVariables.colorT2,
        '&$disabled': {
          color: styleVariables.colorT2Lighter,
        },
      },
      root: {
        color: styleVariables.colorT2,
      },
    },
    MuiInputLabel: {
      formControl: {
        transform: 'translate(12px, 20px) scale(1)',
        '&$focused': {
          color: styleVariables.colorP1,
          transform: 'translate(12px, -6px) scale(0.75)',
        },
        '&$shrink': {
          transform: 'translate(12px, -6px) scale(0.75)',
        },
      },
    },
    MuiButton: {
      root: {
        padding: '15px 22px',
        verticalAlign: 'middle',
        minWidth: '125px',
        borderRadius: '10px',
        color: styleVariables.AFDeepBlack,
        height: 'fit-content',
      },
      sizeSmall: {
        padding: '10px 12px',
      },
      label: {
        textTransform: 'none',
        fontSize: '16px',
        lineHeight: '24px',
        fontWeight: 500,
      },
    },
    MuiStepper: {
      root: {
        paddingLeft: 0,
        paddingRight: 0,
      },
    },
    MuiStepIcon: {
      root: {
        borderRadius: '50%',
        border: `1px solid ${styleVariables.colorT2Pale}`,
        color: styleVariables.colorT5,
        height: 40,
        width: 40,
        marginRight: 8,
        '&$active': {
          borderColor: styleVariables.colorP2,
          color: styleVariables.colorP2,
        },
        '&$completed': {
          borderColor: styleVariables.colorP2,
          color: styleVariables.colorP2,
        },
      },
      text: {
        fill: styleVariables.colorT2,
      },
    },
    MuiStepLabel: {
      label: {
        color: styleVariables.colorT2,
        fontWeight: 400,
        '&$active': {
          color: styleVariables.colorT2,
          fontWeight: 500,
        },
        '&$completed': {
          color: styleVariables.colorT2Light,
          fontWeight: 400,
        },
      },
    },
    MuiStepConnector: {
      line: {
        borderColor: styleVariables.colorT2Paler,
      },
    },
    MuiIconButton: {
      root: {
        '&:hover': {
          backgroundColor: 'transparent',
        },
      },
      colorSecondary: {
        color: styleVariables.colorT2,
        '&:hover': {
          backgroundColor: styleVariables.colorT2Pale,
        },
      },
    },
    MuiTypography: {
      root: {
        color: styleVariables.colorT2Light,
      },
    },
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: styleVariables.colorP1,
      },
    },
    MuiPickersDay: {
      day: {
        color: styleVariables.colorT2Light,
      },
      daySelected: {
        backgroundColor: styleVariables.colorP1,
        color: styleVariables.colorT5,
        '&:hover': {
          backgroundColor: styleVariables.colorP1,
        },
      },
    },
    MuiPaper: {
      root: {
        color: 'inherit',
      },
    },
    MuiAccordion: {
      root: {
        marginBottom: '16px',
        border: `1px solid ${styleVariables.colorT2Pale}`,
        '&:before': {
          display: 'none',
        },
      },
      rounded: {
        borderRadius: '4px',
      },
    },
    MuiAccordionSummary: {
      root: {
        padding: '16px 40px',
      },
      content: {
        '&$expanded': {
          margin: 0,
        },
      },
    },
    MuiAccordionDetails: {
      root: {
        display: 'block',
        padding: '0 40px',
      },
    },
  },
});
