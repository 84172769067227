import { usePaginatedRequest } from '@Hooks/usePaginatedRequest';
import { API } from '@Services';
import { ProjectUpdate } from '@Types';
import { useCallback } from 'react';
import { ReportFilterParameters } from './useGetFunderReportData';

export const useGetFunderProjectUpdates = () => {
  const [fetch, response] = usePaginatedRequest<ProjectUpdate>({ perPage: 10 });

  const getFunderProjectUpdates = useCallback(
    (organizationId: number, filterParams?: ReportFilterParameters) => {
      fetch(API.paths.all_project_updates, {
        funder_id: organizationId,
        is_hidden: false,
        ...filterParams,
      });
    },
    [fetch],
  );

  return [getFunderProjectUpdates, response] as const;
};
