import { Button } from '../Button/Button';
import styles from './PreviewCTA.module.scss';
import { ReactElement } from 'react';

export interface PreviewCTAProps {
  buttonText: string;
  to: string;
  icon?: ReactElement;
}

export const PreviewCTA = ({ buttonText, icon, to }: PreviewCTAProps) => {
  return (
    <div className={styles.container}>
      <Button buttonType="secondary" to={to} className={styles.ctaButton} startIcon={icon}>
        {buttonText}
      </Button>
    </div>
  );
};
