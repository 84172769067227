import { Trans } from 'react-i18next';
import classnames from 'classnames';
import { Link } from '@Components/common';
import styles from './Title.module.scss';

interface TitleProps {
  fund?: boolean;
  project?: boolean;
  id: number;
  name: string;
  organizationId?: number;
  organizationName: string;
}

export const Title = ({
  fund,
  project,
  id,
  name,
  organizationId,
  organizationName,
}: TitleProps) => {
  return (
    <>
      <Link href={fund ? `/fund/${id}` : `/project/${id}`} className={styles.actionName}>
        {name}
      </Link>
      <p className={classnames(styles.organizationName, project && styles.withLink)}>
        <Trans
          ns="common"
          i18nKey="featured_card.by"
          components={[
            <Link
              href={`/${fund ? 'funder' : 'group'}-profile/${organizationId}`}
              className={styles.organizationPageLink}
            />,
          ]}
          values={{ organizationName }}
        />
      </p>
    </>
  );
};
