import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HighchartsMore from 'highcharts/highcharts-more';
import HighchartsSolidGauge from 'highcharts/modules/solid-gauge';
import { useTranslation } from 'react-i18next';
import styles from './Meter.module.scss';
import styleVariables from '@Styles/_variables.module.scss';
import { formatChartTheme } from '@Helpers';

if (typeof Highcharts === 'object') {
  HighchartsMore(Highcharts);
  HighchartsSolidGauge(Highcharts);
}

interface MeterProps {
  data: number;
  quote: string;
}

export const Meter = ({ data, quote }: MeterProps) => {
  const { t } = useTranslation('charts');
  const colors = formatChartTheme(styleVariables.chartsThemeReport);
  const options = {
    chart: {
      type: 'solidgauge',
      height: '80%',
      width: 300,
      style: {
        fontFamily: 'Inter',
      },
    },
    title: {
      text: '',
      style: {
        fontFamily: 'Roboto Slab',
      },
    },
    pane: {
      center: ['50%', '75%'],
      startAngle: -90,
      endAngle: 90,
      size: '100%',
      background: {
        backgroundColor: Highcharts?.defaultOptions?.legend?.backgroundColor || '#EEE',
        innerRadius: '70%',
        outerRadius: '100%',
        shape: 'arc',
      },
    },

    exporting: {
      enabled: false,
    },

    tooltip: {
      enabled: false,
    },
    // the value axis
    yAxis: {
      stops: [
        [0.35, colors[5]],
        [0.7, colors[1]],
        [0.9, colors[0]],
      ],
      lineWidth: 0,
      tickWidth: 0,
      minorTickInterval: null,
      tickAmount: 2,
      labels: {
        y: 16,
        format: '{value}%',
      },
      min: 0,
      max: 100,
    },

    plotOptions: {
      solidgauge: {
        innerRadius: '70%',
        dataLabels: {
          y: 5,
          borderWidth: 0,
          useHTML: true,
        },
      },
    },
    credits: {
      enabled: false,
    },
    series: [
      {
        name: 'test',
        data: [data],
        dataLabels: {
          format:
            '<div style="text-align:center">' +
            '<span style="font-size:32px">{y:.0f}%</span><br/>' +
            '</div>',
          y: -25,
        },
      },
    ],
  };
  return (
    <div className={styles.container}>
      <HighchartsReact highcharts={Highcharts} options={options} />
      <div className={styles.textContainer}>
        <span className={styles.statement}>{t('meter.agreed')}</span>
        <span>{t('meter.quote', { quote })}</span>
      </div>
    </div>
  );
};
