import classnames from 'classnames';
import styles from './Divider.module.scss';

interface DividerProps {
  className?: string;
  small?: boolean;
}

export const Divider = ({ className, small }: DividerProps) => {
  return <hr className={classnames(styles.divider, className, small && styles.smallMargin)} />;
};
