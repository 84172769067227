import { MatrixQuestion } from '@Types';

export const matrixQuestionToMatrixFormat = (matrixQuestion: MatrixQuestion) => {
  let label: string = '';
  // Assigning question label
  if (matrixQuestion) {
    label = matrixQuestion.label;
  }

  // Creating data array
  const unfilteredAnswers: {
    identifier: string;
    percentage: number;
    value: number;
  }[] = [];

  matrixQuestion?.questions.forEach(function (question) {
    Object.entries(question.answers).forEach(function ([key, answer]) {
      unfilteredAnswers.push({
        identifier: key,
        percentage: answer.percentage,
        value: answer.value,
      });
    });
  });

  const filteredAnswers: { identifier: string; percentage: number[]; value: number[] }[] =
    Array.from(new Set(unfilteredAnswers.map((a) => a.identifier))) // Get unique identifier as array
      .map((i) => {
        // Iterate that array
        return {
          percentage: unfilteredAnswers.filter((a) => a.identifier === i).map((a) => a.percentage), // return just the % for original array items matching each unique identifier
          value: unfilteredAnswers.filter((a) => a.identifier === i).map((a) => a.value), // return just the value for original array items matching each unique identifier
          identifier: i,
        };
      });

  // Merging answers with corresponding labels
  let answers = [];
  answers = filteredAnswers.map((item) => {
    const found = matrixQuestion?.choices.find(
      (element) => item.identifier == element.identifier,
    ) as { identifier: string; label: string };
    return { ...item, ...found };
  }) as { label: string; percentage: number[]; value: number[] }[];

  const labels: string[] = [];
  matrixQuestion.questions.map((question) => {
    labels.push(question.label);
  });

  const data = {
    labels: labels,
    answers: answers,
  };

  return { label, data };
};
