import { useRef } from 'react';
import { useFormContext } from 'react-hook-form';
import { Trans } from 'react-i18next';
import classnames from 'classnames';

import DeleteIcon from '@material-ui/icons/Delete';

import { ImageUploadDropzone, MIN_UPDATE_IMAGE_ASPECT_RATIO } from '@Components';
import { DEFAULT_UPLOAD_CONFIG } from '@Helpers/images';
import { useImageUpload, useElementWidth } from '@Hooks';

import styles from './ProjectUpdateImageUpload.module.scss';

const FIELD_NAME = 'image';

interface ProjectUpdateImageUploadProps {
  disabled?: boolean;
  /** isModalOpen is used to force rerender and hide image preview after modal's been closed */
  isModalOpen?: boolean;
}

export const ProjectUpdateImageUpload = ({
  disabled,
  isModalOpen,
}: ProjectUpdateImageUploadProps) => {
  const { errors: fieldErrors, watch } = useFormContext();
  const imagePreviewRef = useRef<HTMLImageElement>(null);

  const { file, deleteFile, dropzoneInputProps, dropzoneRootProps } = useImageUpload({
    fieldName: FIELD_NAME,
    uploadConfig: { ...DEFAULT_UPLOAD_CONFIG, disabled },
    sentryLog: {
      maxSize: 'Create project update - file too big',
    },
  });

  const isDropzoneHidden =
    (Array.isArray(file) || typeof file === 'string') && Boolean(watch(FIELD_NAME));

  const { elementWidth } = useElementWidth({ elementRef: imagePreviewRef, deps: [file] });

  return (
    <>
      <ImageUploadDropzone
        fieldName={FIELD_NAME}
        dropzoneInputProps={dropzoneInputProps}
        dropzoneRootProps={dropzoneRootProps}
        subtext={
          <Trans
            ns="projectUpdate"
            i18nKey="image_upload_hint"
            components={[<strong className={styles.textBold} />]}
          />
        }
        className={classnames(isDropzoneHidden && styles.hidden)}
        disabled={disabled}
      />

      {isDropzoneHidden && (
        <div className={styles.previewWrapper}>
          <img
            className={styles.imagePreview}
            src={typeof file === 'string' ? file : file && file[0].preview}
            alt=""
            ref={imagePreviewRef}
            style={{
              maxHeight: elementWidth ? elementWidth / MIN_UPDATE_IMAGE_ASPECT_RATIO : '',
            }}
          />

          <button onClick={deleteFile} className={styles.deleteIcon} disabled={disabled}>
            <DeleteIcon style={{ fontSize: 20 }} />
          </button>
        </div>
      )}

      {fieldErrors[FIELD_NAME]?.message && (
        <p className={styles.errorMessage}>{fieldErrors[FIELD_NAME].message}</p>
      )}
    </>
  );
};
