import React from 'react';
import {
  Tooltip as MaterialTooltip,
  TooltipProps as MaterialTooltipProps,
} from '@material-ui/core';
import { createStyles, withStyles } from '@material-ui/core/styles';
import styleVariables from '@Styles/_variables.module.scss';

const StyledTooltip = withStyles(() =>
  createStyles({
    tooltip: {
      backgroundColor: styleVariables.colorT2,
      padding: '12px',
      maxWidth: '230px',
      fontSize: '12px',
    },
  }),
)(MaterialTooltip);

export const Tooltip: React.FC<MaterialTooltipProps> = ({ children, ...props }) => {
  return (
    <StyledTooltip {...props} enterTouchDelay={0}>
      {children}
    </StyledTooltip>
  );
};
