import React from 'react';
import styles from './MarketingSiteLinks.module.scss';
import { RoutingPaths } from '@App/paths';
import { useRouter } from '@Helpers/useRouter';
import { useTranslation } from 'react-i18next';

interface MarketingSiteLinksProps {
  className?: string;
}

export const MarketingSiteLinks = ({ className }: MarketingSiteLinksProps) => {
  const { t } = useTranslation('landingPage');
  const { push } = useRouter();
  const marketingLinks = [
    {
      link: RoutingPaths.BUSINESS_SOLUTIONS,
      title: t('business_solutions'),
    },
    {
      link: RoutingPaths.NON_PROFITS,
      title: t('non_profits'),
    },
    {
      link: RoutingPaths.EXPLORE,
      title: t('explore'),
    },
    {
      link: RoutingPaths.ABOUT,
      title: t('about'),
    },
    {
      link: RoutingPaths.RESOURCES,
      title: t('resources'),
    },
  ];
  return (
    <div className={className}>
      <div className={styles.publicButtonsWrapper}>
        {marketingLinks.map(({ link, title }) => (
          <button
            key={title}
            onClick={() => {
              push(link);
            }}
            className={styles.link}
            type="button"
          >
            {title}
          </button>
        ))}
      </div>
    </div>
  );
};
