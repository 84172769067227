import React from 'react';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import TextField, { TextFieldProps as MaterialTextFieldProps } from '@material-ui/core/TextField';
import { useErrorStateStyles } from '@Components/common/themeOverrides';

export type TextInputProps = MaterialTextFieldProps & {
  /** View only. Validation must be handled separately */
  displayCharLimit?: number;
};

export const TextInput: React.FC<TextInputProps> = ({ displayCharLimit, ...props }) => {
  const classes = useErrorStateStyles();
  const { t } = useTranslation('common');
  const { watch } = useFormContext();

  let inputTextLength;
  /** Prevent unnecessary rerenders */
  if (displayCharLimit) {
    inputTextLength = watch(props.name as string, '')?.length || 0;
  }

  return (
    <TextField
      variant="outlined"
      fullWidth
      type="text"
      {...props}
      classes={{ root: props.error ? classes.errorState : classes.nonError }}
      {...(props.type === 'number' && {
        onWheel: (e) => (e.target as HTMLInputElement).blur(),
      })}
      helperText={
        props.helperText ??
        (displayCharLimit && (
          <span style={{ display: 'block', margin: 0, textAlign: 'right' }}>
            {`${inputTextLength}/${displayCharLimit}`} {t('characters')}
          </span>
        ))
      }
    />
  );
};
