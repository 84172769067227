import { SvgIconTypeMap } from '@material-ui/core';
import { OverridableComponent } from '@material-ui/core/OverridableComponent';
import { ReactNode } from 'react';
import styles from './TextWithIcon.module.scss';

interface TextWithIconProps {
  text: string | JSX.Element;
  value?: string | number | ReactNode;
  icon: OverridableComponent<SvgIconTypeMap<{}, 'svg'>>;
}

export const TextWithIcon = ({ text, value, icon }: TextWithIconProps) => {
  const Icon = icon;

  return (
    <div className={styles.textWithIcon}>
      <Icon className={styles.icon} />
      {text}
      {value && `: `}
      <b className={styles.value}>{value}</b>
    </div>
  );
};
