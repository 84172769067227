import React, { useCallback, useEffect, useState } from 'react';
import debounce from 'lodash/debounce';

interface UseElementWidthProps {
  elementRef: React.RefObject<any>;
  deps?: any[];
}

export const useElementWidth = ({ elementRef, deps = [] }: UseElementWidthProps) => {
  const [elementWidth, setElementWidth] = useState<number>();

  const handleResize = useCallback(() => {
    if (elementRef?.current !== null) {
      setElementWidth(elementRef.current.offsetWidth);
    }
  }, [elementRef]);

  useEffect(() => {
    if (!window) return;

    const debouncedHandleResize = debounce(handleResize, 50);
    handleResize();

    window.addEventListener('resize', debouncedHandleResize);

    return () => {
      window.removeEventListener('resize', debouncedHandleResize);
    };
  }, [handleResize, ...deps]); // eslint-disable-line react-hooks/exhaustive-deps

  return { elementWidth, handleResize };
};
