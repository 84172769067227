import React from 'react';
import classnames from 'classnames';
import { Section, SectionProps } from '@Components/Section/Section';
import styles from './ActionsSection.module.scss';

export const ActionsSection: React.FC<SectionProps> = ({ children, viewAsList, ...rest }) => {
  return (
    <Section
      contentStyles={classnames(viewAsList ? styles.listContent : styles.introContent)}
      viewAsList={viewAsList}
      {...rest}
    >
      {children}
    </Section>
  );
};
