import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import FavoriteIcon from '@material-ui/icons/Favorite';
import ShareIcon from '@material-ui/icons/Share';

import { Divider } from '@Components';
import styleVariables from '@Styles/_variables.module.scss';
import { ProjectUpdateLike, User } from '@Types';

import styles from './ShareBar.module.scss';
import { LikeListModal } from './LikeListModal';
import { Button } from '@Components/common';

export const ShareBar = ({
  likes,
  likeCount,
  onLike,
  onShareClick,
  onUnlike,
  user,
}: {
  likes: ProjectUpdateLike[];
  likeCount: number;
  onLike: () => void;
  onShareClick: () => void;
  onUnlike: () => void;
  user: User | undefined;
}) => {
  const { t } = useTranslation('projectUpdate');
  const [internalHasLiked, setInternalHasLiked] = useState(() => {
    if (user !== undefined) {
      return (
        likes.filter((like) => {
          return like.user.id === user.id;
        }).length > 0
      );
    }

    return false;
  });
  const [internalLikes, setInternalLikes] = useState(likes);
  const [internalLikeCount, setInternalLikeCount] = useState(likeCount);
  const [likeModalOpen, setLikeModalOpen] = useState(false);

  const onLikeButtonClick = () => {
    if (user !== undefined) {
      if (internalHasLiked) {
        setInternalHasLiked(false);
        setInternalLikeCount(internalLikeCount - 1);
        setInternalLikes(
          internalLikes.filter((like) => {
            return like.user.id !== user.id;
          }),
        );
        onUnlike();
      } else {
        setInternalHasLiked(true);
        setInternalLikeCount(internalLikeCount + 1);
        setInternalLikes([
          ...internalLikes,
          {
            id: 0,
            user: user,
          },
        ]);
        onLike();
      }
    }
  };

  return (
    <>
      <div className={styles.holder}>
        <div className={styles.summaryHolder}>
          {internalLikeCount > 0 && (
            <>
              <FavoriteIcon htmlColor={styleVariables.colorP2} />
              <p onClick={() => setLikeModalOpen(true)}>
                {t('likes_count', { count: internalLikeCount })}
              </p>
            </>
          )}
        </div>
        {user !== undefined && (
          <>
            <Divider className={styles.divider} />
            <div className={styles.buttonHolder}>
              <Button onClick={onLikeButtonClick} buttonType="text">
                <div className={styles.likeButtonHolder}>
                  {internalHasLiked ? (
                    <FavoriteIcon htmlColor={styleVariables.colorP2} />
                  ) : (
                    <FavoriteBorderIcon htmlColor={styleVariables.colorT2Lighter} />
                  )}
                  <p>{t('like')}</p>
                </div>
              </Button>
              <Button onClick={onShareClick} buttonType="text">
                <div className={styles.likeButtonHolder}>
                  <ShareIcon htmlColor={styleVariables.colorT2Lighter} />
                  <p>{t('share')}</p>
                </div>
              </Button>
            </div>
          </>
        )}
        <LikeListModal
          isOpen={likeModalOpen}
          likes={internalLikes}
          title={'Liked By'}
          onClose={() => {
            setLikeModalOpen(false);
          }}
        />
      </div>
    </>
  );
};
