import { useCallback } from 'react';
import { useRequest } from '@Hooks/useRequest';
import { API } from '@Services';

export const useSaveSurveyData = () => {
  const [fetch, response] = useRequest<any>();

  const saveSurveyData = useCallback(
    (responseId: number, data: any) => {
      return fetch(API.paths.impact_survey_response(responseId), 'put', {
        data,
      });
    },
    [fetch],
  );

  return [saveSurveyData, response] as const;
};
