import { useCallback } from 'react';
import { useRequest } from '@Hooks';
import { API } from '@Services';
import { FundFormStep1, FundFormStep2 } from '@Types';

export const useAddFundDetails = () => {
  const [fetch, response] = useRequest<FundFormStep1 | FundFormStep2>();

  const addFundDetails = useCallback(
    (fundId: number, data: FundFormStep1 | FundFormStep2, update?: boolean) =>
      fetch(API.paths.funds_details(fundId), update ? 'patch' : 'post', {
        data,
      }),
    [fetch],
  );

  return [addFundDetails, response] as const;
};
