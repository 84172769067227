import React from 'react';
import classnames from 'classnames';
import { Button } from '@Components/common';
import styles from './CTABanner.module.scss';

interface CTABannerProps {
  className?: string;
  title: string;
  subtitle: string;
  ctaText: string;
  ctaLink: string;
  ctaText2?: string;
  ctaLink2?: string;
}

export const CTABanner: React.VFC<CTABannerProps> = ({
  className,
  title,
  subtitle,
  ctaText,
  ctaLink,
  ctaText2,
  ctaLink2,
}) => {
  return (
    <section className={classnames(styles.wrapper, className)}>
      <img src="/assets/CtaBanner/arrow.svg" className={styles.bigArrow} alt="" />
      <div className={styles.content}>
        <h5 className={styles.title}>{title}</h5>
        <p className={styles.text}>{subtitle}</p>
      </div>

      <div className={styles.buttonsWrapper}>
        <Button buttonType="text" to={ctaLink} className={styles.linkButton}>
          {ctaText}
        </Button>
        {ctaText2 && ctaLink2 && (
          <Button
            buttonType="text"
            to={ctaLink2}
            className={classnames(styles.linkButton, styles.secondary)}
          >
            {ctaText2}
          </Button>
        )}
      </div>

      <div className={styles.smallArrows}>
        <img src="/assets/CtaBanner/arrow.svg" alt="" />
        <img src="/assets/CtaBanner/arrow.svg" alt="" />
      </div>
    </section>
  );
};
