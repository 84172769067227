import { useCallback } from 'react';
import { useRequest } from '@Hooks/useRequest';
import { API } from '@Services';
import { Project } from '@Types';

export const useGetProject = () => {
  const [fetch, response] = useRequest<Project>();

  const getProject = useCallback(
    (projectId: number) => fetch(API.paths.project(projectId), 'get'),
    [fetch],
  );

  return [getProject, response] as const;
};
