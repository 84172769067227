import { ExploreResultsProject } from '@Types';
import styles from './ExploreProjectCard.module.scss';
import { useTranslation } from 'react-i18next';
import { Link, Tile } from '@Components/common';
import { StatusChip, Tooltip } from '@Components';
import { Chip } from '@material-ui/core';

import { RiBriefcaseFill, RiMapPin2Fill, RiUserFill, RiPlayCircleFill } from 'react-icons/ri';
import { isProjectPlaceholderImage } from '@Helpers/placeholders';

interface ProjectListProps {
  project: ExploreResultsProject;
}

export const ExploreProjectCard = ({ project }: ProjectListProps) => {
  const { t } = useTranslation('landingPage');
  const imageIsPlaceholder = isProjectPlaceholderImage(project.image);

  return (
    <Link href={`/project/${project.id}`} className={styles.link}>
      <Tile className={styles.tile}>
        <div className={styles.card}>
          <div className={styles.imageContainer}>
            <img src={project.image} alt="" className={styles.image} />
            {project.pitch_type == 'video_pitch' && !imageIsPlaceholder && (
              <RiPlayCircleFill size={128} className={styles.videoPlayButton} />
            )}
            <div className={styles.status}>
              <img
                src={'/assets/Logo/ArrowRightUp.svg'}
                alt="ActionFunder arrow pointing right and up"
              />
              <p>{project.status}</p>
            </div>
          </div>

          <div className={styles.projectInfo}>
            <h2>{project.name}</h2>
            <h3>{t('org_name', { org_name: project.organization_name })}</h3>
            <div className={styles.deliveryDetails}>
              <div className={styles.iconText}>
                <RiBriefcaseFill size={16} />
                <p>{t('amount', { amount: project.amount })}</p>
              </div>
              <div className={styles.iconText}>
                <RiUserFill size={16} />
                <p>{project.beneficiaries}</p>
              </div>
              <div className={styles.iconText}>
                <RiMapPin2Fill size={16} />
                <p>{project.location}</p>
              </div>
            </div>

            <div className={styles.categoryList}>
              {project.categories.length > 3 ? (
                <>
                  {project.categories.slice(0, 3).map((category, i) => (
                    <StatusChip className={styles.chip} label={category} key={i} />
                  ))}
                  <Tooltip title={project.categories.slice(3).join(' · ')}>
                    <Chip
                      className={styles.chip}
                      label={t('extra_categories', { count: project.categories.length - 3 })}
                    />
                  </Tooltip>
                </>
              ) : (
                <>
                  {project.categories.map((category, i) => (
                    <StatusChip className={styles.chip} label={category} key={i} />
                  ))}
                </>
              )}
            </div>
          </div>
        </div>
      </Tile>
    </Link>
  );
};
