import { Channel } from 'stream-chat';

export enum RecipientTypeEnum {
  ORGANIZATION = 'Organization',
  PROJECT = 'Project',
  FUND = 'Fund',
}

export interface CreateMessage {
  subject: string;
  message: string;
  label: string;
  recipients: Array<{
    type: string;
    id: number;
  }>;
}

export interface MemberDetails {
  id: number;
  name: string;
}

export interface ArchiveMessage {
  channel_id: string;
}

export interface MessagingResponse {
  status: string;
}

export interface UpdateMessageForFunder {
  channel_id: string;
  subject: string;
  label: string;
  members: number[];
}

export interface UpdateMessageForGroup {
  channel_id: string;
  subject: string;
}

export interface DirectThread {
  type: 'direct';
  name: string;
  channel: Channel;
  unreadCount: number;
}

export interface BCCThread {
  type: 'bcc';
  id: string;
  name: string;
  label: string;
  channels: Channel[];
  unreadCount: number;
}

export type Thread = DirectThread | BCCThread;
