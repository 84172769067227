import React from 'react';
import { Chip as MaterialChip, ChipProps, createStyles, withStyles } from '@material-ui/core';
import styleVariables from '@Styles/_variables.module.scss';

const StyledChip = withStyles(() =>
  createStyles({
    colorPrimary: {
      color: styleVariables.colorT3,
      backgroundColor: styleVariables.colorT3Light,
    },
    colorSecondary: {
      color: styleVariables.colorP2,
      backgroundColor: styleVariables.colorP2Lighter,
    },
  }),
)(MaterialChip);

export const StatusChip: React.FC<ChipProps> = ({ ...props }) => {
  return <StyledChip {...props} />;
};
