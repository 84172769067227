import React from 'react';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import classnames from 'classnames';
import styles from './PasswordRules.module.scss';

interface PasswordValidation {
  [key: string]: string | RegExp;
}

interface PasswordRulesProps {
  fieldName?: string;
}

export const PasswordRules: React.FC<PasswordRulesProps> = ({ fieldName = 'password' }) => {
  const { t } = useTranslation('createAccount');
  const { watch } = useFormContext();
  const passwordValue = watch(fieldName, '');
  const regex = {
    min_chars: /.{8,}/,
    one_number: /\d/,
    one_uppercase: /[A-Z]/,
    one_lowercase: /[a-z]/,
  } as PasswordValidation;
  const rules = t('signing.password_rules', { returnObjects: true }) as PasswordValidation;

  return (
    <div className={styles.container}>
      <ArrowDropUpIcon className={styles.icon} />

      <p className={styles.title}>{t('signing.password_policy')}</p>

      <ul className={styles.list}>
        {Object.keys(regex).map((item) => (
          <li
            key={item}
            className={classnames(passwordValue.match(regex[item]) && styles.ruleFilled)}
          >
            {rules[item]}
          </li>
        ))}
      </ul>
    </div>
  );
};
