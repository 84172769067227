import { useTranslation } from 'react-i18next';
import { OnlinePresenceLinks } from '@Components';
import styles from './LinksTile.module.scss';

export interface LinksTileProps {
  orgWebsiteUrl?: string;
  orgFacebookUrl?: string;
  orgLinkedinUrl?: string;
  orgInstagramUrl?: string;
  orgTwitterUrl?: string;
}

export const LinksTile = ({
  orgWebsiteUrl,
  orgFacebookUrl,
  orgLinkedinUrl,
  orgInstagramUrl,
  orgTwitterUrl,
}: LinksTileProps) => {
  const { t } = useTranslation('profilePage');

  if (!(orgWebsiteUrl || orgFacebookUrl || orgLinkedinUrl || orgInstagramUrl || orgTwitterUrl)) {
    return null;
  }

  return (
    <div className={styles.container}>
      <h3 className={styles.header}>{t('online_presence_links_header')}</h3>
      <OnlinePresenceLinks
        orgWebsiteUrl={orgWebsiteUrl}
        orgFacebookUrl={orgFacebookUrl}
        orgLinkedinUrl={orgLinkedinUrl}
        orgInstagramUrl={orgInstagramUrl}
        orgTwitterUrl={orgTwitterUrl}
      />
    </div>
  );
};
