import { useTranslation } from 'react-i18next';
import { DownloadButtonController } from '@Components/common/DownloadButton/DownloadButtonController';
import { useExportSurveyResponsesForFund } from '@Hooks/Api/useExportSurveyResponsesForFund';

interface ResponsesDownloadButtonProps {
  fundId: number;
}

export const ResponsesDownloadButton = ({ fundId }: ResponsesDownloadButtonProps) => {
  const { t } = useTranslation('fund');
  const jobArgs = { fundId };
  return (
    <DownloadButtonController
      buttonType="primary"
      useStartJob={useExportSurveyResponsesForFund}
      jobArgs={jobArgs}
    >
      {t('report.download_responses')}
    </DownloadButtonController>
  );
};
