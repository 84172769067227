import { useState, useCallback } from 'react';
import { PdfModal } from '@Components';
import { Link } from '@Components/common';
import styles from './Section.module.scss';

interface SectionProps {
  title: string;
  text: string;
  readMoreText?: string;
  image: string;
  pdf?: string;
}

export const Section: React.VFC<SectionProps> = ({ title, text, readMoreText, image, pdf }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = useCallback(() => {
    setIsModalOpen(true);
  }, []);

  const closeModal = useCallback(() => {
    setIsModalOpen(false);
  }, []);

  return (
    <>
      <div className={styles.section}>
        <div>
          <h3 className={styles.sectionTitle}>{title}</h3>
          <p className={styles.sectionText}>{text}</p>
          {readMoreText && (
            <Link primary onClick={openModal} className={styles.readMoreLink}>
              {readMoreText}
            </Link>
          )}
        </div>
        <div>
          <img src={image} alt="" className={styles.sectionImage} />
        </div>
      </div>
      {pdf && <PdfModal isOpen={isModalOpen} file={pdf} onClose={closeModal} />}
    </>
  );
};
