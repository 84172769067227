import React, { Fragment } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import classnames from 'classnames';
import style from './PitchChoice.module.scss';
import VideocamIcon from '@material-ui/icons/Videocam';
import CreateIcon from '@material-ui/icons/Create';
import { PitchTypesEnum } from '@Types';
import { FieldNote } from '@Components';
import { Link } from '@Components/common';
import EmojiObjectsOutlinedIcon from '@material-ui/icons/EmojiObjectsOutlined';
import { HelpCenterRoutes } from '@App/paths';
import { useUserContext } from '@Contexts';
import { getHelpCenterURL } from '@Helpers/helpCenter';

interface PitchChoiceProps {
  activePitch?: PitchTypesEnum;
  onPitchChoice: (type: PitchTypesEnum) => void;
}

interface PitchChoiceData {
  icon: JSX.Element;
  title: string;
  text: string;
  value: PitchTypesEnum;
}

export const PitchChoice: React.FC<PitchChoiceProps> = ({ activePitch, onPitchChoice }) => {
  const { t } = useTranslation('project');
  const pitchesList = [
    {
      icon: <VideocamIcon style={{ fontSize: 32 }} />,
      title: t('pitch.video_pitch'),
      text: t('pitch.video_pitch_desc'),
      value: PitchTypesEnum.VIDEO,
    },
    {
      icon: <CreateIcon style={{ fontSize: 32 }} />,
      title: t('pitch.written_pitch'),
      text: t('pitch.written_pitch_desc'),
      value: PitchTypesEnum.WRITTEN,
    },
  ] as PitchChoiceData[];
  const handlePitchChange = (pitchType: PitchTypesEnum) => {
    onPitchChoice(pitchType);
  };
  const { userData, isUserLoading } = useUserContext();
  const whyPitchWithAVideoURL =
    userData && !isUserLoading
      ? getHelpCenterURL({
          path: HelpCenterRoutes.WHY_PITCH_WITH_A_VIDEO,
          user: userData,
        })
      : '';

  return (
    <Fragment>
      <FieldNote
        className={style.highlightedInfo}
        icon={<EmojiObjectsOutlinedIcon style={{ fontSize: 15 }} />}
      >
        <Trans
          ns="project"
          i18nKey="pitch.pitches_note"
          components={[
            <strong className={style.textBold} />,
            <Link href={whyPitchWithAVideoURL} className={style.hyperlink} />,
          ]}
        />
      </FieldNote>

      <ul className={style.tilesWrapper}>
        {pitchesList.map(({ icon, title, text, value }: PitchChoiceData) => (
          <li
            key={value}
            className={classnames(style.tile, {
              [style.tileActive]: value === activePitch,
            })}
          >
            <button
              className={style.tileInner}
              onClick={() => handlePitchChange(value)}
              type="button"
            >
              <div className={style.tileIcon}>{icon}</div>

              <p className={style.tileTitle}>{title}</p>

              <p className={style.tileText}>{text}</p>
            </button>
          </li>
        ))}
      </ul>
    </Fragment>
  );
};
