/**
 * Any additional namespaces should be imported
 * and added to exported `translations` object.
 */
import common from './common.json';
import createAccount from './createAccount.json';
import dashboard from './dashboard.json';
import counties from './counties.json';
import fund from './fund.json';
import project from './project.json';
import landingPage from './landingPage.json';
import profilePage from './profilePage.json';
import projectUpdate from './projectUpdate.json';
import greeneKing from './greeneKing.json';
import greeneKingPubs from './greeneKingPubs.json';
import howItWorks from './howItWorks.json';
import about from './about.json';
import organizationTile from './organizationTile.json';
import shareTile from './shareTile.json';
import matchesTile from './matchesTile.json';
import surveys from './surveys.json';
import charts from './charts.json';
import nominations from './nominations.json';
import socialValue from './socialValue.json';
import beneficiaryTypes from './beneficiaryTypes.json';
import fundingOpportunities from './fundingOpportunities.json';
import messages from './messages.json';

const translations = {
  common,
  createAccount,
  dashboard,
  counties,
  fund,
  project,
  landingPage,
  profilePage,
  projectUpdate,
  greeneKing,
  greeneKingPubs,
  howItWorks,
  about,
  organizationTile,
  shareTile,
  matchesTile,
  surveys,
  charts,
  nominations,
  socialValue,
  beneficiaryTypes,
  fundingOpportunities,
  messages,
};

export default translations;
