import { useTranslation } from 'react-i18next';

import { Loader, Masonry, UpdatesListItem } from '@Components';
import { ProjectUpdate } from '@Types';

import styles from './ProjectUpdatesSection.module.scss';
import { useUserContext } from '@Contexts';
import { Pagination } from '@Components/common';
import { PaginatedResponse } from '@Hooks/usePaginatedRequest';

export interface ProjectUpdatesSectionProps {
  title?: string;
  projectUpdatesPaginatedData: PaginatedResponse<ProjectUpdate>;
}

export const ProjectUpdatesSection = ({
  projectUpdatesPaginatedData,
  title,
}: ProjectUpdatesSectionProps) => {
  const { t } = useTranslation('profilePage');
  const { userData } = useUserContext();
  const { page: projectUpdates, loading: isProjectUpdatesLoading } = projectUpdatesPaginatedData;

  if (isProjectUpdatesLoading) {
    return (
      <div className={styles.container}>
        <Loader />
      </div>
    );
  }
  return (
    <div className={styles.container}>
      {projectUpdates.length === 0 ? (
        <span className={styles.noUpdates}>{t('empty_project_updates_list')}</span>
      ) : (
        <>
          {title && <h3 className={styles.header}>{title}</h3>}
          <Masonry className={styles.masonry}>
            {projectUpdates.map(
              ({
                id,
                image,
                description,
                created,
                likes,
                video_url,
                organization_id,
                organization_name,
                avatar_thumbnail,
                project_id,
                project_name,
              }) => (
                <UpdatesListItem
                  key={id}
                  id={id}
                  image={image}
                  video={video_url}
                  description={description}
                  createdAt={created}
                  likes={likes}
                  likeCount={likes.length}
                  organizationId={organization_id}
                  organizationName={organization_name}
                  organizationAvatar={avatar_thumbnail}
                  projectId={project_id}
                  projectName={project_name}
                  user={userData}
                />
              ),
            )}
          </Masonry>
          <Pagination paginatedResponse={projectUpdatesPaginatedData} />
        </>
      )}
    </div>
  );
};
