import Analytics from 'analytics';
import googleTagManager from '@analytics/google-tag-manager';
import facebookPixelPlugin from './analyticsPixelPlugin';
import isServer from '@Helpers/isServer';
import { hotjar } from 'react-hotjar';

interface AnalyticsProps {
  gtmContainerId?: string;
  pixelId?: string;
}

export const analytics = ({ gtmContainerId, pixelId }: AnalyticsProps) => {
  const analytics = Analytics({
    app: 'ActionFunder',
    plugins: [
      googleTagManager({
        containerId: gtmContainerId,
      }),
      facebookPixelPlugin({ pixelId }),
    ],
  });
  analytics.page();
};

export const recordDataLayerEvent = (event: string) => {
  if (!isServer && window.dataLayer) {
    window.dataLayer.push({
      event,
    });
    hotjar.event(event);
  }
};
