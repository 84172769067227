export const getOrganizationPagePath = (
  isFund: boolean,
  organizationId: number,
  hasOrganizationProfile?: boolean,
) => {
  let path = `/${isFund ? 'funder' : 'group'}-profile/${organizationId}`;
  if (hasOrganizationProfile == false) {
    path = `/profile/create/${isFund ? 'funder' : 'group'}`;
  }
  return path;
};
