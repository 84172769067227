import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import * as Sentry from '@sentry/react';
import { useGetOrganizationProjects } from '@Hooks/Api';
import { useArchiveProject } from '@Hooks/Api/useArchiveProject';
import { useUserContext, useNotificationsContext } from '@Contexts';
import { ProjectStatusEnum } from '@Types';
import { ProjectsSection } from './ProjectsSection';

export const ProjectsSectionController = () => {
  const { t } = useTranslation('dashboard');
  const [currentTab, setCurrentTab] = useState(0);
  // Set this to True to stop tabs switching around automatically when we refresh the project list due to user input.
  const canAutoSetTab = useRef(true);
  const { success: successNotification, error: errorNotification } = useNotificationsContext();
  const { userData } = useUserContext();
  const [fetchProjectsList, { loading: isProjectsListLoading, data: projectsList }] =
    useGetOrganizationProjects();
  const [archiveProject] = useArchiveProject();

  const getProjectsList = useCallback(() => {
    if (userData) {
      fetchProjectsList(userData.organization_id);
    }
  }, [fetchProjectsList, userData]);

  const archiveProjectCallback = useCallback(
    (projectId: number) => {
      canAutoSetTab.current = false;
      archiveProject(projectId)
        .then(() => {
          successNotification(t('group.archive_project_success'));
          getProjectsList();
        })
        .catch((e) => {
          Sentry.captureException(
            new Error(
              `Archiving project ${projectId} failed. Error: ${
                e?.data?.detail ? e.data.detail.join(' ') : 'Unknown error'
              }`,
            ),
          );
          errorNotification(t('group.archive_project_fail'));
        });
    },
    [archiveProject, successNotification, errorNotification, getProjectsList, t],
  );

  useEffect(() => {
    if (userData) {
      getProjectsList();
    }
  }, [getProjectsList, userData]);

  useEffect(() => {
    if (!projectsList.length) return;

    const tabValues = Object.values(ProjectStatusEnum);
    const statusesList = projectsList.reduce(
      (acc: ProjectStatusEnum[], { status }) => (!acc.includes(status) ? [...acc, status] : acc),
      [],
    );

    if (canAutoSetTab.current) {
      if (statusesList.includes(ProjectStatusEnum.PUBLISHED)) {
        setCurrentTab(
          tabValues.findIndex((statusName) => statusName === ProjectStatusEnum.PUBLISHED),
        );
      }

      if (statusesList.length === 1) {
        setCurrentTab(tabValues.findIndex((statusName) => statusName === statusesList[0]));
      }
    } else {
      canAutoSetTab.current = true;
    }
  }, [projectsList]);

  const handleTabChange = (newTab: number) => {
    setCurrentTab(newTab);
  };

  const name = userData?.name.split(' ')[0] ?? '';

  const tabs = Object.values(ProjectStatusEnum).map((currentStatus) => {
    const projects = projectsList.filter(({ status }) => {
      return status === currentStatus;
    });

    return {
      title: `${t(`group.project_status.${currentStatus}`)} (${projects?.length || 0})`,
    };
  });

  const currentProjects = projectsList.filter(
    ({ status }) => status === Object.values(ProjectStatusEnum)[currentTab],
  );

  // TODO: Fix loading issue https://github.com/10clouds/semble-frontend/issues/28
  const viewAsList = Boolean(projectsList && projectsList.length > 0);

  return (
    <ProjectsSection
      name={name}
      projectsList={currentProjects}
      isProjectsDataLoading={isProjectsListLoading}
      currentTab={currentTab}
      tabs={tabs}
      viewAsList={viewAsList}
      onTabChange={handleTabChange}
      archiveProject={archiveProjectCallback}
    />
  );
};
