import { ReactNode } from 'react';

import { FeatureCollection, GeoJsonProperties } from 'geojson';
import { LeafletMouseEvent } from 'leaflet';

export type LatLngExpression = [number, number];

export enum DrawingMode {
  LOCAL_AUTHORITY = 'LOCAL_AUTHORITY',
  POLYGON = 'POLYGON',
  RADIUS = 'RADIUS',
  UK_WIDE = 'UK_WIDE',
}

export enum InitialFocus {
  DATA = 'DATA',
  NONE = 'NONE',
  UK_WIDE = 'UK_WIDE',
}

export interface MapProps {
  coordinates: LatLngExpression;
  height: number;
  zoom?: number;
  /** Radius in miles */
  radius?: number;
  marker?: boolean;
}

export interface Map2Props {
  boundingBox?: number[][];
  center?: LatLngExpression;
  circles?: Array<{
    center: LatLngExpression;
    /** Radius in miles */
    radius: number;
  }>;
  drawingMode?: DrawingMode;
  initialEditableFeatureCollection?: FeatureCollection;
  featureCollection?: FeatureCollection;
  height: number;
  initialFocus?: InitialFocus;
  markers?: {
    [key: string]: MarkerProps;
  };
  message?: {
    content: string;
    visible: boolean;
  };
  popupFunctions?: {
    [key: string]: (...args: any[]) => ReactNode;
  };
  // Change this number to make the map refocus based on initialFocus
  refocus?: number;
  setEditableFeatureCollection?: (featureCollection: FeatureCollection) => void;
  zoom?: number;
  onMarkerClick?: (featureProperties: GeoJsonProperties, event: LeafletMouseEvent) => void;
}

export interface MarkerProps {
  component?: ReactNode;
  backgroundColor?: string;
  leafletIconName?: 'grey' | 'default' | 'pink';
  zIndexOffset?: number;
}
