import classnames from 'classnames';

import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import CloseIcon from '@material-ui/icons/Close';

import styleVariables from '@Styles/_variables.module.scss';

import styles from './NotificationPopup.module.scss';
import { makeStyles } from '@material-ui/core';
import { IconButton } from '@Components/common/Button/IconButton';

const useStyles = makeStyles({
  closeBtn: {
    backgroundColor: 'transparent',
    color: styleVariables.colorT5,
    minWidth: 'unset',
  },
});

export interface NotificationPopupProps {
  isVisible: boolean;
  content?: string | JSX.Element;
  success?: boolean;
  error?: boolean;
  closeNotification?: () => void;
  pauseNotificationTimeout?: () => void;
  resumeNotificationTimeout?: () => void;
}

export const NotificationPopup: React.FC<NotificationPopupProps> = ({
  isVisible,
  content,
  success,
  error,
  closeNotification,
  pauseNotificationTimeout,
  resumeNotificationTimeout,
}) => {
  const classes = useStyles();

  return (
    <div
      className={classnames(
        styles.notification,
        isVisible && styles.visible,
        success && styles.success,
        error && styles.error,
      )}
      onMouseEnter={pauseNotificationTimeout}
      onMouseLeave={resumeNotificationTimeout}
    >
      <div className={styles.contentWrapper}>
        {success && <CheckCircleIcon />}
        {error && <CancelIcon />}
        <span className={styles.message}>{content}</span>
        {isVisible && closeNotification && (
          <IconButton buttonType="text" onClick={closeNotification} className={classes.closeBtn}>
            <CloseIcon />
          </IconButton>
        )}
      </div>
    </div>
  );
};
