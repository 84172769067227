import React from 'react';
import styles from './SectionHeader.module.scss';
import classnames from 'classnames';

interface SectionHeaderProps {
  title?: string | React.ReactNode;
  subtitle?: string | React.ReactNode;
  className?: string;
  centerHeader?: boolean;
  bigHeader?: boolean;
  scrollRef?: React.RefObject<HTMLDivElement>;
}

export const SectionHeader: React.FC<SectionHeaderProps> = ({
  title,
  subtitle,
  className,
  centerHeader = false,
  bigHeader = false,
  scrollRef,
}) => {
  return (
    <div
      className={classnames(styles.wrapper, className, centerHeader && styles.wrapperCenter)}
      ref={scrollRef}
    >
      {title && (
        <h6
          className={classnames(
            styles.sectionTitle,
            bigHeader ? styles.sectionBigTitle : styles.sectionSmallTitle,
          )}
        >
          {title}
        </h6>
      )}

      {subtitle && (
        <p
          className={classnames(
            styles.sectionSubtitle,
            bigHeader && styles.sectionSubtitleBigHeader,
          )}
        >
          {subtitle}
        </p>
      )}
    </div>
  );
};
