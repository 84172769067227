import { useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormContext, Controller } from 'react-hook-form';
import classnames from 'classnames';

import VisibilityIcon from '@material-ui/icons/Visibility';
import GetAppIcon from '@material-ui/icons/GetApp';

import { Divider, PdfModal, SectionHeader } from '@Components';
import { Button, Checkbox, Link } from '@Components/common';
import { Section } from './Components';
import { msgFieldRequired } from '@Helpers/errorMessages';

import styles from './Finalise.module.scss';
import { useUserContext } from '@Contexts';

interface FinaliseProps {
  buttons: JSX.Element;
  isSaving?: boolean;
  isFundConfirmed?: boolean;
  onSubmit: (e?: React.FormEvent<HTMLFormElement | HTMLButtonElement>) => Promise<void>;
}

export const Finalise: React.VFC<FinaliseProps> = ({
  buttons,
  isSaving,
  isFundConfirmed,
  onSubmit,
}) => {
  const { t } = useTranslation('fund');
  const { errors: fieldErrors, control } = useFormContext();
  const [isTermsModalOpen, setIsTermsModalOpen] = useState(false);
  const { userData } = useUserContext();

  const toggleIsTermsModalOpen = useCallback(() => {
    setIsTermsModalOpen((prevIsTermsModalOpen) => !prevIsTermsModalOpen);
  }, []);

  return (
    <form onSubmit={onSubmit}>
      <Section
        title={t('process.due_diligence_title')}
        text={t('process.due_diligence_text')}
        readMoreText={t('process.due_diligence_cta')}
        image="/assets/Process/due_diligence_image.png"
        pdf="/assets/Process/due_diligence.pdf"
      />
      <Divider className={styles.divider} />
      <Section
        title={t('process.impact_title')}
        text={t('process.impact_text')}
        readMoreText={t('process.read_more')}
        image="/assets/Process/impact_image.png"
        pdf="/assets/Process/impact.pdf"
      />
      <Divider className={styles.divider} />
      <Section
        title={t('process.additional_services_title')}
        text={t('process.additional_services_text')}
        image="/assets/Process/additional_services_image.png"
      />
      <Divider className={styles.divider} />
      <SectionHeader
        title={t('process.terms_section_title')}
        subtitle={t('process.terms_section_subtitle')}
      />
      <div className={styles.termsBox}>
        <SectionHeader
          title={t('process.terms_box_title')}
          subtitle={t('process.terms_box_subtitle')}
          className={styles.termsBoxHeader}
        />
        <div className={styles.termsButtons}>
          <PdfModal
            isOpen={isTermsModalOpen}
            file="/assets/TermsAndConditions/terms_and_conditions.pdf"
            onClose={toggleIsTermsModalOpen}
          />
          <Button
            buttonType="tertiary"
            startIcon={<VisibilityIcon />}
            className={styles.termsButton}
            onClick={toggleIsTermsModalOpen}
          >
            {t('process.terms_box_read')}
          </Button>
          <a
            href="/assets/TermsAndConditions/terms_and_conditions.pdf"
            download="ActionFunder terms and conditions"
            className={classnames(styles.termsButton, styles.downloadButton)}
          >
            <GetAppIcon /> {t('process.terms_box_download')}
          </a>
        </div>
      </div>
      <Controller
        name="terms"
        control={control}
        rules={{ required: msgFieldRequired }}
        defaultValue={false}
        render={({ ref, onChange, value }) => (
          <Checkbox
            inputRef={ref}
            value={ref.current.checked}
            checked={Boolean(value)}
            onChange={(e) => {
              onChange(e.target.checked);
            }}
            error={!!fieldErrors.terms}
            helperText={fieldErrors.terms?.message}
            disabled={isSaving || isFundConfirmed}
          >
            {t('process.terms_agree')}
          </Checkbox>
        )}
      />
      <div className={styles.infoBox}>
        <img src="/assets/Summary/message.png" alt="" className={styles.messageImage} />
        <span className={styles.infoText}>{t('process.info_text')}</span>
        <img src="/assets/Summary/vector.png" alt="" className={styles.cloudImage} />
      </div>
      <div className={styles.contactSection}>
        <span>{t('process.contact_text')}</span>
        <Link primary href="mailto:hello@actionfunder.org">{`hello@actionfunder.org`}</Link>
      </div>
      {buttons}
    </form>
  );
};
