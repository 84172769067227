import React, { useCallback, useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core';
import { Loader } from '@Components';
import { Button } from '@Components/common';
import { useGetResponsesCount } from '@Hooks/Api';
import styleVariables from '@Styles/_variables.module.scss';
import styles from './EnhancedSurveyReportLink.module.scss';
import { SurveyResponseIndicator } from '.';
import router, { useRouter } from 'next/router';

const useStyles = makeStyles({
  root: {
    backgroundColor: styleVariables.colorT1,
    color: styleVariables.colorT5,
    '&:hover': {
      backgroundColor: styleVariables.colorP2,
    },
    maxWidth: 'fit-content',
  },
});

interface EnhancedSurveyReportLinkProps {
  fundId: number;
  projectsCount: number;
  hasEnhancedReporting: boolean;
}

export const EnhancedSurveyReportLink = ({
  fundId,
  projectsCount,
  hasEnhancedReporting,
}: EnhancedSurveyReportLinkProps) => {
  const { t } = useTranslation('fund');
  const classes = useStyles();
  const [getResponsesCount, { loading: isGetResponsesCountLoading, data: responsesCount }] =
    useGetResponsesCount();
  const { push } = useRouter();

  useEffect(() => {
    getResponsesCount(fundId);
  }, [getResponsesCount, fundId]);

  const handleViewReportClick = () => {
    push(`/fund/${fundId}/manage/results/report`);
  };

  if (isGetResponsesCountLoading) return <Loader />;

  return (
    <div className={styles.wrapper}>
      <img
        src={
          responsesCount
            ? '/assets/FundReport/with_results.png'
            : '/assets/FundReport/no_results.png'
        }
        alt="Survey results background"
        className={styles.tileBackground}
      />
      {responsesCount && responsesCount > 0 ? (
        <div className={styles.container}>
          <SurveyResponseIndicator responsesCount={responsesCount} projectsCount={projectsCount} />
          <div className={styles.responsesContainer}>
            <p className={styles.responsesCount}>
              <Trans
                ns="fund"
                i18nKey="report.responses_count"
                components={[<strong />]}
                values={{ responsesCount, projectsCount }}
              />
            </p>
            {hasEnhancedReporting ? (
              <>
                <h4 className={styles.title}>{t('report.enhanced_reporting')}</h4>
                <Button buttonType="primary" classes={classes} onClick={handleViewReportClick}>
                  {t('report.view_report')}
                </Button>
              </>
            ) : (
              <>
                <h4 className={styles.title}>{t('report.no_enhanced_reporting')}</h4>
                <Button
                  buttonType="primary"
                  classes={classes}
                  href="mailto:hello@actionfunder.org?subject=Upgrade%20request%20-%20accessing%20my%20impact%20report"
                >
                  {t('report.upgrade')}
                </Button>
              </>
            )}
          </div>
        </div>
      ) : (
        <div className={styles.responsesContainer}>
          <p className={styles.responsesCount}>
            <Trans
              ns="fund"
              i18nKey="report.responses_count"
              components={[<strong />]}
              values={{ responsesCount, projectsCount }}
            />
          </p>
          <h4 className={styles.title}>{t('report.no_results')}</h4>
        </div>
      )}
    </div>
  );
};
