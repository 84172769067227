import { ContentModal, Meter } from '@Components';
import { Button } from '@Components/common';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TitledTile } from '@Components/common/TitledTile/TitledTile';
import styles from './AgreementStatements.module.scss';

interface AgreementStatementsProps {
  agreementStatements: {
    data: number;
    quote: string;
  }[];
}

export const AgreementStatements = ({ agreementStatements }: AgreementStatementsProps) => {
  const { t } = useTranslation('fund');
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = useCallback(() => {
    setIsModalOpen(true);
  }, []);

  const handleCloseModal = useCallback(() => {
    setIsModalOpen(false);
  }, []);
  return (
    <TitledTile title={t(`report.titles.agreement_statements`)}>
      <div className={styles.container}>
        <div className={styles.meterContainer}>
          {agreementStatements.slice(0, 3).map((statement, i) => (
            <div className={styles.meter} key={i}>
              <Meter quote={statement.quote} data={statement.data} />
            </div>
          ))}
        </div>
        {agreementStatements.length > 3 && (
          <Button buttonType="tertiary" onClick={handleOpenModal} className={styles.showMoreBtn}>
            {t(`report.view_all`)}
          </Button>
        )}
      </div>

      {agreementStatements.length > 3 && (
        <ContentModal
          isOpen={isModalOpen}
          onClose={handleCloseModal}
          title={t('report.titles.agreement_statements')}
        >
          <div className={styles.meterContainer}>
            {agreementStatements.map((statement, i) => (
              <div className={styles.meter} key={i}>
                <Meter quote={statement.quote} data={statement.data} />
              </div>
            ))}
          </div>
        </ContentModal>
      )}
    </TitledTile>
  );
};
