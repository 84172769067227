import { useCallback } from 'react';
import { useInfiniteQuery } from 'react-query';
import { useRequest } from '@Hooks';
import { API } from '@Services';
import { Project, PagedResponse, ProjectStatusEnum } from '@Types';

export const useViewMoreProjectsList = () => {
  const [fetch] = useRequest<PagedResponse<Project>>();

  const fetchProjectsList = useCallback(
    ({ pageParam }) => {
      if (pageParam) {
        return fetch(pageParam, 'get').then((res) => res.data);
      }
      return fetch(API.paths.projects, 'get', {
        params: {
          status: `${ProjectStatusEnum.FUNDED},${ProjectStatusEnum.COMPLETE}`,
          limit: 11,
        },
      }).then((res) => res.data);
    },
    [fetch],
  );

  const { data, fetchNextPage, hasNextPage, isFetchingNextPage, status } = useInfiniteQuery(
    'viewMoreProjectsList',
    fetchProjectsList,
    {
      getNextPageParam: (res) => res.next,
    },
  );

  const projectsList =
    data?.pages.reduce((acc: Project[], curr) => [...acc, ...curr.results], []) || [];

  return {
    data: projectsList,
    fetchMoreProjects: fetchNextPage,
    isFetchMoreLoading: isFetchingNextPage,
    hasNextPage,
    loading: status === 'loading',
  } as const;
};
