import { useCallback } from 'react';
import { useRequest } from '@Hooks/useRequest';
import { API } from '@Services';
import { GroupProfileData } from '@Types';

export const usePublicGroupProfile = () => {
  const [fetch, response] = useRequest<GroupProfileData>();

  const getPublicGroupProfile = useCallback(
    (organizationId: number) => fetch(API.paths.public_group_profile(organizationId), 'get'),
    [fetch],
  );

  return [getPublicGroupProfile, response] as const;
};
