import { useState, useCallback, useEffect, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDropzone, FileRejection, DropzoneOptions } from 'react-dropzone';
import * as Sentry from '@sentry/react';
import { useNotificationsContext } from '@Contexts';
import styleVariables from '@Styles/_variables.module.scss';

const acceptStyle = {
  backgroundColor: styleVariables.colorT3Light,
};

const rejectStyle = {
  backgroundColor: styleVariables.colorT4Light,
  borderColor: styleVariables.colorT4,
  color: styleVariables.colorT4,
};

const disabledStyle = {
  borderColor: styleVariables.colorT2Lighter,
  backgroundColor: styleVariables.colorT2Pale,
};

const toBase64 = (file: File) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => resolve(reader.result);
    reader.onerror = () => reject();
  });
};

interface FileUploadProps extends File {
  preview: string;
}

interface SentryLogErrors {
  maxSize?: string;
}

interface UseImageUploadProps {
  fieldName: string;
  uploadConfig?: DropzoneOptions;
  sentryLog?: SentryLogErrors;
}

export const useImageUpload = ({
  fieldName,
  uploadConfig = {},
  sentryLog,
}: UseImageUploadProps) => {
  const { t } = useTranslation('common');
  const { error: errorNotification } = useNotificationsContext();
  const { setValue, clearErrors, setError, watch } = useFormContext();
  const [file, setFile] = useState<FileUploadProps[]>();

  const onDropAccepted = useCallback(
    async (acceptedFiles: File[]) => {
      try {
        const base64 = await toBase64(acceptedFiles[0]);
        setValue(fieldName, base64);
        setFile(
          acceptedFiles.map((file: File) =>
            Object.assign(file, {
              preview: URL.createObjectURL(file),
            }),
          ),
        );
      } catch {
        errorNotification(t('image_upload.generic_error'));
      }
    },
    [errorNotification, fieldName, setValue, t],
  );

  const onDropRejected = useCallback(
    (fileRejections: FileRejection[]) => {
      if (fileRejections.length > 1) {
        setError(fieldName, {
          message: t('image_upload.more_than_one_file'),
        });
      } else if (fileRejections[0].errors[0].code === 'file-too-large') {
        setError(fieldName, {
          message: t('image_upload.file_too_big'),
        });
        if (sentryLog?.maxSize) {
          Sentry.captureException(new Error(sentryLog.maxSize));
        }
      }
    },
    [fieldName, sentryLog?.maxSize, setError, t],
  );

  useEffect(() => {
    const fieldValue = watch(fieldName);
    if (fieldValue) {
      setFile(fieldValue);
    }
  }, [fieldName, watch]);

  useEffect(() => {
    file && Array.isArray(file) && file.forEach((item) => URL.revokeObjectURL(item.preview));
  }, [file]);

  useEffect(() => {
    clearErrors(fieldName);
  }, [file, clearErrors, fieldName]);

  const { getRootProps, getInputProps, isDragAccept, isDragReject } = useDropzone({
    onDropAccepted,
    onDropRejected,
    ...uploadConfig,
  });

  const style = useMemo(
    () => ({
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
      ...(uploadConfig.disabled ? disabledStyle : {}),
    }),
    [isDragAccept, isDragReject, uploadConfig.disabled],
  );

  const handleDeleteFile = useCallback(() => {
    setFile(undefined);
    setValue(fieldName, null);
  }, [fieldName, setValue]);

  return {
    file,
    dropzoneRootProps: getRootProps({ style }),
    dropzoneInputProps: getInputProps(),
    deleteFile: handleDeleteFile,
  } as const;
};
