import { useRequest } from '@Hooks/useRequest';
import { API } from '@Services';
import { LocalAuthority } from '@Types';
import { useCallback } from 'react';

export const useGetLocalAuthorities = () => {
  const [fetch, response] = useRequest<LocalAuthority[]>();

  const getLocalAuthorities = useCallback(() => {
    return fetch(API.paths.local_authorities, 'get');
  }, [fetch]);

  return [getLocalAuthorities, response] as const;
};
