import { useCallback } from 'react';
import { useRequest } from '@Hooks';
import { API } from '@Services';
import { Project, UpdateMessageForGroup } from '@Types';

export const useUpdateMessageForGroup = () => {
  const [fetch, response] = useRequest<Project>();

  const sendMessage = useCallback(
    (data: UpdateMessageForGroup) => {
      let finalData = {
        channel_id: data.channel_id,
        subject: data.subject,
      };

      fetch(API.paths.update_message, 'patch', {
        data: finalData,
      });
    },
    [fetch],
  );

  return [sendMessage, response] as const;
};
