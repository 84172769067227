import React, { useCallback, useState } from 'react';
import styles from './WelcomeBanner.module.scss';
import { Button, Tile } from '@Components/common';
import { IconButton } from '@Components/common/Button/IconButton';
import { RiCloseFill } from 'react-icons/ri';
import { ContentModal, VideoPlayer } from '@Components';

export interface WelcomeBannerProps {
  onClose: () => void;
  title: string;
  bodyText: string;
  primaryButtonText: string;
  videoURL: string;
  secondaryButtonText: string;
  secondaryButtonLink: string;
}

export const WelcomeBanner = ({
  onClose,
  title,
  bodyText,
  primaryButtonText,
  videoURL,
  secondaryButtonText,
  secondaryButtonLink,
}: WelcomeBannerProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = useCallback(() => {
    setIsModalOpen(true);
  }, []);

  const handleCloseModal = useCallback(() => {
    setIsModalOpen(false);
  }, []);
  return (
    <>
      <Tile className={styles.tile}>
        <div className={styles.container}>
          <div className={styles.content}>
            <h2 className={styles.title}>{title}</h2>
            <p>{bodyText}</p>
            <div>
              <Button buttonType={'text'} to={secondaryButtonLink}>
                {secondaryButtonText}
              </Button>
              <Button buttonType="primary" onClick={handleOpenModal} className={styles.button}>
                {primaryButtonText}
              </Button>
            </div>
          </div>
          <img
            src="/assets/WelcomeBanner/videoPreviewScreenshot.png"
            alt=""
            className={styles.img}
          />

          <IconButton buttonType="text" className={styles.closeButton} onClick={onClose}>
            <RiCloseFill />
          </IconButton>
        </div>
      </Tile>
      <ContentModal wide isOpen={isModalOpen} onClose={handleCloseModal}>
        <VideoPlayer videoUrl={videoURL} />
      </ContentModal>
    </>
  );
};
