import { useTranslation } from 'react-i18next';

import { Loader } from '@Components';
import { Fund } from '@Types';

import { ActionListItem } from '.';
import styles from './ProjectsListSection.module.scss';

export interface ProjectsFundsSectionProps {
  isProjectsFundsListLoading: boolean;
  projectsFundsList: Fund[];
}

export const ProjectsFundsSection = ({
  isProjectsFundsListLoading,
  projectsFundsList,
}: ProjectsFundsSectionProps) => {
  const { t } = useTranslation('fund');

  if (isProjectsFundsListLoading) {
    return (
      <div className={styles.container}>
        <Loader />
      </div>
    );
  }

  return (
    <div className={styles.container}>
      {projectsFundsList.length === 0 ? (
        <span className={styles.noProjects}>{t('profilePage:empty_group_fund_list')}</span>
      ) : (
        <>
          <h3 className={styles.header}>{t('profilePage:project_funds_header')}</h3>
          {projectsFundsList.map(
            ({
              id,
              funding_profile: { organization_id, name, avatar_thumbnail },
              details: { name: fundName },
            }) => (
              <ActionListItem
                withOrganizationAvatar
                key={id}
                className={styles.actionListItem}
                name={name}
                fundName={fundName}
                fundLink={`/fund/${id}`}
                link={`/funder-profile/${organization_id}`}
                organizationAvatar={avatar_thumbnail}
                ctaText={t('profilePage:view_fund')}
              />
            ),
          )}
        </>
      )}
    </div>
  );
};
