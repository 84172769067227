import { createContext, useState, useContext } from 'react';

export const useRedirectContextController = () => {
  const [redirectedFrom, setRedirectedFrom] = useState<string>();

  return {
    redirectedFrom,
    setRedirectedFrom,
  } as const;
};

export const RedirectContext = createContext<ReturnType<
  typeof useRedirectContextController
> | null>(null);

export const useRedirectContext = () => {
  const value = useContext(RedirectContext);
  if (value === null) {
    throw new Error(
      `[App Layout Context] useRedirectContext must be called within RedirectContext tree`,
    );
  }
  return value;
};

export const RedirectContextProvider: React.FC = ({ children }) => {
  const contextController = useRedirectContextController();

  return <RedirectContext.Provider value={contextController}>{children}</RedirectContext.Provider>;
};
