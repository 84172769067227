import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Grid } from '@material-ui/core';
import {
  FundedProjects,
  FundGallery,
  ReportBasicInfo,
  TangibleOutputs,
  Quote,
  FeaturedMediaItem,
  BeneficiarySplit,
  AgreementStatements,
  SurveyResponseIndicator,
} from '.';

import { TitledTile } from '@Components/common/TitledTile/TitledTile';
import {
  Fund,
  Project,
  ProjectUpdate,
  Map2Props,
  DrawingMode,
  LatLngExpression,
  InitialFocus,
  LocalAuthorityWithFeature,
  SurveyTypeEnum,
} from '@Types';
import {
  projectFundingDistributionToDataAndLabelFormat,
  matrixQuestionToMatrixFormat,
} from '@Helpers/DataStandardisation';
import {
  dataAndLabelFormatToHighchartsDoughnut,
  matrixFormatToHighchartsStackedBar,
} from '@Helpers/DataVisualisation';
import dynamic from 'next/dynamic';
import { Feature, FeatureCollection } from 'geojson';
import { Doughnut, StackedBar, FieldNote, Loader } from '@Components';
import { NonprofitTypeStackedColumn } from '@Components/Charts/SurveyReports/NonprofitTypeStackedColumn';
import { SDGsDoughnut } from '@Components/Charts/SurveyReports/SDGsDoughnut';
import flip from '@turf/flip';
import HomeWorkIcon from '@material-ui/icons/HomeWork';
import styleVariables from '@Styles/_variables.module.scss';
import { StandardMaker } from '@Components/Map2/Markers';
import { ProjectPopup } from '@Components/Map2/Popups';
import { getProjectPath, formatAgreementStatements } from '@Helpers';
import { useGetFundReportData, useGetResponsesCount } from '@Hooks/Api';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import styles from './ReportSummary.module.scss';
import { PaginatedResponse } from '@Hooks/usePaginatedRequest';

const Map2 = dynamic<Map2Props>(
  () => import('@Components/Map2/Map2').then((mod) => mod.Map2) as any,
  {
    ssr: false,
  },
);

interface FullReportProps {
  fundId: number;
  fundData: Fund;
  fundedProjectsList: Project[];
  localAuthority?: LocalAuthorityWithFeature;
  updatesPaginatedResponse: PaginatedResponse<ProjectUpdate>;
}

export const FullReport: React.FC<FullReportProps> = ({
  fundId,
  fundData,
  fundedProjectsList,
  localAuthority,
  updatesPaginatedResponse,
}) => {
  const [getFundReportData, { loading: isFundReportDataLoading, data: fundReportData }] =
    useGetFundReportData();
  useEffect(() => {
    getFundReportData(fundId);
  }, [fundId, getFundReportData]);
  const { t } = useTranslation('fund');
  const {
    details: { radius, catchment_area_type, location, areas, local_authority },
    projects_sdg_breakdown,
    id,
  } = fundData;

  let agreementStatements: {
    data: number;
    quote: string;
  }[] = [];

  let SWWCustomQuestion;

  const isShellSurvey = fundReportData?.fund_survey_type == SurveyTypeEnum.SHELL;
  const isSWWSurvey = fundReportData?.fund_survey_type == SurveyTypeEnum.SOUTHWEST_WATER;
  const fundHasCustomSurvey = fundReportData?.fund_survey_type != SurveyTypeEnum.DEFAULT_SURVEY;

  if (fundReportData) {
    const statementsIndex = fundReportData.matrix_questions.findIndex(
      ({ identifier }) => identifier == 'statements',
    );

    // Add SWW custom questions
    if (isSWWSurvey) {
      const SWWCustomMatrixIndex = fundReportData.matrix_questions.findIndex(
        ({ identifier }) => identifier == 'difference',
      );
      SWWCustomQuestion = matrixFormatToHighchartsStackedBar(
        matrixQuestionToMatrixFormat(fundReportData.matrix_questions[SWWCustomMatrixIndex]),
        true,
      );
    }

    agreementStatements = formatAgreementStatements(
      fundReportData.matrix_questions[statementsIndex].questions,
    );
  }

  const projectFundingDistributionInDataAndLabelFormat =
    projectFundingDistributionToDataAndLabelFormat(fundedProjectsList);
  const {
    dataSet: fundingDistributionDataset,
    title: fundingDistributionTitle,
    displayCenteredTitle: fundingDistributionDisplayCenteredTitle,
  } = dataAndLabelFormatToHighchartsDoughnut(projectFundingDistributionInDataAndLabelFormat, true);

  const [circles, setCircles] = useState<
    | Array<{
        center: LatLngExpression;
        radius: number;
      }>
    | undefined
  >(() => {
    if (catchment_area_type === DrawingMode.RADIUS) {
      return [
        {
          center: flip(location).coordinates as LatLngExpression,
          radius: radius,
        },
      ];
    }

    return undefined;
  });

  const [featureCollection, setFeatureCollection] = useState(() => {
    let features: Feature[] = [];

    if (areas) {
      features.push({
        type: 'Feature',
        geometry: areas,
        properties: {},
      });
    }

    if (localAuthority) {
      features.push(localAuthority.feature);
    }

    features.push({
      type: 'Feature',
      geometry: location,
      properties: {
        marker: 'home',
        popup: null,
      },
    });

    features = features.concat(
      fundedProjectsList.map((project) => {
        return {
          type: 'Feature',
          geometry: project.details.location,
          properties: {
            link: getProjectPath(project.id),
            name: project.details.name,
            orgName: project.group_profile.name,
          },
        };
      }),
    );

    return {
      type: 'FeatureCollection',
      features: features,
    } as FeatureCollection;
  });

  const [getResponsesCount, { loading: isGetResponsesCountLoading, data: responsesCount }] =
    useGetResponsesCount();
  useEffect(() => {
    getResponsesCount(fundId);
  }, [getResponsesCount, fundId]);

  const estimatedBeneficiariesCount = fundedProjectsList.reduce(
    (acc, { beneficiaries }) => (beneficiaries ? acc + beneficiaries : acc),
    0,
  );

  if (isFundReportDataLoading) return <Loader />;

  return (
    <>
      {/* If complete_response_count is 0 then we haven't got their data from SM */}
      {fundReportData && fundReportData.complete_response_count > 0 ? (
        <Grid container spacing={3}>
          {responsesCount && (
            <SurveyResponseIndicator
              responsesCount={responsesCount}
              projectsCount={fundedProjectsList.length}
              stickied={true}
            />
          )}
          <Grid item xs={12}>
            <h2 className={styles.title}>{t('report.section_heading.outcomes')}</h2>
          </Grid>
          <Grid item md={8} xs={12}>
            {fundReportData.featured_media_item ? (
              <FeaturedMediaItem
                url={fundReportData.featured_media_item.url}
                type={fundReportData.featured_media_item.type}
                fundID={id}
                updateID={fundReportData.featured_media_item.update_id}
              />
            ) : (
              <TitledTile>
                <p>{t('report.no_media_item')}</p>
              </TitledTile>
            )}
          </Grid>
          <Grid item xs={12} md={4}>
            <ReportBasicInfo
              beneficiaries={fundReportData.total_beneficiaries}
              estimatedBeneficiaries={estimatedBeneficiariesCount}
              averageFunderRating={fundReportData.average_funder_rating}
              volunteers={fundReportData.total_volunteers}
              funder_volunteers={fundReportData.total_funder_volunteers}
              funder_volunteer_hours={fundReportData.total_funder_volunteer_hours}
            />
          </Grid>
          <Grid item xs={12}>
            <AgreementStatements agreementStatements={agreementStatements} />
          </Grid>
          <Grid item md={4} xs={12}>
            <Quote
              quotes={fundReportData.project_impact_quotes}
              projects={fundReportData.projects}
              quotesTitle={t(`report.titles.project_impact_quote`)}
            />
          </Grid>
          <Grid item md={8} xs={12}>
            <BeneficiarySplit
              title={t(`report.titles.beneficiary_split_people`)}
              beneficiarySplit={fundReportData.beneficiary_split_people}
            />
          </Grid>
          <Grid item md={8} xs={12}>
            <BeneficiarySplit
              title={t(`report.titles.beneficiary_split_planet`)}
              beneficiarySplit={fundReportData.beneficiary_split_planet}
            />
          </Grid>
          <Grid item md={4} xs={12}>
            <Quote
              quotes={fundReportData.output_impact_quotes}
              projects={fundReportData.projects}
              quotesTitle={t(`report.titles.project_output_quote`)}
            />
          </Grid>
          <Grid item xs={12}>
            <h2 className={styles.title}>{t('report.section_heading.custom_questions')}</h2>
          </Grid>
          {fundHasCustomSurvey ? (
            <>
              {isSWWSurvey && SWWCustomQuestion && (
                <Grid item xs={12}>
                  <TitledTile title={SWWCustomQuestion.title}>
                    <StackedBar
                      barDataSet={SWWCustomQuestion.dataSet}
                      barLabels={SWWCustomQuestion.labels}
                      displayAsPercentage={SWWCustomQuestion.displayAsPercentage}
                      totalAnswers={0}
                    />
                  </TitledTile>
                </Grid>
              )}
              {isShellSurvey && (
                <>
                  <Grid item xs={12}>
                    <Quote
                      quotes={fundReportData.grant_challenges}
                      projects={fundReportData.projects}
                      quotesTitle={t(`report.titles.grant_challenges`)}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Quote
                      quotes={fundReportData.unaddressed_challenges}
                      projects={fundReportData.projects}
                      quotesTitle={t(`report.titles.unaddressed_challenges`)}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <BeneficiarySplit
                      title={t(`report.titles.how_did_this_fund_support_your_organisation`)}
                      beneficiarySplit={fundReportData.how_did_this_fund_support_your_organisation}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <BeneficiarySplit
                      title={t(`report.titles.funding_resulted_in`)}
                      beneficiarySplit={fundReportData.funding_resulted_in}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Quote
                      quotes={fundReportData.project_plans}
                      projects={fundReportData.projects}
                      quotesTitle={t(`report.titles.project_plans`)}
                    />
                  </Grid>
                </>
              )}
            </>
          ) : (
            <FieldNote
              className={styles.highlightedInfo}
              icon={<InfoIcon style={{ fontSize: 15 }} />}
            >
              <Trans
                ns="fund"
                i18nKey="report.add_custom_question_info"
                components={[
                  <a href="mailto:hello@actionfunder.org" className={styles.textBold} />,
                ]}
              />
            </FieldNote>
          )}
          <Grid item xs={12}>
            <h2 className={styles.title}>{t('report.section_heading.outputs')}</h2>
          </Grid>
          <Grid item xs={12}>
            <TangibleOutputs tangibleOutputs={fundReportData.tangible_outputs} />
          </Grid>
          <Grid item xs={12}>
            <FundGallery fundId={fundId} updatesPaginatedResponse={updatesPaginatedResponse} />
          </Grid>
          <Grid item md={8} xs={12}>
            <TitledTile title={t('report.titles.sdg')}>
              <SDGsDoughnut sdgs={projects_sdg_breakdown} />
            </TitledTile>
          </Grid>
          <Grid item md={4} xs={12}>
            <TitledTile title={t('report.titles.supported_orgs')}>
              <NonprofitTypeStackedColumn
                nonprofitTypes={fundedProjectsList.map((project) => project.group_profile.type)}
              />
            </TitledTile>
          </Grid>
          <Grid item xs={12} md={4}>
            <FundedProjects fundedProjectsList={fundedProjectsList} />
          </Grid>
          <Grid item xs={12} md={8}>
            <Map2
              height={400}
              circles={circles}
              initialFocus={
                catchment_area_type !== DrawingMode.UK_WIDE
                  ? InitialFocus.DATA
                  : InitialFocus.UK_WIDE
              }
              featureCollection={featureCollection}
              markers={{
                home: {
                  backgroundColor: styleVariables.colorT3,
                  component: <HomeWorkIcon htmlColor="white" />,
                },
                default: {
                  component: <StandardMaker />,
                },
              }}
              popupFunctions={{
                default: ProjectPopup,
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <h2 className={styles.title}>{t('report.section_heading.inputs')}</h2>
          </Grid>
          <Grid item xs={12}>
            <TitledTile title={t('report.titles.investment_summary')}>
              <Doughnut
                dataSet={fundingDistributionDataset}
                title={fundingDistributionTitle}
                displayCenteredTitle={fundingDistributionDisplayCenteredTitle}
                prefix={'£'}
              />
            </TitledTile>
          </Grid>
        </Grid>
      ) : (
        <FieldNote className={styles.highlightedInfo} icon={<InfoIcon style={{ fontSize: 15 }} />}>
          <Trans
            ns="fund"
            i18nKey="report.no_sm_data_info"
            components={[<a href="mailto:hello@actionfunder.org" className={styles.textBold} />]}
          />
        </FieldNote>
      )}
    </>
  );
};
