import { useEffect, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  startOfDay,
  parseISO,
  endOfDay,
  isBefore,
  isAfter,
  formatDistanceStrict,
  differenceInCalendarDays,
  differenceInHours,
} from 'date-fns';
import classnames from 'classnames';

import { LinearProgress, withStyles, createStyles } from '@material-ui/core';
import AccessTimeIcon from '@material-ui/icons/AccessTime';

import styleVariables from '@Styles/_variables.module.scss';
import { formatDate } from '@Helpers/formatDate';

import styles from './FundDateProgress.module.scss';
import { FundStatusEnum } from '@Types';

const DATE_STATE_ENUM = {
  BEFORE: 'BEFORE',
  DURING: 'DURING',
  AFTER: 'AFTER',
  FINISHED: 'FINISHED',
};

const StyledProgressBar = withStyles(() =>
  createStyles({
    root: {
      height: '6px',
    },
    colorPrimary: {
      backgroundColor: styleVariables.colorT2Paler,
    },
    colorSecondary: {
      backgroundColor: styleVariables.colorT2Paler,
    },
    barColorPrimary: {
      backgroundColor: styleVariables.colorT1,
    },
    barColorSecondary: {
      backgroundColor: styleVariables.colorT4,
    },
  }),
)(LinearProgress);

interface FundDateProgressProps {
  tile?: boolean;
  startDate: string;
  endDate: string;
  status: string;
  className?: string;
}

export const FundDateProgress = ({
  tile,
  startDate,
  endDate,
  status,
  className,
}: FundDateProgressProps) => {
  const { t } = useTranslation('fund');
  const [dateState, setDateState] = useState<string>();

  const fullStartDate = startOfDay(parseISO(startDate));
  const fullEndDate = endOfDay(parseISO(endDate));
  const currentDate = useMemo(() => new Date(), []);

  useEffect(() => {
    if (
      status === t(`fund_status.${FundStatusEnum.COMPLETE}`) ||
      status === t(`fund_status.${FundStatusEnum.IN_DELIVERY}`)
    ) {
      setDateState(DATE_STATE_ENUM.FINISHED);
    } else if (isBefore(currentDate, fullStartDate)) {
      setDateState(DATE_STATE_ENUM.BEFORE);
    } else if (isAfter(currentDate, fullStartDate) && isBefore(currentDate, fullEndDate)) {
      setDateState(DATE_STATE_ENUM.DURING);
    } else if (isAfter(currentDate, fullEndDate)) {
      setDateState(DATE_STATE_ENUM.AFTER);
    }
  }, [currentDate, fullStartDate, fullEndDate, status, t]);

  let distanceTextColor;
  if (dateState === DATE_STATE_ENUM.BEFORE) {
    distanceTextColor = styleVariables.colorT3;
  } else if (dateState === DATE_STATE_ENUM.DURING) {
    distanceTextColor =
      differenceInCalendarDays(fullEndDate, currentDate) >= 3
        ? styleVariables.colorT1
        : styleVariables.colorT4;
  }

  let distanceText;
  if (dateState === DATE_STATE_ENUM.BEFORE) {
    distanceText = formatDistanceStrict(currentDate, fullStartDate);
  } else if (dateState === DATE_STATE_ENUM.DURING) {
    distanceText = formatDistanceStrict(currentDate, fullEndDate);
  }

  const progressValue = useMemo(
    () =>
      Math.floor(
        (1 -
          differenceInHours(currentDate, fullEndDate) /
            differenceInHours(fullStartDate, fullEndDate)) *
          100,
      ),
    [currentDate, fullEndDate, fullStartDate],
  );

  if (dateState === DATE_STATE_ENUM.FINISHED) {
    return null;
  }

  return (
    <div className={classnames(styles.container, tile && styles.tile, className)}>
      {tile && <AccessTimeIcon />}
      <div className={styles.dateContainer}>
        <span className={styles.text}>{t(`details_page.date_progress.${dateState}`)}</span>
        <span className={styles.date}>
          {(dateState === DATE_STATE_ENUM.BEFORE && formatDate(startDate)) ||
            (dateState === DATE_STATE_ENUM.DURING && formatDate(endDate))}
        </span>
      </div>
      {!(dateState === DATE_STATE_ENUM.AFTER) && (
        <span
          className={classnames(styles.dateDistance, tile && styles.tile)}
          style={{ color: distanceTextColor }}
        >
          {distanceText} <span>{t(`details_page.time_left`)}</span>
        </span>
      )}
      {(dateState === DATE_STATE_ENUM.DURING || dateState === DATE_STATE_ENUM.AFTER) && (
        <div className={styles.progressBarWrapper}>
          <StyledProgressBar
            variant="determinate"
            color={
              differenceInCalendarDays(fullEndDate, currentDate) < 3 ||
              dateState === DATE_STATE_ENUM.AFTER
                ? 'secondary'
                : 'primary'
            }
            value={dateState === DATE_STATE_ENUM.DURING ? progressValue : 100}
          />
        </div>
      )}
    </div>
  );
};
