import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import { Button } from '@Components/common';
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';
import ToggleButton from '@material-ui/lab/ToggleButton';
import { makeStyles } from '@material-ui/core/styles';
import { useRouter } from '@Helpers/useRouter';
import isServer from '@Helpers/isServer';
import { RoutingPaths } from '@App/paths';
import { useAppLayoutContext, useUserContext } from '@Contexts';
import styleVariables from '@Styles/_variables.module.scss';
import styles from './LoginButtons.module.scss';
import { MarketingSiteLinks } from '@Components/Header/MarketingSiteLinks';

const useStyles = makeStyles({
  root: {
    paddingTop: 11,
    paddingBottom: 11,
    paddingLeft: 24,
    paddingRight: 24,
    minWidth: 'auto',
    marginLeft: 20,
  },
});

const useToggleButtonStyles = makeStyles({
  root: {
    border: 0,
    color: styleVariables.AFDustyPink500,
    display: 'none',
    '&.Mui-selected': {
      backgroundColor: 'transparent',
      color: styleVariables.AFDustyPink500,
    },
    [`@media screen and (max-width: ${styleVariables.screenWidthSm})`]: {
      display: 'flex',
    },
  },
});

interface LoginButtonProps {
  publicLayout?: boolean;
}

export const LoginButtons: React.VFC<LoginButtonProps> = ({ publicLayout }) => {
  const { t } = useTranslation('landingPage');
  const { push } = useRouter();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const toggleButtonClasses = useToggleButtonStyles();
  const { userData, isUserLoading } = useUserContext();
  const { signUpClickScrollTargetRef } = useAppLayoutContext();

  const isSignedIn = !!(!isUserLoading && userData);

  const handleSignUp = () => {
    if (signUpClickScrollTargetRef?.current && !isServer) {
      const top =
        signUpClickScrollTargetRef.current.getBoundingClientRect().top + window.pageYOffset - 100;

      window.scrollTo({ top, behavior: 'smooth' });
    } else {
      push(RoutingPaths.REQUEST_DEMO);
    }
  };

  return (
    <>
      {!(!publicLayout && isSignedIn) && (
        <ToggleButton
          value="check"
          selected={isMenuOpen}
          classes={toggleButtonClasses}
          onChange={() => {
            setIsMenuOpen(!isMenuOpen);
          }}
        >
          {isMenuOpen ? <CloseIcon /> : <MenuIcon />}
        </ToggleButton>
      )}

      <div
        className={classnames(
          styles.menu,
          isMenuOpen && styles.menuOpen,
          !isSignedIn && !publicLayout && styles.reversedDirection,
        )}
      >
        {publicLayout && <MarketingSiteLinks className={styles.marketingLinks} />}
        {!isSignedIn && (
          <div className={styles.buttonsWrapper}>
            <button
              onClick={() => {
                push(RoutingPaths.SIGN_IN);
              }}
              className={styles.link}
              type="button"
            >
              {t('sign_in')}
            </button>
            <Button onClick={handleSignUp} className={styles.btn} buttonType="primary">
              {t('demo')}
            </Button>
          </div>
        )}
      </div>
    </>
  );
};
