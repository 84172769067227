import { useCallback } from 'react';
import { useRequest } from '@Hooks';
import { API } from '@Services';
import { FundingProfileData } from '@Types';
import { useUserContext } from '@Contexts';
import { useRouter } from '@Helpers/useRouter';
import { RoutingPaths } from '@App/paths';

export const useUpdateFunderProfile = (doCreate = false) => {
  const [fetch, response] = useRequest<FundingProfileData>();
  const { fetchUserData, isUserLoading } = useUserContext();
  const { push } = useRouter();

  const fetchUpdate = useCallback(
    (data: FundingProfileData) =>
      fetch(API.paths.funding_profile(), doCreate ? 'post' : 'patch', {
        data,
      })
        .then(fetchUserData)
        .then(() => {
          push(RoutingPaths.DASHBOARD_FUNDER);
        }),
    [fetch, doCreate, fetchUserData, push],
  );

  return [fetchUpdate, { ...response, loading: response.loading || isUserLoading }] as const;
};
