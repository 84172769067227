import getConfig from 'next/config';
import { Config } from '@App/ConfigLoader';

const { serverRuntimeConfig: config } = getConfig() as {
  serverRuntimeConfig: Config;
};

export const fetchServerSideData = <T>(path: string): Promise<T> => {
  return fetch(`${config.SSR_API_URL}${path}`).then((res) => res.json() as Promise<T>);
};
