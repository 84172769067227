import React, { ReactElement } from 'react';
import styles from './PageTitle.module.scss';
import classnames from 'classnames';

export interface PageTitleProps {
  title: string | React.ReactNode;
  alignLeft?: boolean;
  subtitle?: string | ReactElement;
  className?: string;
}

export const PageTitle: React.FC<PageTitleProps> = ({ title, subtitle, alignLeft, className }) => {
  return (
    <div className={styles.wrapper}>
      <h1 className={classnames(styles.title, className, alignLeft && styles.textLeft)}>{title}</h1>

      {subtitle && (
        <p className={classnames(styles.subtitle, alignLeft && styles.textLeft)}>{subtitle}</p>
      )}
    </div>
  );
};
