import React from 'react';

import { I18nextProvider } from 'react-i18next';

import { ThemeProvider } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { globalOverrides } from '@Components/common/themeOverrides';

import {
  UserContextProvider,
  AppLayoutContextProvider,
  NotificationsContextProvider,
  RedirectContextProvider,
} from '@Contexts';

import { QueryClient, QueryClientProvider } from 'react-query';

import i18n from '../i18n'; // I18NextProvider is used intentionally (SSR possible in future)

const queryClient = new QueryClient();

export const AppProviders: React.FC = ({ children }) => (
  <React.StrictMode>
    <I18nextProvider i18n={i18n}>
      <ThemeProvider theme={globalOverrides}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <QueryClientProvider client={queryClient}>
            <RedirectContextProvider>
              <UserContextProvider>
                <AppLayoutContextProvider>
                  <NotificationsContextProvider>{children}</NotificationsContextProvider>
                </AppLayoutContextProvider>
              </UserContextProvider>
            </RedirectContextProvider>
          </QueryClientProvider>
        </MuiPickersUtilsProvider>
      </ThemeProvider>
    </I18nextProvider>
  </React.StrictMode>
);
