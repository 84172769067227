import React, { ReactElement, useCallback } from 'react';
import {
  Tabs as MaterialTabs,
  Tab as MaterialTab,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { withStyles, createStyles } from '@material-ui/core/styles';
import styleVariables from '@Styles/_variables.module.scss';

export interface OldTabProps {
  title: string;
  subtitle?: string;
  value?: number;
  disabled?: boolean;
  slug?: string;
  icon?: ReactElement;
}

interface OldTabsProps {
  currentTab: number;
  tabs: OldTabProps[];
  onTabChange?: (newTab: number) => void;
  fullWidth?: boolean;
  subTab?: boolean;
  className?: string;
  primary?: boolean;
}

export const OldTabs: React.FC<OldTabsProps> = ({
  /* Deprecated tabs component, use Tabs for new work */
  currentTab,
  tabs,
  onTabChange,
  fullWidth = true,
  subTab,
  className,
  primary,
}) => {
  const handleChange = useCallback(
    (e, idx) => {
      if (onTabChange) {
        onTabChange(idx);
      }
    },
    [onTabChange],
  );

  const StyledTabs = withStyles(() =>
    createStyles({
      root: {
        borderBottom: `1px solid ${styleVariables.colorT2Pale}`,
        borderTop: `1px solid ${styleVariables.colorT2Pale}`,
      },
      indicator: {
        backgroundColor: primary ? styleVariables.AFNavyBlue500 : styleVariables.colorT1,
      },
    }),
  )(MaterialTabs);

  const StyledTab = withStyles(() =>
    createStyles({
      root: {
        textTransform: 'none',
        padding: '24px 18px',
        flexGrow: fullWidth ? 1 : undefined,
        maxWidth: fullWidth ? 'none' : undefined,
        '&.Mui-disabled': {
          opacity: 0.25,
        },
      },
      selected: {
        color: styleVariables.colorT1,
      },
    }),
  )(MaterialTab);

  const theme = useTheme();
  const screenXs = useMediaQuery(theme.breakpoints.down('xs'));

  return (
    <StyledTabs
      value={currentTab}
      onChange={handleChange}
      textColor="inherit"
      variant={screenXs ? 'scrollable' : 'fullWidth'}
      style={subTab ? { width: '70%', borderTop: 'none' } : {}}
      className={className}
    >
      {tabs.map(({ title, subtitle, value, disabled, icon }, index) => (
        <StyledTab
          key={title}
          value={value || index}
          disabled={disabled}
          style={subTab ? { padding: '12px' } : {}}
          label={
            <>
              <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                {icon}
                <strong>{title}</strong>
              </div>

              {subtitle && <span>{subtitle}</span>}
            </>
          }
        />
      ))}
    </StyledTabs>
  );
};
