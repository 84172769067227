import React, { useMemo, useState, useCallback } from 'react';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { ViewButton } from './index';
import { TitledTile } from '@Components/common/TitledTile/TitledTile';
import { VideoPlayer, ContentModal, Masonry } from '@Components';
import { Link, Pagination } from '@Components/common';
import { Project, ProjectUpdate } from '@Types';
import styles from './FundGallery.module.scss';
import { PaginatedResponse } from '@Hooks/usePaginatedRequest';

const MASONRY_COLUMNS = {
  default: 2,
  420: 1,
};

interface FundGalleryProps {
  fundId: number;
  updatesPaginatedResponse: PaginatedResponse<ProjectUpdate>;
}

enum MediaTypeEnum {
  VIDEO = 'VIDEO',
  IMAGE = 'IMAGE',
}

interface MediaList {
  id: number;
  url: string;
  type: MediaTypeEnum;
}

export const FundGallery: React.FC<FundGalleryProps> = ({ fundId, updatesPaginatedResponse }) => {
  const { t } = useTranslation('fund');
  const [isGalleryModalOpen, setIsGalleryModalOpen] = useState(false);

  const { page: updatesList, totalCount: fundProjectUpdatesTotalCount } = updatesPaginatedResponse;

  const handleOpenGalleryModal = useCallback(() => {
    setIsGalleryModalOpen(true);
  }, []);

  const handleCloseGalleryModal = useCallback(() => {
    setIsGalleryModalOpen(false);
  }, []);

  const mediaList = useMemo(
    () =>
      updatesList.reduce(
        (acc: MediaList[], { id, image, video_url: video }) =>
          !image && !video
            ? acc
            : [
                ...acc,
                {
                  id,
                  url: (image || video) as string,
                  type: image ? MediaTypeEnum.IMAGE : MediaTypeEnum.VIDEO,
                },
              ],
        [],
      ),
    [updatesList],
  );

  return (
    <TitledTile title={t('report.titles.gallery')}>
      <div className={styles.tileTitleWrapper}>
        {!!fundProjectUpdatesTotalCount && fundProjectUpdatesTotalCount > 4 && (
          <ViewButton onClick={handleOpenGalleryModal} />
        )}
      </div>

      {mediaList && mediaList.length ? (
        <div className={styles.galleryWrapper}>
          {mediaList.slice(0, 4).map(({ id, type, url }) => (
            <Link
              to={`/fund/${fundId}/manage/results/summary?projectUpdate=${id}`}
              key={id}
              className={classnames(
                styles.mediaWrapper,
                type === MediaTypeEnum.VIDEO && styles.mediaWrapperVideo,
              )}
            >
              {type === MediaTypeEnum.IMAGE && <img src={url} className={styles.image} alt="" />}
              {type === MediaTypeEnum.VIDEO && <VideoPlayer videoUrl={url} light />}
            </Link>
          ))}
        </div>
      ) : (
        <span className={styles.noImages}>{t('report.no_images')}</span>
      )}

      {!!fundProjectUpdatesTotalCount && fundProjectUpdatesTotalCount > 4 && (
        <ContentModal
          title={t('report.titles.gallery')}
          isOpen={isGalleryModalOpen}
          onClose={handleCloseGalleryModal}
        >
          <Masonry
            className={styles.masonry}
            columnClassName={styles.masonryColumn}
            breakpointCols={MASONRY_COLUMNS}
          >
            {mediaList.map(({ id, type, url }) => (
              <Link to={`/fund/${fundId}/manage/results/summary?projectUpdate=${id}`} key={id}>
                {type === MediaTypeEnum.IMAGE && (
                  <img src={url} className={styles.imageTile} alt="" />
                )}
                {type === MediaTypeEnum.VIDEO && <VideoPlayer videoUrl={url} light />}
              </Link>
            ))}
          </Masonry>
          <Pagination paginatedResponse={updatesPaginatedResponse} />
        </ContentModal>
      )}
    </TitledTile>
  );
};
