import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, ButtonProps } from '@Components/common';
import styles from './CreateActionWrapper.module.scss';
import { ArchiveProjectButton } from '@Components/common/Button/ArchiveProjectButton';

interface CreateActionButtonsProps {
  currentStep: number;
  stepsCount: number;
  cancelButtonProps?: ButtonProps;
  draftButtonProps?: ButtonProps;
  backButtonProps?: ButtonProps;
  nextButtonProps?: ButtonProps;
  projectId?: number;
}

export const CreateActionButtons: React.FC<CreateActionButtonsProps> = ({
  currentStep,
  stepsCount,
  cancelButtonProps,
  draftButtonProps,
  backButtonProps,
  nextButtonProps,
  projectId,
}) => {
  const { t } = useTranslation('common');
  const isLastStep = currentStep === stepsCount - 1;

  return (
    <div className={styles.buttonsWrapper}>
      <div className={styles.additionalButtonContainer}>
        {cancelButtonProps && (
          <Button {...cancelButtonProps}>{cancelButtonProps?.children || t('cancel')}</Button>
        )}
        {projectId && <ArchiveProjectButton id={projectId} />}
      </div>

      <div className={styles.buttonsRow}>
        {currentStep > 0 && (
          <Button buttonType="text" {...backButtonProps}>
            {t('back')}
          </Button>
        )}
        {draftButtonProps && <Button {...draftButtonProps}>{t('save_as_draft')}</Button>}
        <Button buttonType="primary" {...nextButtonProps}>
          {nextButtonProps?.children || (isLastStep ? t('finish') : t('next'))}
        </Button>
      </div>
    </div>
  );
};
