import { useCallback, useEffect, useRef, useState } from 'react';
import { QuoteAuthor } from '..';
import { TitledTile } from '@Components/common/TitledTile/TitledTile';
import styles from './Quote.module.scss';
import MobileStepper from '@material-ui/core/MobileStepper';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import FormatQuoteIcon from '@material-ui/icons/FormatQuote';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core';
import { ContentModal } from '@Components';
import { checkOverflow } from '@Helpers/checkOverflow';
import { IconButton } from '@Components/common/Button/IconButton';
import styleVariables from '@Styles/_variables.module.scss';

interface QuoteItemProps {
  quotes: { quote: string; project_id: number }[];
  projects: { id: number; group_name: string; name: string; image_url: string; url: string }[];
  quotesTitle: string;
}

const useStyles = makeStyles({
  root: {
    background: styleVariables.AFWhiteKnight500,
  },
});

export const Quote = ({ quotes, projects, quotesTitle }: QuoteItemProps) => {
  const { t } = useTranslation('fund');
  const [activeStep, setActiveStep] = useState(0);
  const maxSteps = quotes.length;
  const classes = useStyles();
  const [overflowActive, setOverflowActive] = useState(false);
  const overflowingText = useRef(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    if (checkOverflow(overflowingText.current)) {
      setOverflowActive(true);
      return;
    }
    setOverflowActive(false);
  }, [overflowActive, activeStep]);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleOpenModal = useCallback(() => {
    setIsModalOpen(true);
  }, []);

  const handleCloseModal = useCallback(() => {
    setIsModalOpen(false);
  }, []);

  return (
    <TitledTile title={quotesTitle}>
      <div className={styles.container}>
        <FormatQuoteIcon className={styles.quoteIcon} style={{ fontSize: '64px' }} />
        <h3 className={classnames(styles.quote, styles.clamp)} ref={overflowingText}>
          {quotes[activeStep].quote}
        </h3>
        {overflowActive ? (
          <button className={styles.readMoreButton} onClick={handleOpenModal}>
            {t('report.read_more')}
          </button>
        ) : null}
        <QuoteAuthor projects={projects} quotes={quotes} activeStep={activeStep} />
      </div>
      <MobileStepper
        steps={maxSteps}
        position="static"
        classes={classes}
        activeStep={activeStep}
        nextButton={
          <IconButton
            size="small"
            onClick={handleNext}
            disabled={activeStep === maxSteps - 1}
            buttonType="text"
          >
            <KeyboardArrowRight />
          </IconButton>
        }
        backButton={
          <IconButton
            size="small"
            onClick={handleBack}
            disabled={activeStep === 0}
            buttonType="text"
          >
            <KeyboardArrowLeft />
          </IconButton>
        }
      />
      <ContentModal isOpen={isModalOpen} onClose={handleCloseModal} title={quotesTitle}>
        <div className={styles.container}>
          <FormatQuoteIcon className={styles.quoteIcon} style={{ fontSize: '64px' }} />
          <h3 className={styles.quote}>{quotes[activeStep].quote}</h3>
          <QuoteAuthor projects={projects} quotes={quotes} activeStep={activeStep} />
        </div>
      </ContentModal>
    </TitledTile>
  );
};
