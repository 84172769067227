import { PagedResponse } from '@Types';
import { Fund } from './Fund';
import { Project } from './Project';

export enum SelectionStatusEnum {
  'SHORTLISTED' = 'SHORTLISTED',
  'INVITED' = 'INVITED',
  'INVITATION_ACCEPTED' = 'INVITATION_ACCEPTED',
  'PAYMENT_FAILED' = 'PAYMENT_FAILED',
  'FUNDED' = 'FUNDED',
  'INVITATION_REJECTED' = 'INVITATION_REJECTED',
  'UNAVAILABLE' = 'UNAVAILABLE',
  'NO_LONGER_MATCHES' = 'NO_LONGER_MATCHES',
}

export interface Selection {
  id: number;
  status: SelectionStatusEnum;
  fund: Fund;
  project: Project;
  in_campaign: boolean;
  group_nomination_count: number;
  vote_count?: number;
}

export type SelectionsApiResponse = PagedResponse<Selection>;
