import React from 'react';
import { useFormContext } from 'react-hook-form';
import { Trans } from 'react-i18next';
import classnames from 'classnames';

import DeleteIcon from '@material-ui/icons/Delete';

import { ImageUploadDropzone } from '@Components';
import { AVATAR_UPLOAD_CONFIG } from '@Helpers/images';
import { useImageUpload } from '@Hooks';

import styles from './AvatarUpload.module.scss';

const FIELD_NAME = 'avatar_image';

export const AvatarUpload: React.FC = () => {
  const { errors: fieldErrors } = useFormContext();

  const { file, deleteFile, dropzoneInputProps, dropzoneRootProps } = useImageUpload({
    fieldName: FIELD_NAME,
    uploadConfig: AVATAR_UPLOAD_CONFIG,
  });

  const isDropzoneHidden = Array.isArray(file) || typeof file === 'string';

  return (
    <div className={styles.container}>
      <ImageUploadDropzone
        fieldName={FIELD_NAME}
        dropzoneInputProps={dropzoneInputProps}
        dropzoneRootProps={dropzoneRootProps}
        subtext={
          <Trans
            ns="createAccount"
            i18nKey="create_profile_common.avatar_upload_hint"
            components={[<strong className={styles.textBold} />]}
          />
        }
        className={classnames(isDropzoneHidden && styles.hidden)}
      />

      {isDropzoneHidden && (
        <div className={styles.previewWrapper}>
          <div
            className={styles.imagePreview}
            style={{
              backgroundImage: `url(${typeof file === 'string' ? file : file && file[0].preview})`,
            }}
          />

          <button onClick={deleteFile} className={styles.deleteIcon}>
            <DeleteIcon style={{ fontSize: 20 }} />
          </button>
        </div>
      )}

      {fieldErrors[FIELD_NAME]?.message && (
        <p className={styles.errorMessage}>{fieldErrors[FIELD_NAME].message}</p>
      )}
    </div>
  );
};
