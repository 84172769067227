import React from 'react';
import ReactPlayer from 'react-player/youtube';
import DeleteIcon from '@material-ui/icons/Delete';

import styles from './VideoPlayer.module.scss';

interface VideoPlayerProps {
  videoUrl: string;
  light?: boolean;
  onRemoveVideo?: () => void;
}

export const VideoPlayer: React.FC<VideoPlayerProps> = ({ videoUrl, light, onRemoveVideo }) => {
  return (
    <div className={styles.videoWrapper}>
      <ReactPlayer
        url={videoUrl}
        className={styles.videoPlayer}
        light={light}
        width="100%"
        height="100%"
        config={{
          playerVars: {
            autoplay: 0,
            showinfo: 0,
            controls: 1,
            iv_load_policy: 3,
            modestbranding: 1,
            rel: 0,
          },
        }}
      />

      {onRemoveVideo && (
        <button onClick={onRemoveVideo} className={styles.deleteIcon}>
          <DeleteIcon style={{ fontSize: 20 }} />
        </button>
      )}
    </div>
  );
};
