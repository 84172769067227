import React from 'react';
import {
  Stepper as MaterialStepper,
  StepperProps as MaterialStepperProps,
  Step as MaterialStep,
  StepContent as MaterialStepContent,
  StepProps as MaterialStepProps,
  StepLabel as MaterialStepLabel,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import styleVariables from '@Styles/_variables.module.scss';

const useStyles = makeStyles({
  text: {
    fill: styleVariables.colorT5,
  },
});

interface StepProps extends MaterialStepProps {
  content?: JSX.Element;
  label: string | JSX.Element;
}

export interface StepperProps extends Omit<MaterialStepperProps, 'children'> {
  steps: StepProps[];
}

export const Stepper: React.FC<StepperProps> = ({ steps, ...stepperProps }) => {
  const classes = useStyles();
  const { activeStep, alternativeLabel, orientation } = stepperProps;

  return (
    <MaterialStepper
      activeStep={activeStep}
      alternativeLabel={alternativeLabel}
      className={stepperProps.className}
      orientation={orientation}
    >
      {steps.map((step, i) => (
        <MaterialStep key={i}>
          <MaterialStepLabel
            StepIconProps={{
              classes: activeStep === i ? classes : undefined,
            }}
          >
            {step.label}
          </MaterialStepLabel>
          {step.content && <MaterialStepContent>{step.content}</MaterialStepContent>}
        </MaterialStep>
      ))}
    </MaterialStepper>
  );
};
