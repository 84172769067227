import { PagedResponse } from './Pagination';

export enum TableQueryCellType {
  STRING = 'STRING',
  DATE = 'DATE',
  BOOLEAN = 'BOOLEAN',
  STRING_ARRAY = 'STRING_ARRAY',
  NUMBER = 'NUMBER',
  URL = 'URL',
  CURRENCY = 'CURRENCY',
}

export interface StringTableQueryCell {
  type: TableQueryCellType.STRING;
  value: string;
}

export interface DateTableQueryCell {
  type: TableQueryCellType.DATE;
  value: string | null;
}

export interface BooleanTableQueryCell {
  type: TableQueryCellType.BOOLEAN;
  value: boolean;
}

export interface NumberTableQueryCell {
  type: TableQueryCellType.NUMBER;
  value: number | null;
}

export interface CurrencyTableQueryCell {
  type: TableQueryCellType.CURRENCY;
  value: number | null;
}

export interface StringArrayTableQueryCell {
  type: TableQueryCellType.STRING_ARRAY;
  value: string[];
}

export interface URLTableQueryCell {
  type: TableQueryCellType.URL;
  value: string | null;
  url: string | null;
}

export type TableQueryCell =
  | StringTableQueryCell
  | DateTableQueryCell
  | URLTableQueryCell
  | BooleanTableQueryCell
  | NumberTableQueryCell
  | CurrencyTableQueryCell
  | StringArrayTableQueryCell;

export interface TableQueryRow {
  object_id: number;
  cells: TableQueryCell[];
}

export type TableQueryHeader = string[];

export interface TableQueryResult extends PagedResponse<TableQueryRow> {
  // As well as the normal fields on a paginated response, table queries also provide a list of headers
  // to display on the table.
  header: TableQueryHeader;
}
