import isServer from './isServer';

export const SUBDOMAINS = ['proud-to-pitch-in', 'local-pubs-ptpi'];

export const getIsSubdomain = () => {
  if (!isServer) {
    return Boolean(SUBDOMAINS.find((el) => el === window.location.host.split('.')[0]));
  }
  return false;
};
