import { MatrixFormat } from '@Types';

export const matrixFormatToHighchartsStackedBar = (
  matrixQuestion: MatrixFormat,
  displayAsPercentage: boolean,
) => {
  let title: string = '';
  // Assigning title
  if (matrixQuestion) {
    title = matrixQuestion.label;
  }

  // Assigning labels
  const labels: string[] = [];
  matrixQuestion?.data.labels.forEach(function (label) {
    labels.push(label);
  });

  // Formatting into usable object
  const dataSet: { data: (number | null)[]; name: string }[] = matrixQuestion?.data.answers.map(
    (question) => {
      return {
        data: displayAsPercentage ? question.percentage : question.value,
        name: question.label,
      };
    },
  );
  dataSet.map((answer) => {
    answer.data = answer.data.map((data) => {
      return data === 0 ? null : data;
    });
  });

  return { dataSet, title, labels, displayAsPercentage };
};
