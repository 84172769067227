import { useCallback, useEffect } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { CreateActionButtons } from '@Components';
import { useRouter } from '@Helpers';
import isServer from '@Helpers/isServer';
import { useConfirmFund } from '@Hooks/Api';
import { useNotificationsContext } from '@Contexts';
import { RoutingPaths } from '@App/paths';

import { Finalise } from './Finalise';
import { Grid } from '@material-ui/core';

interface FinaliseControllerProps {
  currentStep: number;
  stepsCount: number;
  prevStep: () => void;
  fundId: number | null;
  isFundConfirmed: boolean;
}

export const FinaliseController: React.VFC<FinaliseControllerProps> = ({
  currentStep,
  stepsCount,
  prevStep,
  fundId,
  isFundConfirmed,
}) => {
  const { t } = useTranslation('fund');
  const { push } = useRouter();
  const [confirmFund, { loading: isConfirmFundLoading }] = useConfirmFund();
  const { success: successNotification, error: errorNotification } = useNotificationsContext();
  const methods = useForm();
  const { handleSubmit, reset } = methods;

  useEffect(() => {
    if (isFundConfirmed) {
      reset({ terms: true });
    }
  }, [isFundConfirmed, reset]);

  const onSubmit = useCallback(() => {
    if (!isFundConfirmed) {
      confirmFund(fundId!)
        .then(() => {
          if (!isServer && window.dataLayer) {
            window.dataLayer.push({ event: 'fundCreate' });
          }
          successNotification(t('confirmed'));
          push(RoutingPaths.DASHBOARD_FUNDER);
        })
        .catch(({ data }) => {
          if (data.detail[0]) {
            errorNotification(data.detail[0]);
          }
        });
    } else {
      push(RoutingPaths.DASHBOARD_FUNDER);
    }
  }, [confirmFund, errorNotification, fundId, isFundConfirmed, push, successNotification, t]);

  const submit = handleSubmit(onSubmit);

  const handleCancel = () => {
    push(RoutingPaths.DASHBOARD_FUNDER);
  };

  return (
    <Grid container justifyContent="center">
      <Grid xs={12} lg={8}>
        <FormProvider {...methods}>
          <Finalise
            isSaving={isConfirmFundLoading}
            isFundConfirmed={isFundConfirmed}
            onSubmit={submit}
            buttons={
              <CreateActionButtons
                currentStep={currentStep}
                stepsCount={stepsCount}
                backButtonProps={{
                  onClick: prevStep,
                  buttonType: 'text',
                }}
                nextButtonProps={{
                  onClick: submit,
                  disabled: isConfirmFundLoading,
                  children: t('process.finish_button'),
                  buttonType: 'primary',
                }}
                cancelButtonProps={{
                  onClick: handleCancel,
                  children: t('save_as_draft'),
                  buttonType: 'tertiary',
                }}
              />
            }
          />
        </FormProvider>
      </Grid>
    </Grid>
  );
};
