import { useCallback } from 'react';
import { PaginationConfiguration, usePaginatedRequest } from '@Hooks/usePaginatedRequest';
import { API } from '@Services';
import { ProjectUpdate } from '@Types';

export const useGetProjectUpdates = (paginationConfiguration?: PaginationConfiguration) => {
  const [fetch, response] = usePaginatedRequest<ProjectUpdate>(paginationConfiguration);

  const getProjectUpdates = useCallback(
    (projectId: number) => fetch(API.paths.project_updates(projectId)),
    [fetch],
  );

  return [getProjectUpdates, response] as const;
};
