import styles from './VideoPitch.module.scss';
import { useTranslation } from 'react-i18next';

interface VideoPitchQuestionProps {
  question: string;
  subText: string;
  hint?: string;
}

export const VideoPitchQuestion: React.FC<VideoPitchQuestionProps> = ({
  question,
  subText,
  hint,
}) => {
  const { t } = useTranslation('project');
  return (
    <div className={styles.questionWrapper}>
      <span className={styles.questionTitle}>{question}</span>
      <div className={styles.questionWrapper}>
        <span className={styles.subText}>{subText}</span>
        {hint && (
          <span className={styles.hint}>
            <b>{t('pitch.hint')}: </b>
            {hint}
          </span>
        )}
      </div>
    </div>
  );
};
