import Slider, { Settings as SliderSettings } from 'react-slick';
import { FeaturedCard } from '@Components';
import { Category, Project } from '@Types';

interface ProjectSlidesProps {
  sliderSettings?: SliderSettings;
  featuredProjects?: Project[];
  categoriesList?: Category[];
  className?: string;
}

export const ProjectSlides = ({
  sliderSettings = {},
  featuredProjects,
  categoriesList,
  className,
}: ProjectSlidesProps) => {
  return (
    <Slider {...sliderSettings}>
      {featuredProjects &&
        featuredProjects.map((project) => {
          return (
            <FeaturedCard
              className={className}
              key={project.id}
              projectData={project}
              allCategories={categoriesList}
            />
          );
        })}
    </Slider>
  );
};
