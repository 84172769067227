import { useCallback } from 'react';
import { useRequest } from '@Hooks';
import { API } from '@Services';
import { ApiErrorObject } from '@Types';

export const useArchiveFund = () => {
  const [fetch, response] = useRequest<null | ApiErrorObject>();

  const archiveFund = useCallback(
    (fundId: number) => fetch(API.paths.fund_archive(fundId), 'post'),
    [fetch],
  );

  return [archiveFund, response] as const;
};
