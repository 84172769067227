import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// Currently we use inlined JSON files.
// In future this resource should be moved to external assets.
// See more: https://react.i18next.com/latest/i18next-instance
import resources from './locales';

function formatPostcode(str: string) {
  str = str.toUpperCase();
  str = str.replace(/[^0-9a-z]/gi, '');
  var parts = str.match(/^([A-Z]{1,2}\d{1,2}[A-Z]?)\s*(\d[A-Z]{2})$/);
  if (parts) {
    parts.shift();
    str = parts.join(' ');
  }
  return str;
}

i18n.use(initReactI18next).init({
  resources,
  lng: 'en',
  defaultNS: 'common',
  interpolation: {
    escapeValue: false, // react already safes from xss
    format: (value, format, lng) => {
      switch (format) {
        case 'lowercase':
          return value.toLocaleLowerCase(lng);
        case 'uppercase':
          return value.toLocaleLowerCase(lng);
        case 'capitalize':
          return value.charAt(0).toLocaleUpperCase(lng) + value.substr(1);
        case 'currency':
          return Intl.NumberFormat(lng, {
            style: 'currency',
            currency: 'GBP',
            minimumFractionDigits: 0,
          }).format(value);
        case 'wholeCurrency':
          return Intl.NumberFormat(lng, {
            style: 'currency',
            currency: 'GBP',
            maximumFractionDigits: 0,
          }).format(value);
        case 'whole':
          return value.toLocaleString('en-GB', {
            maximumFractionDigits: 0,
          });
        case '1sf':
          return value.toLocaleString('en-GB', {
            maximumFractionDigits: 1,
          });
        case 'postcodeFormatUK':
          return formatPostcode(value);
        default:
          return value;
      }
    },
  },
});

export default i18n;
