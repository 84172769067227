import React, { Fragment } from 'react';
import { useTranslation, Trans } from 'react-i18next';

import EmojiObjectsOutlinedIcon from '@material-ui/icons/EmojiObjectsOutlined';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import AlarmIcon from '@material-ui/icons/Alarm';
import BeenhereIcon from '@material-ui/icons/Beenhere';
import { Divider } from '@material-ui/core';

import { FieldNote, VideoPlayer, VideoTextField, SectionHeader } from '@Components';
import { Link } from '@Components/common';
import styleVariables from '@Styles/_variables.module.scss';

import { VideoPitchQuestion } from './VideoPitchQuestion';
import styles from './VideoPitch.module.scss';
import { getHelpCenterURL } from '@Helpers/helpCenter';
import { useUserContext } from '@Contexts';
import { HelpCenterRoutes } from '@App/paths';

const VIDEO_URL = 'https://www.youtube-nocookie.com/embed/edfxakZjyPE';

export const VideoPitch: React.FC = () => {
  const { t } = useTranslation('project');
  const { userData, isUserLoading } = useUserContext();
  const videoPitchGuideURL =
    userData && !isUserLoading
      ? getHelpCenterURL({
          path: HelpCenterRoutes.VIDEO_PITCH_GUIDE,
          user: userData,
        })
      : '';

  return (
    <Fragment>
      <SectionHeader
        centerHeader
        title={t('pitch.video_tutorial_header')}
        subtitle={
          <div className={styles.sectionSubheader}>
            <Trans
              ns="project"
              i18nKey="pitch.video_tutorial_subheader"
              components={[<Link href={videoPitchGuideURL} primary />]}
            />
          </div>
        }
      />

      <VideoPlayer videoUrl={VIDEO_URL} />

      <SectionHeader
        centerHeader
        title={t('pitch.video_pitch_header')}
        subtitle={
          <span className={styles.sectionSubheader}>{t('pitch.video_pitch_subheader')}</span>
        }
      />

      <div className={styles.tipsTable}>
        <div className={styles.tipsTableItem}>
          <div className={styles.tipsTableElement}>
            <FieldNote
              transparent
              icon={<CheckCircleIcon style={{ color: styleVariables.colorT3 }} />}
            >
              <span className={styles.tipsTableText}>
                <Trans ns="project" i18nKey="pitch.video_pitch_tip1" components={[<strong />]} />
              </span>
            </FieldNote>
          </div>
        </div>
        <div className={styles.tipsTableItem}>
          <div className={styles.tipsTableElement}>
            <FieldNote transparent icon={<AlarmIcon style={{ color: styleVariables.colorT3 }} />}>
              <span className={styles.tipsTableText}>
                <Trans ns="project" i18nKey="pitch.video_pitch_tip2" components={[<strong />]} />
              </span>
            </FieldNote>
          </div>
        </div>
        <div className={styles.tipsTableItem}>
          <div className={styles.tipsTableElement}>
            <FieldNote
              transparent
              icon={<BeenhereIcon style={{ color: styleVariables.colorT3 }} />}
            >
              <span className={styles.tipsTableText}>
                <Trans ns="project" i18nKey="pitch.video_pitch_tip3" components={[<strong />]} />
              </span>
            </FieldNote>
          </div>
        </div>
      </div>

      <VideoPitchQuestion
        question={t('pitch.q1title')}
        subText={t('pitch.q1subText')}
        hint={t('pitch.q1hint')}
      />
      <Divider />
      <VideoPitchQuestion
        question={t('pitch.q2title')}
        subText={t('pitch.q2subText')}
        hint={t('pitch.q2hint')}
      />
      <Divider />
      <VideoPitchQuestion
        question={t('pitch.q3title')}
        subText={t('pitch.q3subText')}
        hint={t('pitch.q3hint')}
      />

      <div className={styles.videoInputWrapper}>
        <SectionHeader centerHeader title={t('pitch.video_pitch_box_title')} />
        <VideoTextField field="video_url_1" />
        <FieldNote
          className={styles.inputNote}
          icon={<EmojiObjectsOutlinedIcon style={{ fontSize: 15 }} />}
        >
          <Trans ns="project" i18nKey="pitch.video_pitch_input_desc" components={[<strong />]} />
        </FieldNote>
      </div>
    </Fragment>
  );
};
