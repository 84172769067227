import { useCallback } from 'react';
import { useRequest } from '@Hooks';
import { API } from '@Services';
import { ApiErrorObject } from '@Types';

export const useProjectAcceptsInvitation = () => {
  const [fetch, response] = useRequest<null | ApiErrorObject>();

  const projectAcceptsInvitation = useCallback(
    (projectId: number, selectionId: number) =>
      fetch(API.paths.project_accepts_invitation(projectId, selectionId), 'patch', {
        data: null,
      }),
    [fetch],
  );

  return [projectAcceptsInvitation, response] as const;
};
