import React from 'react';
import classnames from 'classnames';
import { CircularProgress } from '@material-ui/core';
import styles from './Loader.module.scss';

interface LoaderProps {
  className?: string;
}

export const Loader = ({ className }: LoaderProps) => {
  return (
    <div className={classnames(styles.container, className)}>
      <CircularProgress color="inherit" />
    </div>
  );
};
