import axios, { AxiosRequestConfig } from 'axios';
import * as authToken from '@Helpers/authToken';

export const createAxiosClient = (config: AxiosRequestConfig) => {
  const client = axios.create(config);

  client.interceptors.request.use((config) => {
    const token = authToken.get();
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    //We can also set global loading state indicator here if needed (in redux)

    return config;
  });

  client.interceptors.response.use(
    (response) => response,
    (reject) => Promise.reject(reject.response),
  );

  return client;
};
