import { useCallback } from 'react';
import { useRequest } from '@Hooks';
import { API } from '@Services';
import { ApiErrorObject } from '@Types';

export const useHideProjectUpdate = () => {
  const [fetch, response] = useRequest<null | ApiErrorObject>();

  const hideUpdate = useCallback(
    (projectUpdateId: number) =>
      fetch(API.paths.hide_update(projectUpdateId), 'patch', {
        data: null,
      }),
    [fetch],
  );

  return [hideUpdate, response] as const;
};
