import React from 'react';
import { FormControlLabel, Radio } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import styles from './CheckboxTile.module.scss';

interface CheckboxTileProps {
  disabled?: boolean;
  value: string;
  label: string;
  icon?: JSX.Element;
  name: string;
  error: boolean;
  checked: boolean;
  onChange: () => void;
  inputRef?: React.Ref<any>;
}

const useStyles = makeStyles({
  root: {
    display: 'block',
    flexGrow: 1,
    height: 'fit-content',
    marginLeft: 0,
    marginRight: 0,
  },
  label: {
    display: 'flex',
    height: '100%',
  },
});

export const CheckboxTile: React.FC<CheckboxTileProps> = ({
  disabled,
  onChange,
  value,
  label,
  icon,
  name,
  error,
  checked,
  inputRef,
}) => {
  const classes = useStyles();
  return (
    <FormControlLabel
      classes={classes}
      control={
        <span className={styles.formControl}>
          <Radio value={value} name={name} disabled={disabled} onChange={onChange} />
        </span>
      }
      label={
        <div
          className={classnames(
            styles.typeBox,
            error && styles.typeBoxError,
            checked && styles.typeBoxChecked,
            disabled && styles.typeBoxDisabled,
          )}
          role="button"
          aria-describedby={label}
          ref={inputRef}
          tabIndex={0}
        >
          {icon ? icon : label}
        </div>
      }
    />
  );
};
