import { useEffect, useRef } from 'react';

export function useInterval(callback: () => void, delay: number | null) {
  // Based on https://overreacted.io/making-setinterval-declarative-with-react-hooks/
  // Set delay to 'null' to pause the interval or prevent it from running on load.
  const savedCallback = useRef<typeof callback>();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current!();
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}
