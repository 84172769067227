import { ProjectApplicationFormat } from '@Components/FundCriteria/types';
import { MultiPolygon, Point } from 'geojson';
import { FundingProfileData } from './FundingProfileData';
import { DrawingMode, PagedResponse } from './index';
import { MatchSimplified } from './Match';
import { GroupTypes } from './OrganizationTypes';
import { SDGIdentifier } from './SDG';

export interface FundFormStep1 {
  areas?: MultiPolygon | null;
  catchment_area_type: DrawingMode;
  maximum_grant_amount: number;
  minimum_grant_amount: number | null;
  postcode: string;
  radius: number | null;
  starting_amount: number;
  // TODO-MAPS
  group_types_to_support: ('ALL' & typeof GroupTypes)[];
  categories: number[];
  video_pitch_required: boolean;
}

export interface FundFormStep2 {
  name: string;
  purpose: string;
  starting_amount: string;
  maximum_grant_amount: string;
  launch_from: string;
  deadline_to: string;
  project_delivery_deadline: string;
  selection_deadline: string;
  promotion_hashtag: string | null;
  project_name?: string;
  project_postcode?: string;
  project_cost_code?: string;
}

export interface FundDetails {
  areas: MultiPolygon | null;
  // TODO-MAPS
  drawingMode: DrawingMode;
  catchment_area_type: DrawingMode;
  name: string;
  purpose: string;
  starting_amount: string;
  remaining_amount: string;
  maximum_grant_amount: string;
  minimum_grant_amount: string;
  total_cost: string;
  platform_fees: string;
  launch_from: string;
  location: Point;
  deadline_to: string;
  project_delivery_deadline: string;
  postcode: string;
  radius: number;
  group_types_to_support: GroupTypes[];
  // TODO-MAPS
  nonProfitTypes: ('ALL' | GroupTypes)[];
  // TODO-MAPS
  focusAreas: number[];
  categories: number[];
  promotion_hashtag: string | null;
  video_pitch_required: boolean;
  // TODO-MAPS
  projectApplicationFormat: ProjectApplicationFormat;
  project_name?: string;
  project_postcode?: string;
  project_cost_code?: string;
  local_authority: number | null;
  selection_deadline: string;
  beneficiary_types_people: string[];
}

export enum FundStatusEnum {
  'DRAFT' = 'DRAFT',
  'AWAITING_APPROVAL' = 'AWAITING_APPROVAL',
  'APPROVED' = 'APPROVED',
  'IN_DELIVERY' = 'IN_DELIVERY',
  'LIVE' = 'LIVE',
  'CLOSED' = 'CLOSED',
  'COMPLETE' = 'COMPLETE',
}

export interface SDG {
  label: string;
  name: SDGIdentifier;
  percentage: number;
  value: number;
  focus_areas: string[];
}

export interface FundVotingConfigurationSubmission {
  // The data we submit to create/update a FundVotingConfiguration
  deadline: string;
  description: string;
  maximum_votes_per_user: number | null;
}

export interface FundVotingConfiguration extends FundVotingConfigurationSubmission {
  // The data we get when we fetch a FundVotingConfiguration
  id: number;
  deadline_passed: boolean;
  sharing_link: string;
  total_votes: number;
  total_voters: number;
  is_editable: boolean;
}

export interface Fund {
  id: number;
  status: FundStatusEnum;
  details: FundDetails;
  funding_profile: FundingProfileData;
  user_can_manage: boolean;
  matches_list?: MatchSimplified[];
  matches_count?: number;
  projects_sdg_breakdown: SDG[];
  user_has_enhanced_reporting?: boolean;
  user_has_individual_responses?: boolean;
  voting_configuration?: FundVotingConfiguration;
  funded_projects_count?: number;
  user_can_archive: boolean;
  group_has_project_matched: boolean;
}

export interface GroupEligibilityForFund {
  matched_location: boolean;
  matched_categories: boolean;
  matched_group_type: boolean;
}

export interface ResponsesCountApiResponse {
  number_of_responses: number;
}

export interface SurveyDashboardLinkApiResponse {
  dashboard_link: string;
}

export type FundsApiResponse = PagedResponse<Fund>;

export interface JobSubmissionResponse {
  job_id: string;
}

export interface JobResult {
  download_url: string;
}

export interface JobStatusCheckResult {
  job_id: string;
  last_update: string;
  created: string;
  progress_count?: number;
  progress_percentage?: number;
  completed_on?: string;
  result?: JobResult;
}
