import React, { Fragment, useState, useRef, useCallback } from 'react';
import { UserAvatarMenu } from './UserAvatarMenu';
import { LoginButtons } from './LoginButtons';
import { useUserContext } from '@Contexts';
import { useRouter } from '@Helpers/useRouter';
import { getInitials } from '@Helpers/getInitials';
import { UserTypeEnum } from '@Types';
import { getOrganizationPagePath } from '@Helpers/getOrganizationPagePath';
import { RoutingPaths } from '@App/paths';

interface UserAvatarMenuControllerProps {
  publicLayout?: boolean;
}

export const UserAvatarMenuController: React.VFC<UserAvatarMenuControllerProps> = ({
  publicLayout,
}) => {
  const [open, setOpen] = useState(false);
  const anchorEl = useRef<HTMLButtonElement | null>(null);
  const { userData, signOut, isUserLoading } = useUserContext();

  const { push } = useRouter();

  const handleMenuOpen = useCallback(() => {
    setOpen(true);
  }, []);

  const handleMenuClose = useCallback(() => {
    setOpen(false);
  }, []);

  const handleDashboardClick = useCallback(() => {
    push(
      UserTypeEnum.FUNDER === userData?.user_type
        ? RoutingPaths.DASHBOARD_FUNDER
        : RoutingPaths.DASHBOARD_GROUP,
    );
  }, [push, userData?.user_type]);

  const handleProfileClick = useCallback(() => {
    push(
      getOrganizationPagePath(
        Boolean(userData?.user_type === UserTypeEnum.FUNDER),
        userData?.organization_id as number,
        userData?.has_organization_profile,
      ),
    );
  }, [push, userData?.has_organization_profile, userData?.organization_id, userData?.user_type]);

  const avatarContent = getInitials(userData?.name);

  const isSignedIn = !!(!isUserLoading && userData);

  return !isUserLoading ? (
    <Fragment>
      <LoginButtons publicLayout={publicLayout} />
      {isSignedIn && (
        <UserAvatarMenu
          anchorEl={anchorEl}
          isMenuOpen={open}
          avatarContent={avatarContent}
          userName={userData?.name}
          userEmail={userData?.email}
          onMenuOpen={handleMenuOpen}
          onMenuClose={handleMenuClose}
          onSignOut={signOut}
          onProfileClick={handleProfileClick}
          onDashboardClick={handleDashboardClick}
          publicLayout={publicLayout}
        />
      )}
    </Fragment>
  ) : null;
};
