import { useCallback, useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useSetFieldErrors } from '@Hooks';
import { useResetPassword } from '@Hooks/Api';
import { useNotificationsContext } from '@Contexts';
import { PasswordReset } from './PasswordReset';

export const PasswordResetController = () => {
  const methods = useForm();
  const { handleSubmit, setError } = methods;
  const [resetPassword, { loading, error }] = useResetPassword();
  const [isSent, setIsSent] = useState(false);
  const { error: errorNotification } = useNotificationsContext();

  const onSubmit = useCallback(
    ({ email }: { email: string }) => {
      resetPassword(email)
        .then(() => {
          setIsSent(true);
        })
        .catch((err) => {
          if (err?.data?.detail[0]) {
            errorNotification(err?.data?.detail[0]);
          }
        });
    },
    [errorNotification, resetPassword],
  );

  useSetFieldErrors({
    fieldErrors: error?.field_errors,
    setError,
    loading: loading,
  });

  return (
    <FormProvider {...methods}>
      <PasswordReset onSubmit={handleSubmit(onSubmit)} isLoading={loading} isSent={isSent} />
    </FormProvider>
  );
};
