import React, { ReactElement } from 'react';
import styles from './Title.module.scss';
import { RiQuestionLine } from 'react-icons/ri';
import { Tooltip, Divider } from '@Components';

interface TitleProps {
  title: string;
  subtitle?: string | ReactElement;
  tooltip?: string;
  value?: number;
}

export const Title = ({ title, subtitle, tooltip, value }: TitleProps) => {
  return (
    <div className={styles.container}>
      <div>
        <div className={styles.titleContainer}>
          <h2 className={styles.title}>{title}</h2>
          {(value || value === 0) && (
            <div className={styles.value}>
              <span>{value}</span>
            </div>
          )}
        </div>
        {subtitle && <h4 className={styles.subtitle}>{subtitle}</h4>}
      </div>
      {tooltip && (
        <Tooltip title={tooltip} className={styles.helpIcon}>
          <div>
            <RiQuestionLine />
          </div>
        </Tooltip>
      )}
    </div>
  );
};
