import React from 'react';
import { useTranslation } from 'react-i18next';
import { useFormContext, Controller } from 'react-hook-form';
import { PitchChoice, VideoPitch, WrittenPitch, SectionHeader } from '@Components';
import { Checkbox } from '@Components/common';
import styles from './ProjectPitch.module.scss';
import { msgFieldRequired } from '@Helpers/errorMessages';
import { PitchTypesEnum } from '@Types';

interface ProjectPitchProps {
  isLoading?: boolean;
  onSubmit: (e?: React.FormEvent<HTMLFormElement | HTMLButtonElement>) => Promise<void>;
  pitchType?: PitchTypesEnum;
  onPitchChoice: (type: PitchTypesEnum) => void;
  buttons?: JSX.Element;
}

export const ProjectPitch: React.FC<ProjectPitchProps> = ({
  isLoading,
  buttons,
  onSubmit,
  pitchType,
  onPitchChoice,
}) => {
  const { t } = useTranslation('project');
  const { errors: fieldErrors, control } = useFormContext();

  return (
    <form className={styles.form} onSubmit={onSubmit}>
      <PitchChoice activePitch={pitchType} onPitchChoice={onPitchChoice} />

      {pitchType !== undefined && (
        <>
          {pitchType === PitchTypesEnum.VIDEO && <VideoPitch />}
          {pitchType === PitchTypesEnum.WRITTEN && <WrittenPitch />}

          <SectionHeader centerHeader title={t('pitch.permissions')} />
          <Controller
            name="is_permission"
            control={control}
            rules={{ required: msgFieldRequired }}
            defaultValue={false}
            render={({ ref, onChange, value }) => (
              <Checkbox
                inputRef={ref}
                value={ref.current.checked}
                checked={Boolean(value)}
                onChange={(e) => {
                  onChange(e.target.checked);
                }}
                error={!!fieldErrors.is_permission}
                helperText={fieldErrors.is_permission?.message}
                disabled={isLoading}
              >
                {t('pitch.permissions_checkbox')}
              </Checkbox>
            )}
          />
        </>
      )}

      {buttons}
    </form>
  );
};
