import { DataAndLabelFormat } from '@Types';

export const dataAndLabelFormatToHighchartsDoughnut = (
  dataAndLabel: DataAndLabelFormat,
  displayCenteredTitle: boolean,
) => {
  let title = dataAndLabel.label;

  if (/^[0-9]+$/.test(dataAndLabel.label)) {
    title = parseFloat(title).toLocaleString('en');
  }

  const dataSet = dataAndLabel.data.map((projectData) => {
    return {
      name: projectData.label,
      y: projectData.value,
    };
  });
  return { dataSet, title, displayCenteredTitle };
};
