import { useCallback } from 'react';
import { useRequest } from '@Hooks/useRequest';
import { API } from '@Services';
import { ResponsesCountApiResponse } from '@Types';

export const useGetResponsesCount = () => {
  const [fetch, response] = useRequest<ResponsesCountApiResponse>();

  const getResponsesCount = useCallback(
    (fundId: number) => fetch(API.paths.survey_responses_count(fundId), 'get'),
    [fetch],
  );

  return [
    getResponsesCount,
    { ...response, data: response.data ? response.data.number_of_responses : undefined },
  ] as const;
};
