import React from 'react';
import styles from './DemoCTAButtons.module.scss';
import { Button } from '@Components/common';
import { useTranslation } from 'react-i18next';
import { RoutingPaths } from '@App/paths';

export const DemoCTAButtons = () => {
  const { t } = useTranslation('landingPage');

  return (
    <div className={styles.buttonContainer}>
      <Button buttonType="secondary" to={RoutingPaths.CONTACT}>
        {t('contact')}
      </Button>
      <Button buttonType="primary" to={RoutingPaths.REQUEST_DEMO}>
        {t('book_a_demo')}
      </Button>
    </div>
  );
};
