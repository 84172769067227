import React, { useEffect } from 'react';
import { MarketingSiteLinks, SembleLogo, UserAvatarMenuController } from '@Components';
import { Button, Link } from '@Components/common';
import styles from './Header.module.scss';
import { useAppLayoutContext, useUserContext } from '@Contexts';
import { Routes, RoutingPaths } from '@App/paths';
import classnames from 'classnames';
import { useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { useRouter, welcomeMessagei18nKey } from '@Helpers';
import { Trans, useTranslation } from 'react-i18next';
import { RiAddCircleFill } from 'react-icons/ri';

interface HeaderProps {
  publicLayout?: boolean;
}

export const Header = ({ publicLayout }: HeaderProps) => {
  const { headerButtons, setHeaderButtons } = useAppLayoutContext();
  const theme = useTheme();
  const screenXs = useMediaQuery(theme.breakpoints.down('xs'));
  const { userData, isUserLoading } = useUserContext();
  const { t } = useTranslation('dashboard');
  const canCreateProjects = userData?.can_create_projects;
  const canCreateFunds = userData?.can_create_funds;
  const { push } = useRouter();
  useEffect(() => {
    if (!screenXs && canCreateProjects) {
      setHeaderButtons(
        <Button
          buttonType="primary"
          startIcon={<RiAddCircleFill />}
          onClick={() => {
            push(RoutingPaths.CREATE_PROJECT);
          }}
        >
          {t('group.create_project_cta')}
        </Button>,
      );
    } else if (!screenXs && canCreateFunds) {
      setHeaderButtons(
        <Button buttonType="primary" startIcon={<RiAddCircleFill />} to={Routes.FUND_CREATE}>
          {t('funder.create_fund_cta')}
        </Button>,
      );
    }

    return () => {
      setHeaderButtons(undefined);
    };
  }, [setHeaderButtons, t, push, screenXs, canCreateProjects, canCreateFunds]);

  return (
    <div
      className={classnames(
        publicLayout && styles.publicHeader,
        styles.header,
        screenXs && styles.mobileHeader,
      )}
    >
      <div className={styles.content}>
        <div className={styles.linkWrapper}>
          {publicLayout ? (
            <>
              <Link to={Routes.MARKETING_SITE} name="logo" className={styles.logo}>
                <SembleLogo hideMobile />
              </Link>
              <MarketingSiteLinks className={styles.marketingLinks} />
            </>
          ) : (
            <>
              {!screenXs ? (
                <Trans
                  ns="dashboard"
                  i18nKey={welcomeMessagei18nKey()}
                  components={[<strong className={styles.welcomeName} />]}
                  values={{ name: userData?.organization_name }}
                />
              ) : (
                <SembleLogo hideMobile colorReverse />
              )}
            </>
          )}
        </div>
        <div className={styles.contentRight}>
          {headerButtons}
          <UserAvatarMenuController publicLayout={publicLayout} />
        </div>
      </div>
    </div>
  );
};
