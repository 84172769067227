import React, { useCallback } from 'react';
import classnames from 'classnames';
import NextLink from 'next/link';
import { useRouter } from '@Helpers/useRouter';
import isServer from '@Helpers/isServer';
import styles from './Link.module.scss';

interface LinkProps {
  to?: string;
  href?: string;
  primary?: boolean;
  secondary?: boolean;
  onClick?: () => void;
  name?: string;
  className?: string;
  disabled?: boolean;
  underline?: boolean;
  stopPropagation?: boolean;
}

export const Link: React.FC<LinkProps> = ({
  to,
  href,
  primary,
  secondary,
  onClick,
  children,
  name,
  className,
  disabled,
  underline,
  stopPropagation,
}) => {
  const { push } = useRouter();

  const handleClick = useCallback(
    (e) => {
      e.preventDefault();
      if (disabled) return;

      if (onClick) {
        if (stopPropagation) {
          e.stopPropagation();
        }
        onClick();
        return;
      }

      if (href && !isServer) {
        window.open(href, '_blank');
        return;
      }
      if (to || href) {
        push((to || href) as string);
        return;
      }
    },
    [disabled, onClick, href, to, stopPropagation, push],
  );

  return (
    <NextLink href={href || to || ''}>
      <a
        aria-label={name}
        onClick={handleClick}
        className={classnames(
          styles.link,
          primary && styles.primary,
          secondary && styles.secondary,
          disabled && styles.disabled,
          underline && styles.underline,
          className,
        )}
        tabIndex={0}
      >
        {children}
      </a>
    </NextLink>
  );
};
