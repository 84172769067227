import React from 'react';
import TextField, { TextFieldProps as MaterialTextFieldProps } from '@material-ui/core/TextField';
import { useErrorStateStyles } from '@Components/common/themeOverrides';

export type TextAreaProps = MaterialTextFieldProps;

export const TextArea: React.FC<TextAreaProps> = (props) => {
  const classes = useErrorStateStyles();

  return (
    <TextField
      variant="outlined"
      fullWidth
      type="text"
      {...props}
      classes={{ root: props.error ? classes.errorState : classes.nonError }}
      helperText={props.helperText}
      multiline
    />
  );
};
