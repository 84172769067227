import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { Button } from '@Components/common';

import styles from './Pagination.module.scss';
import { PaginatedResponse } from '@Hooks/usePaginatedRequest';
import { RiArrowLeftSLine, RiArrowRightSLine } from 'react-icons/ri';

export interface PaginationProps {
  paginatedResponse: PaginatedResponse<any>;
}

export const Pagination = ({ paginatedResponse }: PaginationProps) => {
  const { t } = useTranslation('common');
  const { moveToNextPage, moveToPreviousPage } = paginatedResponse;
  if (!(moveToNextPage || moveToPreviousPage)) {
    return <></>;
  }
  const { totalCount, paginationOffset, page } = paginatedResponse;
  // Start counting from 1 rather than 0.
  const pageStart = paginationOffset + 1;
  const pageEnd = paginationOffset + page.length;
  // We use a different translation if there's only one item,
  // as the normal text looks a bit odd in that case ('11 to 11 of 11').
  const hasSingleItem = pageStart == pageEnd;
  return (
    <div className={styles.paginationControls}>
      <div>
        {moveToPreviousPage && (
          <Button buttonType="tertiary" onClick={moveToPreviousPage}>
            <RiArrowLeftSLine /> {t('previous')}
          </Button>
        )}
      </div>
      <div>
        {hasSingleItem ? (
          <Trans ns="common" i18nKey="pagination_label_single" values={{ pageStart, totalCount }} />
        ) : (
          <Trans
            ns="common"
            i18nKey="pagination_label"
            values={{ pageStart, pageEnd, totalCount }}
          />
        )}
      </div>
      <div>
        {moveToNextPage && (
          <Button buttonType="tertiary" onClick={moveToNextPage}>
            {t('next')} <RiArrowRightSLine />
          </Button>
        )}
      </div>
    </div>
  );
};
