import { useRequest } from '@Hooks/useRequest';
import { API } from '@Services';
import { ProjectWrittenPitch, PitchTypesEnum, ProjectVideoPitch } from '@Types';
import { useCallback } from 'react';

export const useGetProjectPitch = () => {
  const [fetch, response] = useRequest<ProjectWrittenPitch | ProjectVideoPitch>();

  const getProjectPitch = useCallback(
    (projectId: number, pitchType: PitchTypesEnum) =>
      fetch(
        pitchType === PitchTypesEnum.WRITTEN
          ? API.paths.project_written_pitch(projectId)
          : API.paths.project_video_pitch(projectId),
        'get',
      ),
    [fetch],
  );

  return [getProjectPitch, response] as const;
};
