import React from 'react';
import classnames from 'classnames';
import { FormControl, FormHelperText, FormControlLabel } from '@material-ui/core';
import MaterialCheckbox, {
  CheckboxProps as MaterialCheckboxProps,
} from '@material-ui/core/Checkbox';
import CheckIcon from '@material-ui/icons/Check';
import styles from './Checkbox.module.scss';

interface CheckboxProps extends MaterialCheckboxProps {
  error?: boolean;
  helperText?: string;
}

export const Checkbox: React.FC<CheckboxProps> = ({ children, error, helperText, ...props }) => {
  return (
    <FormControl error={error}>
      <FormControlLabel
        label={<p className={styles.label}>{children}</p>}
        control={
          <MaterialCheckbox
            icon={<span className={styles.icon} />}
            checkedIcon={<CheckIcon className={classnames(styles.icon, styles.checkedIcon)} />}
            {...props}
          />
        }
      />
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
};
