import React, { ReactElement, useCallback, useEffect } from 'react';
import { Button, ButtonProps } from '@Components/common';
import { useCheckJobStatus } from '@Hooks/Api/useCheckJobStatus';
import { useInterval } from '@Hooks/useInterval';

interface DownloadButtonControllerProps extends ButtonProps {
  useStartJob: any;
  children: ReactElement;
  jobArgs: any;
  forceDisabled?: boolean;
  // Allow overriding the onClick behaviour in case custom actions are needed before
  // the download, for example validation.
  // Note that if set, this handler must call startJob or the download will not happen.
  beforeDownload?: (startJob: any) => void;
  // Allow actions to be triggered once a download is complete.
  afterDownload?: () => void;
}

export const DownloadButtonController = ({
  useStartJob,
  children,
  jobArgs,
  forceDisabled,
  beforeDownload,
  afterDownload,
  ...buttonProps
}: DownloadButtonControllerProps) => {
  const [
    startJob,
    { data: jobSetup, loading: isJobStarting, setResponseData: setStartJobResponse },
  ] = useStartJob();
  const [checkJobStatus, { data: jobStatus, setResponseData: setCheckJobStatusResponse }] =
    useCheckJobStatus();

  const jobId = jobSetup && jobSetup.job_id;
  let progressPercentage = undefined;
  let result = null;
  let downloadUrl: string | null = null;
  if (jobStatus) {
    progressPercentage = jobStatus.progress_percentage;
    result = jobStatus.result;
    if (jobStatus.result) {
      downloadUrl = jobStatus.result.download_url;
    }
  }

  useEffect(() => {
    // If jobArgs changed, that means any previous download is now irrelevant to our current parameters,
    // so reset our request responses back to null.
    setStartJobResponse(undefined);
    setCheckJobStatusResponse(undefined);
  }, [jobArgs, setStartJobResponse, setCheckJobStatusResponse]);

  useEffect(() => {
    if (downloadUrl) {
      window.location.href = downloadUrl;
      if (afterDownload) {
        afterDownload();
      }
    }
  }, [downloadUrl, afterDownload]);

  const isPolling = jobId && !downloadUrl;
  const isLoading = isPolling || isJobStarting;
  const isComplete = downloadUrl != null;
  const isDisabled = isLoading || isComplete || forceDisabled;
  useInterval(
    async () => {
      if (jobId) {
        // The conditional is just here for TypeScript -
        // jobId will always be set if this is running.
        await checkJobStatus(jobId);
      }
    },
    isPolling ? 2000 : null,
  );

  const startDownload = useCallback(() => {
    startJob(jobArgs);
  }, [startJob, jobArgs]);
  return (
    <Button
      progressPercentage={progressPercentage}
      loading={isLoading}
      disabled={isDisabled}
      onClick={
        beforeDownload
          ? () => {
              beforeDownload(startDownload);
            }
          : startDownload
      }
      {...buttonProps}
    >
      {children}
    </Button>
  );
};
