import { useCallback } from 'react';
import { useRequest } from '@Hooks';
import { API } from '@Services';
import { Match } from '@Types';

export const useRejectMatch = () => {
  const [fetch, response] = useRequest<Match>();

  const rejectMatch = useCallback(
    (matchId: number) => fetch(API.paths.reject_match(matchId), 'post'),
    [fetch],
  );

  return [rejectMatch, response] as const;
};
