import React from 'react';
import { useTranslation } from 'react-i18next';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import styles from './ViewButton.module.scss';

interface ViewButtonProps {
  onClick: () => void;
}

export const ViewButton: React.FC<ViewButtonProps> = ({ onClick, children }) => {
  const { t } = useTranslation('fund');

  return (
    <button className={styles.button} type="button" onClick={onClick}>
      <span>{t('report.view_all')}</span>
      <ArrowRightIcon className={styles.icon} />
    </button>
  );
};
