import { useCallback } from 'react';

import { useRequest } from '@Hooks';
import { API } from '@Services';

export const useDraftFund = () => {
  const [fetch, response] = useRequest<'' | undefined>();

  const draftFund = useCallback(
    (fundId: number) =>
      fetch(API.paths.funds_draft(fundId), 'patch', {
        data: null,
      }),
    [fetch],
  );

  return [draftFund, response] as const;
};
