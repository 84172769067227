import React, { useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core';
import GetAppIcon from '@material-ui/icons/GetApp';
import dynamic from 'next/dynamic';
import type { DocumentProps, PageProps } from 'react-pdf';
import { Loader } from '@Components';
import { Button } from '@Components/common';

import styleVariables from '@Styles/_variables.module.scss';
import styles from './PdfViewer.module.scss';

const options = {
  cMapUrl: 'cmaps/',
  cMapPacked: true,
};

const PdfDocument = dynamic<DocumentProps>(
  () =>
    (import('react-pdf') as any).then((mod: any) => {
      mod.pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${mod.pdfjs.version}/pdf.worker.js`;
      return mod.Document;
    }),
  {
    ssr: false,
  },
);

const PdfPage = dynamic<PageProps>(
  () => (import('react-pdf') as any).then((mod: any) => mod.Page),
  {
    ssr: false,
  },
);

interface PdfViewerProps {
  file: any;
  showDownload?: boolean;
  pageProps?: PageProps;
}

export const PdfViewer: React.FC<PdfViewerProps> = ({ file, showDownload, pageProps }) => {
  const { t } = useTranslation('common');
  const [pagesCount, setPagesCount] = useState(0);
  const [scale, setScale] = useState(1);
  const [isLoaded, setIsLoaded] = useState(false);

  const handleScaleUp = useCallback(() => {
    setScale(scale < 3 ? scale + 0.1 : scale);
  }, [scale]);

  const handleScaleDown = useCallback(() => {
    setScale(scale > 0.5 ? scale - 0.1 : scale);
  }, [scale]);

  const handleDocumentLoad = useCallback(({ numPages }) => {
    setIsLoaded(true);
    setPagesCount(numPages);
  }, []);

  if (!PdfDocument || !PdfPage) return null;

  return (
    <>
      <div className={styles.container}>
        <PdfDocument
          file={file}
          options={options}
          className={styles.viewer}
          loading={<Loader />}
          onLoadSuccess={handleDocumentLoad}
        >
          {Array.from({ length: pagesCount }, (_, index) => (
            <PdfPage pageNumber={index + 1} scale={scale} {...pageProps} />
          ))}
        </PdfDocument>
      </div>

      {isLoaded && (
        <div className={styles.scaleButtons}>
          <Button buttonType="tertiary" onClick={handleScaleDown}>
            -
          </Button>
          <Button buttonType="tertiary" onClick={handleScaleUp}>
            +
          </Button>

          {showDownload && (
            <div className={styles.download}>
              <GetAppIcon />
              <a href={file} target="_blank" rel="noreferrer" download>
                {t('download')}
              </a>
            </div>
          )}
        </div>
      )}
    </>
  );
};
