import classnames from 'classnames';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { Button } from '@Components/common';
import styles from './CTACard.module.scss';

interface CTACardProps {
  className?: string;
  text: string;
  buttonText: string;
  onCtaClick: () => void;
}

export const CTACard: React.VFC<CTACardProps> = ({ className, text, buttonText, onCtaClick }) => {
  return (
    <div className={classnames(styles.container, className)}>
      <div className={styles.contentWrapper}>
        <h6 className={styles.cardTitle}>{text}</h6>

        <Button
          buttonType="primary"
          type="button"
          startIcon={<AddCircleIcon />}
          onClick={onCtaClick}
        >
          {buttonText}
        </Button>
      </div>
    </div>
  );
};
