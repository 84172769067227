import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import { Button, TextInput } from '@Components/common';
import { msgFieldRequired, useRouter } from '@Helpers';
import { RoutingPaths } from '@App/paths';
import styles from './PasswordReset.module.scss';

interface PasswordResetProps {
  onSubmit: (e?: React.FormEvent<HTMLFormElement | HTMLButtonElement>) => Promise<void>;
  isSent: boolean;
  isLoading?: boolean;
}

export const PasswordReset: React.VFC<PasswordResetProps> = ({ onSubmit, isSent, isLoading }) => {
  const { t } = useTranslation('common');
  const { push } = useRouter();
  const { register, errors: fieldErrors } = useFormContext();

  const handleBackToSignIn = () => {
    push(RoutingPaths.SIGN_IN);
  };

  return (
    <form className={styles.container} onSubmit={onSubmit}>
      <h2 className={styles.header}>
        {t(isSent ? 'password_reset.reset_mail_sent_header' : 'password_reset.reset_page_header')}
      </h2>
      <p className={styles.text}>
        {t(isSent ? 'password_reset.reset_mail_sent_text' : 'password_reset.reset_page_text')}
      </p>
      {!isSent && (
        <TextInput
          label={t('password_reset.email_field_label')}
          type="email"
          inputRef={register({ required: msgFieldRequired })}
          name="email"
          error={!!fieldErrors.email}
          helperText={fieldErrors.email?.message}
          disabled={isLoading}
        />
      )}
      <div className={styles.buttonsBar}>
        <Button buttonType="tertiary" onClick={handleBackToSignIn} disabled={isLoading}>
          {t('password_reset.cancel_button')}
        </Button>
        {!isSent && (
          <Button buttonType="primary" onClick={onSubmit} disabled={isLoading} loading={isLoading}>
            {t('password_reset.reset_button')}
          </Button>
        )}
      </div>
    </form>
  );
};
