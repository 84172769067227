import { usePaginatedRequest } from '@Hooks/usePaginatedRequest';
import { API } from '@Services';
import { Fund, FundStatusEnum } from '@Types';
import { useCallback } from 'react';

export const useGetFundsForGroupMatches = () => {
  const [fetch, response] = usePaginatedRequest<Fund>({ perPage: 10 });

  const getFundsForGroupMatches = useCallback(
    (status?: FundStatusEnum[]) => {
      fetch(API.paths.funds_for_group_matches, {
        status: status ? status.join(',') : undefined,
      });
    },
    [fetch],
  );

  return [getFundsForGroupMatches, response] as const;
};
