import Head from 'next/head';

interface SocialHeadData {
  title?: string;
  type?: string;
  image?: string;
  url?: string;
  description?: string;
}

export const SocialHeadData = ({ title, type, image, url, description }: SocialHeadData) => {
  const truncatedDescription =
    description && description.length > 200 ? `${description.slice(0, 199)}…` : description;

  return (
    <Head>
      {/** Twitter */}
      <meta name="twitter:card" content="summary" />
      {/** Open Graph */}
      {title && <meta property="og:title" content={title} key="title" />}
      {type && <meta property="og:type" content={type} key="type" />}
      {image && <meta property="og:image" content={image} key="image" />}
      {url && <meta property="og:url" content={url} key="url" />}
      {truncatedDescription && (
        <meta property="og:description" content={truncatedDescription} key="description" />
      )}
    </Head>
  );
};
