import { useCallback, useRef, useState, useMemo } from 'react';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { formatDistanceToNow, parseISO } from 'date-fns';
import { UrlObject } from 'url';

import DeleteIcon from '@material-ui/icons/Delete';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';

import { Routes } from '@App/paths';
import {
  OrganizationAvatar,
  Menu,
  createMenuItems,
  CreateMenuItemsProps,
  Tooltip,
  ShareBar,
  ShareModal,
  ConfirmModal,
  VideoPlayer,
} from '@Components';
import { Link } from '@Components/common';
import { useElementWidth } from '@Hooks';
import { useRouter } from '@Helpers/useRouter';
import { cutText } from '@Helpers/cutText';
import { formatDate } from '@Helpers/formatDate';
import isServer from '@Helpers/isServer';
import { RoutingPaths, QueryParams, PathParams } from '@App/paths';
import { ProjectUpdateLike, User } from '@Types';

import styles from './UpdatesListItem.module.scss';
import { useProjectUpdateLike, useProjectUpdateUnlike } from '@Hooks/Api';

export const MIN_UPDATE_IMAGE_ASPECT_RATIO = 0.8;
const TRIM_LENGTH = 300;

interface UpdatesListItemProps {
  className?: string;
  id: number;
  image: string | null;
  video: string | null;
  description: string;
  createdAt: string;
  likes: ProjectUpdateLike[];
  likeCount: number;
  organizationId: number;
  organizationName: string;
  organizationAvatar?: string;
  projectId: number;
  projectName: string;
  onHideProjectUpdate?: (projectUpdateId: number) => void;
  onDeleteProjectUpdate?: (projectUpdateId: number) => void;
  onLike?: () => void;
  onUnlike?: () => void;
  showFullDescription?: boolean;
  user: User | undefined;
  onClick?: (updateId: number) => void;
}

export const UpdatesListItem = ({
  className,
  id,
  image,
  video,
  description,
  createdAt,
  likes,
  likeCount,
  organizationId,
  organizationName,
  organizationAvatar,
  projectId,
  projectName,
  onHideProjectUpdate,
  onDeleteProjectUpdate,
  onLike,
  onUnlike,
  showFullDescription,
  user,
  onClick,
}: UpdatesListItemProps) => {
  const { t } = useTranslation('projectUpdate');
  const menuAnchorRef = useRef(null);
  const imageRef = useRef<HTMLImageElement>(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isDeleteUpdateModalVisible, setDeleteUpdateModalActive] = useState(false);
  const [isHideUpdateModalVisible, setHideUpdateModalVisible] = useState(false);
  const [isButtonHovered, setIsButtonHovered] = useState(false);
  const [isShareModalOpen, setIsShareModalOpen] = useState(false);
  const { pathname, params, push, path, getSingleValueParam } = useRouter();
  const [likeProjectUpdate] = useProjectUpdateLike();
  const [unlikeProjectUpdate] = useProjectUpdateUnlike();
  let UPDATE_URL: string | undefined;

  if (!isServer) {
    let urlPath = '';
    if (pathname === RoutingPaths.FUND_MANAGEMENT || pathname === RoutingPaths.FUND_DETAILS) {
      urlPath = `/fund/${params[PathParams.FUND_ID]}`;
    } else if (pathname === RoutingPaths.FUNDER_PROFILE_PAGE) {
      urlPath = `/funder-profile/${params[PathParams.ORGANIZATION_ID]}`;
    } else if (pathname === RoutingPaths.PROJECT_DETAILS) {
      urlPath = Routes.PROJECT_DETAILS(getSingleValueParam(PathParams.PROJECT_ID));
    } else {
      urlPath = path;
    }

    UPDATE_URL = `${window.location.origin}${urlPath}?projectUpdate=${id}`;
  }

  const openMenu = useCallback(() => {
    setIsMenuOpen(true);
  }, []);
  const closeMenu = useCallback(() => {
    setIsMenuOpen(false);
  }, []);
  const openShare = useCallback(() => {
    setIsShareModalOpen(true);
  }, []);
  const closeShare = useCallback(() => {
    setIsShareModalOpen(false);
  }, []);
  const handleUpdateModalHide = useCallback(() => {
    setHideUpdateModalVisible(false);
  }, []);
  const handleUpdateHide = useCallback(() => {
    if (onHideProjectUpdate) {
      setHideUpdateModalVisible(false);
      onHideProjectUpdate(id);
    }
  }, [id, onHideProjectUpdate]);
  const handleUpdateModalDelete = useCallback(() => {
    setDeleteUpdateModalActive(false);
  }, []);
  const handleUpdateDelete = useCallback(() => {
    if (onDeleteProjectUpdate) {
      setDeleteUpdateModalActive(false);
      onDeleteProjectUpdate(id);
    }
  }, [id, onDeleteProjectUpdate]);

  const menuItems: CreateMenuItemsProps['items'] = useMemo(() => {
    const items = [];

    if (onDeleteProjectUpdate) {
      items.push({
        label: t('delete_update'),
        icon: <DeleteIcon />,
        menuItemProps: {
          onClick: () => {
            setIsMenuOpen(false);
            setDeleteUpdateModalActive(true);
          },
        },
      });
    }

    if (onHideProjectUpdate) {
      items.push({
        label: t('hide_update'),
        icon: <VisibilityOffIcon />,
        menuItemProps: {
          onClick: () => {
            setIsMenuOpen(false);
            setHideUpdateModalVisible(true);
          },
        },
      });
    }

    return items;
  }, [onHideProjectUpdate, onDeleteProjectUpdate, t]);

  const { elementWidth, handleResize } = useElementWidth({ elementRef: imageRef });

  const getPathName = useCallback(
    (path) => {
      switch (path) {
        case RoutingPaths.FUNDER_PROFILE_PAGE:
          return `/funder-profile/${params.organizationId}/updates`;
        case RoutingPaths.GROUP_PROFILE_PAGE:
          return `/group-profile/${params.organizationId}/updates`;
        case RoutingPaths.FUND_MANAGEMENT:
          return `/fund/${params.fundId}/manage/updates`;
        case RoutingPaths.FUND_DETAILS:
          return `/fund/${params.fundId}/updates`;
        case RoutingPaths.PROJECT_DETAILS:
          return Routes.PROJECT_DETAILS(getSingleValueParam(PathParams.PROJECT_ID));
        default:
          return null;
      }
    },
    [params, getSingleValueParam],
  );

  const openUpdateModal = useCallback(() => {
    if (onClick) {
      // By default we have this involved process of redirecting to the path with a projectUpdate parameter,
      // which causes the page to reload with a project update modal visible.
      // Allow the parent to avoid this mess if needed by just providing their own click handler.
      onClick(id);
      return;
    }
    const pushPathname = getPathName(pathname);
    const pushWrapper = (pathToPush: UrlObject) => {
      push(pathToPush, undefined, { shallow: false, scroll: false });
    };

    if (pushPathname) {
      pushWrapper({
        pathname: pushPathname,
        query: { [QueryParams.PROJECT_UPDATE]: id },
      });
    }
  }, [getPathName, id, pathname, push, onClick]);

  return (
    <div
      className={classnames(
        styles.container,
        className,
        isButtonHovered && styles.hovered,
        user !== undefined && styles.shareBarActive,
      )}
    >
      <div className={styles.header}>
        <OrganizationAvatar avatarImage={organizationAvatar} className={styles.avatar} />
        <div className={styles.headerTextWrapper}>
          <Link href={`/project/${projectId}`} className={styles.projectName}>
            {projectName}
          </Link>
          <span className={styles.headerSubtext}>
            {t('by')}
            <span className={styles.space} />
            <Link href={`/group-profile/${organizationId}`} className={styles.organizationName}>
              {organizationName}
            </Link>
            ,&nbsp;
            <Tooltip title={formatDate(createdAt)}>
              <span>{formatDistanceToNow(parseISO(createdAt), { addSuffix: true })}</span>
            </Tooltip>
          </span>
        </div>
        <Menu open={isMenuOpen} anchorEl={menuAnchorRef.current} onClose={closeMenu}>
          {createMenuItems({ items: menuItems })}
        </Menu>
        {menuItems.length > 0 && (
          <>
            <button
              onClick={openMenu}
              ref={menuAnchorRef}
              type="button"
              className={styles.actionButton}
            >
              <MoreVertIcon />
            </button>

            <Menu open={isMenuOpen} anchorEl={menuAnchorRef.current} onClose={closeMenu}>
              {createMenuItems({ items: menuItems })}
            </Menu>
          </>
        )}
      </div>
      <button
        type="button"
        onClick={openUpdateModal}
        onMouseEnter={() => {
          setIsButtonHovered(true);
        }}
        onMouseLeave={() => {
          setIsButtonHovered(false);
        }}
        className={styles.openModalButton}
      >
        {image && (
          <img
            src={image}
            alt=""
            className={styles.image}
            ref={imageRef}
            style={{
              maxHeight: elementWidth ? elementWidth / MIN_UPDATE_IMAGE_ASPECT_RATIO : '',
            }}
            onLoad={handleResize}
          />
        )}
        {video && <VideoPlayer videoUrl={video} />}
        <p className={styles.text}>
          {showFullDescription ? (
            description
          ) : (
            <>
              {cutText(description, TRIM_LENGTH)}
              {description.length > TRIM_LENGTH && (
                <span className={styles.more}>{t('common:more')}</span>
              )}
            </>
          )}
        </p>
      </button>
      <ShareBar
        likes={likes}
        likeCount={likeCount}
        onLike={() => {
          likeProjectUpdate(id);
          if (onLike) {
            onLike();
          }
        }}
        onShareClick={openShare}
        onUnlike={() => {
          unlikeProjectUpdate(id);
          if (onUnlike) {
            onUnlike();
          }
        }}
        user={user}
      />

      <ShareModal
        title={t('share_update')}
        shareUrl={UPDATE_URL}
        isOpen={isShareModalOpen}
        onClose={closeShare}
      />
      <ConfirmModal
        isOpen={isDeleteUpdateModalVisible}
        onClose={handleUpdateModalDelete}
        title={t('delete_update_modal.title')}
        cancelText={t('delete_update_modal.cancel')}
        onCancel={handleUpdateModalDelete}
        confirmText={t('delete_update_modal.confirm')}
        onConfirm={handleUpdateDelete}
      >
        {t('delete_update_modal.text')}
      </ConfirmModal>
      <ConfirmModal
        isOpen={isHideUpdateModalVisible}
        onClose={handleUpdateModalHide}
        title={t('hide_update_modal.title')}
        cancelText={t('hide_update_modal.cancel')}
        onCancel={handleUpdateModalHide}
        confirmText={t('hide_update_modal.confirm')}
        onConfirm={handleUpdateHide}
      >
        {t('hide_update_modal.text')}
      </ConfirmModal>
    </div>
  );
};
