import React, { Fragment } from 'react';
import { useTranslation, Trans } from 'react-i18next';

import AddCircleIcon from '@material-ui/icons/AddCircle';

import { ActionsSection, ProjectCardController, OldTabProps, Loader } from '@Components';
import { Button } from '@Components/common';
import { useUserContext } from '@Contexts';
import { Project } from '@Types';
import { useRouter } from '@Helpers/useRouter';
import { RoutingPaths } from '@App/paths';

import styles from './ProjectsSection.module.scss';

interface ProjectsSectionProps {
  name: string;
  projectsList?: Project[];
  isProjectsDataLoading: boolean;
  currentTab: number;
  tabs: OldTabProps[];
  viewAsList: boolean;
  onTabChange: (newTab: number) => void;
  archiveProject: (projectId: number) => unknown;
}

export const ProjectsSection: React.FC<ProjectsSectionProps> = ({
  name,
  projectsList,
  isProjectsDataLoading,
  currentTab,
  tabs,
  viewAsList,
  onTabChange,
  archiveProject,
}) => {
  const { t } = useTranslation('dashboard');
  const { push } = useRouter();
  const { userData } = useUserContext();
  const canCreateProjects = userData?.can_create_projects;

  if (isProjectsDataLoading) return <Loader />;

  const CtaButton = () => (
    <Button
      buttonType="primary"
      startIcon={<AddCircleIcon />}
      onClick={() => {
        push(RoutingPaths.CREATE_PROJECT);
      }}
    >
      {t('group.create_project_cta')}
    </Button>
  );

  return (
    <ActionsSection
      title={t('group.projects')}
      viewAsList={viewAsList}
      tabs={tabs}
      currentTab={currentTab}
      onTabChange={onTabChange}
      headerButton={
        <div className={styles.actionButtons}>
          {viewAsList && canCreateProjects && <CtaButton />}
        </div>
      }
    >
      {viewAsList &&
        projectsList &&
        projectsList.length > 0 &&
        projectsList.map((project) => (
          <ProjectCardController
            archiveProject={archiveProject}
            key={project.id}
            project={project}
          />
        ))}
      {viewAsList && projectsList?.length === 0 && (
        <span className={styles.noProjects}>{t('group.no_projects')}</span>
      )}
      {!viewAsList && canCreateProjects && (
        <Fragment>
          <div className={styles.introText}>
            <Trans
              ns="dashboard"
              i18nKey="group.create_project"
              values={{ name }}
              components={[<br />]}
            />
          </div>
          <CtaButton />
        </Fragment>
      )}
    </ActionsSection>
  );
};
