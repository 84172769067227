import { useEffect, useCallback } from 'react';
import Head from 'next/head';
import App, { AppContext } from 'next/app';
import type { AppProps } from 'next/app';
import { SocialHeadData, Analytics } from '@Components';
import { AppProviders } from '@App/AppProviders';
import { ConfigProvider } from '@App/ConfigLoader';
import { useRouter, getIsSubdomain } from '@Helpers';
import * as topLoader from '@Helpers/topLoader';

import 'nprogress/nprogress.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import '@Styles/index.scss';
// import 'stream-chat-react/dist/css/v2/index.css';

const MyApp = ({ Component, pageProps }: AppProps) => {
  useEffect(() => {
    // Remove the server-side injected CSS.
    const jssStyles = document.querySelector('#jss-server-side');
    if (jssStyles?.parentElement) {
      jssStyles.parentElement.removeChild(jssStyles);
    }
  }, []);

  const { events } = useRouter();

  const onLoadingStart = useCallback(() => {
    topLoader.start();
  }, []);

  const onLoadingEnd = useCallback(() => {
    topLoader.done();
  }, []);

  useEffect(() => {
    events.on('routeChangeStart', onLoadingStart);
    events.on('routeChangeComplete', onLoadingEnd);

    return () => {
      events.off('routeChangeStart', onLoadingStart);
      events.off('routeChangeComplete', onLoadingEnd);
    };
  }, [events, onLoadingEnd, onLoadingStart]);

  const isSubdomain = getIsSubdomain();

  return (
    <>
      <Head>
        <title>{`ActionFunder - Make Change Happen`}</title>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link
          rel="stylesheet"
          href="https://cdn.jsdelivr.net/npm/stream-chat-react/dist/css/v2/index.css"
        />
      </Head>
      <SocialHeadData
        title="ActionFunder - Make Change Happen"
        type="website"
        image="https://www.actionfunder.org/wp-content/uploads/2023/07/AF-placeholder-image-blue-large.png"
        url="https://www.actionfunder.org/"
        description="The place to give and get funding. ActionFunder matches businesses with community projects to make change happen, fast."
      />

      <ConfigProvider>
        <AppProviders>
          <Component {...pageProps} />
          <Analytics />
        </AppProviders>
      </ConfigProvider>
    </>
  );
};

// Runtime configuration won't be available to any page (or component in a page) without getInitialProps.
// https://nextjs.org/docs/api-reference/next.config.js/runtime-configuration
MyApp.getInitialProps = async (appContext: AppContext) => {
  // calls page's `getInitialProps` and fills `appProps.pageProps`
  const appProps = await App.getInitialProps(appContext);
  return { ...appProps };
};

export default MyApp;
