export const formatAgreementStatements = (
  statementQuestions: {
    label: string;
    answers: {
      [key: string]: {
        percentage: number;
        value: number;
      };
    };
  }[],
): {
  data: number;
  quote: string;
}[] => {
  const dataset: { data: number; quote: string }[] = statementQuestions.map(
    (statementQuestion, index) => {
      return {
        data: statementQuestion.answers['4'].percentage + statementQuestion.answers['5'].percentage,
        quote: statementQuestion.label,
      };
    },
  );
  return dataset.sort((a, b) => (b.data > a.data ? 1 : -1));
};
