import getHours from 'date-fns/getHours';
export const welcomeMessagei18nKey = () => {
  const currentHour = getHours(new Date());
  let welcomeMessage;

  if (currentHour >= 0 && currentHour < 12) {
    welcomeMessage = 'welcome_message.morning';
  } else if (currentHour >= 18 && currentHour < 24) {
    welcomeMessage = 'welcome_message.evening';
  } else {
    welcomeMessage = 'welcome_message.afternoon';
  }
  return welcomeMessage;
};
