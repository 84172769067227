import { useRequest } from '@Hooks/useRequest';
import { API } from '@Services';
import { SelectionsApiResponse } from '@Types';
import { useCallback } from 'react';

export const useGetProjectSelections = () => {
  const [fetch, response] = useRequest<SelectionsApiResponse>();

  const getProjectSelections = useCallback(
    (projectId: number) => {
      fetch(API.paths.project_selections(projectId), 'get');
    },
    [fetch],
  );

  return [
    getProjectSelections,
    { ...response, data: response.data ? response.data.results : [] },
  ] as const;
};
