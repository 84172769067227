import { useCallback, useEffect, useRef, useState } from 'react';
import styles from './DisplayOutput.module.scss';
import { Button } from '@Components/common';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import { ContentModal } from '@Components';
import { checkOverflow } from '@Helpers/checkOverflow';
import { IconButton } from '@Components/common/Button/IconButton';

interface DisplayOutputProps {
  items: {
    project_id: number;
    number: number;
    description: string;
    project_name: string;
    organization_name: string;
  }[];
  maxSteps: number;
}

export const DisplayOutput = ({ items, maxSteps }: DisplayOutputProps) => {
  const { t } = useTranslation('common');
  const [activeStep, setActiveStep] = useState(0);
  const [overflowActive, setOverflowActive] = useState(false);
  const overflowingText = useRef(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    if (checkOverflow(overflowingText.current)) {
      setOverflowActive(true);
      return;
    }
    setOverflowActive(false);
  }, [overflowActive, activeStep]);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleOpenModal = useCallback(() => {
    setIsModalOpen(true);
  }, []);

  const handleCloseModal = useCallback(() => {
    setIsModalOpen(false);
  }, []);

  return (
    <>
      <div className={styles.outputContainer}>
        <IconButton buttonType="text" onClick={handleBack} disabled={activeStep === 0}>
          <KeyboardArrowLeft />
        </IconButton>

        <div className={styles.itemContainer}>
          <p className={styles.number}>
            {t('whole_number', { amount: Number(items[activeStep].number) })}
          </p>
          <p className={classnames(styles.description, styles.clamp)} ref={overflowingText}>
            {items[activeStep].description}
          </p>
          {overflowActive ? (
            <button className={styles.readMoreButton} onClick={handleOpenModal}>
              {t('fund:report.read_more')}
            </button>
          ) : null}
          <p className={styles.name}>{items[activeStep].project_name}</p>
          <p className={styles.name}>{items[activeStep].organization_name}</p>
        </div>
        <IconButton buttonType="text" onClick={handleNext} disabled={activeStep === maxSteps - 1}>
          <KeyboardArrowRight />
        </IconButton>
      </div>
      <ContentModal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        title={t(`fund:report.titles.outputs`)}
      >
        <div className={styles.itemContainer}>
          <p className={styles.number}>
            {t('whole_number', { amount: Number(items[activeStep].number) })}
          </p>
          <p className={styles.description}>{items[activeStep].description}</p>
          <p className={styles.name}>{items[activeStep].project_name}</p>
          <p className={styles.name}>{items[activeStep].organization_name}</p>
        </div>
      </ContentModal>
    </>
  );
};
