import { useRequest } from '@Hooks/useRequest';
import { API } from '@Services';
import { GroupEligibilityForFund } from '@Types';
import { useCallback } from 'react';

export const useGetGroupEligibilityForFund = () => {
  const [fetch, response] = useRequest<GroupEligibilityForFund>();

  const getGroupEligibilityForFund = useCallback(
    (fundId: number) => fetch(API.paths.group_eligibility_for_fund(fundId), 'get'),
    [fetch],
  );

  return [getGroupEligibilityForFund, response] as const;
};
