import React from 'react';
import CloseIcon from '@material-ui/icons/Close';
import Modal from '@material-ui/core/Modal';
import styles from './ContentModal.module.scss';
import classnames from 'classnames';

interface ContentModalProps {
  isOpen: boolean;
  onClose: () => void;
  title?: string;
  wide?: boolean;
  keepMounted?: boolean;
}

export const ContentModal: React.FC<ContentModalProps> = ({
  isOpen,
  onClose,
  title,
  children,
  wide,
  keepMounted,
}) => {
  return (
    <Modal keepMounted={keepMounted} open={isOpen} className={styles.container} onClose={onClose}>
      <div className={classnames(styles.modal, wide && styles.wideModal)}>
        <div className={styles.header}>
          <h6 className={styles.title}>{title}</h6>
          <button type="button" onClick={onClose}>
            <CloseIcon />
          </button>
        </div>
        <div className={styles.content}>{children}</div>
      </div>
    </Modal>
  );
};
