import React, { useCallback } from 'react';
import styles from './ProfileCard.module.scss';
import { Grid } from '@material-ui/core';
import { Button, Tile } from '@Components/common';
import { useTranslation } from 'react-i18next';
import { useRouter } from 'next/router';
import { UserTypeEnum } from '@Types';
import { Routes, RoutingPaths } from '@App/paths';

interface IncompleteProfileCardProps {
  userType: UserTypeEnum;
}

export const IncompleteProfileCard = ({ userType }: IncompleteProfileCardProps) => {
  const { t } = useTranslation('common');
  const { push } = useRouter();

  const completeProfile = useCallback(() => {
    push(
      userType === UserTypeEnum.GROUP ? Routes.GROUP_PROFILE_CREATE : Routes.FUNDER_PROFILE_CREATE,
    );
  }, [push, userType]);

  return (
    <Tile className={styles.container}>
      <Grid container direction="column">
        <Grid item>
          <h3 className={styles.title}>{t('complete_profile_title')}</h3>
        </Grid>
        <Grid item>
          <p>{t('complete_profile_subtitle')}</p>
        </Grid>

        <Grid item className={styles.buttonContainer}>
          <Button buttonType="primary" onClick={completeProfile}>
            {t('setup_profile')}
          </Button>
        </Grid>
      </Grid>
    </Tile>
  );
};
