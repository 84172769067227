import { useCallback } from 'react';
import { useRequest } from '@Hooks';
import { API } from '@Services';
import { ApiErrorObject } from '@Types';

export const useResendVerificationEmail = (verifyKey?: string) => {
  const [fetch, response] = useRequest<ApiErrorObject | null>();

  const resendVerificationEmail = useCallback(
    () =>
      fetch(API.paths.resend_verifiaction_email, 'post', {
        data: { key: verifyKey },
      }),
    [fetch, verifyKey],
  );

  return [resendVerificationEmail, response] as const;
};
