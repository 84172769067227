import React from 'react';
import classnames from 'classnames';
import styles from './Content.module.scss';

interface ContentProps {
  size?: 'small' | 'large';
  className?: string;
}

export const Content: React.FC<ContentProps> = ({ size = 'large', className, children }) => {
  return (
    <div className={classnames(styles.container, size === 'small' && styles.small, className)}>
      {children}
    </div>
  );
};
