import { useCallback } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { Modal } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import { Button } from '@Components/common';
import { RoutingPaths } from '@App/paths';
import { useRouter } from '@Helpers/useRouter';
import * as storage from '@Helpers/storage';

import styles from './PopupModal.module.scss';

export const POPUP_CLOSED_STORAGE_KEY = 'isFundPagePopupShown';

interface PopupModalProps {
  isOpen: boolean;
  onClose?: () => void;
}

export const PopupModal = ({ isOpen, onClose }: PopupModalProps) => {
  const { t } = useTranslation('fund');
  const { push } = useRouter();

  const handleCtaClick = useCallback(() => {
    storage.setItem(POPUP_CLOSED_STORAGE_KEY, 'true');
    push(RoutingPaths.SIGNUP_GROUP);
  }, [push]);

  return (
    <Modal open={isOpen} className={styles.container}>
      <div className={styles.modal}>
        <Button buttonType="text" className={styles.closeBtn} onClick={onClose}>
          <CloseIcon />
        </Button>
        <h3 className={styles.header}>{t('details_page.popup_modal.header')}</h3>
        <span className={styles.text}>
          <Trans ns="fund" i18nKey="details_page.popup_modal.text" components={[<b />, <hr />]} />
        </span>
        <div className={styles.actionButtons}>
          <Button buttonType="primary" onClick={handleCtaClick}>
            {t('details_page.popup_modal.cta_button')}
          </Button>
        </div>
      </div>
    </Modal>
  );
};
