import React, { useState, useCallback } from 'react';

import { Button } from '@Components/common';
import { ShareModal } from '@Components';

interface ShareButtonProps {
  title: string;
  className?: string;
}

export const ShareButton = ({ title, className }: ShareButtonProps) => {
  const [isShareModalOpen, setIsShareModalOpen] = useState(false);

  const openShareModal = useCallback(() => {
    setIsShareModalOpen(true);
  }, []);

  const closeShareModal = useCallback(() => {
    setIsShareModalOpen(false);
  }, []);

  return (
    <>
      <Button buttonType="tertiary" className={className} onClick={openShareModal}>
        {title}
      </Button>

      <ShareModal title={title} isOpen={isShareModalOpen} onClose={closeShareModal} />
    </>
  );
};
