import React from 'react';
import getConfig from 'next/config';

import { createAxiosClient } from '@Services/Api';
import { AxiosInstance } from 'axios';
import GA4React from 'ga-4-react';
import { GA4ReactResolveInterface } from 'ga-4-react/dist/models/gtagModels';

export interface Config {
  API_URL: string;
  SSR_API_URL?: string;
  SENTRY_DSN: string;
  GA_KEY?: string;
  ENVIRONMENT?: string;
  GTM_CONTAINER_ID?: string;
  FACEBOOK_PIXEL_ID?: string;
  CHARITY_BASE_API_KEY?: string;
}

function noop() {}

const dullAnalytics: GA4ReactResolveInterface = {
  pageview: noop,
  event: noop,
  gtag: noop,
  ga: noop,
};

export const useConfigLoader = () => {
  const { publicRuntimeConfig: config } = getConfig() as {
    serverRuntimeConfig: {};
    publicRuntimeConfig: Config;
  };

  if (!config.API_URL) {
    throw new Error('[useConfigLoader] Missing api url');
  }

  const axiosClient = React.useRef<AxiosInstance>(createAxiosClient({ baseURL: config.API_URL }));
  const [analyticsInstance, setAnalyticsInstance] =
    React.useState<GA4ReactResolveInterface>(dullAnalytics);

  React.useEffect(() => {
    if (config.GA_KEY && config.GA_KEY !== '') {
      const ga4react = new GA4React(config.GA_KEY);
      ga4react.initialize().then(
        (ga4) => {
          setAnalyticsInstance(ga4);
        },
        (err) => {
          console.warn(err);
          setAnalyticsInstance(dullAnalytics);
        },
      );
    }
  }, [config]);

  if (!config || !axiosClient.current) {
    return null;
  }

  return { config, axiosClient: axiosClient.current, analyticsInstance };
};

export const ConfigContext = React.createContext<ReturnType<typeof useConfigLoader>>(null);

export const useAppConfig = () => {
  const configValue = React.useContext(ConfigContext);
  if (configValue === null) {
    throw new Error(`[Config Context] Incorrect usage - AppConfig is not loaded yet`);
  }

  return configValue;
};

export const ConfigProvider: React.FC = ({ children }) => {
  const contextController = useConfigLoader();

  return <ConfigContext.Provider value={contextController}>{children}</ConfigContext.Provider>;
};
