import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import CheckCircleIcon from '@material-ui/icons/CheckCircle';

import { StatusChip } from '@Components';
import { Button, ButtonProps } from '@Components/common';
import { useAppLayoutContext, useUserContext } from '@Contexts';
import { SelectionStatusEnum, UserTypeEnum, FundStatusEnum } from '@Types';
import { useRouter } from '@Helpers/useRouter';

import styles from './CtaButton.module.scss';

enum CTA_STATES_ENUM {
  FUND_FUNDER_ADMIN = 'FUND_FUNDER_ADMIN',
  FUND_FUNDER_NONADMIN = 'FUND_FUNDER_NONADMIN',
  FUND_GROUP_MATCHED = 'FUND_GROUP_MATCHED',
  FUND_GROUP_UNMATCHED = 'FUND_GROUP_UNMATCHED',
  FUND_UNLOGGED_USER = 'FUND_UNLOGGED_USER',
}

interface CtaButtonProps {
  headerRef: React.MutableRefObject<React.ReactNode>;
  fund: boolean;
  fundId: number;
  userCanManage: boolean;
  selectionId?: number;
  selectionStatus?: SelectionStatusEnum;
  status?: string;
  groupHasProjectMatched?: boolean;
}

export const CtaButton: React.FC<CtaButtonProps> = ({
  headerRef,
  fund,
  fundId,
  userCanManage,
  selectionId,
  selectionStatus,
  status,
  groupHasProjectMatched,
}) => {
  const [isBtnInView, setIsBtnInView] = useState(true);
  const [ctaState, setCtaState] = useState<CTA_STATES_ENUM>();
  const { setHeaderButtons } = useAppLayoutContext();
  const { t: tFund } = useTranslation('fund');
  const { userData } = useUserContext();
  const { push } = useRouter();

  const isBtnPrimary = ctaState !== CTA_STATES_ENUM.FUND_FUNDER_ADMIN;

  const handleScroll = useCallback(() => {
    const { bottom } = (headerRef.current as HTMLElement)?.getBoundingClientRect() || {};

    if (!bottom) return;

    if (bottom < 80 && isBtnInView) {
      setIsBtnInView(false);
    } else if (bottom >= 80 && !isBtnInView) {
      setIsBtnInView(true);
    }
  }, [headerRef, isBtnInView]);

  useEffect(() => {
    if (window) {
      window.addEventListener('scroll', handleScroll, { passive: true });

      return () => window.removeEventListener('scroll', handleScroll);
    }
  }, [handleScroll]);

  useEffect(() => {
    if (userData) {
      if (userData.user_type === UserTypeEnum.FUNDER) {
        if (userCanManage) {
          setCtaState(CTA_STATES_ENUM.FUND_FUNDER_ADMIN);
        } else {
          setCtaState(CTA_STATES_ENUM.FUND_FUNDER_NONADMIN);
        }
      } else {
        if (groupHasProjectMatched) {
          setCtaState(CTA_STATES_ENUM.FUND_GROUP_MATCHED);
        } else if (status === tFund(`fund_status.${FundStatusEnum.LIVE}`)) {
          setCtaState(CTA_STATES_ENUM.FUND_GROUP_UNMATCHED);
        }
      }
    } else if (status === tFund(`fund_status.${FundStatusEnum.LIVE}`)) {
      setCtaState(CTA_STATES_ENUM.FUND_UNLOGGED_USER);
    }
  }, [
    fund,
    userCanManage,
    groupHasProjectMatched,
    userData,
    selectionId,
    selectionStatus,
    status,
    tFund,
  ]);

  const mapCtaAttributes = useMemo(
    () => ({
      [CTA_STATES_ENUM.FUND_FUNDER_ADMIN]: {
        ctaText: tFund('details_page.manage_fund'),
        onClick: () => {
          push(`/fund/${fundId}/manage`);
        },
      },
      [CTA_STATES_ENUM.FUND_GROUP_UNMATCHED]: {
        ctaText: tFund('details_page.create_project_to_apply'),
        onClick: () => {
          push('/project/create');
        },
      },
      [CTA_STATES_ENUM.FUND_UNLOGGED_USER]: {
        ctaText: tFund('details_page.sign_up_to_apply'),
        onClick: () => {
          push('/sign-up/group');
        },
      },
    }),
    [fundId, push, tFund],
  );

  const ButtonWithProps = useCallback(
    ({ className, ...props }: ButtonProps) => {
      if (
        ctaState &&
        ctaState !== CTA_STATES_ENUM.FUND_FUNDER_NONADMIN &&
        ctaState !== CTA_STATES_ENUM.FUND_GROUP_MATCHED
      ) {
        const ctaAttributes: { ctaText: string; onClick: () => void; startIcon?: JSX.Element } =
          ctaState && mapCtaAttributes[ctaState];

        return (
          <Button
            className={className}
            onClick={ctaAttributes.onClick}
            startIcon={ctaAttributes.startIcon}
            aria-label={fund ? 'fund-page-cta-button' : 'project-page-cta-button'}
            {...props}
          >
            {ctaAttributes.ctaText}
          </Button>
        );
      }

      return null;
    },
    [ctaState, mapCtaAttributes, fund],
  );

  useEffect(() => {
    setHeaderButtons(
      isBtnInView ? undefined : (
        <ButtonWithProps buttonType={isBtnPrimary ? 'primary' : 'tertiary'} />
      ),
    );

    return () => {
      setHeaderButtons(undefined);
    };
  }, [setHeaderButtons, ButtonWithProps, isBtnInView, isBtnPrimary]);

  return (
    <>
      <ButtonWithProps buttonType={isBtnPrimary ? 'primary' : 'tertiary'} />
      {ctaState === CTA_STATES_ENUM.FUND_GROUP_MATCHED && (
        <StatusChip
          color="primary"
          label={
            <span className={styles.chipLabel}>
              <CheckCircleIcon style={{ fontSize: '22px' }} />
              <span>{tFund('details_page.matched')}</span>
            </span>
          }
        />
      )}
    </>
  );
};
