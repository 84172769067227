import { useCallback } from 'react';
import { useRequest } from '@Hooks';
import { API } from '@Services';

export const useFundInvitesProject = () => {
  const [fetch, response] = useRequest();

  const fundInvitesProject = useCallback(
    (fundId: number, selectionId: number) =>
      fetch(API.paths.fund_invites_project(fundId, selectionId), 'patch', {
        data: null,
      }),
    [fetch],
  );

  return [fundInvitesProject, response] as const;
};
