import React, { ReactElement } from 'react';
import classnames from 'classnames';
import { PageTitle } from '@Components';
import { Stepper, StepperProps } from '@Components/common';
import styles from './CreateActionWrapper.module.scss';

interface CreateActionWrapperProps {
  currentStep: StepperProps['activeStep'];
  steps: StepperProps['steps'];
  header?: string;
  subheader?: string | ReactElement;
  childrenWrapperClassName?: string;
}

export const CreateActionWrapper: React.FC<CreateActionWrapperProps> = ({
  currentStep = 0,
  steps,
  children,
  header,
  subheader,
  childrenWrapperClassName,
}) => {
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <Stepper steps={steps} activeStep={currentStep} className={styles.stepper} />
      </div>
      {header && <PageTitle className={styles.content} title={header} subtitle={subheader} />}
      <div className={classnames(styles.content, childrenWrapperClassName)}>{children}</div>
    </div>
  );
};
