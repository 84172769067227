import React from 'react';
import { useTranslation } from 'react-i18next';
import { Divider, List, ListItem, ListItemAvatar, ListItemText, Modal } from '@material-ui/core';

import CloseIcon from '@material-ui/icons/Close';

import { ProjectUpdateLike, User, UserTypeEnum } from '@Types';
import styleVariables from '@Styles/_variables.module.scss';
import styles from './LikeListModal.module.scss';
import { OrganizationAvatar } from '@Components';
import { IconButton } from '@Components/common/Button/IconButton';

export const LikeListModal = ({
  isOpen,
  likes,
  title,
  onClose,
}: {
  isOpen: boolean;
  likes: ProjectUpdateLike[];
  title: string;
  onClose: () => void;
}) => {
  return (
    <Modal
      open={isOpen}
      className={styles.container}
      style={{ zIndex: Number(styleVariables.zIndexShareModal) }}
      onClose={onClose}
    >
      <div className={styles.modal}>
        <h6 className={styles.title}>{title}</h6>
        <IconButton buttonType="text" className={styles.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
        <List className={styles.list}>
          {likes.map((like, i) => {
            return (
              <>
                <ListItem alignItems="flex-start" key={i}>
                  <ListItemAvatar>
                    <OrganizationAvatar
                      avatarImage={like.user.organization_avatar}
                      size={'small'}
                    />
                  </ListItemAvatar>
                  <ListItemText
                    primary={
                      <a
                        className={styles.organisationName}
                        href={
                          like.user.user_type === UserTypeEnum.FUNDER
                            ? `/funder-profile/${like.user.organization_id}`
                            : `/group-profile/${like.user.organization_id}`
                        }
                      >
                        {like.user.organization_name}
                      </a>
                    }
                    secondary={like.user.name}
                  />
                </ListItem>
                {i !== likes.length - 1 && <Divider component="li" />}
              </>
            );
          })}
        </List>
      </div>
    </Modal>
  );
};
