// @ts-nocheck
import { useCallback } from 'react';
import { useRequest } from '@Hooks';
import { API } from '@Services';
import { Project, CreateMessage } from '@Types';

export const useSendMessageForGroup = () => {
  const [fetch, response] = useRequest<Project>();

  const sendMessage = useCallback(
    (data: CreateMessage) => {
      let finalData = {
        subject: data.subject,
        message: data.message,
        recipient: data.recipient,
      };

      fetch(API.paths.send_message, 'post', {
        data: finalData,
      });
    },
    [fetch],
  );

  return [sendMessage, response] as const;
};
