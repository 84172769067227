import React from 'react';
import {
  MenuItem as MaterialMenuItem,
  MenuItemProps as MaterialMenuItemProps,
} from '@material-ui/core';
import { createStyles, withStyles } from '@material-ui/core/styles';
import styleVariables from '@Styles/_variables.module.scss';
import styles from './MenuItems.module.scss';

const StyledMenuItem = withStyles(() =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      lineHeight: 1,
      padding: '12px 16px',
      color: styleVariables.colorT2,
      fontSize: '16px',
      fontFamily: 'inherit',
    },
  }),
)(MaterialMenuItem);

export interface CreateMenuItemsProps {
  items: {
    icon?: JSX.Element;
    label: JSX.Element | string;
    menuItemProps?: Omit<MaterialMenuItemProps, 'button'>;
  }[];
}

export const createMenuItems = ({ items }: CreateMenuItemsProps) => {
  return items.map(({ icon, label, menuItemProps }, i) => (
    <StyledMenuItem key={i} className={styles.item} {...menuItemProps}>
      {icon}
      {label}
    </StyledMenuItem>
  ));
};
