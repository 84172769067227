import React, { useCallback, useState } from 'react';
import { ContentModal } from '@Components';
import styles from './BeneficiarySplit.module.scss';
import styleVariables from '@Styles/_variables.module.scss';
import { TitledTile } from '@Components/common/TitledTile/TitledTile';
import { Trans, useTranslation } from 'react-i18next';
import { Button } from '@Components/common';
import { createStyles, LinearProgress, withStyles } from '@material-ui/core';

interface BeneficiarySplitProps {
  title: string;
  beneficiarySplit:
    | { name: string; label: string; percentage: number; response_count: number }[]
    | { name: string; label: string; percentage: number; count: number }[];
}

const StyledProgressBar = withStyles(() =>
  createStyles({
    root: {
      height: '40px',
      width: '100%',
    },
    colorPrimary: {
      backgroundColor: styleVariables.colorT2Paler,
    },
    barColorPrimary: {
      backgroundColor: styleVariables.colorP2,
    },
  }),
)(LinearProgress);

export const BeneficiarySplit = ({ title, beneficiarySplit }: BeneficiarySplitProps) => {
  const { t } = useTranslation('fund');

  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = useCallback(() => {
    setIsModalOpen(true);
  }, []);

  const handleCloseModal = useCallback(() => {
    setIsModalOpen(false);
  }, []);

  beneficiarySplit.sort((b, a) => a.percentage - b.percentage);

  return (
    <TitledTile title={title}>
      <div className={styles.tileContainer}>
        {beneficiarySplit.slice(0, 4).map((beneficiary, index) => (
          <div className={styles.container} key={index}>
            <p className={styles.name}>{beneficiary.label}</p>
            <StyledProgressBar
              color="primary"
              variant="determinate"
              value={beneficiary.percentage}
            />
            <Trans
              ns="fund"
              i18nKey="report.beneficiary_label"
              values={{ percentage: beneficiary.percentage }}
            />
          </div>
        ))}
        {beneficiarySplit.length > 4 && (
          <Button buttonType="tertiary" onClick={handleOpenModal} className={styles.showMoreBtn}>
            {t(`report.view_all`)}
          </Button>
        )}
      </div>

      {beneficiarySplit.length > 4 && (
        <ContentModal isOpen={isModalOpen} onClose={handleCloseModal} title={title}>
          <div className={styles.meterContainer}>
            {beneficiarySplit.map((beneficiary, index) => (
              <div className={styles.container} key={index}>
                <p className={styles.name}>{beneficiary.label}</p>
                <StyledProgressBar
                  color="primary"
                  variant="determinate"
                  value={beneficiary.percentage}
                />
                <Trans
                  ns="fund"
                  i18nKey="report.beneficiary_label"
                  values={{ percentage: beneficiary.percentage }}
                />
              </div>
            ))}
          </div>
        </ContentModal>
      )}
    </TitledTile>
  );
};
