export enum ErrorCode {
  AUTHENTICATION_FAILED = 'AUTHENTICATION_FAILED',
  NOT_AUTHENTICATED = 'NOT_AUTHENTICATED',
  PERMISSION_DENIED = 'PERMISSION_DENIED',
  NOT_FOUND = 'NOT_FOUND',
  METHOD_NOT_ALLOWED = 'METHOD_NOT_ALLOWED',
  NOT_ACCEPTABLE = 'NOT_ACCEPTABLE',
  UNSUPPORTED_MEDIA_TYPE = 'UNSUPPORTED_MEDIA_TYPE',
  THROTTLED = 'THROTTLED',
  SERVER_ERROR = 'SERVER_ERROR',
  TWO_FACTOR_CODE_INVALID = 'TWO_FACTOR_CODE_INVALID',
  TOO_MANY_VERIFICATIONS = 'TOO_MANY_VERIFICATIONS',
}

// A list of errors for a single field
export type FieldErrorList = string[];

// An object storing errors for a set of fields,
// which maybe be nested in order to group fields.
export interface FieldErrors {
  [key: string]: FieldErrorList | FieldErrors;
}

export interface ApiErrorObject {
  code: keyof typeof ErrorCode;
  detail?: string[];
  field_errors?: FieldErrors;
}
