// @ts-nocheck
import { useCallback } from 'react';
import { useRequest } from '@Hooks';
import { API } from '@Services';
import { Project, UpdateMessageForFunder } from '@Types';

export const useUpdateMessageForFunder = () => {
  const [fetch, response] = useRequest<Project>();

  const sendMessage = useCallback(
    (data: UpdateMessageForFunder) => {
      let finalData = {
        channel_id: data.channel_id,
        subject: data.subject,
        members: data.members,
      };

      if (data.label) {
        finalData['label'] = data.label;
      }

      fetch(API.paths.update_message, 'patch', {
        data: finalData,
      });
    },
    [fetch],
  );

  return [sendMessage, response] as const;
};
