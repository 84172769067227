import React, { Fragment } from 'react';
import {
  Accordion as MaterialAccordion,
  AccordionProps as MaterialAccordionProps,
  AccordionSummary as MaterialAccordionSummary,
  AccordionDetails as MaterialAccordionDetails,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ErrorIcon from '@material-ui/icons/Error';
import styles from './Accordion.module.scss';

export interface AccordionProps {
  items: {
    errors?: Boolean;
    title: React.ReactNode;
    content: React.ReactNode;
    accordionProps?: MaterialAccordionProps;
  }[];
}

export const Accordion: React.FC<AccordionProps> = ({ items }) => {
  return (
    <Fragment>
      {items.map(({ errors, title, content, accordionProps = {} }, i) => {
        return (
          <MaterialAccordion key={i} elevation={0} {...accordionProps}>
            <MaterialAccordionSummary expandIcon={<ExpandMoreIcon />}>
              {errors ? (
                <div className={styles.errorSummary}>
                  <span>{title}</span> <ErrorIcon color="error" fontSize="small" />
                </div>
              ) : (
                title
              )}
            </MaterialAccordionSummary>
            <MaterialAccordionDetails>{content}</MaterialAccordionDetails>
          </MaterialAccordion>
        );
      })}
    </Fragment>
  );
};
