import { useCallback } from 'react';

import { useRequest } from '@Hooks';
import { API } from '@Services';
import { ApiErrorObject } from '@Types';

export const useJoinCampaign = () => {
  const [fetch, response] = useRequest<'' | ApiErrorObject>();

  const joinCampaign = useCallback(
    (organizationId: number, campaignCode: string) =>
      fetch(API.paths.join_campaign(organizationId), 'patch', {
        data: {
          affiliate_code: campaignCode,
        },
      }),
    [fetch],
  );

  return [joinCampaign, response] as const;
};
