import { useEffect } from 'react';
import styles from './GroupEligibilityInformation.module.scss';
import { RiMapPin2Fill, RiHeartsFill, RiStarFill, RiCloseCircleFill } from 'react-icons/ri';
import { useTranslation } from 'react-i18next';
import { StatusChip, Tooltip } from '@Components';
import { GroupEligibilityForFund, GroupTypes } from '@Types';
import { useCategories } from '@Hooks/Api';
import { Link } from '@Components/common';
import { useUserContext } from '@Contexts';
import { getHelpCenterURL } from '@Helpers/helpCenter';
import { HelpCenterRoutes } from '@App/paths';
export interface GroupEligibilityInformationProps {
  groupEligibilityForFundData?: GroupEligibilityForFund;
  fundLocation: JSX.Element;
  categoryIDs: number[];
  groupTypes: GroupTypes[];
}

export const GroupEligibilityInformation = ({
  groupEligibilityForFundData,
  fundLocation,
  groupTypes,
  categoryIDs,
}: GroupEligibilityInformationProps) => {
  const { t } = useTranslation('fundingOpportunities');
  const groupEligibilityCriteria: {
    chip: any;
    label: string;
    icon: JSX.Element;
  }[] = [];
  const { userData } = useUserContext();

  const matchCriteriaURL = userData
    ? getHelpCenterURL({
        path: HelpCenterRoutes.MATCH_CRITERIA,
        user: userData,
      })
    : undefined;

  const [getCategories, { data: categoriesList, loading: isCategoriesListLoading }] =
    useCategories(true);

  useEffect(() => {
    getCategories();
  }, [getCategories]);

  const formattedCategories =
    !isCategoriesListLoading && categoriesList
      ? categoriesList.reduce((acc: string[], { id, name }) => {
          if (categoryIDs.find((categoryId) => id === categoryId)) {
            return [...acc, name];
          }
          return acc;
        }, [])
      : [];

  // If any of the matched criteria are false then display that fund criteria to the group
  if (!groupEligibilityForFundData?.matched_location) {
    groupEligibilityCriteria.push({
      label: t('location'),
      icon: <RiMapPin2Fill size={14} />,
      chip: (
        <StatusChip
          className={styles.DangerChip}
          icon={<RiCloseCircleFill size={16} className={styles.DangerIcon} />}
          label={fundLocation}
        />
      ),
    });
  }
  if (!groupEligibilityForFundData?.matched_categories) {
    groupEligibilityCriteria.push({
      label: t('categories'),
      icon: <RiStarFill size={14} />,
      chip:
        formattedCategories.length > 3 ? (
          <>
            {formattedCategories.slice(0, 3).map((category, i) => (
              <StatusChip
                className={styles.DangerChip}
                icon={<RiCloseCircleFill size={16} className={styles.DangerIcon} />}
                label={category}
                key={i}
              />
            ))}
            <Tooltip title={formattedCategories.slice(3).join(' · ')}>
              <span>
                <StatusChip
                  className={styles.DangerChip}
                  icon={<RiCloseCircleFill size={16} className={styles.DangerIcon} />}
                  label={t('extra_categories', { count: formattedCategories.length - 3 })}
                />
              </span>
            </Tooltip>
          </>
        ) : (
          <>
            {formattedCategories.map((category, i) => (
              <StatusChip
                className={styles.DangerChip}
                icon={<RiCloseCircleFill size={16} className={styles.DangerIcon} />}
                label={category}
                key={i}
              />
            ))}
          </>
        ),
    });
  }
  if (!groupEligibilityForFundData?.matched_group_type) {
    groupEligibilityCriteria.push({
      label: t('group_type'),
      icon: <RiHeartsFill size={14} />,
      chip: groupTypes.map((groupType, i) => {
        return (
          <StatusChip
            className={styles.DangerChip}
            key={i}
            icon={<RiCloseCircleFill size={16} className={styles.DangerIcon} />}
            label={t(`group_types.${groupType}`)}
          />
        );
      }),
    });
  }

  return (
    <div className={styles.container}>
      {groupEligibilityCriteria.map(({ label, icon, chip }, i) => {
        return (
          <div key={i} className={styles.criteriaContainer}>
            {icon}
            {label}
            {chip}
          </div>
        );
      })}
      <Link underline stopPropagation to={matchCriteriaURL} className={styles.eligibilityHelpLink}>
        {t('eligibility_help_text')}
      </Link>
    </div>
  );
};
