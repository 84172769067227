import { useCallback } from 'react';
import { useRequest } from '@Hooks';
import { API } from '@Services';
import { Fund, FundFormStep1 } from '@Types';

export const useCreateFundDetails = () => {
  const [fetch, response] = useRequest<Fund>();

  const createFundDetails = useCallback(
    (fundDetails: FundFormStep1) =>
      fetch(API.paths.funds, 'post', {
        data: { details: fundDetails, additional_services: null },
      }),
    [fetch],
  );

  return [createFundDetails, response] as const;
};
