import React from 'react';
import { Avatar } from '@Components/common';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import styleVariables from '@Styles/_variables.module.scss';
import { useUserContext } from '@Contexts';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      width: '48px',
      height: '48px',
      fontSize: '16px',
      textTransform: 'uppercase',
    },
    fallback: {
      width: '50%',
      height: '50%',
    },
    colorDefault: {
      color: styleVariables.colorP2,
      backgroundColor: styleVariables.colorP2Light,
      backgroundPosition: 'center center',
      backgroundSize: 'cover',
    },
  }),
);

export const UserAvatar: React.FC = ({ children }) => {
  const classes = useStyles();
  const { userData } = useUserContext();

  const organizationAvatar = userData?.organization_avatar;
  const styles: React.CSSProperties = {};
  if (organizationAvatar) {
    styles.backgroundImage = `url(${organizationAvatar})`;
  }

  return (
    <Avatar classes={classes} style={styles}>
      {!organizationAvatar && children}
    </Avatar>
  );
};
