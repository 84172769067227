import { useEffect } from 'react';
import { analytics } from '@Helpers/analytics';
import { useConfigLoader } from '@App/ConfigLoader';

export const Analytics = () => {
  const { config } = useConfigLoader() || {};
  useEffect(() => {
    if (config?.FACEBOOK_PIXEL_ID || config?.GTM_CONTAINER_ID) {
      analytics({ gtmContainerId: config?.GTM_CONTAINER_ID, pixelId: config?.FACEBOOK_PIXEL_ID });
    }
  }, [config?.FACEBOOK_PIXEL_ID, config?.GTM_CONTAINER_ID]);

  return null;
};
