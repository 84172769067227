import { useCallback } from 'react';
import { useRequest } from '@Hooks/useRequest';
import { API } from '@Services';
import { JobSubmissionResponse, FundPreviewDataParams } from '@Types';

export interface FundPreviewDataExportProps
  extends Omit<FundPreviewDataParams, 'name' | 'starting_amount'> {}

export const useFundPreviewDataExport = () => {
  const [fetch, response] = useRequest<JobSubmissionResponse>();

  const doExport = useCallback(
    (data: FundPreviewDataExportProps) => {
      fetch(API.paths.fund_preview_data_export, 'put', {
        data,
      });
    },
    [fetch],
  );

  return [doExport, response] as const;
};
