import { useCallback } from 'react';
import { useRequest } from '@Hooks';
import { API } from '@Services';
import { Project, ProjectDetails } from '@Types';

export const useCreateProjectDetails = () => {
  const [fetch, response] = useRequest<Project>();

  const createProjectDetails = useCallback(
    (details: ProjectDetails) =>
      fetch(API.paths.projects, 'post', {
        data: { details, delivery_plan: null, written_pitch: null, video_pitch: null },
      }),
    [fetch],
  );

  return [createProjectDetails, response] as const;
};
