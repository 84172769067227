import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Grid } from '@material-ui/core';
import PeopleIcon from '@material-ui/icons/People';
import HeartIcon from '@material-ui/icons/Favorite';
import StarIcon from '@material-ui/icons/Star';
import ArrowUpIcon from '@material-ui/icons/ArrowUpward';
import EmojiPeople from '@material-ui/icons/EmojiPeople';
import styles from './ReportBasicInfo.module.scss';
import classnames from 'classnames';
import { TitledTile } from '@Components/common/TitledTile/TitledTile';
import { percentageChange } from '@Helpers/calculatePercentageChange';

interface ReportBasicInfoProps {
  beneficiaries: number;
  estimatedBeneficiaries: number;
  averageFunderRating: number;
  volunteers: number;
  funder_volunteers: number;
  funder_volunteer_hours: number;
}

export const ReportBasicInfo = ({
  beneficiaries,
  averageFunderRating,
  volunteers,
  estimatedBeneficiaries,
  funder_volunteers,
  funder_volunteer_hours,
}: ReportBasicInfoProps) => {
  const { t } = useTranslation('fund');

  const showFunderVolunteers = funder_volunteers != 0 && funder_volunteer_hours != 0;

  let beneficiariesIncrease = 0;
  if (beneficiaries > estimatedBeneficiaries && estimatedBeneficiaries > 0) {
    beneficiariesIncrease = Math.round(percentageChange(estimatedBeneficiaries, beneficiaries));
  }

  return (
    <TitledTile title={t('report.titles.key_results')}>
      <Grid container spacing={3} className={styles.container}>
        <Grid item className={styles.iconWrapper}>
          <div className={classnames(styles.heartIcon, styles.iconBackground)}>
            <HeartIcon style={{ fontSize: '42px' }} />
          </div>
          <div className={styles.iconText}>
            <Trans
              ns="fund"
              i18nKey="report.actual_beneficiaries"
              components={[<div />, <strong />]}
              values={{ amount: beneficiaries }}
            />
            {beneficiariesIncrease > 0 && (
              <div className={styles.subTextContainer}>
                <Trans
                  ns="fund"
                  i18nKey="report.percentage_increase"
                  components={[
                    <span className={styles.percentageNumber}></span>,
                    <ArrowUpIcon className={styles.percentageIcon} />,
                    <span className={styles.percentageText}></span>,
                  ]}
                  values={{ percentage: beneficiariesIncrease }}
                />
              </div>
            )}
          </div>
        </Grid>
        {showFunderVolunteers ? (
          <Grid item className={styles.iconWrapper}>
            <div className={classnames(styles.peopleIcon, styles.iconBackground)}>
              <EmojiPeople style={{ fontSize: '42px' }} />
            </div>
            <div className={styles.iconText}>
              <Trans
                ns="fund"
                i18nKey="report.funder_volunteer_hours_given"
                components={[<div />, <strong />]}
                values={{ amount: funder_volunteer_hours }}
              />
              <div className={styles.subTextContainer}>
                <Trans
                  ns="fund"
                  i18nKey="report.funder_volunteers_engaged"
                  components={[<span className={styles.bold} />]}
                  values={{ amount: funder_volunteers }}
                />
              </div>
            </div>
          </Grid>
        ) : (
          <Grid item className={styles.iconWrapper}>
            <div className={classnames(styles.peopleIcon, styles.iconBackground)}>
              <PeopleIcon style={{ fontSize: '42px' }} />
            </div>
            <div className={styles.iconText}>
              <Trans
                ns="fund"
                i18nKey="report.volunteers_engaged"
                components={[<div />, <strong />]}
                values={{ amount: volunteers }}
              />
            </div>
          </Grid>
        )}

        <Grid item className={styles.iconWrapper}>
          <div className={classnames(styles.starIcon, styles.iconBackground)}>
            <StarIcon style={{ fontSize: '42px' }} />
          </div>
          <div className={styles.iconText}>
            <Trans
              ns="fund"
              i18nKey="report.average_funder_rating"
              components={[<div />, <strong />]}
              values={{ value: averageFunderRating }}
            />
          </div>
        </Grid>
      </Grid>
    </TitledTile>
  );
};
