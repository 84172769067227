import { useCallback } from 'react';
import { useRequest } from '@Hooks';
import { API } from '@Services';
import { ProjectUpdate } from '@Types';

export const useCreateProjectUpdate = () => {
  const [fetch, response] = useRequest<ProjectUpdate>();

  const createProjectUpdate = useCallback(
    (data: ProjectUpdate) =>
      fetch(API.paths.all_project_updates, 'post', {
        data,
      }),
    [fetch],
  );

  return [createProjectUpdate, response] as const;
};
