import { useTranslation } from 'react-i18next';

import { Button } from '@Components/common';
import { useUserContext } from '@Contexts';
import { useRouter } from '@Helpers/useRouter';

import styles from './NotFound404.module.scss';

export const NotFound404 = () => {
  const { t } = useTranslation('common');
  const { userData } = useUserContext();
  const { push } = useRouter();

  const handleRedirect = () => {
    if (userData) {
      push('/dashboard');
    } else {
      push('/');
    }
  };

  return (
    <div className={styles.container}>
      <h1 className={styles.title}>404</h1>
      <h2 className={styles.subtitle}>{t('page_404.subtitle')}</h2>
      <p className={styles.text}>{t('page_404.text')}</p>
      <Button buttonType="tertiary" className={styles.button} onClick={handleRedirect}>
        {userData ? t('page_404.back_to_dashboard') : t('page_404.back_to_homepage')}
      </Button>
    </div>
  );
};
