import { useCallback } from 'react';
import { useRequest } from '@Hooks';
import { API } from '@Services';
import { ApiErrorObject, GroupProfileData } from '@Types';
import { useUserContext } from '@Contexts';
import { useRouter } from '@Helpers/useRouter';
import { RoutingPaths } from '@App/paths';

export const useUpdateGroupProfile = (doCreate = false) => {
  const [fetch, response] = useRequest<GroupProfileData | ApiErrorObject>();
  const { fetchUserData, isUserLoading } = useUserContext();
  const { push } = useRouter();

  const updateGroupProfile = useCallback(
    (data: GroupProfileData) =>
      fetch(API.paths.group_profile(), doCreate ? 'post' : 'patch', {
        data,
      })
        .then(fetchUserData)
        .then(() => {
          push(RoutingPaths.DASHBOARD_GROUP);
        }),
    [fetch, doCreate, fetchUserData, push],
  );

  return [updateGroupProfile, { ...response, loading: response.loading || isUserLoading }] as const;
};
