import React from 'react';
import classnames from 'classnames';
import { OldTabs, OldTabProps } from '@Components';
import styles from './Section.module.scss';

export interface SectionProps {
  title?: string;
  subtitle?: string;
  className?: string;
  contentStyles?: string;
  viewAsList?: boolean;
  tabs?: OldTabProps[];
  currentTab?: number;
  onTabChange?: (newTab: number) => void;
  headerButton?: JSX.Element;
}

export const Section: React.FC<SectionProps> = ({
  title,
  subtitle,
  className,
  contentStyles,
  children,
  viewAsList,
  tabs = [],
  currentTab = 0,
  onTabChange,
  headerButton,
}) => {
  const withTabs = viewAsList && tabs.length > 0 && onTabChange;

  return (
    <section className={classnames(className)}>
      <div className={styles.header}>
        <div>
          <h2 className={styles.title}>{title}</h2>
          {subtitle && <p className={styles.subtitle}>{subtitle}</p>}
        </div>
        {headerButton}
      </div>
      {withTabs && (
        <div className={styles.tabsWrapper}>
          <OldTabs currentTab={currentTab} tabs={tabs} onTabChange={onTabChange} />
        </div>
      )}
      <div className={classnames(styles.content, contentStyles)}>{children}</div>
    </section>
  );
};
