import React from 'react';
import {
  Menu as MaterialMenu,
  MenuProps as MaterialMenuProps,
  MenuItem as MaterialMenuItem,
  MenuItemProps as MaterialMenuItemProps,
} from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import styleVariables from '@Styles/_variables.module.scss';

export const Menu: React.FC<MaterialMenuProps> = ({ children, ...props }) => (
  <MaterialMenu
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  >
    {children}
  </MaterialMenu>
);

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      minWidth: '150px',
      display: 'block',
      lineHeight: 1,
      padding: '12px 16px',
      color: styleVariables.colorT2,
    },
  }),
);

export const MenuItem = ({ children, button, ...props }: MaterialMenuItemProps) => {
  const classes = useStyles();

  return (
    <MaterialMenuItem className={classes.root} {...props}>
      {children}
    </MaterialMenuItem>
  );
};
