import { useCallback, useRef, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Modal } from '@material-ui/core';
import debounce from 'lodash/debounce';

import CloseIcon from '@material-ui/icons/Close';

import { ShareModal } from '@Components';
import { useRouter } from '@Helpers/useRouter';
import styleVariables from '@Styles/_variables.module.scss';

import styles from './ViewProjectUpdateModal.module.scss';
import isServer from '@Helpers/isServer';
import { RoutingPaths, PathParams } from '@App/paths';
import { Loader } from '@Components/Loader/Loader';
import { UpdatesListItem } from '@Components/UpdatesListItem/UpdatesListItem';
import { useUserContext } from '@Contexts';
import { ProjectUpdateLike } from '@Types';
import { IconButton } from '@Components/common/Button/IconButton';

interface ViewProjectUpdateModalProps {
  id: number;
  isLoading?: boolean;
  image?: string | null;
  video?: string | null;
  description?: string;
  createdAt?: string;
  likes?: ProjectUpdateLike[];
  likeCount?: number;
  organizationId?: number;
  organizationName?: string;
  organizationAvatar?: string;
  projectId?: number;
  projectName?: string;
  onClose?: () => void;
}

export const ViewProjectUpdateModal = ({
  id,
  isLoading,
  image,
  video,
  description,
  createdAt,
  likes,
  likeCount,
  organizationId,
  organizationName,
  organizationAvatar,
  projectId,
  projectName,
  onClose,
}: ViewProjectUpdateModalProps) => {
  const { t } = useTranslation('projectUpdate');
  const imageRef = useRef<HTMLImageElement>(null);
  const [currentImageWidth, setCurrentImageWidth] = useState<number>();
  const [isShareModalOpen, setIsShareModalOpen] = useState(false);
  const { pathname, path, params } = useRouter();
  const { userData } = useUserContext();
  let UPDATE_URL: string | undefined;

  const isFundManagementPath = pathname === RoutingPaths.FUND_MANAGEMENT;

  if (!isServer) {
    const urlPath = isFundManagementPath ? `/fund/${params[PathParams.FUND_ID]}` : path;

    UPDATE_URL = `${window.location.origin}${urlPath}?projectUpdate=${id}`;
  }

  const handleResize = useCallback(() => {
    if (imageRef.current !== null) {
      setCurrentImageWidth(imageRef.current.offsetWidth);
    }
  }, []);

  const handleShareOpen = useCallback(() => {
    setIsShareModalOpen(true);
  }, []);

  const handleShareClose = useCallback(() => {
    setIsShareModalOpen(false);
  }, []);

  useEffect(() => {
    if (!window || isLoading) return;
    handleResize();

    const debouncedHandleResize = debounce(handleResize, 50);

    window.addEventListener('resize', debouncedHandleResize);

    return () => {
      window.removeEventListener('resize', debouncedHandleResize);
    };
  }, [handleResize, isLoading]);

  useEffect(() => {
    if (!currentImageWidth && !isLoading) {
      handleResize();
    }
  }, [currentImageWidth, handleResize, isLoading]);

  return (
    <>
      <Modal
        open
        // className={styles.container}
        style={{ zIndex: Number(styleVariables.zIndexUpdateModal) }}
        onClose={onClose}
      >
        <Box className={styles.modal}>
          {isLoading ? (
            <div>
              <Loader className={styles.loader} />
            </div>
          ) : (
            <>
              <UpdatesListItem
                id={id}
                image={image ? image : ''}
                video={video ? video : ''}
                description={description ? description : ''}
                createdAt={createdAt ? createdAt : ''}
                likes={likes ? likes : []}
                likeCount={likeCount ? likeCount : 0}
                organizationAvatar={organizationAvatar}
                organizationId={organizationId ? organizationId : 0}
                organizationName={organizationName ? organizationName : ''}
                projectId={projectId ? projectId : 0}
                projectName={projectName ? projectName : ''}
                showFullDescription={true}
                user={userData}
              />
              <IconButton buttonType="text" className={styles.actionButton} onClick={onClose}>
                <CloseIcon />
              </IconButton>
            </>
          )}
        </Box>
      </Modal>

      <ShareModal
        title={t('share_update')}
        shareUrl={UPDATE_URL}
        isOpen={isShareModalOpen}
        onClose={handleShareClose}
      />
    </>
  );
};
