export const GK_URL = 'https://www.greeneking.co.uk/';
export const GK_PTPI_URL = 'https://www.greeneking-pubs.co.uk/proud-to-pitch-in/';

export const openUrl = (url: string) => {
  /* Open `url` in this window if it's a URL on this site, or a new window if it's from a different site. */
  if (!url) {
    return;
  }
  const urlObject = new URL(url);
  if (typeof window !== 'undefined') {
    if (urlObject.host === window.location.host) {
      window.location.href = url;
    } else {
      window.open(url, '_blank');
    }
  }
};
