import { useRequest } from '@Hooks/useRequest';
import { API } from '@Services';
import { ExploreResults, MatchesPreviewParams } from '@Types';
import { useCallback } from 'react';

export const useGetExploreProjectsPost = () => {
  const [fetch, response] = useRequest<ExploreResults>();

  const getExploreProjects = useCallback(
    (data: MatchesPreviewParams) =>
      fetch(API.paths.explore_projects, 'post', {
        data,
      }),
    [fetch],
  );

  return [getExploreProjects, response] as const;
};
