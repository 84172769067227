import { usePaginatedRequest } from '@Hooks/usePaginatedRequest';
import { API } from '@Services';
import { Project } from '@Types';
import { useCallback } from 'react';

interface GetFundedProjectProps {
  fundId?: number | number[];
  organizationId?: number | number[];
}

export const useGetFundedProjects = () => {
  const [fetch, response] = usePaginatedRequest<Project>();

  const getFundedProjects = useCallback(
    ({ fundId, organizationId }: GetFundedProjectProps) =>
      fetch(API.paths.funded_projects, {
        fund_id: Array.isArray(fundId) ? fundId.join(',') : fundId,
        organization_id: Array.isArray(organizationId) ? organizationId.join(',') : organizationId,
      }),
    [fetch],
  );

  return [getFundedProjects, response] as const;
};
