import React, { useState, useCallback } from 'react';
import {
  FormHelperText,
  OutlinedInput,
  FormControl,
  InputAdornment,
  InputLabel,
  IconButton,
  OutlinedInputProps,
} from '@material-ui/core';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { PasswordRules } from './PasswordRules';
import { useErrorStateStyles } from '@Components/common/themeOverrides';
import { makeStyles } from '@material-ui/core/styles';
import styleVariables from '@Styles/_variables.module.scss';

interface PasswordInputProps extends OutlinedInputProps {
  helperText?: string;
  showRules?: boolean;
}

export const Password: React.FC<PasswordInputProps> = ({
  children,
  helperText,
  showRules,
  ...props
}) => {
  const classes = useErrorStateStyles();
  const [isPasswordVisible, setPasswordVisibility] = useState(false);

  const handleClickShowPassword = useCallback(() => {
    setPasswordVisibility(!isPasswordVisible);
  }, [isPasswordVisible]);

  const passwordClasses = makeStyles({
    root: {
      '&.Mui-focused + div': {
        opacity: 1,
        visibility: 'visible',
      },
    },
  })();

  return (
    <FormControl
      classes={{ root: props.error ? classes.errorState : classes.nonError }}
      error={props.error}
      variant="outlined"
    >
      <InputLabel>{props.label}</InputLabel>
      <OutlinedInput
        {...props}
        id="password"
        type={isPasswordVisible ? 'text' : 'password'}
        className={passwordClasses.root}
        endAdornment={
          <InputAdornment position="end">
            <IconButton onClick={handleClickShowPassword} edge="end">
              {isPasswordVisible ? (
                <VisibilityOff style={{ color: styleVariables.colorP1 }} />
              ) : (
                <Visibility />
              )}
            </IconButton>
          </InputAdornment>
        }
      />
      {showRules && <PasswordRules fieldName={props.name} />}
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
};
