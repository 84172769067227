export const getProjectPath = (projectId: number | string) => `/project/${projectId}`;

export const getFundPath = (fundId: number | string) => `/fund/${fundId}`;

export const getGroupProfilePath = (organizationId: number | string) =>
  `/group-profile/${organizationId}`;

export const getFunderProfilePath = (organizationId: number | string) =>
  `/funder-profile/${organizationId}`;

export const getJoinCampaignPath = (campaignCode: string) => `/join-campaign/${campaignCode}`;
