import React, { createContext, useState, useContext, useCallback, useRef } from 'react';
import { NotificationPopupProps } from '@Components';

const DEFAULT_NOTIFICATION_DURATION = 5000;

interface ShowNotificationOptions {
  duration?: number;
  isClosable?: boolean;
}

export const useNotificationsContextController = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [notificationContent, setNotificationContent] = useState<string | JSX.Element>();
  const [notificationType, setNotificationType] = useState<'success' | 'error'>();
  const [notificationDuration, setNotificationDuration] = useState<number>(
    DEFAULT_NOTIFICATION_DURATION,
  );
  const [isClosable, setIsClosable] = useState(true);
  const pendingTimeout = useRef<ReturnType<typeof setTimeout>>();

  const clearPendingTimeout = useCallback(() => {
    if (typeof pendingTimeout?.current !== 'undefined') {
      clearTimeout(pendingTimeout.current);
    }
  }, []);

  const hideNotification = useCallback(() => {
    clearPendingTimeout();
    setIsVisible(false);
  }, [clearPendingTimeout]);

  const scheduleHideNotification = useCallback(() => {
    clearPendingTimeout();
    pendingTimeout.current = setTimeout(hideNotification, notificationDuration);
  }, [clearPendingTimeout, hideNotification, notificationDuration]);

  const showNotification = useCallback(
    (
      message: string | JSX.Element,
      type: 'success' | 'error',
      options: ShowNotificationOptions = { duration: DEFAULT_NOTIFICATION_DURATION },
    ) => {
      setIsVisible(true);
      setNotificationContent(message);
      setNotificationType(type);
      setNotificationDuration(options?.duration || DEFAULT_NOTIFICATION_DURATION);
      setIsClosable(options?.isClosable === false ? false : true);
      scheduleHideNotification();
    },
    [scheduleHideNotification],
  );

  const success = useCallback(
    (message: string | JSX.Element, options?: ShowNotificationOptions) => {
      showNotification(message, 'success', options);
    },
    [showNotification],
  );

  const error = useCallback(
    (message: string | JSX.Element, options?: ShowNotificationOptions) => {
      showNotification(message, 'error', options);
    },
    [showNotification],
  );

  const notificationProps: NotificationPopupProps = {
    isVisible,
    content: notificationContent,
    success: notificationType === 'success',
    error: notificationType === 'error',
    closeNotification: isClosable ? hideNotification : undefined,
    pauseNotificationTimeout: clearPendingTimeout,
    resumeNotificationTimeout: scheduleHideNotification,
  };

  return {
    notificationProps,
    success,
    error,
    hideNotification,
  } as const;
};

export const NotificationsContext = createContext<ReturnType<
  typeof useNotificationsContextController
> | null>(null);

export const useNotificationsContext = () => {
  const value = useContext(NotificationsContext);
  if (value === null) {
    throw new Error(
      `[Notifications Context] useNotificationsContext must be called within NotificationsContext tree`,
    );
  }
  return value;
};

export const NotificationsContextProvider: React.FC = ({ children }) => {
  const contextController = useNotificationsContextController();

  return (
    <NotificationsContext.Provider value={contextController}>
      {children}
    </NotificationsContext.Provider>
  );
};
