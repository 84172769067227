import { useCallback, useEffect } from 'react';
import { useRequest } from '@Hooks/useRequest';
import * as authToken from '@Helpers/authToken';
import * as twoFactorToken from '@Helpers/twoFactorToken';
import { useRouter } from '@Helpers/useRouter';
import { API } from '@Services';
import { QueryParams, Routes, RoutingPaths } from '@App/paths';
import { useRedirectContext, useUserContext } from '@Contexts';
import { getJoinCampaignPath } from '@Helpers';
import { UserTypeEnum } from '@Types';
import { recordDataLayerEvent } from '@Helpers/analytics';

export interface SignInVariables {
  email: string;
  password: string;
}

interface TwoFactorSignInResponse {
  two_factor_required: true;
  two_factor_token: string;
}

interface NormalSignInResponse {
  two_factor_required: false;
  login_token: string;
}

type SignInResponse = NormalSignInResponse | TwoFactorSignInResponse;

export const useSignIn = () => {
  const [fetch, response] = useRequest<SignInResponse>();
  const { push, params } = useRouter();
  const { fetchUserData } = useUserContext();
  const { redirectedFrom } = useRedirectContext();
  const campaignCode = params[QueryParams.CAMPAIGN_CODE];

  const signIn = useCallback(
    (data: SignInVariables) =>
      fetch(API.paths.sign_in, 'post', {
        data,
      }),
    [fetch],
  );

  useEffect(() => {
    if (!response.data) {
      return;
    }
    if (response.data.two_factor_required) {
      twoFactorToken.set(response.data.two_factor_token);
      push(Routes.TWO_FACTOR_VERIFICATION);
    } else {
      authToken.set(response.data.login_token);
      fetchUserData().then((response) => {
        recordDataLayerEvent(
          response.data.user_type === UserTypeEnum.GROUP ? 'groupSignin' : 'funderSignin',
        );
      });
      if (redirectedFrom) {
        push(redirectedFrom);
      } else if (typeof campaignCode === 'string') {
        push(getJoinCampaignPath(campaignCode));
      } else {
        push(RoutingPaths.DASHBOARD);
      }
    }
  }, [response.data, push, fetchUserData, redirectedFrom, campaignCode]);

  return { signIn, response };
};
