import { Category, Fund, LocalAuthority, Project } from '@Types';
import { FeaturedFundCard } from './FeaturedFundCard';
import { FeaturedProjectCard } from './FeaturedProjectCard';

interface FeaturedCardProps {
  className?: string;
  fundData?: Fund;
  projectData?: Project;
  allCategories?: Category[];
  localAuthorities?: LocalAuthority[];
}

export const FeaturedCard = ({
  className,
  fundData,
  projectData,
  allCategories,
  localAuthorities,
}: FeaturedCardProps) => {
  if (fundData && allCategories && localAuthorities) {
    return (
      <FeaturedFundCard
        className={className}
        fundData={fundData}
        allCategories={allCategories}
        localAuthorities={localAuthorities}
      />
    );
  }

  if (projectData && allCategories) {
    return (
      <FeaturedProjectCard
        className={className}
        projectData={projectData}
        allCategories={allCategories}
      />
    );
  }

  return null;
};
