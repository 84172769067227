import classnames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';

import LocationCityIcon from '@material-ui/icons/LocationCity';

import { OrganizationAvatar, OnlinePresenceLinks } from '@Components';
import { Button } from '@Components/common';
import { getOrganizationPagePath } from '@Helpers/getOrganizationPagePath';

import styles from './OrganizationTile.module.scss';
import { getFundPath } from '@Helpers';

interface OrganizationTileProps {
  className?: string;
  fund?: boolean;
  subtitle: string;
  organizationName?: string;
  organizationType?: string;
  avatarImage?: string;
  orgWebsiteUrl?: string;
  orgFacebookUrl?: string;
  orgLinkedinUrl?: string;
  orgInstagramUrl?: string;
  orgTwitterUrl?: string;
  organizationId?: number;
  fundID?: number;
  fundName?: string;
  funderName?: string;
}

export const OrganizationTile: React.FC<OrganizationTileProps> = ({
  className,
  fund,
  subtitle,
  organizationName,
  organizationType,
  avatarImage,
  orgWebsiteUrl,
  orgFacebookUrl,
  orgLinkedinUrl,
  orgInstagramUrl,
  orgTwitterUrl,
  organizationId,
  fundID,
  fundName,
  funderName,
}) => {
  const { t } = useTranslation('organizationTile');

  return (
    <div className={classnames(styles.organizationTile, className)}>
      <OrganizationAvatar avatarImage={avatarImage}>
        <LocationCityIcon style={{ fontSize: '32px' }} />
      </OrganizationAvatar>
      <span className={styles.createdBy}>{subtitle}</span>
      <h4 className={styles.organizationName}>{organizationName ? organizationName : fundName}</h4>
      <span className={styles.organizationType}>
        {organizationType ? organizationType : funderName}
      </span>
      <OnlinePresenceLinks
        className={styles.linksWrapper}
        orgWebsiteUrl={orgWebsiteUrl}
        orgFacebookUrl={orgFacebookUrl}
        orgLinkedinUrl={orgLinkedinUrl}
        orgInstagramUrl={orgInstagramUrl}
        orgTwitterUrl={orgTwitterUrl}
      />
      {organizationId && (
        <Button buttonType="tertiary" to={getOrganizationPagePath(Boolean(fund), organizationId)}>
          {t('view_profile_button')}
        </Button>
      )}
      {fundID && (
        <Button buttonType="tertiary" to={getFundPath(fundID)}>
          {t('view_fund_button')}
        </Button>
      )}
    </div>
  );
};
