import { useCallback } from 'react';
import { useRequest } from '@Hooks';
import { API } from '@Services';
import { ArchiveMessage, Project } from '@Types';

export const useArchiveMessage = () => {
  const [fetch, response] = useRequest<Project>();

  const archiveMessage = useCallback(
    (data: ArchiveMessage) => {
      fetch(API.paths.archive_message, 'patch', {
        data: data,
      });
    },
    [fetch],
  );

  return [archiveMessage, response] as const;
};
