import { useEffect } from 'react';
import { isArray } from 'lodash';
import { ErrorOption } from 'react-hook-form';
import { FieldErrors } from '@Types';
import { useNotificationsContext } from '@Contexts/NotificationsContext';

interface UseSetFieldErrorsProps {
  fieldErrors?: FieldErrors;
  setError: (name: string, error: ErrorOption) => void;
  loading: boolean;
}

export const setFieldErrors = (
  fieldErrors: FieldErrors,
  setError: UseSetFieldErrorsProps['setError'],
  handleNonFieldError: any,
  prefix?: string,
) => {
  prefix = prefix || '';
  Object.entries(fieldErrors).forEach(([key, value]) => {
    if (isArray(value)) {
      if (key === 'non_field_errors') {
        // Errors not related to specific fields will generally be displayed as a notification.
        handleNonFieldError(value[0]);
      } else {
        // This is a list of errors for a single field - just show the first.
        setError(`${prefix}${key}`, { message: value[0], shouldFocus: true });
      }
    } else {
      // This is an object representing nested data, e.g. {part_of_form: {field1: ["Error 1"], field2: ["Error 2"]}}.
      // These should store errors against fields called 'part_of_form.field1' and 'part_of_form.field2'.
      // Recurse on this object with a prefix added.
      setFieldErrors(value, setError, handleNonFieldError, `${prefix}${key}.`);
    }
  });
};

export const useSetFieldErrors = ({ fieldErrors, setError, loading }: UseSetFieldErrorsProps) => {
  const { error } = useNotificationsContext();

  useEffect(() => {
    if (fieldErrors && !loading) {
      setFieldErrors(fieldErrors, setError, error);
    }
  }, [fieldErrors, setError, loading, error]);
};
