import React from 'react';
import { useTranslation } from 'react-i18next';
import { DisplayOutput } from '..';
import { TitledTile } from '@Components/common/TitledTile/TitledTile';
import styles from './TangibleOutputs.module.scss';

interface TangibleOutput {
  project_id: number;
  number: number;
  description: string;
  project_name: string;
  organization_name: string;
}

interface TangibleOutputsProps {
  tangibleOutputs: TangibleOutput[];
}

export const TangibleOutputs = ({ tangibleOutputs }: TangibleOutputsProps) => {
  const { t } = useTranslation('fund');

  const tangibleOutputsSplit: TangibleOutput[][] = [[], [], []];
  tangibleOutputs
    .filter((item) => !isNaN(item.number))
    .forEach((item, itemIndex) => {
      tangibleOutputsSplit[itemIndex % 3].push(item);
    });

  return (
    <TitledTile title={t(`report.titles.outputs`)}>
      <div className={styles.container}>
        <div className={styles.outputContainer}>
          {tangibleOutputsSplit.map((element, index) => (
            <div key={index}>
              {element.length >= 1 && <DisplayOutput items={element} maxSteps={element.length} />}
            </div>
          ))}
        </div>
      </div>
    </TitledTile>
  );
};
