import { useCallback } from 'react';
import { useRequest } from '@Hooks/useRequest';
import { API } from '@Services';

export const useGetSurveyConfig = () => {
  // The camelCase data will be immediately converted into snake_case,
  // so it doesn't really make sense to maintain types for both.
  const [fetch, response] = useRequest<any>();

  const getSurveyConfig = useCallback(
    (responseId: number) => fetch(API.paths.impact_survey_response(responseId), 'get'),
    [fetch],
  );

  return [getSurveyConfig, response] as const;
};
