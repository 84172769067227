import { differenceInDays, endOfDay, formatDistanceStrict, isBefore, parseISO } from 'date-fns';

export const getDeadlineDateInformationBreakdown = (deadlineDate: string | undefined) => {
  const currentDate = new Date();
  const deadlineDateParsed = deadlineDate ? endOfDay(parseISO(deadlineDate)) : undefined;
  const isBeforeDeadline = deadlineDateParsed && isBefore(currentDate, deadlineDateParsed);
  const distanceToDeadline =
    deadlineDateParsed && formatDistanceStrict(currentDate, deadlineDateParsed);
  const numberOfDaysBeforeDeadline =
    deadlineDateParsed && differenceInDays(deadlineDateParsed, currentDate);

  return { isBeforeDeadline, distanceToDeadline, numberOfDaysBeforeDeadline };
};
