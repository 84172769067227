import MasonryComponent, { MasonryProps as MasonryComponentProps } from 'react-masonry-css';
import classnames from 'classnames';
import styles from './Masonry.module.scss';

const breakpointColumnsObj = {
  default: 2,
  720: 1,
};

interface MasonryProps {
  breakpointCols?: MasonryComponentProps['breakpointCols'];
  columnClassName?: MasonryComponentProps['columnClassName'];
  className?: string;
}

export const Masonry: React.FC<MasonryProps> = ({
  breakpointCols,
  columnClassName,
  className,
  children,
}) => {
  return (
    <MasonryComponent
      breakpointCols={breakpointCols || breakpointColumnsObj}
      className={classnames(styles.masonryGrid, className)}
      columnClassName={classnames(styles.masonryGridColumn, columnClassName)}
    >
      {children}
    </MasonryComponent>
  );
};
