export enum SpecialUsers {
  ME = 'me',
}
type Myself = SpecialUsers.ME;
export type UserId = number | Myself;

export const paths = {
  sign_up: '/auth/registration/',
  sign_in: '/auth/login/',
  two_factor_verification: '/auth/verify/',
  two_factor_retry: '/auth/verify/retry/',
  become_user: (userId: number) => `/users/${userId}/become/`,
  verify_email: '/auth/account-confirm-email/',
  resend_verifiaction_email: '/auth/resend-account-confirmation-email/',
  organization_lookup: '/organization/lookup/',
  categories: '/categories/',
  user: `/users/${SpecialUsers.ME}/`,
  funding_profile: (userId: UserId = SpecialUsers.ME) =>
    `/users/${userId}/organization/funding_profile/`,
  group_profile: (userId: UserId = SpecialUsers.ME) =>
    `/users/${userId}/organization/group_profile/`,
  public_group_profile: (organizationId: number) =>
    `/organizations/${organizationId}/group_profile/`,
  public_funder_profile: (organizationId: number) =>
    `/organizations/${organizationId}/funding_profile/`,
  survey_responses_count: (fundId: number) => `/funds/${fundId}/no_responses/`,
  survey_dashboard_link: (fundId: number) => `/funds/${fundId}/dashboard_link/`,
  funds_by_group: (organizationId: number) => `/organizations/${organizationId}/projects_funds/`,
  group_eligibility_for_fund: (fundId: number) => `/funds/${fundId}/group_eligibility_for_fund`,
  funds: '/funds/',
  my_funds: '/my-funds/',
  fund: (fundId: number) => `/funds/${fundId}/`,
  funds_details: (fundId: number) => `/funds/${fundId}/details/`,
  funds_confirm: (fundId: number) => `/funds/${fundId}/confirm/`,
  funds_draft: (fundId: number) => `/funds/${fundId}/draft/`,
  fund_matches: (fundId: number) => `/funds/${fundId}/project_matches/`,
  fund_selections: (fundId: number) => `/funds/${fundId}/selections/`,
  fund_archive: (fundId: number) => `/funds/${fundId}/archive/`,
  fund_selection: (fundId: number, selectionId: number) =>
    `/funds/${fundId}/selections/${selectionId}/`,
  fund_invites_project: (fundId: number, selectionId: number) =>
    `/funds/${fundId}/selections/${selectionId}/invite/`,
  fund_voting_configuration_create: (fundId: number) => `/funds/${fundId}/voting-configuration/`,
  fund_voting_configuration_update: (fundId: number, configurationId: number) =>
    `/funds/${fundId}/voting-configuration/${configurationId}/`,
  selection_voting: (fundId: number, sharingToken: string, userIdentifier: string) =>
    `/funds/${fundId}/voting?sharing_token=${sharingToken}&user_identifier=${userIdentifier}`,
  selection_vote: (fundId: number) => `/funds/${fundId}/vote/`,
  funds_for_group_matches: '/organization/group_profile/funds-for-group-matches/',
  featured_funds: '/funds/promoted/',
  fund_report_data: (fundId: number) => `fund/${fundId}/survey-report/`,
  funder_report_data: (organizationId: number) => `organization/${organizationId}/survey-report/`,
  funder_report_summary_data: (organizationId: number) =>
    `organization/${organizationId}/survey-report-summary/`,
  impact_survey_response: (responseId: number) => `/impact_survey/complete/${responseId}/`,
  projects: '/projects/',
  my_projects: '/my-projects/',
  organization_projects: (organizationId: number) => `/organizations/${organizationId}/projects/`,
  project: (projectId: number) => `/projects/${projectId}/`,
  project_update: (projectUpdateId: number) => `/project_updates/${projectUpdateId}/`,
  project_updates: (projectId: number) => `/projects/${projectId}/updates/`,
  project_details: (projectId: number) => `/projects/${projectId}/details/`,
  project_written_pitch: (projectId: number) => `/projects/${projectId}/written_pitch/`,
  project_video_pitch: (projectId: number) => `/projects/${projectId}/video_pitch/`,
  project_delivery_plan: (projectId: number) => `/projects/${projectId}/delivery_plan/`,
  project_complete: (projectId: number) => `/projects/${projectId}/mark_project_as_completed/`,
  project_archive: (projectId: number) => `/projects/${projectId}/archive/`,
  project_survey: (projectId: number) => `/projects/${projectId}/get_survey_url/`,
  project_confirm: (projectId: number) => `/projects/${projectId}/confirm/`,
  project_matches: (projectId: number) => `/projects/${projectId}/fund_matches/`,
  project_selections: (projectId: number) => `/projects/${projectId}/selections/`,
  project_funding_source: (projectId: number) => `/projects/${projectId}/funding_source/`,
  project_rejects_invitation: (projectId: number, selectionId: number) =>
    `/projects/${projectId}/selections/${selectionId}/reject_invitation/`,
  project_accepts_invitation: (projectId: number, selectionId: number) =>
    `/projects/${projectId}/selections/${selectionId}/accept_invitation/`,
  all_project_updates: '/project_updates/',
  hide_update: (projectUpdateId: number) => `/project_updates/${projectUpdateId}/hide/`,
  project_update_like: (projectUpdateId: number) => `/project_updates/${projectUpdateId}/like/`,
  featured_projects: '/projects/promoted/',
  funded_projects: '/projects/funded/',
  match: (matchId: number) => `/matches/${matchId}/`,
  reject_match: (matchId: number) => `/matches/${matchId}/reject/`,
  join_campaign: (organizationId: number) => `/organizations/${organizationId}/join_campaign/`,
  campaigns: `/campaigns/`,
  password_reset: '/auth/password_reset/',
  set_new_password: '/auth/password_reset/confirm/',
  export_survey_responses_for_fund: '/jobs/export-survey-responses/',
  export_projects: `/jobs/export-projects/`,
  check_job_status: (jobId: string) => `/jobs/status/${jobId}/`,
  nomination_form: (slug: string) => `/nomination-forms/${slug}`,
  nomination_form_possible_matches: (slug: string) => `/nomination-forms/${slug}/possible_matches/`,
  nomination_form_nominate: (slug: string) => `/nomination-forms/${slug}/nominate/`,
  explore_projects: '/explore-projects/',
  fund_preview_data: '/fund-preview-data/',
  fund_preview_data_export: '/jobs/fund-preview-data-export/',
  regions: '/regions/',
  local_authorities: '/local-authorities/',
  local_authority: (authorityId: number) => `/local-authorities/${authorityId}/`,
  local_authority_with_feature: (authorityId: number) =>
    `/local-authorities-with-feature/${authorityId}/`,
  voting_landing_page: (slug: string) => `voting/${slug}/`,
  send_message: 'messaging/send-message/',
  list_members_you_can_assign_to_message: 'messaging/list-members-you-can-assign-to-message/',
  list_recipients_you_can_assign_to_message: 'messaging/list-recipients-you-can-assign-to-message/',
  archive_message: 'messaging/archive-message/',
  avatar_urls: 'messaging/avatar-urls/',
  update_message: 'messaging/update-message/',
  signed_url: (uploadId: number) => `messaging/signed-url/${uploadId}/`,
  signed_put_url: 'messaging/signed-put-url/',
  survey_preview: (selection_id: number) => `impact_survey/preview/${selection_id}/`,
};
