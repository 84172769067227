import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid } from '@material-ui/core';
import { EstimatedBeneficiaries, FundedProjects, FundGallery, EnhancedSurveyReportLink } from '.';
import { TitledTile } from '@Components/common/TitledTile/TitledTile';
import {
  Fund,
  Project,
  ProjectUpdate,
  Map2Props,
  DrawingMode,
  LatLngExpression,
  InitialFocus,
  LocalAuthorityWithFeature,
} from '@Types';
import styles from './ReportSummary.module.scss';
import { projectFundingDistributionToDataAndLabelFormat } from '@Helpers/DataStandardisation';
import { dataAndLabelFormatToHighchartsDoughnut } from '@Helpers/DataVisualisation';
import { Doughnut } from '@Components/Charts/Doughnut';
import dynamic from 'next/dynamic';
import { Feature, FeatureCollection } from 'geojson';
import { NonprofitTypeStackedColumn } from '@Components/Charts/SurveyReports/NonprofitTypeStackedColumn';
import { SDGsDoughnut } from '@Components/Charts/SurveyReports/SDGsDoughnut';
import flip from '@turf/flip';
import HomeWorkIcon from '@material-ui/icons/HomeWork';
import styleVariables from '@Styles/_variables.module.scss';
import { StandardMaker } from '@Components/Map2/Markers';
import { ProjectPopup } from '@Components/Map2/Popups';
import { getProjectPath } from '@Helpers';
import { PaginatedResponse } from '@Hooks/usePaginatedRequest';

const Map2 = dynamic<Map2Props>(
  () => import('@Components/Map2/Map2').then((mod) => mod.Map2) as any,
  {
    ssr: false,
  },
);

interface ReportSummaryProps {
  fundId: number;
  fundData: Fund;
  fundedProjectsList: Project[];
  localAuthority?: LocalAuthorityWithFeature;
  updatesPaginatedResponse: PaginatedResponse<ProjectUpdate>;
}

export const ReportSummary: React.FC<ReportSummaryProps> = ({
  fundId,
  fundData,
  fundedProjectsList,
  localAuthority,
  updatesPaginatedResponse,
}) => {
  const { t } = useTranslation('fund');
  const hasEnhancedReporting = Boolean(fundData.user_has_enhanced_reporting);
  const {
    details: { radius, catchment_area_type, location, areas },
  } = fundData;
  const projectsCount = fundedProjectsList.length;

  const projectFundingDistributionInDataAndLabelFormat =
    projectFundingDistributionToDataAndLabelFormat(fundedProjectsList);
  const {
    dataSet: fundingDistributionDataset,
    title: fundingDistributionTitle,
    displayCenteredTitle: fundingDistributionDisplayCenteredTitle,
  } = dataAndLabelFormatToHighchartsDoughnut(projectFundingDistributionInDataAndLabelFormat, true);

  const [circles, setCircles] = useState<
    | Array<{
        center: LatLngExpression;
        radius: number;
      }>
    | undefined
  >(() => {
    if (catchment_area_type === DrawingMode.RADIUS) {
      return [
        {
          center: flip(location).coordinates as LatLngExpression,
          radius: radius,
        },
      ];
    }

    return undefined;
  });

  const [featureCollection, setFeatureCollection] = useState(() => {
    let features: Feature[] = [];

    if (areas) {
      features.push({
        type: 'Feature',
        geometry: areas,
        properties: {},
      });
    }

    if (localAuthority) {
      features.push(localAuthority.feature);
    }

    features.push({
      type: 'Feature',
      geometry: location,
      properties: {
        marker: 'home',
        popup: null,
      },
    });

    features = features.concat(
      fundedProjectsList.map((project) => {
        return {
          type: 'Feature',
          geometry: project.details.location,
          properties: {
            link: getProjectPath(project.id),
            name: project.details.name,
            orgName: project.group_profile.name,
          },
        };
      }),
    );

    return {
      type: 'FeatureCollection',
      features: features,
    } as FeatureCollection;
  });

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <h2 className={styles.title}>{t('report.section_heading.inputs_outputs')}</h2>
        </Grid>
        <Grid item xs={12} md={8}>
          <TitledTile title={t('report.titles.investment_summary')}>
            <Doughnut
              dataSet={fundingDistributionDataset}
              title={fundingDistributionTitle}
              displayCenteredTitle={fundingDistributionDisplayCenteredTitle}
              prefix={'£'}
            />
          </TitledTile>
        </Grid>
        <Grid
          item
          container
          direction="column"
          justifyContent="space-evenly"
          spacing={3}
          xs={12}
          md={4}
        >
          <Grid item>
            <FundedProjects fundedProjectsList={fundedProjectsList} />
          </Grid>
          <Grid item>
            <EstimatedBeneficiaries fundedProjectsList={fundedProjectsList} />
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Map2
            height={400}
            circles={circles}
            initialFocus={
              catchment_area_type !== DrawingMode.UK_WIDE ? InitialFocus.DATA : InitialFocus.UK_WIDE
            }
            featureCollection={featureCollection}
            markers={{
              home: {
                backgroundColor: styleVariables.colorT3,
                component: <HomeWorkIcon htmlColor="white" />,
              },
              default: {
                component: <StandardMaker />,
              },
            }}
            popupFunctions={{
              default: ProjectPopup,
            }}
          />
        </Grid>

        <Grid item xs={12}>
          {updatesPaginatedResponse.page?.length && (
            <FundGallery fundId={fundId} updatesPaginatedResponse={updatesPaginatedResponse} />
          )}
        </Grid>

        <Grid item md={8} xs={12}>
          <TitledTile title={t('report.titles.sdg')}>
            <SDGsDoughnut sdgs={fundData.projects_sdg_breakdown} />
          </TitledTile>
        </Grid>
        <Grid item md={4} xs={12}>
          <TitledTile title={t('report.titles.supported_orgs')}>
            <NonprofitTypeStackedColumn
              nonprofitTypes={fundedProjectsList.map((project) => project.group_profile.type)}
            />
          </TitledTile>
        </Grid>

        <Grid item xs={12}>
          <h2 className={styles.title}>{t('report.section_heading.outcomes')}</h2>
        </Grid>
        <Grid item xs={12}>
          <EnhancedSurveyReportLink
            hasEnhancedReporting={hasEnhancedReporting}
            fundId={fundId}
            projectsCount={projectsCount}
          />
        </Grid>
      </Grid>
    </>
  );
};
