import React, { useState, useMemo, useEffect, useCallback } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import classnames from 'classnames';
import AvatarGroup from '@material-ui/lab/AvatarGroup';
import { Grid, makeStyles } from '@material-ui/core';
import LocationCityIcon from '@material-ui/icons/LocationCity';
import { useCategories } from '@Hooks/Api';
import styleVariables from '@Styles/_variables.module.scss';
import { ViewButton } from './index';
import { Loader, ContentModal } from '@Components';
import { TitledTile } from '@Components/common/TitledTile/TitledTile';
import { Project } from '@Types';
import styles from './FundedProjects.module.scss';
import { formatNumber } from '@Helpers';

interface FundedProjectProps {
  fundedProjectsList: Project[];
}

interface OrganizationList {
  name: string;
  value: number;
}

const useStyles = makeStyles({
  root: {
    alignItems: 'center',
    marginLeft: 'auto',
  },
  avatar: {
    marginLeft: 0,
    marginRight: -8,
    '&.MuiAvatar-colorDefault': {
      backgroundColor: styleVariables.colorT3,
      border: `2px solid ${styleVariables.colorT5}`,
      fontSize: 16,
      fontWeight: 500,
      lineHeight: 21,
      height: 52,
      order: -1,
      width: 52,
      zIndex: `${styleVariables.zIndexAvatar} !important`,
    },
  },
});

export const FundedProjects: React.FC<FundedProjectProps> = ({ fundedProjectsList }) => {
  const { t } = useTranslation('fund');
  const classes = useStyles();
  const [isProjectsListModalOpen, setIsProjectsListModalOpen] = useState(false);

  const handleCloseProjectsListModal = useCallback(() => {
    setIsProjectsListModalOpen(false);
  }, []);

  const handleOpenProjectsListModal = useCallback(() => {
    setIsProjectsListModalOpen(true);
  }, []);

  const avatarsList = useMemo(
    () =>
      fundedProjectsList.map(({ id, details: { name }, group_profile: { avatar_thumbnail } }) => ({
        name,
        image: avatar_thumbnail,
        url: `/project/${id}`,
      })),
    [fundedProjectsList],
  );

  const AvatarItem = useCallback(
    ({ name, image, className, ...props }) => (
      <div className={classnames(styles.avatar, className)} {...props}>
        {image ? <img src={image} alt={name} /> : <LocationCityIcon style={{ fontSize: '24px' }} />}
      </div>
    ),
    [],
  );

  return (
    <>
      <TitledTile title={t('report.titles.grantees')}>
        <div>
          <h3 className={styles.count}>{fundedProjectsList.length}</h3>
          <span className={styles.text}>{t('report.funded_projects')}</span>
          <ViewButton onClick={handleOpenProjectsListModal} />
        </div>

        <AvatarGroup classes={classes} max={4}>
          {avatarsList.map(({ name, ...props }) => (
            <AvatarItem key={name} name={name} {...props} />
          ))}
        </AvatarGroup>
      </TitledTile>

      <ContentModal
        title={t('report.titles.grantees')}
        isOpen={isProjectsListModalOpen}
        onClose={handleCloseProjectsListModal}
      >
        <div className={styles.projectsListWrapper}>
          {avatarsList.map(({ name, url, ...props }, index) => (
            <a href={url} className={styles.projectsRow} key={index}>
              <AvatarItem name={name} {...props} />
              <p className={styles.projectUrl}>{name}</p>
            </a>
          ))}
        </div>
      </ContentModal>
    </>
  );
};
