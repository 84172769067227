import { useTranslation } from 'react-i18next';

import { Loader } from '@Components';
import { Project } from '@Types';
import { getYoutubeThumbnailUrl } from '@Helpers/getYoutubeThumbnailUrl';

import { ActionListItem } from '.';
import styles from './ProjectsListSection.module.scss';
import { PaginatedResponse } from '@Hooks/usePaginatedRequest';
import { Pagination } from '@Components/common';

export interface ProjectsListSectionProps {
  projectsPaginatedResponse: PaginatedResponse<Project>;
  title?: string;
  emptyMessage?: string;
}

export const ProjectsListSection = ({
  title,
  emptyMessage,
  projectsPaginatedResponse,
}: ProjectsListSectionProps) => {
  const { t } = useTranslation('project');

  const { loading: isProjectsListLoading, page: projectsList } = projectsPaginatedResponse;

  if (isProjectsListLoading) {
    return (
      <div className={styles.container}>
        <Loader />
      </div>
    );
  }

  return (
    <div className={styles.container}>
      {projectsList.length === 0 ? (
        <span className={styles.noProjects}>{emptyMessage}</span>
      ) : (
        <>
          {title && <h3 className={styles.header}>{title}</h3>}
          {projectsList.map(
            ({ id, details: { name, amount }, status, written_pitch, video_pitch }) => (
              <ActionListItem
                key={id}
                className={styles.actionListItem}
                name={name}
                link={`/project/${id}`}
                fundingRequired={amount}
                status={t(`status.${status}`)}
                image={written_pitch?.image}
                videoThumbnail={getYoutubeThumbnailUrl(video_pitch?.video_url_1)}
                ctaText={t('profilePage:view_project')}
              />
            ),
          )}
          <Pagination paginatedResponse={projectsPaginatedResponse} />
        </>
      )}
    </div>
  );
};
