import { useCallback, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation, Trans } from 'react-i18next';
import { makeStyles } from '@material-ui/core';
import * as Sentry from '@sentry/react';

import { useNotificationsContext } from '@Contexts';
import { useSetFieldErrors } from '@Hooks';
import { useCreateProjectUpdate, useGroupProfile } from '@Hooks/Api';

import {
  CreateProjectUpdateModal,
  CreateProjectUpdateModalProps,
} from './CreateProjectUpdateModal';
import { Button } from '@Components/common';
import styleVariables from '@Styles/_variables.module.scss';

const INITIAL_FORM_STATE: [string, string | null | number][] = [
  ['image', null],
  ['video_url', ''],
  ['description', ''],
];

const useStyles = makeStyles({
  button: {
    color: styleVariables.colorT5,
    borderColor: styleVariables.colorT5,
    padding: '8px 16px',
    margin: '8px',
  },
});

interface CreateProjectUpdateModalControllerProps
  extends Pick<CreateProjectUpdateModalProps, 'isOpen' | 'onClose' | 'project'> {
  updateProjectUpdates?: (projectId: number) => any;
  onOpen: () => void;
}

export const CreateProjectUpdateModalController = ({
  onOpen,
  updateProjectUpdates,
  ...modalProps
}: CreateProjectUpdateModalControllerProps) => {
  const methods = useForm({ mode: 'onChange' });
  const { handleSubmit, setError, setValue, register } = methods;
  const { onClose, project } = modalProps;
  const {
    success: successNotification,
    error: errorNotification,
    hideNotification,
  } = useNotificationsContext();
  const { t } = useTranslation('projectUpdate');
  const classes = useStyles();
  const [isVideoActive, setIsVideoActive] = useState(false);

  const [fetchGroupProfile, { loading: isProfileDataLoading, data: profileData }] =
    useGroupProfile();
  const [
    createProjectUpdate,
    { loading: isProjectUpdateCreating, error: createProjectUpdateErrors },
  ] = useCreateProjectUpdate();

  useEffect(() => {
    fetchGroupProfile();
  }, [fetchGroupProfile]);

  const openVideo = useCallback(() => {
    setIsVideoActive(true);
  }, []);
  const closeVideo = useCallback(() => {
    setIsVideoActive(false);
    setValue('video_url', '');
  }, [setValue]);

  const closeWithClear = useCallback(() => {
    INITIAL_FORM_STATE.forEach(([key, value]) => {
      setValue(key, value);
    });
    setIsVideoActive(false);
    onClose();
  }, [onClose, setValue]);

  const handleBackToEditClick = useCallback(() => {
    onOpen();
    hideNotification();
  }, [hideNotification, onOpen]);

  const onSubmit = useCallback(
    (data) => {
      createProjectUpdate({
        ...data,
        project_id: project.id,
      })
        .then(() => {
          successNotification(t('update_created_success'));
          closeWithClear();
          if (updateProjectUpdates) {
            updateProjectUpdates(project.id);
          }
        })
        .catch((e) => {
          Sentry.captureException(
            new Error(
              `Create update failed. Error: ${
                e?.data?.detail ? e.data.detail.join(' ') : 'Unknown error'
              }`,
            ),
          );
          errorNotification(
            <Trans
              ns="projectUpdate"
              i18nKey="update_created_fail"
              components={[
                <Button
                  buttonType="tertiary"
                  className={classes.button}
                  onClick={handleBackToEditClick}
                />,
              ]}
            />,
            { duration: 30000 },
          );
          onClose();
        });
    },
    [
      classes.button,
      closeWithClear,
      createProjectUpdate,
      errorNotification,
      handleBackToEditClick,
      onClose,
      successNotification,
      updateProjectUpdates,
      t,
      project,
    ],
  );

  useSetFieldErrors({
    fieldErrors: createProjectUpdateErrors?.field_errors,
    setError,
    loading: isProjectUpdateCreating,
  });

  return (
    <FormProvider {...methods}>
      <CreateProjectUpdateModal
        {...modalProps}
        onClose={closeWithClear}
        project={project}
        organizationName={profileData?.name}
        isOrganizationDataLoading={isProfileDataLoading}
        onSubmit={handleSubmit(onSubmit)}
        isVideoActive={isVideoActive}
        onOpenVideo={openVideo}
        onCloseVideo={closeVideo}
        isProjectUpdateCreating={isProjectUpdateCreating}
      />
    </FormProvider>
  );
};
