import { createContext, useState, useContext } from 'react';

export const useAppLayoutContextController = () => {
  const [subheader, setSubheader] = useState<JSX.Element>();
  const [headerButtons, setHeaderButtons] = useState<JSX.Element>();
  const [signUpClickScrollTargetRef, setSignUpClickScrollTargetRef] =
    useState<React.MutableRefObject<HTMLElement | null>>();

  return {
    subheader,
    setSubheader,
    headerButtons,
    setHeaderButtons,
    signUpClickScrollTargetRef,
    setSignUpClickScrollTargetRef,
  } as const;
};

export const AppLayoutContext = createContext<ReturnType<
  typeof useAppLayoutContextController
> | null>(null);

export const useAppLayoutContext = () => {
  const value = useContext(AppLayoutContext);
  if (value === null) {
    throw new Error(
      `[App Layout Context] useAppLayoutContext must be called within AppLayoutContext tree`,
    );
  }
  return value;
};

export const AppLayoutContextProvider: React.FC = ({ children }) => {
  const contextController = useAppLayoutContextController();

  return (
    <AppLayoutContext.Provider value={contextController}>{children}</AppLayoutContext.Provider>
  );
};
