import React, { ReactElement } from 'react';
import styles from './Tile.module.scss';
import classnames from 'classnames';

interface TileProps {
  className?: string;
  children?: ReactElement;
}
export const Tile = ({ className, children }: TileProps) => {
  return <div className={classnames(styles.tileContainer, className)}>{children}</div>;
};
