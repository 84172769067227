import { useCallback } from 'react';
import { useRequest } from '@Hooks';
import { API } from '@Services';
import { MemberDetails } from '@Types';

export const useListRecipientsYouCanAssignToMessage = () => {
  const [fetch, response] = useRequest<Array<MemberDetails>>();
  const {
    data: recipients,
    loading: isRecipientsLoading,
    isError: isRecipientsError,
    error: recipientsError,
  } = response;

  const listRecipientsYouCanAssignToMessage = useCallback(() => {
    fetch(API.paths.list_recipients_you_can_assign_to_message, 'get');
  }, [fetch]);

  return [listRecipientsYouCanAssignToMessage, recipients] as const;
};
