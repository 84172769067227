import { useCallback } from 'react';
import { useRequest } from '@Hooks/useRequest';
import { API } from '@Services';
import { ProjectWrittenPitch } from '@Types';

export const useAddProjectWrittenPitch = () => {
  const [fetch, response] = useRequest<ProjectWrittenPitch>();

  const addProjectWrittenPitch = useCallback(
    (projectId: number, data: ProjectWrittenPitch, update?: boolean) =>
      fetch(API.paths.project_written_pitch(projectId), update ? 'patch' : 'post', {
        data,
      }),
    [fetch],
  );

  return [addProjectWrittenPitch, response] as const;
};
