import { useCallback } from 'react';
import { useRequest } from '@Hooks/useRequest';
import { API } from '@Services';
import { Project, PagedResponse, ProjectStatusEnum } from 'Types';

export const useGetOrganizationProjects = () => {
  const [fetch, response] = useRequest<PagedResponse<Project>>();

  const fetchProjectsList = useCallback(
    (organizationId: number, status?: ProjectStatusEnum, statuses?: ProjectStatusEnum[]) => {
      let finalStatus: string | undefined = status;

      if (statuses && statuses.length > 0) {
        finalStatus = statuses.join();
      }

      return fetch(API.paths.organization_projects(organizationId), 'get', {
        params: { status: finalStatus },
      });
    },
    [fetch],
  );

  return [
    fetchProjectsList,
    { ...response, data: response.data ? response.data.results : [] },
  ] as const;
};
