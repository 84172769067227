import { Grid, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { Link } from '@Components/common';

import styles from './ProjectPopup.module.scss';

export const ProjectPopup = ({
  link,
  name,
  orgName,
}: {
  link: string;
  name: string;
  orgName: string;
}) => {
  const { t } = useTranslation('project');

  return (
    <div className={styles.holder}>
      <Grid container direction="row">
        <Grid item xs={12}>
          <Typography className={styles.name}>{name}</Typography>
          <Typography className={styles.by}>
            {t('popup.by')} {orgName}
          </Typography>
          <Typography className={styles.link}>
            <Link href={link}>{t('popup.see_project')}</Link>
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
};
