import { useCallback } from 'react';
import { useRequest } from '@Hooks/useRequest';
import { API } from '@Services';
import { JobSubmissionResponse } from '@Types';

export const useExportSurveyResponsesForFund = () => {
  const [fetch, response] = useRequest<JobSubmissionResponse>();

  const exportSurveyResponsesForFund = useCallback(
    ({ fundId }) =>
      fetch(API.paths.export_survey_responses_for_fund, 'put', {
        data: { fund_id: fundId },
      }),
    [fetch],
  );

  return [exportSurveyResponsesForFund, response] as const;
};
