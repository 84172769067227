import { useCallback } from 'react';
import { useRequest } from '@Hooks';
import { API } from '@Services';
import { MemberDetails } from '@Types';

export const useListMembersYouCanAssignToMessage = () => {
  const [fetch, response] = useRequest<Array<MemberDetails>>();
  const {
    data: members,
    loading: isMembersLoading,
    isError: isMembersError,
    error: membersError,
  } = response;

  const listMembersYouCanAssignToMessage = useCallback(() => {
    fetch(API.paths.list_members_you_can_assign_to_message, 'get');
  }, [fetch]);

  return [listMembersYouCanAssignToMessage, members] as const;
};
