import { useTranslation } from 'react-i18next';
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter';
import PeopleIcon from '@material-ui/icons/People';

import { CategoryTilesController, IconTile } from '@Components';
import { Link } from '@Components/common';
import { formatNumber } from '@Helpers';

import { ProfileSectionHeader } from '../ProfilePage';
import styles from './AboutSection.module.scss';
import Linkify from 'linkify-react';

export interface AboutSectionProps {
  description?: string;
  organizationType?: string;
  organizationNumber?: string;
  setupYear?: number;
  legalStructure?: string;
  staffNumber?: number;
  volunteersNumber?: number;
  memberName1?: string;
  memberEmail1?: string;
  memberName2?: string;
  memberEmail2?: string;
  categoriesIds?: number[];
  totalAmount?: number;
  totalBeneficiaries?: number;
}

export const AboutSection = ({
  description,
  organizationType,
  organizationNumber,
  setupYear,
  legalStructure,
  staffNumber,
  volunteersNumber,
  memberName1,
  memberEmail1,
  memberName2,
  memberEmail2,
  categoriesIds,
  totalAmount,
  totalBeneficiaries,
}: AboutSectionProps) => {
  const { t } = useTranslation('profilePage');
  const { t: tFund } = useTranslation('fund');
  return (
    <>
      <div className={styles.iconTiles}>
        {totalAmount && (
          <IconTile
            labelText={tFund('details_page.total_amount')}
            valueText={tFund('common:currency_format', { amount: totalAmount })}
          >
            <BusinessCenterIcon />
          </IconTile>
        )}
        {totalBeneficiaries && totalBeneficiaries !== 0 && (
          <IconTile
            labelText={tFund('details_page.beneficiaries')}
            valueText={formatNumber(totalBeneficiaries) || ''}
          >
            <PeopleIcon />
          </IconTile>
        )}
      </div>

      <ProfileSectionHeader className={styles.smallMargin}>{t('who_we_are')}</ProfileSectionHeader>

      {description && <h3 className={styles.aboutItemHeader}>{t('description_header')}</h3>}
      <p className={styles.aboutItem}>
        <Linkify>{description}</Linkify>
      </p>

      {organizationType && (
        <h3 className={styles.aboutItemHeader}>{t('organization_type_header')}</h3>
      )}
      <p className={styles.aboutItem}>{organizationType}</p>

      {organizationNumber && (
        <h3 className={styles.aboutItemHeader}>{t('organization_number_header')}</h3>
      )}
      <p className={styles.aboutItem}>{organizationNumber}</p>

      {setupYear && <h3 className={styles.aboutItemHeader}>{t('setup_year_header')}</h3>}
      <p className={styles.aboutItem}>{setupYear}</p>

      {legalStructure && <h3 className={styles.aboutItemHeader}>{t('legal_structure_header')}</h3>}
      <p className={styles.aboutItem}>{legalStructure}</p>

      {typeof staffNumber === 'number' && (
        <h3 className={styles.aboutItemHeader}>{t('staff_number_header')}</h3>
      )}
      <p className={styles.aboutItem}>{formatNumber(staffNumber)}</p>

      {typeof volunteersNumber === 'number' && (
        <h3 className={styles.aboutItemHeader}>{t('volunteers_number_header')}</h3>
      )}
      <p className={styles.aboutItem}>{formatNumber(volunteersNumber)}</p>

      {(memberName1 || memberEmail1 || memberName2 || memberEmail2) && (
        <h3 className={styles.aboutItemHeader}>{t('members_list_header')}</h3>
      )}
      <p className={styles.aboutItem}>
        {memberName1}
        {memberName1 && memberEmail1 && ' - '}
        {memberEmail1 && (
          <Link href={`mailto:${memberEmail1}`} secondary>
            {memberEmail1}
          </Link>
        )}
        {(memberName1 || memberEmail1) && (memberName2 || memberEmail2) && (
          <span className={styles.membersSpacer} />
        )}
        {memberName2}
        {memberName2 && memberEmail2 && ' - '}
        {memberEmail2 && (
          <Link href={`mailto:${memberEmail2}`} secondary>
            {memberEmail2}
          </Link>
        )}
      </p>

      <ProfileSectionHeader>{t('focus_areas')}</ProfileSectionHeader>
      <CategoryTilesController categoriesIds={categoriesIds} />
    </>
  );
};
