import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import styleVariables from '@Styles/_variables.module.scss';
import { formatChartTheme } from '@Helpers/formatChartTheme';

interface StackedBarProps {
  barDataSet: {
    data: (number | null)[];
    name: string;
  }[];
  barLabels: string[];
  title?: string;
  displayAsPercentage: boolean;
  totalAnswers: number;
}

export const StackedBar = ({
  barDataSet,
  barLabels,
  title,
  displayAsPercentage,
  totalAnswers,
}: StackedBarProps) => {
  const options = {
    colors: formatChartTheme(styleVariables.chartsThemeStackedBar),
    chart: {
      type: 'bar',
      style: {
        fontFamily: 'Inter',
      },
    },
    title: {
      text: title,
      style: {
        fontFamily: 'Roboto Slab',
      },
    },
    series: barDataSet,
    xAxis: {
      categories: barLabels,
    },
    tooltip: {
      valueSuffix: displayAsPercentage ? '%' : '',
    },
    yAxis: {
      min: 0,
      max: displayAsPercentage ? 100 : totalAnswers,
      labels: {
        format: displayAsPercentage ? '{value}%' : '{value}',
      },
      allowDecimals: false,
    },
    credits: {
      enabled: false,
    },
    plotOptions: {
      series: {
        stacking: 'normal',
        dataLabels: {
          enabled: true,
          color: '#000',
          format: displayAsPercentage ? '{y}%' : '{y}',
          inside: true,
        },
      },
    },
  };
  return (
    <>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </>
  );
};
