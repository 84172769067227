import { useRequest } from '@Hooks/useRequest';
import { API } from '@Services';
import { FundDetails } from '@Types';
import { useCallback } from 'react';

export const useGetFundDetails = () => {
  const [fetch, response] = useRequest<FundDetails>();

  const getFundDetails = useCallback(
    (fundId: number) => {
      fetch(API.paths.funds_details(fundId), 'get');
    },
    [fetch],
  );

  return [getFundDetails, response] as const;
};
