import React from 'react';
import { useTranslation } from 'react-i18next';
import { Controller, useFormContext } from 'react-hook-form';
import { Autocomplete } from '@material-ui/lab';
import Grid from '@material-ui/core/Grid';

import { TextInput } from '@Components/common';
import { msgFieldRequired } from '@Helpers/errorMessages';
import { VALIDATE_POSTCODE_REGEX } from '@Helpers/fieldValidation';
import style from './AddressSection.module.scss';

const autoCompleteGetOption = (option: string) => option;

interface AddressSectionProps {
  isLoading: boolean;
}

export const AddressSection: React.FC<AddressSectionProps> = ({ isLoading }) => {
  const { t } = useTranslation('common');
  const { register, control, errors: fieldErrors } = useFormContext();

  return (
    <>
      <TextInput
        label={t('address_line', { num: 1 })}
        type="text"
        inputRef={register({ required: msgFieldRequired })}
        name="address1"
        error={!!fieldErrors.address1}
        helperText={fieldErrors.address1?.message}
        disabled={isLoading}
      />

      <TextInput
        label={t('address_line', { num: 2, context: 'optional' })}
        type="text"
        name="address2"
        inputRef={register()}
        error={!!fieldErrors.address2}
        helperText={fieldErrors.address2?.message}
        disabled={isLoading}
      />
      <TextInput
        label={t('address_line', { num: 3, context: 'optional' })}
        type="text"
        name="address3"
        inputRef={register()}
        error={!!fieldErrors.address3}
        helperText={fieldErrors.address3?.message}
        disabled={isLoading}
      />

      <div className={style.addressBar}>
        <Grid container spacing={3}>
          <Grid item xs={8}>
            <TextInput
              label={t('city')}
              inputRef={register({ required: msgFieldRequired })}
              name="city"
              error={!!fieldErrors.city}
              helperText={fieldErrors.city?.message}
              disabled={isLoading}
            />
          </Grid>
          <Grid item xs={4}>
            <TextInput
              label={t('postcode')}
              inputRef={register({
                required: msgFieldRequired,
                pattern: {
                  value: VALIDATE_POSTCODE_REGEX,
                  message: t('invalid_postcode'),
                },
              })}
              name="postcode"
              error={!!fieldErrors.postcode}
              helperText={fieldErrors.postcode?.message}
            />
          </Grid>
        </Grid>
      </div>

      <Grid container spacing={3}>
        <Grid item xs={6}>
          <Controller
            name="county"
            rules={{ required: msgFieldRequired }}
            control={control}
            defaultValue={null}
            render={({ ref, onChange, value }) => (
              <Autocomplete
                value={value}
                onChange={(_, val) => onChange(val)}
                options={t('counties:counties', { returnObjects: true }) as string[]}
                getOptionLabel={autoCompleteGetOption}
                disableClearable
                disabled={isLoading}
                renderInput={(params) => (
                  <TextInput
                    label={t('county')}
                    type="text"
                    error={!!fieldErrors.county}
                    helperText={fieldErrors.county?.message}
                    inputRef={ref}
                    {...params}
                  />
                )}
              />
            )}
          />
        </Grid>
        <Grid item xs={6}>
          <TextInput label={t('country')} value={t('countries.uk')} disabled type="text" />
        </Grid>
      </Grid>
    </>
  );
};
