export enum UserTypeEnum {
  GROUP = 'GROUP_USER',
  FUNDER = 'FUNDER_USER',
}

export const getOppositeUserType = (type: UserTypeEnum) =>
  type === UserTypeEnum.GROUP ? UserTypeEnum.FUNDER : UserTypeEnum.GROUP;

export interface User {
  id: number;
  email: string;
  email_confirmed: boolean;
  name: string;
  user_type: UserTypeEnum;
  has_organization: boolean;
  has_organization_profile: boolean;
  organization_id: number;
  organization_avatar: string;
  organization_name: string;
  progress: number;
  can_modify_organization_profile: boolean;
  can_create_funds: boolean;
  can_create_projects: boolean;
  organization_level_impact_reporting_enabled: boolean;
  selection_voting_enabled: boolean;
  individual_survey_responses_enabled: boolean;
  messaging_details: {
    stream_chat_id: string;
    stream_chat_token: string;
  };
  messaging_enabled: boolean;
  grandparenting_enabled: boolean;
}

export interface PublicUser {
  id: number;
  name: string;
  user_type: UserTypeEnum;
  organization_id: number;
  organization_avatar: string;
  organization_name: string;
}

export interface SameOrganizationUser {
  // Another user in the current user's organization,
  // with minimal information to identify them.
  id: number;
  name: string;
  email: string;
}
