import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { formatChartTheme } from '@Helpers';
import styleVariables from '@Styles/_variables.module.scss';

interface StackedColumnProps {
  yLabel: string;
  data: { name: string; data: number[] }[];
}

export const StackedColumn = ({ yLabel, data }: StackedColumnProps) => {
  const options = {
    colors: formatChartTheme(styleVariables.chartsThemeReport),
    chart: {
      type: 'column',
      style: {
        fontFamily: 'Inter',
      },
    },
    title: {
      text: null,
      style: {
        fontFamily: 'Roboto Slab',
      },
    },
    xAxis: {
      categories: [''],
    },
    yAxis: {
      min: 0,
      allowDecimals: false,
      title: {
        text: yLabel,
      },
    },
    legend: {
      enabled: true,
    },
    tooltip: {
      pointFormat: yLabel + ': <b>{point.y:.0f}</b>',
    },
    plotOptions: { column: { stacking: 'normal' } },
    credits: {
      enabled: false,
    },
    series: data,
  };
  return (
    <>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </>
  );
};
