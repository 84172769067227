import { useCallback } from 'react';
import { useRequest } from '@Hooks';
import { API } from '@Services';
import { ApiErrorObject } from '@Types';

export const useArchiveProject = () => {
  const [fetch, response] = useRequest<null | ApiErrorObject>();

  const archiveProject = useCallback(
    (projectId: number) => fetch(API.paths.project_archive(projectId), 'post'),
    [fetch],
  );

  return [archiveProject, response] as const;
};
