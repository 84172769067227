import { useTranslation } from 'react-i18next';

import BusinessCenterIcon from '@material-ui/icons/BusinessCenter';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import StarIcon from '@material-ui/icons/Star';
import EmojiPeople from '@material-ui/icons/EmojiPeople';

import { Tooltip } from '@Components';
import { Link } from '@Components/common';
import { Category, Project } from '@Types';
import { getProjectPath, getYoutubeThumbnailUrl } from '@Helpers';

import { Card, HeaderImage, Title, TextWithIcon } from './Components';
import styles from './FeaturedProjectCard.module.scss';
import { getHeaderImage } from '@Helpers/getHeaderImage';

export interface FeaturedProjectCardProps {
  className?: string;
  projectData: Project;
  allCategories: Category[];
}

export const FeaturedProjectCard = ({
  className,
  projectData,
  allCategories,
}: FeaturedProjectCardProps) => {
  const { t } = useTranslation('common');

  const {
    id,
    status,
    group_profile: { avatar_thumbnail, name: groupName, organization_id, city },
    details: { name, amount, categories },
    written_pitch,
    video_pitch,
    has_volunteering_opportunity: hasVolunteeringOpportunity,
    user_can_view_volunteering_opportunities: showVolunteering,
  } = projectData;

  const formattedCategories = allCategories
    ? allCategories.reduce((acc: string[], { id, name }) => {
        if (categories.find((categoryId) => id === categoryId)) {
          return [...acc, name];
        }
        return acc;
      }, [])
    : [];

  return (
    <Card className={className}>
      <HeaderImage
        project
        avatar={avatar_thumbnail}
        status={t(`project:status.${status}`)}
        image={getHeaderImage(written_pitch?.image, video_pitch?.video_url_1)}
        video={Boolean(video_pitch)}
      />
      <Title
        project
        id={id}
        name={name}
        organizationId={organization_id}
        organizationName={groupName}
      />
      <div className={styles.spacer} />
      <div className={styles.textWithIconWrapper}>
        <TextWithIcon
          text={t('featured_card.funding_required')}
          icon={BusinessCenterIcon}
          value={t('currency_format', { amount }) as string}
        />
        <TextWithIcon text={t('location.header')} icon={LocationOnIcon} value={city} />
        <TextWithIcon
          text={
            formattedCategories && formattedCategories?.length > 3 ? (
              <Tooltip title={formattedCategories.join(' · ')}>
                <span>{`${formattedCategories[0]} · ${formattedCategories[1]} · ${t(
                  'featured_card.and_more',
                  {
                    amount: formattedCategories.length - 2,
                  },
                )}`}</span>
              </Tooltip>
            ) : (
              formattedCategories?.join(' · ')
            )
          }
          icon={StarIcon}
        />
        {hasVolunteeringOpportunity && showVolunteering && (
          <TextWithIcon text={t('project:volunteering.header')} icon={EmojiPeople} />
        )}
      </div>
      <Link to={getProjectPath(id)} className={styles.bottomLink} primary>
        {t('featured_card.see_project')}
      </Link>
    </Card>
  );
};
