import { useCallback, useState } from 'react';
import dynamic from 'next/dynamic';
import { useForm, FormProvider } from 'react-hook-form';
import { useSetFieldErrors } from '@Hooks';
import { useSetNewPassword } from '@Hooks/Api';
import { useRouter } from '@Helpers';
import { PathParams } from '@App/paths';
import { SetNewPasswordProps, SetNewPasswordStateEnum } from './SetNewPassword';

/** TODO: Workaround for class name hydration issue between server and client */
const SetNewPasswordNoSSR = dynamic<SetNewPasswordProps>(
  () => import('./SetNewPassword').then((mod) => mod.SetNewPassword) as any,
  {
    ssr: false,
  },
);

export const SetNewPasswordController = () => {
  const methods = useForm();
  const { handleSubmit, setError } = methods;
  const [setNewPassword, { loading, error }] = useSetNewPassword();
  const { params } = useRouter();
  const passwordResetToken = params[PathParams.PASSWORD_RESET_TOKEN];
  const [componentState, setComponentState] = useState<SetNewPasswordStateEnum>(
    SetNewPasswordStateEnum.INITIAL,
  );

  const onSubmit = useCallback(
    ({ password }: { password: string }) => {
      if (typeof passwordResetToken === 'string') {
        setNewPassword(password, passwordResetToken)
          .then(() => {
            setComponentState(SetNewPasswordStateEnum.SUCCESS);
          })
          .catch((err) => {
            if (!err?.data?.field_errors) {
              setComponentState(SetNewPasswordStateEnum.ERROR);
            }
          });
      }
    },
    [passwordResetToken, setNewPassword],
  );

  useSetFieldErrors({
    fieldErrors: error?.field_errors,
    setError,
    loading: loading,
  });

  return (
    <FormProvider {...methods}>
      <SetNewPasswordNoSSR
        onSubmit={handleSubmit(onSubmit)}
        isLoading={loading}
        componentState={componentState}
      />
    </FormProvider>
  );
};
