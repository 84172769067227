import React from 'react';

import { Header } from '@Components';
import { useRouter } from '@Helpers/useRouter';
import { UserTypeEnum } from '@Types';

import styles from './AuthenticationLayout.module.scss';

export const AuthenticationLayout: React.FC = ({ children }) => {
  const { params } = useRouter();

  const currUserType = params.userType === 'group' ? UserTypeEnum.GROUP : UserTypeEnum.FUNDER;

  return (
    <div className={styles.container}>
      <Header publicLayout />

      <div
        className={styles.imgWrapper}
        style={{
          backgroundImage: `url(${
            currUserType === UserTypeEnum.GROUP
              ? '/assets/AppLayout/group.jpg'
              : '/assets/AppLayout/funder.jpg'
          })`,
        }}
      />
      <div className={styles.childrenWrapper}>{children}</div>
    </div>
  );
};
