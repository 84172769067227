import React, { useState, useEffect, useCallback } from 'react';
import ReactPlayer from 'react-player/youtube';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';

import LinkIcon from '@material-ui/icons/Link';
import { makeStyles } from '@material-ui/core/styles';

import styleVariables from '@Styles/_variables.module.scss';
import { TextInput } from '@Components/common';
import { VideoPlayer } from '@Components/VideoPlayer';
import { msgFieldRequired } from '@Helpers/errorMessages';
import { InputAdornment } from '@material-ui/core';

interface VideoTextFieldProps {
  field: string;
  handleCloseVideo?: () => void;
}

const useStyles = makeStyles({
  root: {
    color: styleVariables.colorT2Light,
    order: 2,
    marginRight: 2,
  },
});

export const VideoTextField: React.FC<VideoTextFieldProps> = ({ field, handleCloseVideo }) => {
  const { register, errors: fieldErrors, watch } = useFormContext();
  const { t } = useTranslation('project');
  const value = watch(field);

  const classes = useStyles();
  const [isPlayerVisible, setIsPlayerVisible] = useState(false);
  const [inputValue, setInputValue] = useState<string>();

  useEffect(() => {
    setInputValue(value);
  }, [field, value]);

  useEffect(() => {
    if (inputValue) {
      setIsPlayerVisible(ReactPlayer.canPlay(inputValue));
    }
  }, [inputValue]);

  const onCloseVideo = useCallback(() => {
    if (handleCloseVideo) {
      setInputValue('');
      setIsPlayerVisible(false);
      handleCloseVideo();
    }
  }, [handleCloseVideo]);

  return (
    <>
      <TextInput
        name={field}
        label={t('pitch.video_answer_label')}
        style={{
          ...(handleCloseVideo &&
            inputValue &&
            isPlayerVisible && {
              display: 'none',
            }),
        }}
        inputRef={register({
          required: msgFieldRequired,
          validate: {
            validUrl: (value) =>
              ReactPlayer.canPlay(value) || (t('pitch.video_invalid_url') as string),
          },
        })}
        error={!!fieldErrors[field]}
        helperText={fieldErrors[field]?.message}
        InputProps={{
          endAdornment: (
            <InputAdornment classes={classes} position="start">
              <LinkIcon />
            </InputAdornment>
          ),
        }}
        onBlur={() => setInputValue(value)}
      />
      {isPlayerVisible && inputValue && (
        <VideoPlayer
          onRemoveVideo={handleCloseVideo ? onCloseVideo : undefined}
          videoUrl={inputValue}
        />
      )}
    </>
  );
};
