import { useCallback } from 'react';
import { useRequest } from '@Hooks';
import { API } from '@Services';
import { ProjectDetails } from '@Types';

export const useAddProjectDetails = () => {
  const [fetch, response] = useRequest<ProjectDetails>();

  const addProjectDetails = useCallback(
    (projectId: number, data: ProjectDetails, update?: boolean) =>
      fetch(API.paths.project_details(projectId), update ? 'patch' : 'post', {
        data,
      }),
    [fetch],
  );

  return [addProjectDetails, response] as const;
};
