import { useTranslation, Trans } from 'react-i18next';

import PeopleIcon from '@material-ui/icons/People';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import StarIcon from '@material-ui/icons/Star';

import { FundDateProgress, Tooltip } from '@Components';
import { Button, Link } from '@Components/common';

import { Card, HeaderImage, TextWithIcon, Title } from './Components';
import styles from './FeaturedFundCard.module.scss';
import { Category, Fund, LocalAuthority } from '@Types';
import { getFundPath } from '@Helpers';
import { FundCatchmentArea } from '@Components/FundCatchementArea/FundCatchmentArea';

export interface FeaturedFundCardProps {
  className?: string;
  fundData: Fund;
  allCategories: Category[];
  localAuthorities: LocalAuthority[];
}

export const FeaturedFundCard = ({
  className,
  fundData,
  allCategories,
  localAuthorities,
}: FeaturedFundCardProps) => {
  const { t } = useTranslation('common');
  if (!fundData.details || !fundData.funding_profile) {
    return null;
  }
  const {
    id,
    status,
    funding_profile: { avatar_thumbnail, name: funderName, organization_id },
    details: {
      name,
      categories,
      launch_from,
      deadline_to,
      maximum_grant_amount,
      postcode,
      radius,
      catchment_area_type,
    },
  } = fundData;

  const formattedCategories = allCategories
    ? allCategories.reduce((acc: string[], { id, name }) => {
        if (categories.find((categoryId) => id === categoryId)) {
          return [...acc, name];
        }
        return acc;
      }, [])
    : [];

  return (
    <Card className={className}>
      <HeaderImage
        fund
        avatar={avatar_thumbnail}
        status={t(`fund:fund_status.${status}`)}
        image="/assets/Featured/FeaturedFundImage.png"
      />
      <Title
        fund
        id={id}
        name={name}
        organizationName={funderName}
        organizationId={organization_id}
      />
      <div className={styles.spacer} />
      <FundDateProgress
        status={t(`fund:fund_status.${status}`)}
        startDate={launch_from}
        endDate={deadline_to}
        className={styles.progress}
      />
      <div className={styles.textWithIconWrapper}>
        <TextWithIcon
          text={t('featured_card.maximum_grant_amount')}
          icon={PeopleIcon}
          value={t('currency_format', { amount: maximum_grant_amount }) as string}
        />
        <TextWithIcon
          text={t('featured_card.application_format')}
          icon={InsertDriveFileIcon}
          value={
            fundData.details.video_pitch_required
              ? (t('featured_card.video') as string)
              : (t('featured_card.text_and_video') as string)
          }
        />
        <TextWithIcon
          text={t('location.header')}
          icon={LocationOnIcon}
          value={
            <FundCatchmentArea
              catchmentAreaType={catchment_area_type}
              radius={radius}
              postcode={postcode}
              localAuthority={
                localAuthorities.find((la) => la.id === fundData.details.local_authority)?.name
              }
            />
          }
        />
        <TextWithIcon
          text={t('featured_card.focus_area')}
          value={
            formattedCategories && formattedCategories?.length > 2 ? (
              <Tooltip title={formattedCategories.join(' · ')}>
                <span>{`${formattedCategories[0]} · ${formattedCategories[1]} · ${t(
                  'featured_card.and_more',
                  {
                    amount: formattedCategories.length - 2,
                  },
                )}`}</span>
              </Tooltip>
            ) : (
              (formattedCategories?.join(' · ') as string)
            )
          }
          icon={StarIcon}
        />
      </div>
      <Button buttonType="secondary" to={getFundPath(id)}>
        {t('featured_card.see_fund')}
      </Button>
    </Card>
  );
};
