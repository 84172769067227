import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { CTACard, CTABanner, Loader, FeaturedCard } from '@Components';
import { Button } from '@Components/common';
import { useRouter } from '@Helpers';
import { Category, Fund, LocalAuthority, Project } from '@Types';

import styles from './ActionsListPage.module.scss';

interface List {
  data?: Project | Fund;
}

interface ActionsListPageProps {
  projectsList?: Project[];
  categoriesList?: Category[];
  fundsList?: Fund[];
  fetchMore: () => void;
  isFetchMoreLoading: boolean;
  hasNextPage?: boolean;
  isLoading?: boolean;
  headerTitle: string;
  headerSubtitle: string;
  headerButton: string;
  cardText: string;
  cardButtonText: string;
  ctaLink: string;
  localAuthorities?: LocalAuthority[];
}

export const ActionsListPage: React.VFC<ActionsListPageProps> = ({
  projectsList,
  categoriesList,
  fundsList,
  fetchMore,
  isFetchMoreLoading,
  hasNextPage,
  isLoading,
  headerTitle,
  headerSubtitle,
  headerButton,
  cardText,
  cardButtonText,
  ctaLink,
  localAuthorities,
}) => {
  const { t } = useTranslation('common');
  const { push } = useRouter();

  const handleCtaClick = useCallback(
    (path: string) => () => {
      push(path);
    },
    [push],
  );

  const list = projectsList || fundsList;

  if (!list) return null;

  const listToDisplay: List[] = list.map((project) => ({
    data: project,
  }));

  for (let i = 0; i < Math.max(Math.floor(list.length / 11), 1); i++) {
    listToDisplay.splice(12 * i + 9, 0, { data: undefined });
  }

  return (
    <div className={styles.container}>
      <CTABanner
        className={styles.banner}
        title={headerTitle}
        subtitle={headerSubtitle}
        ctaText={headerButton}
        ctaLink={ctaLink}
      />
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <div className={styles.cardsWrapper}>
            {listToDisplay.map(({ data }, idx) => {
              if (data) {
                return (
                  <FeaturedCard
                    key={data.id}
                    className={styles.card}
                    projectData={projectsList ? (data as Project) : undefined}
                    allCategories={categoriesList}
                    fundData={fundsList ? (data as Fund) : undefined}
                    localAuthorities={localAuthorities}
                  />
                );
              }

              return (
                <CTACard
                  key={`CTA-${idx}`}
                  className={styles.card}
                  text={cardText}
                  buttonText={cardButtonText}
                  onCtaClick={handleCtaClick(ctaLink)}
                />
              );
            })}
          </div>
          {hasNextPage && (
            <div className={styles.buttonWrapper}>
              <Button
                buttonType="tertiary"
                disabled={isFetchMoreLoading}
                loading={isFetchMoreLoading}
                onClick={fetchMore}
              >
                {t(`load_more`)}
              </Button>
            </div>
          )}
        </>
      )}
    </div>
  );
};
