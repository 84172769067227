import React, { useState, useEffect, useCallback, useRef } from 'react';
import * as Sentry from '@sentry/react';
import { useTranslation } from 'react-i18next';
import { Loader } from '@Components';
import { useMyFundsList } from '@Hooks/Api';
import { useNotificationsContext, useUserContext } from '@Contexts';
import { FundsSection } from './FundsSection';
import { FundStatusEnum } from '@Types';
import { useArchiveFund } from '@Hooks/Api/useArchiveFund';

const TABS_ENUM = {
  PRE_LAUNCH: 0,
  LAUNCHED: 1,
  IN_DELIVERY: 2,
  COMPLETE: 3,
};

const MAP_STATUS_TO_TAB = {
  [FundStatusEnum.DRAFT]: TABS_ENUM.PRE_LAUNCH,
  [FundStatusEnum.AWAITING_APPROVAL]: TABS_ENUM.PRE_LAUNCH,
  [FundStatusEnum.APPROVED]: TABS_ENUM.PRE_LAUNCH,
  [FundStatusEnum.LIVE]: TABS_ENUM.LAUNCHED,
  [FundStatusEnum.CLOSED]: TABS_ENUM.LAUNCHED,
  [FundStatusEnum.IN_DELIVERY]: TABS_ENUM.IN_DELIVERY,
  [FundStatusEnum.COMPLETE]: TABS_ENUM.COMPLETE,
};

export const FundsSectionController = () => {
  const { t } = useTranslation('dashboard');
  const [currentTab, setCurrentTab] = useState(0);
  const { userData, isUserLoading } = useUserContext();
  // Set this to True to stop tabs switching around automatically when we refresh the project list due to user input.
  const canAutoSetTab = useRef(true);
  const { success: successNotification, error: errorNotification } = useNotificationsContext();
  const [fetchFundsList, { loading: isFundsListLoading, data: fundsList }] = useMyFundsList();
  const [archiveFund] = useArchiveFund();

  useEffect(() => {
    if (userData) {
      fetchFundsList();
    }
  }, [fetchFundsList, userData]);

  const archiveFundCallback = useCallback(
    (fundId: number) => {
      canAutoSetTab.current = false;
      archiveFund(fundId)
        .then(() => {
          successNotification(t('funder.archive_fund_success'));
          fetchFundsList();
        })
        .catch((e) => {
          Sentry.captureException(
            new Error(
              `Archiving fund ${fundId} failed. Error: ${
                e?.data?.detail ? e.data.detail.join(' ') : 'Unknown error'
              }`,
            ),
          );
          errorNotification(t('funder.archive_fund_fail'));
        });
    },
    [archiveFund, successNotification, errorNotification, fetchFundsList, t],
  );

  useEffect(() => {
    if (!fundsList?.length) return;

    const statusesList = fundsList.reduce(
      (acc: FundStatusEnum[], { status }) => (!acc.includes(status) ? [...acc, status] : acc),
      [],
    );

    if (canAutoSetTab.current) {
      if (statusesList.includes(FundStatusEnum.LIVE)) {
        setCurrentTab(TABS_ENUM.LAUNCHED);
      }

      if (statusesList.length === 1) {
        setCurrentTab(MAP_STATUS_TO_TAB[statusesList[0]]);
      }
    } else {
      canAutoSetTab.current = true;
    }
  }, [fundsList]);

  const handleTabChange = (newTab: number) => {
    setCurrentTab(newTab);
  };

  if (isUserLoading || isFundsListLoading) return <Loader />;

  const tabs = fundsList
    ? Object.entries(TABS_ENUM).map(([tabName, tabIndex]) => {
        const funds = fundsList.filter(({ status }) => MAP_STATUS_TO_TAB[status] === tabIndex);

        return {
          title: `${t(`funder.fund_tabs.${tabName}`)}${funds.length ? ` (${funds.length})` : ''}`,
          subtitle: t(`funder.fund_status_desc.${tabName}`),
        };
      })
    : undefined;

  const currentFunds = fundsList?.filter(({ status }) => MAP_STATUS_TO_TAB[status] === currentTab);

  return (
    <>
      <FundsSection
        fundsList={currentFunds}
        currentTab={currentTab}
        tabs={tabs}
        onTabChange={handleTabChange}
        viewAsList={Boolean(fundsList && fundsList.length > 0)}
        archiveFund={archiveFundCallback}
      />
    </>
  );
};
