import React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import LocationCityIcon from '@material-ui/icons/LocationCity';
import { Avatar } from '@Components/common';
import styleVariables from '@Styles/_variables.module.scss';

export interface StyleProps {
  size: string;
}

const useStyles = makeStyles<Theme, StyleProps>((theme) =>
  createStyles({
    root: {
      width: ({ size }) => {
        if (size === 'small') {
          return '40px';
        }

        return '80px';
      },
      height: ({ size }) => {
        if (size === 'small') {
          return '40px';
        }

        return '80px';
      },
      fontSize: '16px',
      textTransform: 'uppercase',
    },
    colorDefault: {
      color: styleVariables.colorT3,
      backgroundColor: styleVariables.colorT3Light,
      backgroundPosition: 'center center',
      backgroundSize: 'cover',
    },
  }),
);

interface OrganizationAvatarProps {
  avatarImage?: string;
  size?: 'small' | 'medium';
  className?: string;
}

export const OrganizationAvatar: React.FC<OrganizationAvatarProps> = ({
  avatarImage,
  children,
  className,
  size,
}) => {
  const classes = useStyles({
    size: size ? size : 'medium',
  });

  const styles: React.CSSProperties = {};
  if (avatarImage) {
    styles.backgroundImage = `url(${avatarImage})`;
  }

  return (
    <Avatar classes={classes} style={styles} className={className}>
      {!avatarImage && (children || <LocationCityIcon style={{ fontSize: '32px' }} />)}
    </Avatar>
  );
};
