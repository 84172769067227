import React, { useCallback, useState } from 'react';
import { useRouter } from '@Helpers/useRouter';
import { useTranslation } from 'react-i18next';
import { RiDeleteBinLine } from 'react-icons/ri';
import { Button } from '@Components/common';
import { useArchiveProject } from '@Hooks/Api/useArchiveProject';
import { useNotificationsContext } from '@Contexts';
import * as Sentry from '@sentry/react';
import { RoutingPaths } from '@App/paths';
import { ConfirmModal } from '@Components';

export interface ArchiveProjectButtonProps {
  id: number;
}

export const ArchiveProjectButton = ({ id }: ArchiveProjectButtonProps) => {
  const { t } = useTranslation('dashboard');
  const [archiveProject] = useArchiveProject();
  const { success: successNotification, error: errorNotification } = useNotificationsContext();
  const [isArchiveProjectModalVisible, setIsArchiveProjectModalVisible] = useState(false);

  const handleArchiveModalShow = useCallback(() => {
    setIsArchiveProjectModalVisible(true);
  }, []);
  const handleArchiveModalHide = useCallback(() => {
    setIsArchiveProjectModalVisible(false);
  }, []);
  const { push } = useRouter();

  const handleArchiveModalConfirm = useCallback(() => {
    archiveProject(id)
      .then(() => {
        successNotification(t('group.archive_project_success'));
        push(RoutingPaths.DASHBOARD_GROUP);
      })
      .catch((e) => {
        Sentry.captureException(
          new Error(
            `Archiving project ${id} failed. Error: ${
              e?.data?.detail ? e.data.detail.join(' ') : 'Unknown error'
            }`,
          ),
        );
        errorNotification(t('group.archive_project_fail'));
      });
    handleArchiveModalHide();
  }, [archiveProject, errorNotification, handleArchiveModalHide, id, push, successNotification, t]);

  return (
    <>
      <Button buttonType="danger" onClick={handleArchiveModalShow} startIcon={<RiDeleteBinLine />}>
        {t('group.archive_modal.confirm')}
      </Button>
      <ConfirmModal
        isOpen={isArchiveProjectModalVisible}
        onClose={handleArchiveModalHide}
        title={t('group.archive_modal.title')}
        cancelText={t('group.archive_modal.cancel')}
        onCancel={handleArchiveModalHide}
        confirmText={t('group.archive_modal.confirm')}
        onConfirm={handleArchiveModalConfirm}
        isConfirmBtnDanger
      >
        {t('group.archive_modal.text')}
      </ConfirmModal>
    </>
  );
};
