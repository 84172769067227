import React, { useCallback } from 'react';
import { CircularProgress, makeStyles } from '@material-ui/core';
import MaterialButton, { ButtonProps as MaterialButtonProps } from '@material-ui/core/Button';
import { useRouter } from '@Helpers/useRouter';
import styleVariables from '@Styles/_variables.module.scss';
import classNames from 'classnames';

export type buttonTypes = 'primary' | 'secondary' | 'tertiary' | 'text' | 'danger' | 'success';

export interface ButtonProps extends MaterialButtonProps {
  loading?: boolean;
  to?: string;
  href?: string;
  progressPercentage?: number;
  buttonType: buttonTypes;
}

export const buttonTypeStyles = {
  primary: {
    color: styleVariables.AFWhite,
    backgroundColor: styleVariables.AFChalkyBlue500,
    '&:hover': {
      backgroundColor: styleVariables.AFChalkyBlue500Hover,
      '@media (hover: none)': {
        backgroundColor: styleVariables.AFChalkyBlue500Hover,
      },
    },
  },
  secondary: {
    color: styleVariables.AFNavyBlue500,
    backgroundColor: styleVariables.AFNavyBlue200,
    '&:hover': {
      backgroundColor: styleVariables.AFNavyBlue200Hover,
      '@media (hover: none)': {
        backgroundColor: styleVariables.AFNavyBlue200Hover,
      },
    },
  },
  tertiary: {
    color: styleVariables.AFDeepBlack,
    backgroundColor: styleVariables.AFSteel400,
    '&:hover': {
      backgroundColor: styleVariables.AFSteel400Hover,
      '@media (hover: none)': {
        backgroundColor: styleVariables.AFSteel400Hover,
      },
    },
  },
  text: {
    textDecoration: 'underline',
    background: 'none',
    '&:hover': {
      textDecoration: 'underline',
      background: 'none',
      '@media (hover: none)': {
        textDecoration: 'underline',
        background: 'none',
      },
    },
  },
  danger: {
    color: styleVariables.AFWhite,
    backgroundColor: styleVariables.AFDanger,
    '&:hover': {
      backgroundColor: styleVariables.AFDangerHover,
      '@media (hover: none)': {
        backgroundColor: styleVariables.AFDangerHover,
      },
    },
  },
  success: {
    color: styleVariables.AFWhite,
    backgroundColor: styleVariables.AFSuccess,
    '&:hover': {
      backgroundColor: styleVariables.AFSuccessHover,
      '@media (hover: none)': {
        backgroundColor: styleVariables.AFSuccessHover,
      },
    },
  },
};

const buttonStyles = makeStyles(buttonTypeStyles);

export const Button = ({
  buttonType,
  loading,
  children,
  to,
  href,
  onClick,
  progressPercentage,
  ...props
}: ButtonProps) => {
  const router = useRouter();
  const { [buttonType]: buttonStyle } = buttonStyles();

  const handleClick = useCallback(() => {
    if (to) {
      router.push(to);
    } else if (href && window) {
      window.open(href, '_blank');
    }
  }, [router, to, href]);

  return (
    <MaterialButton
      {...props}
      className={classNames(props.className, buttonStyle)}
      variant={props.variant || 'contained'}
      disableElevation
      onClick={to || href ? handleClick : onClick}
    >
      {loading ? (
        <CircularProgress color="inherit" size={24} value={progressPercentage} />
      ) : (
        children
      )}
    </MaterialButton>
  );
};
