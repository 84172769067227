import React from 'react';

import styles from './IconTile.module.scss';

export interface IconTileProps {
  labelText: string;
  valueText: string;
  children: React.ReactNode;
}

export const IconTile = ({ labelText, valueText, children }: IconTileProps) => {
  return (
    <div className={styles.tile}>
      <div className={styles.iconWrapper}>{children}</div>
      <div className={styles.tileText}>
        <span>{labelText}</span>
        <b>{valueText}</b>
      </div>
    </div>
  );
};
