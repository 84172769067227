import { Category, ExploreResultsProject } from '@Types';
import styles from './ProjectList.module.scss';
import { Trans, useTranslation } from 'react-i18next';
import { StatusChip } from '@Components';
import { DemoCTAButtons } from './DemoCTAButtons';
import { Link, Tile } from '@Components/common';
import { RoutingPaths } from '@App/paths';
import { RiFilter3Fill, RiMapPin2Fill, RiStarFill } from 'react-icons/ri';
import { ExploreProjectCard } from '@Components/ExploreProjectCard/ExploreProjectCard';

interface ProjectListProps {
  projects?: ExploreResultsProject[];
  limited_project_count?: number;
  total_project_count?: number;
  postcode: string | null;
  category: number[] | null;
  categoriesData?: Category[];
  openFilter: () => void;
  numberOfRequests: number;
  totalNumberOfAllowedRequests: number;
  hideFilters?: boolean;
  hidePadlock?: boolean;
  hideSearchesRemaining?: boolean;
  title?: string;
}

export const ProjectList = ({
  total_project_count = 0,
  limited_project_count = 0,
  projects,
  postcode,
  category,
  categoriesData,
  openFilter,
  numberOfRequests,
  totalNumberOfAllowedRequests,
  hideFilters,
  hidePadlock,
  hideSearchesRemaining,
  title,
}: ProjectListProps) => {
  const { t } = useTranslation('landingPage');

  const totalNumberOfSearchesRemaining =
    (numberOfRequests < totalNumberOfAllowedRequests &&
      totalNumberOfAllowedRequests - numberOfRequests) ||
    0;

  const searchedCategory = categoriesData?.find((obj) => {
    return obj.id === Number(category);
  });

  return (
    <>
      {!hideFilters && (
        <div className={styles.filterContainer}>
          {category && category.length === 1 && (
            <StatusChip
              className={styles.chip}
              icon={<RiStarFill size={16} />}
              label={searchedCategory?.name}
            />
          )}
          {postcode && (
            <StatusChip
              className={styles.chip}
              icon={<RiMapPin2Fill size={16} />}
              label={t('common:postcode_format_uk', { postcode: postcode })}
            />
          )}

          <button type="button" className={styles.filterButton} onClick={openFilter}>
            <RiFilter3Fill />
            <p className={styles.filter}>{t('project_list.manage_filters')}</p>
          </button>
        </div>
      )}
      {title && <h3 className={styles.header}>{title}</h3>}
      <div className={styles.subtitleContainer}>
        <p className={styles.subtitle}>
          {t('project_list.matched_project_count', {
            limited_count: limited_project_count,
            count: total_project_count,
          })}
        </p>
        {!hideSearchesRemaining && (
          <p className={styles.subtitle}>
            {t('project_list.search_count', {
              count: totalNumberOfSearchesRemaining,
            })}
          </p>
        )}
      </div>
      <div className={styles.projectsContainer}>
        {projects ? (
          <>
            {projects.map((project, i) => (
              <ExploreProjectCard project={project} key={i} />
            ))}
            {!hidePadlock && (
              <Tile className={styles.ctaTile}>
                <div className={styles.card}>
                  <img src="/assets/Explore/padlock.svg" alt="Padlock image" />
                  <div className={styles.ctaInfo}>
                    <h2>{t('ctaCard.title')}</h2>
                    <p>{t('ctaCard.subtitle')}</p>
                    <DemoCTAButtons />
                  </div>
                </div>
              </Tile>
            )}
          </>
        ) : (
          <div>
            <Trans
              ns="landingPage"
              i18nKey="project_list.no_projects"
              components={[<Link to={RoutingPaths.EXPLORE} className={styles.link}></Link>]}
            />
          </div>
        )}
      </div>
    </>
  );
};
