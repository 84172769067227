import React from 'react';
import { Chip, makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import WatchLaterIcon from '@material-ui/icons/WatchLater';

import { SelectionStatusEnum, ProjectStatusEnum } from '@Types';
import styleVariables from '@Styles/_variables.module.scss';

import styles from './InvitationStatusChip.module.scss';

const useStyles = makeStyles({
  colorError: {
    backgroundColor: styleVariables.colorT4Light,
    color: styleVariables.colorT4,
  },
  colorPending: {
    backgroundColor: styleVariables.colorT3Light,
    color: styleVariables.colorT3,
  },
  colorApproved: {
    backgroundColor: styleVariables.colorT1Light,
    color: styleVariables.colorT1,
  },
});

interface ChipAttributes {
  style: 'colorError' | 'colorPending' | 'colorApproved';
  icon: JSX.Element;
}

const ERROR_ATTRS: ChipAttributes = {
  style: 'colorError',
  icon: <CancelIcon />,
};
const PENDING_ATTRS: ChipAttributes = {
  style: 'colorPending',
  icon: <WatchLaterIcon />,
};
const APPROVED_ATTRS: ChipAttributes = {
  style: 'colorApproved',
  icon: <CheckCircleIcon />,
};

const MAP_STATES: { [key: string]: ChipAttributes } = {
  [ProjectStatusEnum.FUNDED]: APPROVED_ATTRS,
  [ProjectStatusEnum.COMPLETE]: APPROVED_ATTRS,
  [SelectionStatusEnum.INVITED]: PENDING_ATTRS,
  [SelectionStatusEnum.INVITATION_ACCEPTED]: APPROVED_ATTRS,
  [SelectionStatusEnum.FUNDED]: APPROVED_ATTRS,
  [SelectionStatusEnum.INVITATION_REJECTED]: ERROR_ATTRS,
  [SelectionStatusEnum.PAYMENT_FAILED]: ERROR_ATTRS,
};

interface InvitationStatusChipProps {
  status: SelectionStatusEnum | ProjectStatusEnum;
}

export const InvitationStatusChip: React.FC<InvitationStatusChipProps> = ({ status }) => {
  const classes = useStyles();
  const { t } = useTranslation('fund');

  return (
    <Chip
      label={
        <div className={styles.container}>
          {MAP_STATES[status].icon}
          <span>{t(`management.status_chip.${status}`)}</span>
        </div>
      }
      className={classes[MAP_STATES[status].style]}
    />
  );
};
