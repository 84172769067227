import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';

import DateRangeIcon from '@material-ui/icons/DateRange';
import SettingsIcon from '@material-ui/icons/Settings';
import EditIcon from '@material-ui/icons/Edit';
import InsertChartOutlinedIcon from '@material-ui/icons/InsertChartOutlined';
import { RiDeleteBinLine } from 'react-icons/ri';

import { ConfirmModal, StatusChip } from '@Components';
import { Button, Link } from '@Components/common';
import { Fund, FundStatusEnum } from '@Types';
import { useRouter } from '@Helpers/useRouter';
import { formatDate } from '@Helpers/formatDate';

import styles from './FundCard.module.scss';
import { IconButton } from '@Components/common/Button/IconButton';
import { useDraftFund } from '@Hooks/useDraftFund';
import { useNotificationsContext } from '@Contexts';

export interface FundCardProps {
  fundData: Fund;
  className?: string;
  archiveFund: (fundId: number) => unknown;
}

export const FundCard: React.FC<FundCardProps> = ({ className, fundData, archiveFund }) => {
  const { t } = useTranslation('dashboard');
  const { push } = useRouter();
  const [isArchiveFundModalVisible, setIsArchiveFundModalVisible] = useState(false);
  const [isEditApprovedFundModalVisible, setIsEditApprovedFundModalVisible] = useState(false);
  const [draftFund, { loading: isDraftFundLoading }] = useDraftFund();
  const { error: errorNotification } = useNotificationsContext();

  const {
    id,
    status,
    matches_count: matchesCount,
    details: { starting_amount: totalAmount, name, deadline_to: deadlineTo },
    user_can_manage: userCanManage,
  } = fundData;

  const handleEditClick = () => {
    push(`/fund/${id}/edit`);
  };

  const handleManageClick = () => {
    push(`/fund/${id}/manage`);
  };

  const handleImpactClick = () => {
    push(`/fund/${id}/manage/results`);
  };

  const handleEditApprovedFundConfirm = () => {
    draftFund(id!)
      .then(() => {
        push(`/fund/${id}/edit`);
        handleEditApprovedFundModalHide();
      })
      .catch(({ data }) => {
        if (data.detail[0]) {
          errorNotification(data.detail[0]);
        }
      });
  };

  const handleEditApprovedFundModalShow = useCallback(() => {
    setIsEditApprovedFundModalVisible(true);
  }, []);

  const handleEditApprovedFundModalHide = useCallback(() => {
    setIsEditApprovedFundModalVisible(false);
  }, []);

  const primaryButtons = [];
  const secondaryButtons = [];

  const isEditableStatus = [
    FundStatusEnum.DRAFT,
    FundStatusEnum.AWAITING_APPROVAL,
    FundStatusEnum.APPROVED,
  ].includes(status);

  if (userCanManage) {
    if (isEditableStatus) {
      primaryButtons.push({
        label: t('funder.edit_fund'),
        icon: <EditIcon />,
        onClick:
          status === FundStatusEnum.APPROVED ? handleEditApprovedFundModalShow : handleEditClick,
      });
    } else if (status !== FundStatusEnum.IN_DELIVERY && status !== FundStatusEnum.COMPLETE) {
      primaryButtons.push({
        label: t('funder.manage_fund'),
        icon: <SettingsIcon />,
        onClick: handleManageClick,
      });
    } else {
      primaryButtons.push({
        label: t('funder.view_impact'),
        icon: <InsertChartOutlinedIcon />,
        onClick: handleImpactClick,
      });
      secondaryButtons.push({
        label: t('funder.manage_fund'),
        icon: <SettingsIcon />,
        onClick: handleManageClick,
      });
    }
  }

  const handleArchiveModalShow = useCallback(() => {
    setIsArchiveFundModalVisible(true);
  }, []);

  const handleArchiveModalHide = useCallback(() => {
    setIsArchiveFundModalVisible(false);
  }, []);

  const handleArchiveModalConfirm = useCallback(() => {
    archiveFund(fundData.id);
    handleArchiveModalHide();
  }, [archiveFund, handleArchiveModalHide, fundData]);

  const showArchiveButton = fundData.user_can_archive;

  return (
    <div className={classnames(styles.container, className)}>
      <div className={styles.header}>
        <div className={styles.chipsWrapper}>
          <div className={styles.statusFlag}>{t(`funder.fund_status.${status}`)}</div>
          {matchesCount !== undefined &&
            matchesCount > 0 &&
            status !== FundStatusEnum.IN_DELIVERY &&
            status !== FundStatusEnum.COMPLETE && (
              <Link to={`/fund/${id}/manage`} className={styles.chipLink}>
                <StatusChip
                  label={t('chip_matches_count', { count: matchesCount })}
                  color="primary"
                  className={styles.matchesChip}
                />
              </Link>
            )}
        </div>
      </div>
      {isEditableStatus ? (
        <div className={styles.name}>{name}</div>
      ) : (
        <Link className={styles.name} to={`/fund/${id}`}>
          {name}
        </Link>
      )}
      <span className={styles.amountWrapper}>
        {t('funder.fund_amount')}:
        <b className={styles.amount}> {t('common:currency_format', { amount: totalAmount })}</b>
      </span>
      <span className={styles.dateWrapper}>
        <DateRangeIcon color="inherit" />
        <span className={styles.dateText}>
          {t('funder.fund_due_date')} {formatDate(deadlineTo)}
        </span>
      </span>
      <div className={styles.buttonContainer}>
        {primaryButtons.map(({ label, icon, onClick }, index) => {
          return (
            <Button
              key={index}
              buttonType="primary"
              onClick={onClick}
              startIcon={icon}
              size="small"
              fullWidth
            >
              <span className={styles.fundCtaButton}>{label}</span>
            </Button>
          );
        })}
        {secondaryButtons.map(({ label, icon, onClick }, index) => {
          return (
            <Button
              key={index}
              buttonType="tertiary"
              onClick={onClick}
              startIcon={icon}
              size="small"
              fullWidth
            >
              <span className={styles.fundCtaButton}>{label}</span>
            </Button>
          );
        })}
        {showArchiveButton && (
          <IconButton buttonType="danger" onClick={handleArchiveModalShow} size="small">
            <RiDeleteBinLine />
          </IconButton>
        )}
      </div>

      <ConfirmModal
        isOpen={isArchiveFundModalVisible}
        onClose={handleArchiveModalHide}
        title={t('funder.archive_modal.title')}
        cancelText={t('funder.archive_modal.cancel')}
        onCancel={handleArchiveModalHide}
        confirmText={t('funder.archive_modal.confirm')}
        onConfirm={handleArchiveModalConfirm}
        isConfirmBtnDanger
      >
        {t('funder.archive_modal.text')}
      </ConfirmModal>
      <ConfirmModal
        isOpen={isEditApprovedFundModalVisible}
        onClose={handleEditApprovedFundModalHide}
        title={t('funder.edit_modal.title')}
        cancelText={t('funder.edit_modal.cancel')}
        onCancel={handleEditApprovedFundModalHide}
        confirmText={t('funder.edit_modal.confirm')}
        onConfirm={handleEditApprovedFundConfirm}
      >
        {t('funder.edit_modal.text')}
      </ConfirmModal>
    </div>
  );
};
