import { Trans, useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import { Password, Button, Link } from '@Components/common';
import { msgFieldRequired, useRouter } from '@Helpers';
import styles from './SetNewPassword.module.scss';
import { RoutingPaths } from '@App/paths';

export enum SetNewPasswordStateEnum {
  'INITIAL' = 'INITIAL',
  'SUCCESS' = 'SUCCESS',
  'ERROR' = 'ERROR',
}

export interface SetNewPasswordProps {
  onSubmit: (e?: React.FormEvent<HTMLFormElement | HTMLButtonElement>) => Promise<void>;
  isLoading?: boolean;
  componentState: SetNewPasswordStateEnum;
}

export const SetNewPassword: React.VFC<SetNewPasswordProps> = ({
  onSubmit,
  isLoading,
  componentState,
}) => {
  const { t } = useTranslation('common');
  const { errors: fieldErrors, register } = useFormContext();
  const { push } = useRouter();

  const handleBackToReset = () => {
    push(RoutingPaths.PASSWORD_RESET);
  };

  const handleGoToSignIn = () => {
    push(RoutingPaths.SIGN_IN);
  };

  return (
    <form className={styles.container} onSubmit={onSubmit}>
      <h2 className={styles.header}>
        {componentState === SetNewPasswordStateEnum.INITIAL &&
          t('password_reset.new_password_page_header')}
        {componentState === SetNewPasswordStateEnum.ERROR &&
          t('password_reset.new_password_page_error_header')}
        {componentState === SetNewPasswordStateEnum.SUCCESS &&
          t('password_reset.new_password_page_success_header')}
      </h2>
      <p className={styles.text}>
        {componentState === SetNewPasswordStateEnum.INITIAL &&
          t('password_reset.new_password_page_text')}
        {componentState === SetNewPasswordStateEnum.ERROR && (
          <Trans
            ns="common"
            i18nKey="password_reset.new_password_page_error_text"
            components={[<Link primary href="mailto:hello@actionfunder.org" />]}
          />
        )}
        {componentState === SetNewPasswordStateEnum.SUCCESS &&
          t('password_reset.new_password_page_success_text')}
      </p>
      {componentState === SetNewPasswordStateEnum.INITIAL && (
        <Password
          inputRef={register({ required: msgFieldRequired })}
          showRules
          name="password"
          label={t('password_reset.new_password_label')}
          error={!!fieldErrors.password}
          helperText={fieldErrors.password?.message}
          disabled={isLoading}
        />
      )}
      <div className={styles.buttonsBar}>
        {componentState === SetNewPasswordStateEnum.INITIAL && (
          <Button buttonType="primary" onClick={onSubmit} className={styles.doneButton}>
            {t('password_reset.set_new_password_button')}
          </Button>
        )}
        {componentState === SetNewPasswordStateEnum.ERROR && (
          <Button buttonType="tertiary" onClick={handleBackToReset}>
            {t('password_reset.new_password_page_error_button')}
          </Button>
        )}
        {componentState === SetNewPasswordStateEnum.SUCCESS && (
          <Button buttonType="primary" onClick={handleGoToSignIn}>
            {t('password_reset.new_password_page_success_button')}
          </Button>
        )}
      </div>
    </form>
  );
};
