import { useTranslation } from 'react-i18next';

import { Loader } from '@Components';

import { ActionListItem } from '.';
import styles from './FundsListSection.module.scss';
import { Fund } from '@Types';
import { PaginatedResponse } from '@Hooks/usePaginatedRequest';
import { Pagination } from '@Components/common';

export interface FundsListSectionProps {
  fundsListPaginatedResponse: PaginatedResponse<Fund>;
}

export const FundsListSection = ({ fundsListPaginatedResponse }: FundsListSectionProps) => {
  const { t } = useTranslation('fund');

  const {
    page: fundsList,
    totalCount: totalFundsCount,
    loading: isFundsListLoading,
  } = fundsListPaginatedResponse;

  if (isFundsListLoading) {
    return (
      <div className={styles.container}>
        <Loader />
      </div>
    );
  }

  return (
    <div className={styles.container}>
      {fundsList?.length === 0 ? (
        <span className={styles.noFunds}>{t('profilePage:empty_funds_list')}</span>
      ) : (
        <>
          <h3 className={styles.header}>
            {t('profilePage:funds_list_header', { fundsCount: totalFundsCount })}
          </h3>

          {fundsList?.map(
            ({
              id,
              details: { name, starting_amount, maximum_grant_amount, deadline_to },
              funding_profile: { avatar_image },
              status,
            }) => (
              <ActionListItem
                key={id}
                className={styles.actionListItem}
                name={name}
                link={`/fund/${id}`}
                totalFundAmount={starting_amount}
                maximumGrantAmount={maximum_grant_amount}
                fundDeadline={deadline_to}
                status={t(`fund_status.${status}`)}
                ctaText={t('profilePage:view_fund')}
                organizationAvatar={avatar_image}
                withOrganizationAvatar
              />
            ),
          )}
          <Pagination paginatedResponse={fundsListPaginatedResponse} />
        </>
      )}
    </div>
  );
};
