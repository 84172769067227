import { useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import Script from 'next/script';
import { RiLinkedinBoxFill, RiTwitterXFill, RiFacebookCircleFill } from 'react-icons/ri';

import { Button, Link } from '@Components/common';
import { RoutingPaths } from '@App/paths';

import { NewsletterForm } from './NewsletterForm';
import styles from './Footer.module.scss';

const socials = [
  {
    icon: <RiFacebookCircleFill size={'2em'} />,
    link: 'https://www.facebook.com/ActionFunder',
  },
  {
    icon: <RiLinkedinBoxFill size={'2em'} />,
    link: 'https://www.linkedin.com/company/actionfunder/',
  },
  {
    icon: <RiTwitterXFill size={'2em'} />,
    link: 'https://x.com/ActionFunder',
  },
];

export const Footer: React.FC = () => {
  const { t } = useTranslation('landingPage');

  const footerLinks = useMemo(
    () => [
      {
        title: t('links.business_solutions.title'),
        links: [
          {
            text: t('links.business_solutions.business_solutions'),
            href: RoutingPaths.BUSINESS_SOLUTIONS,
          },
          {
            text: t('links.business_solutions.how_it_works'),
            href: RoutingPaths.HOW_IT_WORKS_FUNDERS,
          },
          // ? Disabled until marketing finishes the pages
          // {
          //   text: t('links.business_solutions.case_studies'),
          //   href: RoutingPaths.CASE_STUDIES,
          // },
          // {
          //   text: t('links.business_solutions.prices_and_features'),
          //   href: RoutingPaths.PRICES_AND_FEATURES,
          // },
          {
            text: t('links.business_solutions.explore'),
            href: RoutingPaths.EXPLORE,
          },
        ],
      },
      {
        title: t('links.non_profits.title'),
        links: [
          {
            text: t('links.non_profits.non_profits'),
            href: RoutingPaths.NON_PROFITS,
          },
          {
            text: t('links.non_profits.how_it_works'),
            href: RoutingPaths.HOW_IT_WORKS_GROUPS,
          },
        ],
      },
      {
        title: t('links.more_info.title'),
        links: [
          {
            text: t('links.more_info.about'),
            href: RoutingPaths.ABOUT,
          },
          {
            text: t('links.more_info.resources'),
            href: RoutingPaths.RESOURCES,
          },
          {
            text: t('links.more_info.faq'),
            href: RoutingPaths.FAQS,
          },
        ],
      },
      {
        title: t('links.take_action.title'),
        links: [
          {
            text: t('links.take_action.sign_in_sign_up'),
            href: RoutingPaths.SIGNUP_GROUP,
          },
          {
            text: t('links.take_action.request_demo'),
            href: RoutingPaths.REQUEST_DEMO,
          },
          {
            text: t('contact'),
            href: RoutingPaths.CONTACT,
          },
        ],
      },
    ],
    [t],
  );

  return (
    <div className={styles.footer}>
      <Script
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
          (function(e,t,o,n,p,r,i){e.visitorGlobalObjectAlias=n;e[e.visitorGlobalObjectAlias]=e[e.visitorGlobalObjectAlias]||function(){(e[e.visitorGlobalObjectAlias].q=e[e.visitorGlobalObjectAlias].q||[]).push(arguments)};e[e.visitorGlobalObjectAlias].l=(new Date).getTime();r=t.createElement("script");r.src=o;r.async=true;i=t.getElementsByTagName("script")[0];i.parentNode.insertBefore(r,i)})(window,document,"https://diffuser-cdn.app-us1.com/diffuser/diffuser.js","vgo");
          vgo('setAccount', '609968956');
          vgo('setTrackByDefault', true);

          vgo('process');
        `,
        }}
      />

      {/* SECONDARY FOOTER */}
      <div className={styles.secondaryFooter}>
        <div className={styles.secondaryFooterContentContainer}>
          <div className={styles.demoContainer}>
            <h3 className={styles.title}>{t('funders.title')}</h3>
            <p>{t('funders.subtitle')}</p>
            <Button buttonType="secondary" to={RoutingPaths.REQUEST_DEMO} className={styles.btn}>
              {t('book_a_demo')}
            </Button>
          </div>
          <div className={styles.signupContainer}>
            <h3 className={styles.title}>{t('nonprofit.title')}</h3>
            <p>{t('nonprofit.subtitle')}</p>
            <Button buttonType="secondary" to={RoutingPaths.SIGNUP_GROUP} className={styles.btn}>
              {t('nonprofit.sign_up')}
            </Button>
          </div>
        </div>
      </div>

      {/* MAIN FOOTER */}
      <div className={styles.mainFooter}>
        <div className={styles.footerContentContainer}>
          {/* This is the logo and socials */}
          <div className={styles.logoSocialsContainer}>
            <div className={styles.logoContainer}>
              <img src="/assets/Logo/primaryLogoStackedWithStrapline.svg" alt="ActionFunder Logo" />
              <img
                src="/assets/Footer/logo.svg"
                className={styles.certifiedLogo}
                alt="Footer logo"
              />
            </div>
            <div className={styles.socials}>
              {socials.map(({ icon, link }) => (
                <Link href={link} key={link}>
                  {icon}
                </Link>
              ))}
            </div>
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', flex: '2' }}>
            <div>
              <NewsletterForm />
            </div>
            <div className={styles.linksColumn}>
              {footerLinks.map(({ title, links }) => (
                <div className={styles.links} key={title}>
                  <strong className={styles.linksColumnTitle}>{title}</strong>
                  {links.map(({ href, text }) => (
                    <Link to={href} key={text} className={styles.link}>
                      {text}
                    </Link>
                  ))}
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className={styles.copyright}>
          <Trans
            ns="landingPage"
            i18nKey="copyrights"
            components={[
              <Link href={RoutingPaths.PRIVACY_POLICY} primary />,
              <Link href={RoutingPaths.COOKIE_POLICY} primary />,
              <Link href={RoutingPaths.TERMS_OF_USE} primary />,
            ]}
          />
        </div>
      </div>
    </div>
  );
};
