import { useCallback } from 'react';
import { useRequest } from '@Hooks/useRequest';
import { API } from '@Services';
import { ProjectVideoPitch } from '@Types';

export const useAddProjectVideoPitch = () => {
  const [fetch, response] = useRequest<ProjectVideoPitch>();

  const addProjectVideoPitch = useCallback(
    (projectId: number, data: ProjectVideoPitch, update?: boolean) =>
      fetch(API.paths.project_video_pitch(projectId), update ? 'patch' : 'post', {
        data,
      }),
    [fetch],
  );

  return [addProjectVideoPitch, response] as const;
};
